import { Button, Chip } from '@mui/material';
import React from 'react';

function MoreBtn({onClick}) {
    return (
        <Chip
        variant='outlined'
         label="MORE >>"
         sx={{
            cursor:"pointer",
            marginLeft:"30px"
         }}
         onClick={onClick}
        />
    );
}

export default MoreBtn;