import React from 'react';

function BoardTxtArea({
    readOnly=false,
    width,height,value,onChange}) {
    return (
        <textarea
        disabled={readOnly}
        placeholder='글내용'
        style={{
            width:width,
            height:height,
            resize:"none",
            borderRadius:"8px",
            paddingLeft:"8px",
            border:"2px solid #104A62"
        }}
        value={value}
        onChange={onChange}
        />
    );
}

export default BoardTxtArea;