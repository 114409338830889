import React from 'react';
import { lgFontSize } from '../../const/const';
import HorizontalDivider from '../../components/HorizontalDivider';
import CustomWriteBtn from '../Btn/CustomWriteBtn';

function TitleBar({title,goWritePage,useBtn=true}) {
    return (
        <div
            style={{
                display:'flex',
                flexDirection:"column",
                width:"100%"
            }}
            ><div
            style={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"flex-end"
            }}
            ><span
                style={{
                    fontSize:lgFontSize
                }}
                >{title}</span> 
              {useBtn&&  <CustomWriteBtn onClick={goWritePage}/>}
                </div>
                
                <HorizontalDivider/></div>
    );
}

export default TitleBar;