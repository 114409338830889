import { Button, Card, CardContent, List, ListItem, TextField } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../../atom/atom';
import { useNavigate, useParams } from 'react-router-dom';
import MoreBtn from '../Btn/MoreBtn';
import MainReply from '../MainReply';

function ChatCard({list,reply,replys,
    onChangeReply,
    onEnterReply}) {
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
 const navigate=useNavigate()
 const params=useParams()
 console.log("replys>>>",replys)
    return (
        <Card 
        variant='outlined'
        sx={{
          height:"100%"
        }}
        >
          <CardContent
           sx={{
            padding:"10px",
            margin:0,
          }}
          >
            <div
            style={{
                height:"40px",
                fontSize:"20px",
                display:"flex",
                alignItems:"center",
                fontWeight:600,
           
            }}
            >{} 광장
            
            <MoreBtn
            onClick={()=>{
              navigate(`/${params.fullAddress}/${params.si}/${params.dong}/community-table/meeting-place/1`)
           
            }}
            /></div>
              <List sx={{
                padding:0,
                margin:0,
                // display:"flex",
                // flexDirection:"column",
                // gap:"3px",
               overflowY:"scroll",
               height:"calc(32px * 3.5)",
            width: '100%', bgcolor: 'background.paper' }}>
         
{       replys.map(r=><Card
variant='outlined'
sx={{
    padding:"2px",
    display:"flex",
    alignItems:"center"
}}
                      >
                        
                        <MainReply 
                      reply={r}/>
                       </Card>)}
    
    </List>  <TextField 
      size='small'
      sx={{
        width:"100%"
      }}
                value={reply}
                onChange={onChangeReply}
                onKeyDown={onEnterReply}
                placeholder='댓글 입력후 엔터' />
        </CardContent></Card>
    );
}

export default ChatCard;