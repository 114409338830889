import { atom } from "recoil";
export const openAreaDialogState=atom({
  key: "openAreaDialog",
  default:false
})
// export const mainLoadingState = atom({
//   key: "mainLoading",
//   default:null
// }); 
//임시 디폴트값
export const nowAreaState = atom({
  key: "nowArea",
  // default:{fullAddress:null,si:null,dong:null}
  default:{fullAddress:"국립강릉원주대학교 강릉캠퍼스",si:"강릉시",dong:"지변동"}
}); 
export const nowAreaDialogState = atom({
  key: "dialog",
  default: ""
});
export const latestPots=atom({
  key:"latestPots",
  default:[]
})
export const deliveryPots=atom({
  key:"deliveryPots",
  default:[]
})
export const taxiPots=atom({
  key:"taxiPots",
  default:[]
})
// export const nowPotNameState=atom({
//   key:"nowPotName",
//   default:""
// })
export const favListState=atom({
  key:"favList",
  default:[]
})
export const mainLoadingState= atom({

  key:"mainLoading",
  default:false
})
export const loginDialogState=atom({
  key:"loginDialog",
  default:false
})
export const siState=atom({
  key:"si",
  default:[]
})
export const sidoState=atom({
  key:"sido",
  default:[]

})