import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import TitleBar from '../components/TopBar/TitleBar';
import CustomTable from '../components/Table/CustomTable';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../atom/atom';
import PageBtns from '../components/Btn/PageBtns';
import MbPageBtns from '../components/Btn/MbPageBtns';
import { postsPerPage, convertToDateType, mdWidth, pcEmpty, smWidth } from '../const/const';

function InquiryPage(props) {
  const [inquiryPosts, setInquiryPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const postsCol = collection(db, "inquiry");
  const params = useParams();
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const navigate = useNavigate();

  const fetchPosts = async () => {
    try {
      const querySnapshot = await getDocs(postsCol);
      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ docId: doc.id, ...doc.data() });
      });
      return posts;
    } catch (error) {
      console.error("Error fetching posts: ", error);
      return [];
    }
  };

  const sortList = (dataList) => {
    return dataList.sort((a, b) => {
      const dateA = convertToDateType(a.registerDate).getTime();
      const dateB = convertToDateType(b.registerDate).getTime();
      return dateB - dateA; // 최신글이 상단에 오도록 정렬
    });
  };

  const fetchAllPosts = async () => {
    const posts = await fetchPosts();
    const sortedPosts = sortList(posts);
    const batchedPosts = [];

    for (let i = 0; i < sortedPosts.length; i += postsPerPage) {
      batchedPosts.push(sortedPosts.slice(i, i + postsPerPage));
    }

    setInquiryPosts(batchedPosts);
  };

  useEffect(() => {
    fetchAllPosts();
  }, []);

  const handlePageChange = (index) => {
    setCurrentPage(index);
  };

  const handleNextPage = () => {
    if (currentPage < inquiryPosts.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(inquiryPosts.length - 1);
  };

  const goWritePage = () => {
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/inquiry-write`);
  };

  const currentPosts = inquiryPosts[currentPage] || [];
  const totalPosts = currentPage * postsPerPage + currentPosts.length;
  const startNumber = totalPosts;

  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
      {/* PC UI */}
      <PC>
        <div style={{ width: mdWidth, minWidth: "900px" }}>
          <div style={{ height: pcEmpty }} />
          <TitleBar title={"문의사항"} goWritePage={goWritePage} />
          <CustomTable
            prevPgs={currentPage < 1 ? 0 : 20 * currentPage}
            currentPage={currentPage}
            list={currentPosts}
            heads={[
              { name: "글번호", width: "5%", align: "center" },
              { name: "제목", width: "70%", align: "left" },
              { name: "글쓴이", width: "10%", align: "center" },
              { name: "작성일", width: "15%", align: "center" },
            ]}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px"
            }}
          >
            <PageBtns
              length={inquiryPosts.length}
              nowIdx={currentPage}
              handleFirstBtn={handleFirstPage}
              handlePrevBtn={handlePrevPage}
              handleNextBtn={handleNextPage}
              handleLastBtn={handleLastPage}
              onClick={handlePageChange}
            />
          </div>
        </div>
      </PC>

      {/* Mobile UI */}
      <Mobile>
        <div style={{ width: smWidth, minWidth: "280px" }}>
          <div style={{ height: pcEmpty }} />
          <TitleBar title={"문의사항"} goWritePage={goWritePage} />
          <div>
            {currentPosts.map((item, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  backgroundColor: "#f9f9f9",
                  cursor: "pointer"
                }}
                onClick={() => {
                  sessionStorage.setItem("inquiry", JSON.stringify(item));
                  navigate(`/${params.fullAddress}/${params.si}/${params.dong}/inquiry-show`);
                }}
              >
                <strong>글번호: {startNumber - idx}</strong>
                <p>{item.type === '1' ? '비공개' : item.title} ({item.replies ? item.replies.length : 0})</p>
                <p>글쓴이: {item.id}</p>
                <p>작성일: {item.registerDate}</p>
              </div>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px"
            }}
          >
            <MbPageBtns
              length={inquiryPosts.length}
              nowIdx={currentPage}
              handleFirstBtn={handleFirstPage}
              handlePrevBtn={handlePrevPage}
              handleNextBtn={handleNextPage}
              handleLastBtn={handleLastPage}
              onClick={handlePageChange}
            />
          </div>
        </div>
      </Mobile>
    </div>
  );
}

export default InquiryPage;
