import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import React from 'react';
import './Dialog.css';
function MembershipInfoEditDialog({open,onClose,nickname,email,onChangeNickname,
    onChangeEmail,
onSaveComplete}) {
    return (
        <Dialog
        open={open}
        onClose={onClose}
        >
            <DialogContent
            className='edit-content'
            >
                <Typography variant='h6'>회원정보 변경</Typography>
                    <div
                    className='edit-row'
                    >
                        <div
                        className='edit-title'
                        >닉네임</div>
                        <TextField
                    size='small'
                    className='edit-txtfield'
                    value={nickname}
                    onChange={onChangeNickname}
                    /></div>
                    {/* <div
                    className='edit-row'> 
                     <div
                        className='edit-title'
                        >이메일</div>
                    <TextField
                    size='small'
                    className='edit-txtfield'
                    value={email}
                    onChange={onChangeEmail}
                    /></div> */}
                   
            </DialogContent>
            <DialogActions>
        <Button
        variant='contained'
        className='edit-complete-btn'
        sx={{
            backgroundColor:"#002D82",
            ":hover":{
                backgroundColor:"#002D82"
            }
        }}
        onClick={onSaveComplete}
        >변경 완료</Button>

            </DialogActions>
        </Dialog>
    );
}

export default MembershipInfoEditDialog;