import React from 'react';
import DefaultBtn from "../components/DefaultBtn"
import OutlinedBtn from '../components/OutlinedBtn';
import { useNavigate } from 'react-router-dom';
import MainLogo from "../images/main-logo.png"
import { mainColor, secondaryColor } from '../const/const';
function InitPage(props) {
    const navigate=useNavigate()

    const goPage=(e)=>{
        const pageName=e.target.id
        navigate(`/${pageName}`)
    }
    
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            width:"100%",
            height:"100vh",
            justifyContent:"center"
          }}
        >
            <div
            style={{
                width:"100%",
                height:"100%",
                display:"flex",
                flexDirection:"column",
                // backgroundColor:"red",
                alignItems:"center",
                justifyContent:"center"
            }}
            ><img
            style={{
                paddingTop:"370px"
            }}
            width={"153px"}
            src={MainLogo}
            />
            <div
            style={{
                fontSize:"40px",
                fontWeight:700,
                color:mainColor
            }}
            >같이 시켜</div>
            <div
            style={{
                fontWeight:700,
                fontSize:"16px",
                color:secondaryColor
            }}
            >뭉치면 싸지는 배달비</div>
            </div>
            
            <DefaultBtn 
            width='90%'
            id={"email-continue"}
            title={"이메일로 계속하기"}
            onClick={goPage }
            />
            <div
            style={{
                height:"16px"
            }}
            />
            <OutlinedBtn
            width='90%'
            title={"둘러보기"}
            />
              <div
            style={{
                height:"32px"
            }}
            />
             <div
            style={{
                height:"32px"
            }}
            />
        </div>
    );
}

export default InitPage;