import React from 'react';
import { Button } from '@mui/material';
import { goBtnSx } from '../../const/BtnStyle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useParams } from 'react-router-dom';

function GoTaxiPotBtn({ active }) {
    const isMobile = useMediaQuery('(max-width:767px)');
    const navigate=useNavigate()
    const params=useParams()
console.log("is mb>>>",isMobile)
    return (
        <Button
            variant='outlined'
            sx={{width:"160px",
                height:"40px",
                color: "white",
                fontSize: isMobile?"14px":"16px",
                backgroundColor:  "white" , // 선택됐을 때 배경색 변경
                color:"#104A6E"  ,
                border:"1px solid #104A6E",
                ":hover": {
                    backgroundColor: "#104A6E",
                    color: "white",
                }
            }}
            onClick={()=>{
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/taxi-table/meeting-place/1`);
            }}
        >
            택시팟
        </Button>
    );
}

export default GoTaxiPotBtn;
