import React, { useState } from 'react';
import BorderTxtField from './BorderTxtField';
import WithLabelTxtField from './WithLabelTxtField';
import { Checkbox, Popover, Typography } from '@mui/material';
import { BsArrowBarUp, BsArrowRight } from 'react-icons/bs';
import { BiArrowFromTop, BiUpArrow } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';
import { communityValues, mdWidth } from '../const/const';
import HourSelect from './HourSelect';
import MinuteSelect from './MinuteSelect';
import DottedHorizontalDivider from './DottedHorizontalDivider';

function CommunityWriteContent({
    useT,
    setUseT,
  foodCategory,
  onChangeFoodCategory,
  goDate,
  goSiTime,
  goBunTime,
  newChatLink,
  onChangeOpenChat,
  newContent,
  onChangeNewContent,
  inputFormatDate,
  setGoDate,
  handleInpuBunChange,
  handleInpuSiChange,
  nowCommunityCategory,onChangeCommunityCategory,
  allWidth,userId,pw,
  onChangeId,onChangePw
}) {
    // const [popup,setPopup]=useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const onClickDropdown=(e)=>{

        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
      };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div
        style={{
            width: allWidth,
            display: "flex",
            flexDirection:"column"
        }}
        
        >
                    <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: allWidth===mdWidth?"22px":"6px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField 
                width='100%'
                placeholder={"아이디"} 
                value={userId}
                onChange={onChangeId}
                />
                 <BsArrowRight
                 style={{
                    opacity:0
                 }}
                 />
                    <BorderTxtField
                    width='100%'
                    placeholder={"비밀번호"} 
                    value={pw}
                    onChange={onChangePw}
                    />
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    </div>
               
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div
                    style={{
                        width:"193px",
                        height:"36px",
                        borderRadius:"8px",
                        paddingLeft:"8px",
                        border:"2px solid #104A62",
                        display:"flex",
                        alignItems:"center"
                    }}
                    >
                        <span
                        style={{
                            width:"120px",
                            height:"100%",
                            display:"flex",
                            alignItems:"center",
                            borderRight:"2px solid #104A62",
                        }}
                        >카테고리</span>
                        
                        <button
                        style={{
                            height:"100%",
                            width:"100%",
                            backgroundColor:"transparent",
                            border:"none",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-evenly"
                        }}
                        onClick={onClickDropdown}
                        ><span>{nowCommunityCategory}</span>
                       <IoIosArrowDown size={20} />
                        </button>
                        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
<ul
style={{
    margin:0,
    padding:"16px",
    listStyle:"none",
    width:"160px",
}}
>
    {communityValues.map(c=><li
    style={{
        height:"30px",
        paddingTop:"8px",
        paddingBottom:"8px",
        display:"flex",
        alignItems:"center",
        borderBottom:"1px solid #104A62"
    }}
    >
        <button
        id={c}
        style={{
            display:"flex",
            justifyContent:"flex-start",
            width:"100%",
            backgroundColor:"transparent",
            border:"none",
            cursor:"pointer"
        }}
        onClick={onChangeCommunityCategory}
        >{c}</button>
        
        </li>)}
</ul>
      </Popover>
                        </div>
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
             
                     <DottedHorizontalDivider/>
                     <div
                    style={{
                        height: "22px"
                    }}
                />
                  <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: allWidth===mdWidth?"22px":"6px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                     <Checkbox
                checked={useT}
                onChange={(e)=>{
                    setUseT(e.target.checked)}
                }
                />
                    <span
                    style={{
                        minWidth:"60px"
                    }}
                    >일정</span>
                    
                    <input type='date' 
                       readOnly={!useT}
                    style={{
                        minWidth:"100px"
                      }}
                value={inputFormatDate(goDate)}
                onChange={(e)=>{
                    setGoDate(new Date(e.target.value))
                }}
                />
               <HourSelect
               disabled={!useT}
                value={goSiTime}
                onChange={handleInpuSiChange}
                />
                시
                <MinuteSelect
                      disabled={!useT}
                value={goBunTime}
                onChange={handleInpuBunChange}
                />
                분
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                     <DottedHorizontalDivider/>
                     <div
                    style={{
                        height: "22px"
                    }}
                />
             
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField
                         width='100%'
                        placeholder={"(선택) 오픈채팅 링크"}
                        value={newChatLink}
                        onChange={onChangeOpenChat}
                    /></div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><textarea
                value={newContent}
                onChange={onChangeNewContent}
                        style={{
                            height: "200px",
                            width: "100%",
                            border:"2px solid #104A62",
                            borderRadius:"8px",
                            resize:"none"
                        }}
                    />
                    </div>
               
               
              
        </div>
    );
}

export default CommunityWriteContent;