import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import BoardBtn from './BoardBtn';
import { postsPerPage } from '../const/const';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../atom/atom';
import { useLocation, useNavigate } from 'react-router-dom';

function BoardBtns({margin,setCurrentPage,currentPage,totalPageLength}) {
    const navigate=useNavigate()
    const location=useLocation()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    // const [selNum,setSelNum]=useState("1")
    // const arr=Array.from(Array(10), (_, index) => index + 1);
    // const postsPerPage = 10;
    const pageNumbers = [];
for (let i = 1; i <= Math.ceil(totalPageLength / postsPerPage); i++) {
  pageNumbers.push(i);
}

// 최대 10개의 페이지 버튼만 보여주도록 제한
// 
const [pgIdx,setPgIdx]=useState(1)
useEffect(()=>{
const pgIdx=Math.ceil(currentPage / postsPerPage)
setPgIdx(pgIdx)
},[currentPage])
// const tempMax=2
// const pageNumbers2 = Array.from({ length: Math.ceil(totalPageLength/postsPerPage) }, (_, index) => index + 1);
const renderPageNumbers = pageNumbers.map(number => {
  return (
    <li key={number}>
    <BoardBtn
        id={(number).toString()}
        selNum={currentPage}
        onClick={()=>{setCurrentPage(number)

// const exceptNumPath=location.pathname.split("table/")[0]
// return;
// if (sessionStorage.getItem("postType")==="meetingPlace") {
    // navigate(`${exceptNumPath}table/${number}`,{state:location.state})
// }
// else if(sessionStorage.getItem("postType")==="si"){

//     // navigate(`/${number}/${nowAddress.si}`,{state:{
//     //     nowAddress:nowAddress,
//     //     nowPotName:"delivery",
//     //     postType:location.state.postType,
//     //     ownerUserId:location.state.ownerUserId,
//     //    //  favorites:location.state.favorites
//     //    // ...location.state
//     // }})
// }
// else if(sessionStorage.getItem("postType")==="sidong"){

//     navigate(`/${number}/${nowAddress.dong}`)
// }
// else{
//     return
// }
        }}
        />
    </li>
  );
});
const onClickNext=(currentPg,tableLength)=>{
        const maxPage=Math.ceil(tableLength / postsPerPage)
        if (currentPg===maxPage) {
            return;
        }else{
           setCurrentPage(p=>p+1) 
        }
        }
    return (
        <ul
        style={{
            marginTop:margin,
            marginBottom:margin,
            listStyle:"none",
            display:"flex",
            flexDirection:"row",
            padding:0,
            // margin:0,
            gap:"10px"
        }}
        >
           <li><button
        className='boardBtn'
        style={{
            backgroundColor:"transparent",
            border:`1px solid #104A62`,
            cursor:"pointer"
        }}
        onClick={()=>{setCurrentPage(1)}}
        ><RxDoubleArrowLeft
        color={"#104A62"}
        
        /></button></li>
             <li><button
        className='boardBtn'
        style={{
            backgroundColor:"transparent",
            border:`1px solid #104A62`,
            cursor:"pointer"
        }}
        onClick={()=>{
            if (currentPage===1) {
                return;
            }else{
               setCurrentPage(p=>p-1) 
            }
            }}
        ><IoIosArrowBack
        color={"#104A62"}
        /></button></li>
      {renderPageNumbers}
    <li><button
        className='boardBtn'
        style={{
            backgroundColor:"transparent",
            border:`1px solid #104A62`,
            cursor:"pointer"
        }}
        onClick={(e)=>{onClickNext(currentPage,totalPageLength)}} 
        ><IoIosArrowForward
        color={"#104A62"}
        
        /></button></li>
        <li><button
        className='boardBtn'
        style={{
            backgroundColor:"transparent",
            border:`1px solid #104A62`,
            cursor:"pointer"
        }}
        onClick={()=>{
            const maxPage=Math.ceil(totalPageLength / postsPerPage)
            setCurrentPage(maxPage)
        }}
        ><RxDoubleArrowRight
        color={"#104A62"}
        /></button></li>
         </ul>
    );
}

export default BoardBtns;

// function BoardBtns({table,setCurrentPage,currentPage}) {
//     // const [selNum,setSelNum]=useState("1")
//     const arr=Array.from(Array(10), (_, index) => index + 1);
//     // const postsPerPage = 10;
//     const pageNumbers = [];
// for (let i = 1; i <= Math.ceil(table.length / postsPerPage); i++) {
//   pageNumbers.push(i);
// }

// // 최대 10개의 페이지 버튼만 보여주도록 제한
// // 
// const [pgIdx,setPgIdx]=useState(1)
// useEffect(()=>{
// const pgIdx=Math.ceil(currentPage / postsPerPage)
// setPgIdx(pgIdx)
// },[currentPage])
// console.log("page idx",pgIdx)
// // const tempMax=2
// const renderPageNumbers = pageNumbers.slice(postsPerPage*(pgIdx-1), (postsPerPage*pgIdx)).map(number => {
//   return (
//     <li key={number}>
//     <BoardBtn
//         id={(number).toString()}
//         selNum={currentPage}
//         onClick={()=>{setCurrentPage(number)}}
//         />
//     </li>
//   );
// });
//     return (
//         <ul
//         style={{
//             listStyle:"none",
//             display:"flex",
//             flexDirection:"row",
//             padding:0,
//             margin:0,
//             gap:"10px"
//         }}
//         >
//            <li><button
//         className='boardBtn'
//         style={{
//             backgroundColor:"transparent",
//             border:`1px solid #104A62`,
//             cursor:"pointer"
//         }}
//         onClick={()=>{setCurrentPage(1)}}
//         ><RxDoubleArrowLeft
//         color={"#104A62"}
        
//         /></button></li>
//              <li><button
//         className='boardBtn'
//         style={{
//             backgroundColor:"transparent",
//             border:`1px solid #104A62`,
//             cursor:"pointer"
//         }}
//         onClick={()=>{
//             if (currentPage===1) {
//                 return;
//             }else{
//                setCurrentPage(p=>p-1) 
//             }
//             }}
//         ><IoIosArrowBack
//         color={"#104A62"}
//         /></button></li>
//       {renderPageNumbers}
//     <li><button
//         className='boardBtn'
//         style={{
//             backgroundColor:"transparent",
//             border:`1px solid #104A62`,
//             cursor:"pointer"
//         }}
//         onClick={()=>{
//             const maxPage=Math.ceil(table.length / postsPerPage)
//             console.log("다음버튼",currentPage,maxPage)
//             if (currentPage===maxPage) {
//                 return;
//             }else{
//                setCurrentPage(p=>p+1) 
//             }
//             }} 
//         ><IoIosArrowForward
//         color={"#104A62"}
        
//         /></button></li>
//         <li><button
//         className='boardBtn'
//         style={{
//             backgroundColor:"transparent",
//             border:`1px solid #104A62`,
//             cursor:"pointer"
//         }}
//         onClick={()=>{
//             const maxPage=Math.ceil(table.length / postsPerPage)
//             setCurrentPage(maxPage)
//         }}
//         ><RxDoubleArrowRight
//         color={"#104A62"}
//         /></button></li>
//          </ul>
//     );
// }

// export default BoardBtns;