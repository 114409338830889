import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import ResArea from '../components/ResArea';
import SmResArea from '../components/SmResArea';
import BorderTxtField from '../components/BorderTxtField';
import WithLabelTxtField from '../components/WithLabelTxtField';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../atom/atom';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../service/firebase';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { communityValues, completedWrite, mdWidth, noWriteTxtField, smWidth } from '../const/const';
import uuid from 'react-uuid';
import { Checkbox, Radio } from '@mui/material';
import DeliveryWriteContent from '../components/DeliveryWriteContent';
import TaxiWriteContent from '../components/TaxiWriteContent';
import CommunityWriteContent from '../components/CommunityWriteContent';
import SmDeliveryWriteContent from '../components/SmDeliveryWriteContent';
import HorizontalDivider from '../components/HorizontalDivider';

function CommunityEditPage(props) {
    
    const location = useLocation()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
    const [newRestaurant, setNewRestaurant] = useState("")
    const [newDetailAddress, setNewDetailAddress] = useState("")
    const [newType, setNewType] = useState("같이시켜")
    //(선택) 주문시간->새로 추가 된 필드값->시작
    const nowTime = new Date()
    const [newOrderTime, setNewOrderTime] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newOrderSiTime, setNewOrderSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newOrderBunTime, setNewOrderBunTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
    const [id,setId]=useState("")
    const [pw,setPw]=useState("")
    //(선택)오픈채팅 링크
    const [newChatLink, setNewChatLink] = useState("")
    //(선택)내용
    const [newContents, setNewContents] = useState("")
    const [useOrderT,setUseOrderT]=useState(false)
    //출발지
    const [startAddress,setStartAddress]=useState("")
    //도착지
    const [endAddress,setEndAddress]=useState("")
//가는날
const [goDate, setGoDate] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
const [goSiTime, setGoSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
const [goBunTime, setGoBunTime] = useState("00")  
//community
const [foodCategory,setFoodCategory]=useState("")
const [nowCommunityCategory,setNowCommunityCategory]=useState(communityValues[0])
    const navigate = useNavigate()
    function inputFormatDate(date) {
        // 날짜를 받아와서 원하는 형식으로 변환합니다.
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
        const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.
      
        // 원하는 형식으로 날짜를 조합하여 반환합니다.
        return `${year}-${month}-${day}`;
      }
      
   
      const goDelivery=(postType)=>{
        navigate(`/${nowAddress.fullAddress}/type-select-delivery`,{state:{
                   nowAddress:nowAddress,
                   nowPotName:"delivery",
                   postType:"meetingPlace",
                  //  ownerUserId:location.state.ownerUserId,
                  //  favorites:location.state.favorites
                  ...location.state
               }})
           
         }
    const goTaxi = () => {
        navigate(`/${nowAddress.fullAddress}/type-select-taxi`,{state:{
            nowAddress:nowAddress,
            nowPotName:"taxi",
            postType:"meetingPlace",
           //  ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           ...location.state
        }})
    
    }
    const goCommunity = () => {
        navigate(`/${nowAddress.fullAddress}/type-select-community`,{state:{
            nowAddress:nowAddress,
            nowPotName:"community",
            postType:"meetingPlace",
           //  ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           ...location.state
        }})
    
    }
    useEffect(() => {
        // setNowPotName(location.state.nowPotName)
    }, [location])
    const formatForDbRgDate = (date) => {
        // const date = new Date(isoDateTime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
        const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
        const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
        const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };
    const formatForDbDate = (date,hours,minutes) => {
        // const date = new Date(isoDateTime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
        const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
        // const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
        // const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };
    const getFoodCategory = (potName) => {
        if (potName === "delivery") {
            return "한식"
        } else if (potName === "taxi") {
            return "같이타요"
        } else if (potName === "community") {
            return "일반"
        }
        else {
            return ""
        }
    }
    const writeDel=async(nowPotName,nowAddress)=>{
        const writeCondition =
        // newFoodCategory.trim() != "" &&
        nowPotName.trim() != "" &&
        newRestaurant.trim() != "" &&
        newDetailAddress.trim() != "" &&
        newType.trim() != ""

        const formattedRgTime = formatForDbRgDate(newRegisterDate)
        const formattedOrderTime = formatForDbDate(newOrderTime,newOrderSiTime,newOrderBunTime)
        if (writeCondition) {
        const docId = uuid()
        await setDoc(doc(db, "posts", docId), {
            chatLink: newChatLink,
            contents: newContents,
            //
            deadline: false,
            detailAddress: newDetailAddress,
            foodCategory: getFoodCategory(nowPotName),
            //
            foodLink:"",
            //
            isReported:false,
            //주소 가져올때 넣기
            latitude:1,
            longitude:1,
            meetingPlace:nowAddress.fullAddress,
            members:[],
            ownerUserId:"",
            orderTime: formattedOrderTime,
            registerDate:formattedRgTime,
            restaurant: newRestaurant,
            //로그인 할 때 화면 구현후 가지고 오기
            type: newType,
        })
        window.confirm(completedWrite)
        setNewRestaurant("")
        setNewDetailAddress("")
        setNewType("같이시켜")
        setNewOrderSiTime("")
        setNewOrderBunTime("")
        setNewChatLink("")
        setNewContents("")
        setStartAddress("")
        setEndAddress("")
        setGoSiTime("")
        setGoBunTime("")
        setFoodCategory("")
    } else {
        window.confirm(noWriteTxtField)
    }
    }
    const writeTaxi=async(nowPotName,nowAddress)=>{
        const writeCondition =
        // newFoodCategory.trim() != "" &&
        nowPotName.trim() != "" &&
        startAddress.trim() != "" &&
        endAddress.trim() != "" &&
        // goDate.trim() != ""&&
        goSiTime.trim() != ""&&
        goBunTime.trim() != ""

        const formattedRgTime = formatForDbRgDate(newRegisterDate)
        const formattedGoTime = formatForDbDate(goDate,goSiTime,goBunTime)
        if (writeCondition) {
        const docId = uuid()
        await setDoc(doc(db, "posts", docId), {
            chatLink: newChatLink,
            contents: newContents,
            //
            deadline: false,
            detailAddress: "",
            foodCategory: getFoodCategory(nowPotName),
            //
            foodLink:"",
            //
            isReported:false,
            //주소 가져올때 넣기
            latitude:1,
            longitude:1,
            meetingPlace:nowAddress.fullAddress,
            members:[],
            ownerUserId: 
            sessionStorage.getItem("login")==="success"?
            sessionStorage.getItem("userUuid"):"" ,
            orderTime: "",
            goTime:formattedGoTime,
            registerDate:formattedRgTime,
            restaurant: "",
            //로그인 할 때 화면 구현후 가지고 오기
            type: "",
            //택시팟일 경우에만 생기는 필드값
            startAddress:startAddress,
            endAddress:endAddress
        })
        window.confirm(completedWrite)
        setNewRestaurant("")
        setNewDetailAddress("")
        setNewType("같이시켜")
        setNewOrderSiTime("")
        setNewOrderBunTime("")
        setNewChatLink("")
        setNewContents("")
        setStartAddress("")
        setEndAddress("")
        setGoSiTime("")
        setGoBunTime("")
        setFoodCategory("")
    } else {
        window.confirm(noWriteTxtField)
    }
    }
    const editCommunity=async(nowPotName,nowAddress)=>{
    

        const formattedRgTime = formatForDbRgDate(newRegisterDate)
        const formattedGoTime = formatForDbDate(goDate,goSiTime,goBunTime)
        const docId = uuid()
        await updateDoc(doc(db, "posts",sessionStorage.getItem("editRowId")), {
            chatLink: newChatLink,
            contents: newContents,
            //
            deadline: false,
            detailAddress: "",
            foodCategory: nowCommunityCategory,
            //
            foodLink:"",
            //
            isReported:false,
            //주소 가져올때 넣기
            latitude:1,
            longitude:1,
            si:nowAddress.si,
            dong:nowAddress.dong,
            meetingPlace:nowAddress.fullAddress,
            members:[],
            ownerUserId:"",
            orderTime: "",
            goTime:formattedGoTime,
            registerDate:formattedRgTime,
            restaurant: "",
            //로그인 할 때 화면 구현후 가지고 오기
            type: "",
            //커뮤니티일 경우에만 생기는 필드값
           
        })
        window.confirm(completedWrite)
        setNewRestaurant("")
        setNewDetailAddress("")
        setNewType("같이시켜")
        setNewOrderSiTime("")
        setNewOrderBunTime("")
        setNewChatLink("")
        setNewContents("")
        setStartAddress("")
        setEndAddress("")
        setGoSiTime("")
        setGoBunTime("")
        setFoodCategory("")
  
    }
    const writeContent = async (potName,nowAddress) => {
        if(potName==="delivery"){
            writeDel(potName,nowAddress)
        } else if(potName==="taxi"){
            writeTaxi(potName,nowAddress)
        }else if(potName==="community"){
            // writeCommunity(potName,nowAddress)
            editCommunity(potName,nowAddress)
        }
       

    }
    const onChangeRes = (e) => {
        setNewRestaurant(e.target.value)
    }
    const onChangeDetailAddress = (e) => {
        setNewDetailAddress(e.target.value)
    }
    const onChangeOpenChat = (e) => {
        setNewChatLink(e.target.value)
    }
    function handleInputGoSiChange(e) {
        let inputValue = e.target.value;

  // 입력된 값이 숫자인지 확인하는 정규 표현식
  const regex = /^[0-9]*$/;

  // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
  if (inputValue.length > 2) {
    inputValue = inputValue.slice(-2);
  }

  // 입력된 값이 숫자이고 최대 두 자리인지 확인
  if (regex.test(inputValue)) {
    // 입력된 값을 state에 설정
    setGoSiTime(inputValue);
  }
      }
      function handleInputGoBunChange(e) {
        let inputValue = e.target.value;

        // 입력된 값이 숫자인지 확인하는 정규 표현식
        const regex = /^[0-9]*$/;
      
        // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
        if (inputValue.length > 2) {
          inputValue = inputValue.slice(-2);
        }
      
        // 입력된 값이 숫자이고 최대 두 자리인지 확인
        if (regex.test(inputValue)) {
          // 입력된 값을 state에 설정
          setGoBunTime(inputValue);
        }
      }
    function handleInpuSiChange(e) {
        let inputValue = e.target.value;

  // 입력된 값이 숫자인지 확인하는 정규 표현식
  const regex = /^[0-9]*$/;

  // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
  if (inputValue.length > 2) {
    inputValue = inputValue.slice(-2);
  }

  // 입력된 값이 숫자이고 최대 두 자리인지 확인
  if (regex.test(inputValue)) {
    // 입력된 값을 state에 설정
    setNewOrderSiTime(inputValue);
  }
      }
      function handleInpuBunChange(e) {
        let inputValue = e.target.value;

        // 입력된 값이 숫자인지 확인하는 정규 표현식
        const regex = /^[0-9]*$/;
      
        // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
        if (inputValue.length > 2) {
          inputValue = inputValue.slice(-2);
        }
      
        // 입력된 값이 숫자이고 최대 두 자리인지 확인
        if (regex.test(inputValue)) {
          // 입력된 값을 state에 설정
          setNewOrderBunTime(inputValue);
        }
      }
      const onClickTypeToggle=(e)=>{
setNewType(e.target.id)
      }
      const onChangeStartAddress=(e)=>{
        setStartAddress(e.target.value)
      }
      const onChangeEndAddress=(e)=>{
        setEndAddress(e.target.value)
      }
      const onChangeFoodCategory=(e)=>{
        setFoodCategory(e.target.value)
      }
const onChangeNewContent=(e)=>{
    setNewContents(e.target.value)
}
const onChangeCommunityCategory=(e)=>{
    setNowCommunityCategory(e.target.id)
}
      const getWriteContent=(potType,widthType)=>{

        if (potType==="delivery") {
            return <DeliveryWriteContent 
            allWidth={widthType==="md"?mdWidth:smWidth}
            newContent={newContents}
            onChangeNewContent={onChangeNewContent}
            handleInpuBunChange={handleInpuBunChange}
                        handleInpuSiChange={handleInpuSiChange}
                        inputFormatDate={inputFormatDate}
                        newChatLink={newChatLink}
                        newDetailAddress={newDetailAddress}
                        newOrderBunTime={newOrderBunTime}
                        newOrderSiTime={newOrderSiTime}
                        newOrderTime={newOrderTime}
                        newRestaurant={newRestaurant}
                        newType={newType}
                        onChangeDetailAddress={onChangeDetailAddress}
                        onChangeOpenChat={onChangeOpenChat}
                        onChangeRes={onChangeRes}
                        onClickTypeToggle={onClickTypeToggle}
                        setNewOrderTime={setNewOrderTime}
                        setUseOrderT={setUseOrderT}
                        useOrderT={useOrderT}/>
        }
        else if (potType==="taxi") {
            return <TaxiWriteContent
            allWidth={widthType==="md"?mdWidth:smWidth}
            newContent={newContents}
            onChangeNewContent={onChangeNewContent}
            startAddress={startAddress}
            onChangeStartAddress={onChangeStartAddress}
            endAddress={endAddress}
            onChangeEndAddress={onChangeEndAddress}
            handleInpuBunChange={handleInputGoBunChange}
                        handleInpuSiChange={handleInputGoSiChange}
                        inputFormatDate={inputFormatDate}
                        newChatLink={newChatLink}
                       goBunTime={goBunTime}
                        goSiTime={goSiTime}
                        goDate={goDate}
                        setGoDate={setGoDate}
                        onChangeOpenChat={onChangeOpenChat}
            />
        } else if (potType==="community"){
            return <CommunityWriteContent
            userId={id}
            pw={pw}
            allWidth={widthType==="md"?mdWidth:smWidth}
            nowCommunityCategory={nowCommunityCategory}
            onChangeCommunityCategory={onChangeCommunityCategory}
            foodCategory={foodCategory}
            goBunTime={goBunTime}
            goSiTime={goSiTime}
            goDate={goDate}
            handleInpuBunChange={handleInputGoBunChange}
            handleInpuSiChange={handleInputGoSiChange}
            inputFormatDate={inputFormatDate}
            newChatLink={newChatLink}
            newContent={newContents}
            onChangeFoodCategory={onChangeFoodCategory}
            onChangeNewContent={onChangeNewContent}
            onChangeOpenChat={onChangeOpenChat}
            setGoDate={setGoDate}

            />
        }
      }
      useEffect(() => {
        const nowUserId=sessionStorage.getItem("userId");
        const nowUserPw=sessionStorage.getItem("userPw");
        const login=sessionStorage.getItem("login")
        // setFoodCategory(location.state.foodCategory)
        const editRow=JSON.parse(sessionStorage.getItem("editRow"))
    setNowCommunityCategory(editRow.foodCategory)    
    const gT=editRow.goTime
    setGoDate(gT===undefined?new Date():new Date(gT))
    setGoSiTime(gT===undefined?"00":gT.split(" ")[1].split(":")[0])
    setGoBunTime(gT===undefined?"00":gT.split(" ")[1].split(":")[1])
   setNewChatLink(editRow.chatLink)
   setNewContents(editRow.contents)
    if (login==="success") {
             setId(nowUserId)
            setPw(nowUserPw)
            
        }else{
           setId("ㅇㅇ")
            setPw("1111")
        //  getUserData(location.state.ownerUserId)
        }
                // setNowPotName(location.state.nowPotName)
            }, [])
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: "center"
            }}>
            <Mobile>
                <div
                    style={{
                        height: "55px"
                    }}
                />
                 <ResArea
                 componentType='sm'
                 padding='16px'
                    nowPotName={"community"}
                    nowAddress={nowAddress} goDelivery={goDelivery} goTaxi={goTaxi} goCommunity={goCommunity} />
                <div
                    style={{
                        height: "2px"
                    }}
                />
                <div
                    style={{
                        height: "1px",
                        width: smWidth,
                        backgroundColor: "#D9D9D9"
                    }}
                />
                <div
                    style={{

                        height: "17px"
                    }}
                />
             {getWriteContent("community","sm")}
             <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: smWidth,
                        justifyContent: "flex-end"
                    }}
                >
                    <button
                        style={{
                            border: "1px solid #104A62",
                            color: "#104A62"
                        }}
                        onClick={(e)=>{writeContent("community",nowAddress)
                        //글쓰고 나면 변수들 초기화
                        navigate(-1)
                        }}
                    >수정 완료</button></div>
            </Mobile>
            <PC>
                <div
                    style={{
                        height: "55px"
                    }}
                />
                <ResArea
                    nowPotName={"community"}
                    nowAddress={nowAddress} goDelivery={goDelivery} goTaxi={goTaxi} goCommunity={goCommunity} />
                 <HorizontalDivider
             width={mdWidth}
             />
                <div
                    style={{

                        height: "17px"
                    }}
                />
        {/* nowPotName에 따라 내용이 변경될 곳 */}
        
{getWriteContent("community","md")}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "calc(100% - 80px - 80px)",
                        justifyContent: "flex-end"
                    }}
                >
                    <button
                        style={{
                            border: "1px solid #104A62",
                            color: "#104A62"
                        }}
                        onClick={(e)=>{writeContent("community",nowAddress)
                        //글쓰고 나면 변수들 초기화
                        navigate(-1)
                        }}
                    >수정 완료</button>
                </div>
            </PC>
        </div>
    );
}

export default CommunityEditPage;