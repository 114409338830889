import moment from "moment"

//앱바 아래 공백-공통
export const pcEmpty="32px"
export const mainColor="#3B4890"//"#114962"
export const secondaryColor="#929CD3"
export const smFontSize="14px"
export const mdFontSize="16px"
export const lgFontSize="20px"
export const appbarColor="#002D82"
export const latestVisitsColor="#EDEDED"
export const rowHeight="36px"
//문구들
export const noEmailPwTxtField="이메일, 비밀번호를 모두 입력 해 주세요!"
export const noWriteTxtField="모든 정보를 입력 해 주세요.!"
export const completedWrite="글쓰기가 완료 되었습니다!."
//배달팟
export const taxiValues=["같이타요",]
export const communityValues=["일반","쇼핑","OTT","도움/심부름"]
export const smWidth="calc(100% - 27px - 27px)"
export const mdWidth="calc(100% - 80px - 80px)"
export const mdMinWidth="900px"
export const mdAppbarPadding="200px"
export const deliveryValues=["치킨/피자","한식","중식","찜/탕","족발/보쌈","일식/회","햄버거","햄버거","분식/기타","양식/돈까스","카페/디저트",]
//몇분전인지 계산하는 함수
const getBeforeMinutes=(todayDate,regDate)=>{
    const divParam=(24*60*60*1000)
      //일자 계산
    
      const curD=todayDate//new Date('2023/01/10 09:00:00')
      const prevD=regDate//new Date('2023/01/12 09:00:00') 
      const diffMSec=curD.getTime()-prevD.getTime()
      const diffDate=diffMSec/divParam
   // 일, 시간, 분 단위로 변환
   const days = Math.floor(diffDate);
   const hours = Math.floor((diffDate - days) * 24);
   const minutes = Math.floor((((diffDate - days) * 24) - hours) * 60);
  
   // 포맷팅된 문자열 반환
   return `${days}일 ${hours}시간 ${minutes}분`;
    }
    export const getBeforeTimeOrDeadLineTime=(type,registerDate,today)=>{
    //토글 상태가 전체이면 0분 전
    //일정순이면 00:00에 마감
    if (registerDate!=undefined) {
  
    const [datePart, timePart] = registerDate.split(' ');
  const [year, month, day] = datePart.split('.');
  const [hour, minute] = timePart.split(':');
  // Date 객체 생성
  const divParam=(60*1000)
  const registerDateTime = new Date(year, month - 1, day, hour, minute); // 월은 0부터 시작하므로 1을 빼줍니다.
  const resMin=getBeforeMinutes(today,registerDateTime)
  let txt=""
  if (resMin>=0) {
    txt="전"
  }else if(resMin<0){
  
    txt="후"
  }else{
    txt="-"
  }
  
  //0이면 전체 1이면 일정순
    //    if (type==="0") {
    return resMin+" 전"
//   } else {
//     return "00:00에 마감"
//   }
    } else {
      
    }
   
  
  }
export  const postsPerPage = 20;
// export const extractLinkFromString=(str)=> {
//   // 정규식을 사용하여 문자열에서 링크를 추출합니다.
//  const removed= str.replace(/"/g, '');
//   const regex = /https:\/\/[^"]+/;
//   const match = removed.match(regex);

//   // 추출된 링크가 있으면 반환하고, 없으면 null을 반환합니다.
//   return match ? match[0] : null;
// }
export const extractLinkFromString=(str)=> {
  console.log("오픈채팅 링크>>>",str)
  if (str===""||str===undefined) {
    return ""
  } else {
    // 정규식을 사용하여 문자열에서 http로 시작하는 주소를 추출합니다.
  // const removed= str.replace(/"/g, '');
  const regex = /http[s]?:\/\/[^ "]+/g;
  const matches = str.match(regex);
  // 추출된 링크들을 반환합니다.
  return matches===null?"": matches[0] || "";
  }
  
}

// export const removeHangulAndQuotes=(str)=> {
//   // 정규식을 사용하여 한글과 큰따옴표를 제거합니다.
//   return str.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|""]/g, '');
// }
export const sortedDateList=(dataList)=>{
  
  return dataList.slice().sort((a, b) => {
 const dateA = new Date(a.registerDate).getTime();
 const dateB = new Date(b.registerDate).getTime();
 return dateA-dateB;
})};
export const formatForDbRgDate = (date) => {
  // const date = new Date(isoDateTime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
  const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
  const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
  const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};
export const convertToDateType=(dateString)=> {
  
  const parts = dateString.split(" "); // 공백과 점을 기준으로 문자열을 분할

 const year=parseInt(parts[0].split(".")[0])
 const month=parseInt(parts[0].split(".")[1])-1// 월은 0부터 시작하므로 1을 빼줍니다.
 const day=parseInt(parts[0].split(".")[2]) 
 const hour = parseInt(parts[1].split(":")[0]);
  const minute = parseInt(parts[1].split(":")[1])
  return new Date(year, month, day, hour, minute);
}
export const findTopNUniversities = (universityList, N) => {
  var universityCounts = {}; // 대학 이름과 등장 횟수를 저장할 객체

  // 각 대학의 등장 횟수를 세기
  universityList.forEach(function (university) {
    if (university.meetingPlace in universityCounts) {
      universityCounts[university.meetingPlace] += 1;
    } else {
      universityCounts[university.meetingPlace] = 1;
    }
  });

  // 등장 횟수가 많은 대학 순으로 정렬
  var sortedUniversities = Object.keys(universityCounts).sort(function (a, b) {
    return universityCounts[b] - universityCounts[a];
  });

  // 상위 N개의 대학 객체를 반환
  const topNUniversities = [];
  sortedUniversities.slice(0, N).forEach(function (place) {
    const universities = universityList.filter(function (university) {
      return university.meetingPlace === place;
    });
    // 대학 이름이 같은 객체는 하나만 포함
    const uniqueUniversities = removeDuplicates(universities, "universityName");
    topNUniversities.push(...uniqueUniversities);
  });

  return topNUniversities;
};

// 중복된 객체를 제거하는 함수
function removeDuplicates(array, key) {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}

export const getRepliesStatus=(replies)=>{
      
  if (replies===undefined||replies.length===0) {
      return ""
  }else{
      return `(${replies.length})`
  }
}
export const ruleTxt=<>같이시켜 개인정보 처리방침


같이시켜는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」및 관계 법령이 정한 바를

준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보

보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한절차 및 기준을 안내하고, 이와

관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보

처리방침을 수립·공개합니다.


- 목차 -


n 개인정보의 처리 목적, 수집항목, 보유 및 이용기간

n 만 14세 미만 아동의 개인정보 처리에 관한 사항

n 개인정보의 제3자 제공

n 개인정보 처리의 위탁

n 개인정보의 파기 절차 및 방법

n 정보주체와 법정대리인의 권리·의무 및 행사방법

n 개인정보의 안전성 확보조치

n 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항

n 개인정보 보호책임자 및 개인정보 열람청구

n 권익침해 구제방법

n 영상정보처리기기 설치·운영

n 개인정보 처리방침의 변경


n 개인정보의 처리 목적, 수집항목, 보유 및 이용기간

같이시켜는 개인정보 수집을 하지 않습니다.


n 만 14세 미만 아동의 개인정보 처리에 관한 사항

같이시켜는 만 14세 미만 아동에 대해 개인정보를 수집하지 않습니다.


n 개인정보의 제3자 제공


같이시켜는  정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만

처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에

해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를

제3자에게 제공하지 않습니다.


▪ 개인정보의 국외 이전

같이시켜는 클라우드 서비스 사용 업무를 국외 법인인 구글에 아래와 같이 위탁하고

있습니다.

1. 수탁업체: 구글(파이어베이스)

2. 수탁업체의 위치: 구글본사 주소 작성

3. 위탁 일시 및 방법: 네트워크를 통한 상시 전송

4. 정보관리책임자의 연락처 : 전자우편 주소, 전화번호

5. 위탁하는 개인정보 항목 : OOO, OOO, OOO

6. 위탁 업무 내용 : 클라우드 서비스 사용

7. 개인정보의 보유 및 이용기간 : 서비스 계약 종료 시


n 개인정보 처리의 위탁

같이시켜는 원활한 개인정보 업무처리를 위하여 개인정보처리업무를 위탁하는 경우

지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.


같이시켜는위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외

개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한관리·감독, 손해배상

등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게

처리하는지를 감독하도록 하겠습니다.


n 개인정보의 파기 절차 및 방법

같이시켜는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.


정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도

불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를

별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.


개인정보 파기의 절차 및 방법은 다음과 같습니다.

1. 파기절차

파기 사유가 발생한 개인정보를 선정하고, 같이시켜는의 개인정보 보호책임자의 승인을 받아

개인정보를 파기합니다.

2. 파기방법

전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기합니다.


n 정보주체와 법정대리인의 권리·의무 및 행사방법

정보주체는 언제든지 개인정보 열람·정정·삭제·처리정지요구 등의 권리를 행사할 수

있습니다.


권리 행사는 「개인정보 보호법」 시행령 제41조 제1항에따라 서면, 전자우편,

모사전송(FAX) 등을 통하여 하실 수 있으며, 같이시켜는 이에 대해 지체없이 조치하겠습니다.


권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도

있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른

위임장을 제출하셔야 합니다.


개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에

의하여 정보주체의 권리가 제한 될 수 있습니다.


개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어있는

경우에는 그 삭제를 요구할 수 없습니다.


같이시켜는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람

등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.


n 개인정보의 안전성 확보조치

같이시켜는개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고있습니다.

1. 관리적 조치 : 내부관리계획 수립·시행, 전담인력 운영, 정기적 직원 교육

2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의

암호화, 보안프로그램 설치 및 갱신


n 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항

같이시켜는 정보주체에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고

수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.


n 개인정보 보호책임자 및 개인정보 열람청구

같이시켜는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보처리와 관련한

정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고

있습니다.


정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할

수 있습니다. 같이시켜는 정보주체의 개인정보 열람청구가 신속하게 처리되도록

노력하겠습니다.


정보주체는 같이시켜의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보보호 관련 문의,

불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수

있습니다. 같이시켜는정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.

구분 성명 및 직책 연락처


개인정보 보호책임자


성명 : 김신

직책 : 대표


이메일 : itshonigo@gmail.com


개인정보 열람청구


성명 : 김신

직책 : 대표


이메일 : itshonigo@gmail.com


n 권익침해 구제방법

같이시켜는 정보주체의 개인정보자기결정권을 보장하고, 개인정보 침해로 인한 상담 및 피해

구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기

바랍니다.

1. 고충처리 부서 부서명 : 같이시켜 고충처리과

2. 담당자 : 김신

3. 연락처 : itshonigo@gmail.com


정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,

한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.

이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)

2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)

3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)

4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)


「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제),

제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분

또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라

행정심판을 청구할 수 있습니다.

1. 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)


n 개인정보 처리방침의 변경


이 개인정보 처리방침은 2023. 12. 7일 부터 적용됩니다.


이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.

20XX. X. X ~ 20XX. X. X 적용 (클릭)</>
///delivery table func
export const formattedRegDate = (dateString) => {
  console.log("dateString in func>>>", dateString);
  const currentDate = moment();
  const postDate = moment(dateString, 'YYYY.MM.DD HH:mm'); // 날짜 형식 지정

  if (!postDate.isValid()) return '-';

  const diffMinutes = currentDate.diff(postDate, 'minutes');
  const diffHours = currentDate.diff(postDate, 'hours');
  const diffDays = currentDate.diff(postDate, 'days');

  if (diffDays < 1) {
    if (diffMinutes < 60) {
      return `${diffMinutes}분전`;
    } else {
      return postDate.format('HH:mm');
    }
  } else if (diffDays < 365 && postDate.year() === currentDate.year()) {
    return postDate.format('MM/DD');
  } else {
    return postDate.format('YYYY/MM/DD HH:mm');
  }
};
// export const formattedOrderTime = (dateString) => {
//   const currentDate = new Date();
//   const postDate = new Date(dateString);
//   if (dateString === ""||dateString===undefined) {
//       return "-"
//    }
//   else {
//       const year = postDate.getFullYear();
//       const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
//       const day = postDate.getDate().toString().padStart(2, '0');
//       const hours = postDate.getHours().toString().padStart(2, '0');
//       const minutes = postDate.getMinutes().toString().padStart(2, '0');

//       // Check if year is different
//       if (postDate.getFullYear() !== currentDate.getFullYear()) {
//           return `${year}/${month}/${day} ${hours}:${minutes}`;
//       } else {
//           const firstList = dateString.split(" ")[0].split(".")
//           const exceptYear = firstList[1] + "." + firstList[2]
//           return exceptYear + " " + dateString.split(" ")[1]
//       }

//   }

// }
export const formattedOrderTime = (dateString) => {
  if (!dateString) return "-";

  const postDate = new Date(dateString.replace(/\./g, "-"));
  if (isNaN(postDate)) return "-";

  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  const formattedDate = new Intl.DateTimeFormat('ko-KR', options).format(postDate);

  return formattedDate.replace(/\./g, '/');
};
