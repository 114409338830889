import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../../atom/atom';
import { MbResAreaSx } from '../../const/BtnStyle';
import MbMoreBtn from '../Btn/MbMoreBtn';

function MbResArea({ componentType = "md", nowPotName }) {
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = useState(-1);

    useEffect(() => {
        if (location.pathname.includes('delivery-table')) {
            setValue(0);
        } else if (location.pathname.includes('taxi-table')) {
            setValue(1);
        } else if (location.pathname.includes('community-table')) {
            setValue(2);
        } else {
            setValue(-1); // Reset to -1 if none of the paths match
        }
    }, [location.pathname]);

    const handleChange = (newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/delivery-table/meeting-place/1`);
                break;
            case 1:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/taxi-table/meeting-place/1`);
                break;
            case 2:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/community-table/meeting-place/1`);
                break;
            default:
                break;
        }
    };

    return (
        <div
            style={{
                width: '100%',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop:"10px",
                    marginBottom: '10px' // Add spacing between buttons and address button
                }}
            >
                <Button
                variant='text'
                    // sx={MbResAreaSx}
                    onClick={() => handleChange(0)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 0||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 0||value === -1 ? '#114962':"white",
                        color: value === 0||value === -1 ?"white":"#114962"
                    }}
                >
                    배달팟
                </Button>
                <Button
                    onClick={() => handleChange(1)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 1||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 1||value === -1 ? '#114962':"white",
                        color: value === 1||value === -1 ?"white":"#114962"
                    }}
                >
                    택시팟
                </Button>
                <Button
                    onClick={() => handleChange(2)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 2||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 2||value === -1 ? '#114962':"white",
                        color: value === 2||value === -1 ?"white":"#114962"
                    }}
                >
                    커뮤니티
                </Button>
            </div>
            <Button
                sx={MbResAreaSx}
                onClick={() => {
                    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/delivery-table/meeting-place/1`);
                }}
                style={{
                    width: '100%',
                    overflow: "hidden",
                    marginBottom: '10px' // Add spacing between address button and Tabs
                }}
            >
                <span
                    style={{
                        display: "block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {params.fullAddress === null ? "주소를 선택해 주세요" : params.fullAddress}
                </span>
                <MbMoreBtn />
            </Button>
        </div>
    );
}

export default MbResArea;
