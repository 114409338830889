import React from 'react';
import { useParams } from 'react-router-dom';
import TypeSelectPage from './TypeSelectPage';
import WriteTaxiPage from './WriteTaxiPage';
import TypeSelectTaxiPage from './TypeSelectTaxiPage';
import TypeSelectCommunityPage from './TypeSelectCommunityPage';
import EditDeliveryPage from './EditDeliveryPage';
import EditTaxiPage from './EditTaxiPage';
import EditCommunityPage from './EditCommunityPage';

function EditPage(props) {
    const params=useParams()
    if (params.tableType==="delivery-table") {
         return <EditDeliveryPage/>
    
    } else if(params.tableType==="taxi-table"){
        return <EditTaxiPage/>
    }
    else if(params.tableType==="community-table"){
        return <EditCommunityPage/>
    }
   
}

export default EditPage;