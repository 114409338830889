import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function AllDateToggle({onChange,type}) {
 
  return (
    <ToggleButtonGroup
      // color="primary"
      value={type}
      exclusive
      onChange={onChange}
      aria-label="Platform"
    >
      <ToggleButton value="all"selected >전체글</ToggleButton>
      <ToggleButton value="day">일정순</ToggleButton>
    </ToggleButtonGroup>
  );
}