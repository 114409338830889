import React, { useEffect, useRef } from 'react';
import CommunityReply from './CommunityReply';
import { Card, List, ListItem, TextField, Typography } from '@mui/material';

function CommunityBox({onChangeReply,reply,onEnterReply,communityReplyList}) {
    const ulRef = useRef(null);

    // useEffect(() => {
    //     // 컴포넌트가 마운트된 후에 ul 요소의 scrollTop을 최하단으로 이동
    //     if (ulRef.current) {
    //         ulRef.current.scrollTop = ulRef.current.scrollHeight;
    //     }
    // }, [communityReplyList]);
    return (
        <Card
        variant='outlined'
        sx={{
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"flex-start",
            width:"100%",
            height:"300px",
            backgroundColor:"#f1f1f1"
        }}
        >
            <div
            style={{
                width:"calc(100% - 16px)",
                padding:"8px",
                height:"26px",
                display:"flex",
                alignItems:"center",
                backgroundColor:"#d9d9d9",
                fontWeight:700
            }}
            >
           <Typography
           variant='h6'
           >광장</Typography>
            </div>
            <div
            style={{
              
                width:"calc(100% - 16px - 16px)",
               
                height:"100%",
                backgroundColor:"white"
            }}
            >
               
                <List
                ref={ulRef}
                sx={{
                    listStyle:"none",
                    margin:0,
                    padding:0,
                    height:"calc(60px * 4)",
               
                    overflowY:"scroll",
                
              
                }}
                >
                    {
              communityReplyList&&communityReplyList.map((r,idx)=><CommunityReply 
                            // ownerUserId={"r.ownerUserId"}
                            // registerDate={"r.registerDate"}
                            last={idx===communityReplyList.length-1}
                            reply={r}/>)
                    }
               
                </List>
                <TextField
                size='small'
                type="text" 
                        
                        placeholder='댓글 입력후, Enter'
                        // onKeyDown={onEnterReply}
                        onKeyDown={onEnterReply}
                        value={reply}
                        onChange={onChangeReply}
                        style={{
                            // position:"absolute",
                            bottom:"40px",
                            zIndex:999,
                            // padding:8,
                            borderRadius:8,
                            width:"calc(100%)",
                        }}
                        inputProps={{
                            style:{
                                backgroundColor:"white"
                            }
                        }}
                        />
                       
                </div>
                
                
           
        </Card>
    );
}

export default CommunityBox;