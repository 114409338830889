import { Button, useMediaQuery } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

function FavoriteBtn({onClick}) {
    const params=useParams()
    const isMobile = useMediaQuery('(max-width:767px)');
    return (
        <Button
        variant='contained'
        size='small'
        onClick={onClick}
        sx={{
            backgroundColor:"#A5B2BA",
            color:"white",
            fontSize:isMobile?"14px":"16px",
            ":hover":{
                backgroundColor:"#A5B2BA",
                color:"white",
            }
        }}
        >
            즐겨찾기
        </Button>
    );
}

export default FavoriteBtn;