import React from 'react';
import BoardSearchInput from "../components/BoardSearchInput"
function TaxiPotBox({type="pc",searchStartAddress,onChangeStart,searchEndAddress
,onChangeEnd
,onSearchTaxiList
,onAlllTaxiList
,searchGoTime,
searchGoSiTime,
searchGoBunTime,
inputFormatDate,
handleInputSiChange,
handleInputBunChange,
setSearchGoTime

}) {
    return (
        <div
        style={{
          
            width:"calc(100% - 30px )",
            border:"2px solid #002D82",
            height:"170px",
            display:"flex",
            flexDirection:"row",
            padding:"16px"
        }}
        >
<div
style={{
    display:"flex",
    flexDirection:"column",gap:"16px"
}}
>

<span>출발지</span>
<BoardSearchInput type={type} value={searchStartAddress} onChange={onChangeStart}/>
<span>도착지</span>
<BoardSearchInput type={type} value={searchEndAddress} onChange={onChangeEnd}/>

</div>
<div
            style={{
                width:"30px"
            }}
            />
            <div
            style={{
                height:"100%",
                backgroundColor:"#d9d9d9",
                width:"2px"
            }}
            />
            <div
            style={{
                width:"30px"
            }}
            />
          <div
          style={{width:"100%",
            display:"flex",
            flexDirection:"column",
            alignItems:"flex-start",
            gap:"16px"
          }}
          ><span>가는일시</span>
          <div
          style={{
            display:"flex",
            flexDirection:"row",
            gap:"16px"
          }}>
 <input type='date' 
                value={inputFormatDate(searchGoTime)}
                onChange={(e)=>{
                    setSearchGoTime(new Date(e.target.value))
                }}
                />
                <input type='text'
                style={{
                    width:"50px"
                }}
                
                value={searchGoSiTime}
                onChange={handleInputSiChange}
                />시
                 <input type='text'
                style={{
                    width:"50px"
                }}
                
                value={searchGoBunTime}
                onChange={handleInputBunChange}
                />분

          </div>
          <div
          style={{
            flex:1
          }}
          />
          <div
          style={{
            width:"100%",
            display:"flex",
            flexDirection:"row",
            alignItems:"flex-end",
            justifyContent:"flex-end",
            gap:"16px"
          }}
          >
            <button
          
          style={{
            width:"200px",
            backgroundColor:"#002D82",
            color:"white",
            border:"none",
            cursor:"pointer",
            height:"36px"
          }}
          onClick={onAlllTaxiList}
          >전체 글 보기</button>
            <button
          
          style={{
            width:"200px",
            backgroundColor:"#002D82",
            color:"white",
            border:"none",
            cursor:"pointer",
            height:"36px"
          }}
          onClick={onSearchTaxiList}
          >조회하기</button></div>
          
          </div>
        </div>
    );
}

export default TaxiPotBox;