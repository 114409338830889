import React from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import { mdWidth, smWidth } from '../const/const';

function PageLayout({children}) {
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
          }}>
        <Mobile>
            <div
            style={{
                width:smWidth,
                
                paddingTop:"70px"
            }}
            >{children}</div>
            </Mobile>
        <PC><div
            style={{
                width:mdWidth,
                paddingTop:"70px",
            }}
            >{children}</div></PC>
        </div>
    );
}

export default PageLayout;