import React from 'react';
// import { companyColor } from '../const/const';

function BoardBtn({id,selNum,onClick}) {
    return (
        <button
        id={id}
        className='boardBtn'
        style={{
            fontWeight: id==selNum?'bold':"normal",
            color: id==selNum?"white":"black",
            border:id==selNum?`1px solid #104A62`:"none",
            backgroundColor:id==selNum?"#104A62":null,
            cursor:"pointer"
        }}
        onClick={onClick}
        >{id}</button>
    );
}

export default BoardBtn;