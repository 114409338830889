import React from 'react';
import { mdFontSize } from '../const/const';

function MembershipBorderBtn({title,onClick,type}) {
    return (
        <button
        id={type}
        onClick={onClick}
        style={{
            fontSize:mdFontSize,
            height:"36px",
            width:"120px",
            border:"1px solid #ABABAB",
            borderRadius:"6px",
            cursor:"pointer"
        }}
        >
            {title}
        </button>
    );
}

export default MembershipBorderBtn;