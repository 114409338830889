import React from 'react';
import TaxiPotTable from './TaxiPotTable';
import AllDayToggle from './AllDayToggle';
import TaxiPotBox from './TaxiPotBox';
import SmFavArea from './SmFavArea';
import WriteBtn from './WriteBtn';
import { useLocation } from 'react-router-dom';

function TaxiPotContent({list,searchStartAddress,
  currentPage,
  onChangeStart,
  searchEndAddress,
  onChangeEnd,
  onSearchTaxiList,
  handleInputBunChange,
  handleInputSiChange,
  inputFormatDate,
  searchGoBunTime,
  searchGoSiTime,
  searchGoTime,
  setSearchGoTime,
  onAlllTaxiList,
  onClickTaxiPotToggle,
taxiPotToggle,searchNextDay
,searchToday,
setSearchToday,
setSearchNextDay,
onClickTodaySearch,onClickNextSearch,nowPotName
}) {
  const location=useLocation()
    return (
        <div
        style={{
            display:"flex",
            flexDirection:"column",
            width:"100%"
        }}
        >
        
         <div
          style={{
            width: "100%",
          }}
        >   <TaxiPotBox
        onAlllTaxiList={onAlllTaxiList}
        handleInputBunChange={handleInputBunChange}
        handleInputSiChange={handleInputSiChange}
        inputFormatDate={inputFormatDate}
        searchGoBunTime={searchGoBunTime}
        searchGoSiTime={searchGoSiTime}
        searchGoTime={searchGoTime}
        setSearchGoTime={setSearchGoTime}
  
        searchStartAddress={searchStartAddress}
        onChangeStart={onChangeStart}
        searchEndAddress={searchEndAddress}
        onChangeEnd={onChangeEnd}
        onSearchTaxiList={onSearchTaxiList}
        /></div>
     <div
     style={{
        height:"100px"
     }}
     />
      <div
          style={{
            display:"flex",

            width: "100%",
          }}
        >  
        <AllDayToggle toggle={taxiPotToggle} clickToggle={onClickTaxiPotToggle}
        
        />
         {/* {taxiPotToggle==="1"&&<div
        style={{
          display:"flex",
          paddingLeft:"30px"
        }}
        ><input type='date'
        value={inputFormatDate(searchToday)}
        onChange={
          (e)=>{
            const day=new Date(e.target.value)
            const nextDay = new Date(day);
            nextDay.setDate(nextDay.getDate() + 1); 
            setSearchToday(day)
            setSearchNextDay(nextDay)
          }
        }
        /><button
        style={{
          // height:"20px"
          backgroundColor:"white",
          border:"1px solid black"
        }}
        onClick={(e)=>{onClickTodaySearch(nowPotName,searchToday)}}
        >조회하기</button></div>} */}
        <div
        style={{
            flex:1
        }}
        />
        {/* {taxiPotToggle==="0"?location.state.postType==="si"?<div>시 최신 글에서는 글작성이 불가능 합니다.</div>:<WriteBtn
        pageName={"taxi"}
        />:<div
        style={{
          display:"flex",
          flexDirection:"row",
          alignItems:"center",
          gap:"8px"
        }}
        > 
      
        
        </div>
        
        } */}
        </div>
        <div
     style={{
        height:"30px"
     }}
     />
        <div
          style={{
            display:"flex",

            width: "100%",
          }}
        >  <TaxiPotTable list={list} currentPage={currentPage}/></div>
        </div>
    );
}

export default TaxiPotContent;