import React, { useState } from 'react';

function PotBtn({title,active=false,onClick,componentType}) {
    const [hover,setHover]=useState(false)
    const onMouseEnter=()=>{
        setHover(true)
    }
    const onMouseLeave=()=>{

        setHover(false)
    }
    return (
        <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{
            height:"30px",
            // minWidth:"80px",
            width:componentType==="md"?"100px":"60px",
            cursor:"pointer",
            backgroundColor:active||hover?"#104A62":"white",
            fontSize:componentType==="md"?"16px":"10px",
            border:active||hover?"2px solid #104A62":"2px solid #A5B2BA",
            borderRadius:"6px",
            fontWeight:500,
            color:active||hover?"white":"black",
            zIndex:800
        }}
        onClick={onClick}
        >
            {title}
        </button>
    );
}

export default PotBtn;