import React, { useEffect } from 'react';
import { IoMdArrowDropright } from "react-icons/io";
import PotBtn from './PotBtn';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../atom/atom';
function ResArea({padding="80px",noArrow=false,goDelivery,
componentType="md",
nowPotName,
goTaxi,
goCommunity}) {
  
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);

    return (
        <div
        style={{
            width:`calc(100% - ${padding} - ${padding})`,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"space-between"
        }}
        >
            <div
            style={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"3px",
                cursor:"pointer"
            }}
        onClick={
            
            ()=>{
                
                if (nowPotName==="delivery") {
                    goDelivery()
                } else if(nowPotName==="taxi") {
                    goTaxi()
                }else{
                    goCommunity()
                }
                }
            }
            > 
            {/* <div
            style={{ width:componentType==="md"?"800px":"76px",
            minWidth:componentType==="md"?"800px":"76px",
            display: "block",
            whiteSpace:"nowrap",
            overflow:"hidden",
            textOverflow:"elipsis",
                backgroundColor:"red"
            }}
            > */}
                <div
            style={{
                
                width:componentType==="md"?"800px":"70px",
                display: "block",
                // alignItems:"flex-end",
            whiteSpace:"nowrap",
            overflow:"hidden",
            textOverflow:"ellipsis",
                // backgroundColor:"red",
                // minWidth:componentType==="md"?"800px":"100px",
            height:"30px",
            
                fontSize:componentType==="md"?"20px":"14px",
                fontWeight:700}}
            >
                
                {nowAddress.fullAddress===null?"주소를 선택해 주세요":nowAddress.fullAddress}</div>
            {/* </div> */}
            
            </div>
           <div
           style={{
            display:"flex",
            gap:"8px",
            backgroundColor:""
           }}
           >
            {!noArrow&&<button
            style={{
                border:"none",
                backgroundColor:"transparent",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                cursor:"pointer"
            }}
            ><IoMdArrowDropright size={20}/></button>}
           <PotBtn 
           componentType={componentType}
           title={"배달팟"} onClick={goDelivery} active={nowPotName==="delivery"}/>
           <PotBtn 
           componentType={componentType}
           title={"택시팟"} onClick={goTaxi} active={nowPotName==="taxi"}/>
           <PotBtn 
           componentType={componentType}
           title={"커뮤니티"} onClick={goCommunity} active={nowPotName==="community"}/>

           </div>
        </div>
    );
}

export default ResArea;