import React from 'react';
import BorderTxtField from './BorderTxtField';
import WithLabelTxtField from './WithLabelTxtField';
import { Checkbox } from '@mui/material';
import { BsArrowRight } from 'react-icons/bs';
import { mdWidth } from '../const/const';
import HourSelect from './HourSelect';
import MinuteSelect from './MinuteSelect';
import { useLocation } from 'react-router-dom';
import DottedHorizontalDivider from './DottedHorizontalDivider';

function TaxiWriteContent({
    startAddress,
    onChangeStartAddress,
    endAddress,
    onChangeEndAddress,
    handleInpuBunChange,
    handleInpuSiChange,
    inputFormatDate,
    newChatLink,
    goBunTime,
    goSiTime,
    goDate,
    setGoDate,
    onChangeOpenChat,
    newContent,
onChangeNewContent,
allWidth,id,pw,onChangeId,
onChangePw,
}) {
    const location=useLocation()
    return (
        <div
        style={{
            width: allWidth,
            display: "flex",
            flexDirection:"column"
        }}
        
        >
                    <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField 
                width='100%'
                placeholder={"아이디"} 
                value={id}onChange={onChangeId}
                />
                 <BsArrowRight
                 style={{
                    opacity:0
                 }}
                 />
                    <BorderTxtField 
                    width='100%'
                    placeholder={"비밀번호"}   value={pw} onChange={onChangePw}/>
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                {/* {location.state.postType==="sidong"&&<div>출발지, 도착지를 건물명으로 적어주세요</div>} */}
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField
                width='100%'
                        placeholder={"출발지"}
                        onChange={onChangeStartAddress}
                        value={startAddress}

                    />
                    <BsArrowRight/>
                    <BorderTxtField
                    width='100%'
                         placeholder={"도착지"}
                        onChange={onChangeEndAddress}
                        value={endAddress}
                    />
                    </div>
               
                <div
                    style={{
                        height: "22px"
                    }}
                />
                  <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: allWidth===mdWidth?"22px":"3px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <span
                    style={{
                        minWidth:"50px"
                    }}
                    >가는날</span>
                    
                    <input type='date' 

                    style={{
                        minWidth:"100px"
                    }}
                value={inputFormatDate(goDate)}
                onChange={(e)=>{
                    setGoDate(new Date(e.target.value))
                }}
                />
                <HourSelect
                value={goSiTime}
                onChange={handleInpuSiChange}
                />
                시
                <MinuteSelect
                value={goBunTime}
                onChange={handleInpuBunChange}
                />
                분
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                     <DottedHorizontalDivider/>
               
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField
                         width='100%'
                        placeholder={"(선택) 오픈채팅 링크"}
                        value={newChatLink}
                        onChange={onChangeOpenChat}
                    /></div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><textarea
                value={newContent}
                onChange={onChangeNewContent}
                        style={{
                            height: "200px",
                            width: "100%",
                            border:"2px solid #104A62",
                            borderRadius:"8px",
                            resize:"none"
                          
                        }}
                    />
                    
                    </div>
               
               
              
        </div>
    );
}

export default TaxiWriteContent;