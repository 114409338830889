import { Button } from '@mui/material';
import React from 'react';
import { FaPenAlt } from 'react-icons/fa';

function EditCompleteBtn({onClick}) {
    return (
        <Button
        size='small'
        sx={{
            fontSize:"16px",
            border:"1px solid #104A62",
            color:"#104A62"
        }}
        onClick={onClick}
        >
              수정 완료
        </Button>
    );
}

export default EditCompleteBtn;