import React from 'react';
import { useParams } from 'react-router-dom';
import DeliveryTablePage from './DeliveryTablePage';
import TaxiTablePage from './TaxiTablePage';
import CommunityPage from './CommunityPage';

function TablePage(props) {
    const params=useParams()
    if (params.tableType==="delivery-table") {
        return <DeliveryTablePage/>
    } else if(params.tableType==="taxi-table") {
     return   <TaxiTablePage/>
    }else{
      return  <CommunityPage/>
    }
   
}

export default TablePage;