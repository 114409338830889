import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography, Box } from '@mui/material';
import { lgFontSize, ruleTxt, mdWidth, pcEmpty } from '../const/const';
import { IoPerson } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { mainLoadingState } from '../atom/atom';
import emailjs from '@emailjs/browser';
import uuid from 'react-uuid';
import { db } from '../service/firebase';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { Mobile, PC } from '../ReactiveLayout';

function SignUpPage(props) {
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");
  const [nickname, setNickname] = useState("");
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
  const [communityRule, setCommunityRule] = useState(false);
  const [personalRule, setPersonalRule] = useState(false);
  const [showCheckNumber, setShowCheckNumber] = useState(false);
  const [checkNumber, setCheckNumber] = useState("");
  const [sendNum, setSendNum] = useState(null);
  const [numberOk, setNumberOk] = useState(false);
  const navigate = useNavigate();
  const form = useRef();

  useEffect(() => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    setSendNum(randomNumber);
  }, []);

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangePw = (e) => setPw(e.target.value);
  const onChangePwCheck = (e) => setPwCheck(e.target.value);
  const onChangeNickname = (e) => setNickname(e.target.value);
  const onChangeCheckNumber = (e) => {
    setCheckNumber(e.target.value);
    if (e.target.value === sendNum.toString()) {
      setNumberOk(true);
    } else {
      setNumberOk(false);
    }
  };

  const validatePassword = (password) => {
    const minLength = 6;
    const hasNumber = /[0-9]/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    return password.length >= minLength && hasNumber && hasLetter;
  };

  const checkEmailExists = async (email) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // 이메일이 존재하면 true, 존재하지 않으면 false 반환
  };

  const onSendEmail = async () => {
    if (!communityRule || !personalRule) {
      alert("동의항목을 체크 해 주세요!");
    } else if (!email || !pw || !pwCheck || !nickname) {
      alert("빈칸을 전부 채워 주세요");
    } else if (pw !== pwCheck) {
      alert("비밀번호와 비밀번호 확인이 다릅니다.");
    } else if (!validatePassword(pw)) {
      alert("비밀번호는 6자리 이상이어야 하며 영문과 숫자를 반드시 포함해야 합니다.");
    } else {
      // 이메일 중복 확인
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        alert("이미 가입된 이메일입니다.");
        return;
      }

      setShowCheckNumber(true);
      sessionStorage.setItem("sendNum", sendNum);

      // emailjs 로직
      const templateParams = {
        to_email: email,
        to_name: nickname,
        message: sendNum,
      };

      emailjs.send(
        'service_dgp5g76', // 서비스 ID
        'template_xqcp0gh', // 템플릿 ID
        templateParams,
        'l7UyWMF2iSVcIQNHn' // public-key
      )
      .then((response) => {
        window.confirm("인증 메일이 성공적으로 보내졌습니다");
        console.log('이메일이 성공적으로 보내졌습니다:', response);
      })
      .catch((error) => {
        console.error('이메일 보내기 실패:', error);
      });
    }
  };

  const onSignup = async () => {
    try {
      const docId = uuid();
      await setDoc(doc(db, "users", docId), {
        email,
        favorites: [],
        fcm: "",
        push: true,
        pw,
        nickname,
      });

      // 자동 로그인 설정
      sessionStorage.setItem("userId", email);
      sessionStorage.setItem("userPw", email);
      sessionStorage.setItem("userNickname", nickname);
      sessionStorage.setItem("userUuid", docId);

      alert("회원가입 성공!");
      navigate('/'); // 메인 페이지로 이동
    } catch (error) {
      alert("회원가입 실패!");
      console.error("회원가입 실패:", error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", padding: "16px" }}>
      {/* PC UI */}
      <PC>
        <div style={{ width: mdWidth, minWidth: "900px" }}>
          <div style={{ height: pcEmpty }} />
          <Box style={{ backgroundColor: "#111111", padding: "32px", color: "white" }}>
            <Box style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "32px" }}>
              <IoPerson size={20} color='#919090' />
              <Typography variant="h5" fontWeight={700}>회원가입</Typography>
            </Box>

            <Box style={{ marginBottom: "32px" }}>
              <Typography variant="h6">커뮤니티 규칙(필수)</Typography>
              <Box style={{ backgroundColor: "#222222", padding: "16px", borderRadius: "8px", marginTop: "8px" }}>
                <div style={{ overflowY: "scroll", height: "150px" }}>{ruleTxt}</div>
                <FormControlLabel
                  control={<Checkbox 
                    sx={{
                        color:"white"
                    }}
                    onChange={(e) => setCommunityRule(e.target.checked)} />}
                  label="동의합니다."
                  style={{ color: "white" }}
                />
              </Box>
            </Box>

            <Box style={{ marginBottom: "32px" }}>
              <Typography variant="h6">개인정보처리방침(필수)</Typography>
              <Box style={{ backgroundColor: "#222222", padding: "16px", borderRadius: "8px", marginTop: "8px" }}>
                <div style={{ overflowY: "scroll", height: "150px" }}>{ruleTxt}</div>
                <FormControlLabel
                  control={<Checkbox   sx={{
                    color:"white"
                }} onChange={(e) => setPersonalRule(e.target.checked)} />}
                  label="동의합니다."
                  style={{ color: "white" }}
                />
              </Box>
            </Box>

            <Box style={{ marginBottom: "32px" }}>
              <TextField
                label="이메일 주소"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={onChangeEmail}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="비밀번호"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={pw}
                onChange={onChangePw}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="비밀번호 확인"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={pwCheck}
                onChange={onChangePwCheck}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="닉네임"
                variant="outlined"
                fullWidth
                margin="normal"
                value={nickname}
                onChange={onChangeNickname}
                style={{ backgroundColor: "white" }}
              />
              {showCheckNumber && (
                <TextField
                  label="인증번호"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={checkNumber}
                  onChange={onChangeCheckNumber}
                  style={{ backgroundColor: "white" }}
                />
              )}
            </Box>

            <Box sx={{ display: "flex",flexDirection:"row",
                width:"100%",
                justifyContent: "space-between", marginTop: "32px" }}>
              <Button 
                sx={{
                    backgroundColor:"#114962",
                    color:"white",
                ":hover":{
                     backgroundColor:"#114962",
                     color:"white",
                }
                }}
              variant="contained" color="primary" onClick={onSendEmail} style={{ width: "48%" }}>
                인증메일 받기
              </Button>
              <Button 
                sx={{
                    backgroundColor:"#114962",
                    color:"white",
                ":hover":{
                     backgroundColor:"#114962",
                     color:"white",
                }
                }}
              variant="contained"  onClick={onSignup} style={{ width: "48%" }} disabled={!numberOk}>
                회원가입 하기
              </Button>
            </Box>
          </Box>
        </div>
      </PC>

      {/* Mobile UI */}
      <Mobile>
        <div style={{ width: "100%", minWidth: "300px" }}>
          <Box style={{ padding: "16px", backgroundColor: "#111111", color: "white", borderRadius: "8px" }}>
            <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <IoPerson size={20} color='#919090' />
              <Typography variant="h6" fontWeight={700}>회원가입</Typography>
            </Box>

            <Box style={{ marginTop: "16px" }}>
              <Typography variant="body1">커뮤니티 규칙(필수)</Typography>
              <Box style={{ backgroundColor: "#222222", padding: "8px", borderRadius: "4px", marginTop: "8px" }}>
                <div style={{ overflowY: "scroll", height: "150px" }}>{ruleTxt}</div>
                <FormControlLabel
                  control={<Checkbox 
                    sx={{
                        color:"white"
                    }}
                    onChange={(e) => setCommunityRule(e.target.checked)} />}
                  label="동의합니다."
                  style={{ color: "white" }}
                />
              </Box>
            </Box>

            <Box style={{ marginTop: "16px" }}>
              <Typography variant="body1">개인정보처리방침(필수)</Typography>
              <Box style={{ backgroundColor: "#222222", padding: "8px", borderRadius: "4px", marginTop: "8px" }}>
                <div style={{ overflowY: "scroll", height: "150px" }}>{ruleTxt}</div>
                <FormControlLabel
                  control={<Checkbox 
                    sx={{
                        color:"white"
                    }}
                    onChange={(e) => setPersonalRule(e.target.checked)} />}
                  label="동의합니다."
                  style={{ color: "white" }}
                />
              </Box>
            </Box>

            <Box style={{ marginTop: "16px" }}>
              <TextField
                label="이메일 주소"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={onChangeEmail}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="비밀번호"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={pw}
                onChange={onChangePw}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="비밀번호 확인"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={pwCheck}
                onChange={onChangePwCheck}
                style={{ backgroundColor: "white" }}
              />
              <TextField
                label="닉네임"
                variant="outlined"
                fullWidth
                margin="normal"
                value={nickname}
                onChange={onChangeNickname}
                style={{ backgroundColor: "white" }}
              />
              {showCheckNumber && (
                <Box style={{ marginBottom: "8px" }}>
                  <TextField
                    label="인증번호"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={checkNumber}
                    onChange={onChangeCheckNumber}
                    style={{ backgroundColor: "white" }}
                  />
                  
                </Box>
              )}
              <Box sx={{ display: "flex",flexDirection:"row",
                width:"100%",
                justifyContent: "space-between", marginTop: "16px" }}>
                <Button 
                sx={{
                    backgroundColor:"#114962",
                    color:"white",
                ":hover":{
                     backgroundColor:"#114962",
                     color:"white",
                }
                }}
                variant="contained" color="primary" onClick={onSendEmail} fullWidth style={{ marginRight: "8px" }}>
                  인증메일 받기
                </Button>
               {showCheckNumber&& <Button 
                  sx={{
                    backgroundColor:"#114962",
                    color:"white",
                ":hover":{
                     backgroundColor:"#114962",
                     color:"white",
                }
                }}
                variant="contained" color="primary" fullWidth onClick={onSignup} disabled={!numberOk}>
                  회원가입 하기
                </Button>}
              </Box>
            </Box>
          </Box>
        </div>
      </Mobile>
    </div>
  );
}

export default SignUpPage;
