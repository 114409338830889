import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formattedOrderTime, formattedRegDate } from '../../const/const';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../service/firebase';
import { useNavigate, useParams } from 'react-router-dom';

export default function BasicDeliveryTable({ tableHeads, rows, nowPageIdx }) {
  const [writers, setWriters] = React.useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const fetchUserByDocId = async (docId) => {
    try {
      if (docId) {
        const docRef = doc(db, "users", docId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const res = docSnapshot.data();
          return res.nickname || res.email;
        } else {
          console.error("No such document!");
          return null;
        }
      } else {
        return "";
      }
    } catch (error) {
      window.confirm(docId)
      console.error("Error fetching user by doc id: ", error, docId);
      return null;
    }
  };

  React.useEffect(() => {
    if (rows && rows.length > 0) {
      const fetchUserNicknames = async () => {
        const promises = rows.map((item) => fetchUserByDocId(item.ownerUserId));
        const nicknames = await Promise.all(promises);
        setWriters(nicknames);
      };

      fetchUserNicknames();
    }
  }, [rows]);

  const goViewWritePage = async (writeId, row) => {
    sessionStorage.setItem("viewWrite", JSON.stringify(row));
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`);
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" sx={{}}>
        <TableHead sx={{ backgroundColor: "#d9d9d9" }}>
          <TableRow>
            {tableHeads && tableHeads.map((th, index) => <TableCell key={index}>{th}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 ? rows.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
              onClick={() => { goViewWritePage(row.docId, row) }}
            >
              <TableCell component="th" scope="row">
                {rows.length - (idx)}
              </TableCell>
              <TableCell align="left">{row.registerDate??"-"}</TableCell>
              <TableCell align="left">{row.detailAddress ?? "-"}</TableCell>
              <TableCell align="left">{row.title ?? "-"}  {row.replies ? `(${row.replies.length})` : "(0)"}</TableCell>
              <TableCell align="left">{row.id??"-"}</TableCell>
              <TableCell align="left">{formattedRegDate(row.writeDate?row.writeDate:row.registerDate) ?? "-"}</TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={tableHeads.length} align="center">No data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
