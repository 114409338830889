import React, { useState } from 'react';
import { Box, MenuItem, Select, TextField, styled, useMediaQuery } from '@mui/material';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaSearch } from 'react-icons/fa';
import CustomWriteBtn from './Btn/CustomWriteBtn';
import { useParams } from 'react-router-dom';

function TableSearchBar({ onChangeSearchType, searchOptions, selectedSearchType, onSearch, inputValue, goWrite, onChangeInput }) {
  const CustomArrowDropDownIcon = styled(IoMdArrowDropdown)(({ theme }) => ({
    color: 'red',
  }));

  const [menuOpen, setMenuOpen] = useState(false);
  const params = useParams();
  const isMobile = useMediaQuery('(max-width:767px)');

  const getPlace = () => {
    if (params.postType === 'si-place') {
      return <div></div>;
    } else if (params.postType === 'dong-place') {
      return <div></div>;
    } else {
      return <CustomWriteBtn onClick={goWrite} />;
    }
  };

  const arrowIconBoxStyle = {
    width: '40px',
    minWidth: '30px',
    height: '100%',
    backgroundColor: '#002D82',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  const searchIconBoxStyle = {
    width: '40px',
    minWidth: '20px',
    height: '100%',
    backgroundColor: '#002D82',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  const handleSelectChange = (event) => {
    onChangeSearchType(event.target.value);
    setMenuOpen(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: isMobile ? '30px' : '33px',
        alignItems: 'center',
        gap: '16px',
        marginTop: '32px',
        marginBottom: '32px',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          border: '3px solid #002D82',
          width: isMobile ? '160px' : '320px',
          height: isMobile ? '26px' : '35.57px',
          boxSizing: 'content-box',
          margin: 0,
          padding: 0,
          borderRadius: '4px',
        }}
      >
        <Select
          open={menuOpen}
          onClose={() => {
            setMenuOpen(false);
          }}
          onOpen={() => {
            setMenuOpen(true);
          }}
          onChange={handleSelectChange}
          sx={{
            width: '100%',
            fontSize: isMobile ? '12px' : 'inherit',
            whiteSpace: 'nowrap',
            overflow: 'visible',
            textOverflow: 'unset',
            '& .MuiSelect-select': {
              whiteSpace: 'nowrap',
              overflow: 'visible',
              textOverflow: 'unset',
              display: 'flex',
              alignItems: 'center',
            },
          }}
          value={selectedSearchType}
          IconComponent={() => (
            <div
              style={arrowIconBoxStyle}
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <IoMdArrowDropdown color="white" size={20} />
            </div>
          )}
        >
          {searchOptions.map((op) => (
            <MenuItem
              key={op}
              value={op}
              sx={{
                fontSize: isMobile ? '12px' : 'inherit',
                whiteSpace: 'normal',
              }}
            >
              {op}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          border: '3px solid #002D82',
          width: isMobile ? '160px' : '320px',
          height: isMobile ? '26px' : '35.57px',
          boxSizing: 'content-box',
          margin: 0,
          padding: 0,
          borderRadius: '4px',
        }}
      >
        <TextField
          placeholder="검색어를 입력하세요"
          sx={{
            padding: 0,
            margin: 0,
            width: '100%',
            fontSize: isMobile ? '12px' : 'inherit',
          }}
          value={inputValue}
          onChange={onChangeInput}
          variant="standard"
          InputProps={{
            style: {
              fontSize: isMobile ? '12px' : 'inherit',
              padding: isMobile ? '0 8px' : '0',
              height: '100%',
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: isMobile ? '12px' : 'inherit',
            },
          }}
        />
        <div
          style={searchIconBoxStyle}
          onClick={onSearch}
        >
          <FaSearch
            size={14}
            style={{
              paddingBottom: '4px',
            }}
            color="white"
          />
        </div>
      </Box>

      {getPlace()}
    </div>
  );
}

export default TableSearchBar;

// import React, { useState } from 'react';
// import { Box, Button, Modal, Select, MenuItem, TextField, useMediaQuery } from '@mui/material';
// import { FaSearch } from 'react-icons/fa';
// import CustomWriteBtn from './Btn/CustomWriteBtn';
// import { useParams } from 'react-router-dom';

// function TableSearchBar({ onSearch }) {
//   const [filterOpen, setFilterOpen] = useState(false);
//   const [searchType, setSearchType] = useState('');
//   const [inputValue, setInputValue] = useState('');
//   const params = useParams();
//   const isMobile = useMediaQuery('(max-width:767px)');

//   const handleOpen = () => setFilterOpen(true);
//   const handleClose = () => setFilterOpen(false);
//   const handleSearch = () => {
//     onSearch(searchType, inputValue);
//     handleClose();
//   };

//   return (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         gap: '16px',
//         marginTop: '32px',
//         marginBottom: '32px',
//       }}
//     >
//       <Button
//         variant="contained"
//         sx={{ backgroundColor: '#002D82', color: 'white' }}
//         onClick={handleOpen}
//       >
//         검색필터
//       </Button>

//       <Modal
//         open={filterOpen}
//         onClose={handleClose}
//         aria-labelledby="filter-modal-title"
//         aria-describedby="filter-modal-description"
//       >
//         <Box
//           sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: 300,
//             bgcolor: 'background.paper',
//             border: '2px solid #000',
//             boxShadow: 24,
//             p: 4,
//             outline: 'none',
//           }}
//         >
//           <h2 id="filter-modal-title">검색 필터</h2>
//           <div id="filter-modal-description">
//             <Select
//               fullWidth
//               value={searchType}
//               onChange={(e) => setSearchType(e.target.value)}
//               displayEmpty
//             >
//               <MenuItem value="">검색 조건 선택</MenuItem>
//               <MenuItem value="place">수령 장소</MenuItem>
//               <MenuItem value="restaurant">음식점</MenuItem>
//               <MenuItem value="category">카테고리</MenuItem>
//               <MenuItem value="writer">글쓴이</MenuItem>
//             </Select>
//             <TextField
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               placeholder="검색어를 입력하세요"
//               value={inputValue}
//               onChange={(e) => setInputValue(e.target.value)}
//             />
//             <Button
//               fullWidth
//               variant="contained"
//               color="primary"
//               onClick={handleSearch}
//               sx={{ mt: 2 }}
//             >
//               검색
//             </Button>
//           </div>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

// export default TableSearchBar;
