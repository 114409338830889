
import { MenuItem, Select } from '@mui/material';
import React from 'react';

function HourSelect({value,onChange,disabled}) {
    const hours = Array.from({ length: 25 }, (_, index) => index.toString().padStart(2, '0'));
    // const minutes = Array.from({ length: 12 }, (_, index) => (index * 5).toString().padStart(2, '0'));
    return (
        // <select 
        // disabled={disabled}
        // value={value} onChange={onChange}>
        //   {hours.map((minute, index) => (
        //     <option key={index} value={minute}>
        //       {minute}
        //     </option>
        //   ))}
        // </select>
          <Select
          // disabled
          value={value}
          onChange={onChange}
          >
          {hours.map((hr, index) => (
                      <MenuItem key={index} value={hr}>
                        {hr}
                      </MenuItem>
                    ))}
          </Select>
      );
}

export default HourSelect;
