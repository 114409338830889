import React from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import BorderTxtField from '../components/BorderTxtField';
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
function TogetherRidePage(props) {
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
          }}>
        <Mobile>
        <div
        style={{
            height:"55px"
        }}
        />
        {/* 타이틀 구간 */}
            <div
            style={{
                display:"flex",
                flexDirection:"column",
                width:"calc(100% - 27px - 27px)",
                fontWeight:700,
                fontSize:"20px"
            }}
            >
                <span>강릉대학교</span>
                  
            <div
          style={{
            height: "2px"
          }}
        />
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#D9D9D9"
          }}
        />
                </div>
                <div
        style={{
            height:"22px"
        }}
        />
         <div
            style={{
                display:"flex",
                flexDirection:"row",
                width:"calc(100% - 27px - 27px)",
                gap:"32px"
            }}
            >
                <BorderTxtField placeholder={"아이디"}/>
                <BorderTxtField placeholder={"비밀번호"}/>

            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 27px - 27px)",
                gap:"calc(32px - 20px - 8px)"
            }}
            >
                <BorderTxtField placeholder={"출발지"}/>
                <FaLongArrowAltRight size={20}/>
                <BorderTxtField placeholder={"도착지"}/>

            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 27px - 27px)",
                gap:"8px"
            }}
            >
            <span>가는날 2024-04-10 (수)</span>
            <button
            style={{
                backgroundColor:"white",
                border:"2px solid #A5B2BA"
            }}
            >19시<MdKeyboardArrowDown/></button>
            <button
            style={{
                backgroundColor:"white",
                border:"2px solid #A5B2BA"
            }}
            >60분 <MdKeyboardArrowDown/></button>
            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 27px - 27px)",
                gap:"8px"
            }}
            ><BorderTxtField 
            width='100%'
            placeholder={"(선택)오픈채팅링크"}/></div>
             <div
        style={{
            height:"60px"
        }}
        />
                <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 27px - 27px)",
                gap:"8px"
            }}
            ><textarea
            placeholder='(선택) 글내용'
            style={{
                borderRadius:"8px",
                paddingTop:"8px",
            paddingLeft:"8px",
            border:"2px solid #104A62",
                width:"100%",
                height:"300px"
            }}
            /></div>

        </Mobile>
        <PC>
        <div
        style={{
            height:"55px"
        }}
        />
        {/* 타이틀 구간 */}
            <div
            style={{
                display:"flex",
                flexDirection:"column",
                width:"calc(100% - 80px - 80px)",
                fontWeight:700,
                fontSize:"20px"
            }}
            >
                <span>강릉대학교</span>
                  
            <div
          style={{
            height: "2px"
          }}
        />
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#D9D9D9"
          }}
        />
                </div>
                <div
        style={{
            height:"22px"
        }}
        />
         <div
            style={{
                display:"flex",
                flexDirection:"row",
                width:"calc(100% - 80px - 80px)",
                gap:"32px"
            }}
            >
                <BorderTxtField placeholder={"아이디"}/>
                <BorderTxtField placeholder={"비밀번호"}/>

            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 80px - 80px)",
                gap:"calc(32px - 20px - 8px)"
            }}
            >
                <BorderTxtField placeholder={"출발지"}/>
                <FaLongArrowAltRight size={20}/>
                <BorderTxtField placeholder={"도착지"}/>

            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 80px - 80px)",
                gap:"8px"
            }}
            >
            <span>가는날 2024-04-10 (수)</span>
            <button
            style={{
                backgroundColor:"white",
                border:"2px solid #A5B2BA"
            }}
            >19시<MdKeyboardArrowDown/></button>
            <button
            style={{
                backgroundColor:"white",
                border:"2px solid #A5B2BA"
            }}
            >60분 <MdKeyboardArrowDown/></button>
            </div>
            <div
        style={{
            height:"60px"
        }}
        />
            <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 80px - 80px)",
                gap:"8px"
            }}
            ><BorderTxtField 
            width='100%'
            placeholder={"(선택)오픈채팅링크"}/></div>
             <div
        style={{
            height:"60px"
        }}
        />
                <div
            style={{
                display:"flex",
                alignItems:"center",
                flexDirection:"row",
                width:"calc(100% - 80px - 80px)",
                gap:"8px"
            }}
            ><textarea
            placeholder='(선택) 글내용'
            style={{
                borderRadius:"8px",
                paddingTop:"8px",
            paddingLeft:"8px",
            border:"2px solid #104A62",
                width:"100%",
                height:"300px"
            }}
            /></div>
        </PC>
            
        </div>
    );
}

export default TogetherRidePage;