import React, { useEffect, useState } from 'react';
import PageLayout from '../Layouts/PageLayout';
import TitleBar from '../components/TopBar/TitleBar';
import BorderTxtField from '../components/BorderTxtField';
import BoardTxtArea from '../components/BoardTxtArea';
import { completedWrite, formatForDbRgDate, mdWidth, noWriteTxtField, pcEmpty, smWidth } from '../const/const';
import { db } from '../service/firebase';
import { doc, setDoc } from 'firebase/firestore';
import uuid from 'react-uuid';
import { useRecoilState } from 'recoil';
import { mainLoadingState } from '../atom/atom';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { PC, Mobile } from '../ReactiveLayout';
import moment from 'moment';

function InquiryWritePage(props) {
    const [id, setId] = useState("ㅇㅇ");
    const [pw, setPw] = useState("1111");
    const [radio, setRadio] = useState("0");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
    const userId = sessionStorage.getItem("userId");
    const userPw = sessionStorage.getItem("userPw");
    const navigate = useNavigate();

    const onChangeRadio = (e) => {
        setRadio(e.target.value);
    };

    const writePost = async () => {
        setMainLoading(true);
        const writeCondition = title.trim() !== "" && content !== "";
        if (writeCondition) {
            const docId = uuid();
            await setDoc(doc(db, "inquiry", docId), {
                member: false,
                id: id,
                pw: pw,
                replies: [],
                type: radio,
                title: title,
                content: content,
                registerDate: moment().format("YYYY.MM.DD hh:mm:ss")
            });
            navigate(-1);
            window.confirm(completedWrite);
        } else {
            window.confirm(noWriteTxtField);
        }
        setMainLoading(false);
    };

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const onChangeContent = (e) => {
        setContent(e.target.value);
    };

    useEffect(() => {
        if (userId) {
            // 로그인 된 상태
            setId(userId);
            setPw(userPw);
        } else {
            // 로그인 안된 상태
            setId("ㅇㅇ");
            setPw("1111");
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            {/* PC UI */}
            <PC>
                <div style={{ width: mdWidth, minWidth: "900px" }}>
                    <TitleBar title={"문의사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            placeholder={"아이디"}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                        <BorderTxtField
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            value={title}
                            onChange={onChangeTitle}
                            placeholder={"제목"}
                            width='calc(193px + 193px + 16px + 16px)'
                        />
                    </div>
                    <BoardTxtArea
                        value={content}
                        onChange={onChangeContent}
                        width={"60%"}
                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "16px"
                        }}
                    >
                        <input
                            checked={radio === "0"}
                            value={"0"}
                            type='radio'
                            onClick={onChangeRadio}
                            style={{ accentColor: radio === "0" ? "#104A62" : undefined }}
                        /> <span>공개</span>
                        <div style={{ width: "32px" }} />
                        <input
                            checked={radio === "1"}
                            value={"1"}
                            type='radio'
                            onClick={onChangeRadio}
                            style={{ accentColor: radio === "1" ? "#104A62" : undefined }}
                        /> <span>비공개</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                border: "1px solid #104A62",
                                color: "#104A62",
                                fontSize: "16px",
                                width: "100px",
                                ":hover": {
                                    border: "1px solid #104A62",
                                    color: "#104A62",
                                    fontSize: "16px",
                                    width: "100px",
                                }
                            }}
                            onClick={writePost}
                        >등록</Button>
                    </div>
                </div>
            </PC>

            {/* Mobile UI */}
            <Mobile>
            <div style={{ width: smWidth, minWidth: "280px" }}>
            <div style={{ height: pcEmpty }} />
                    <TitleBar title={"문의사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            placeholder={"아이디"}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            fullWidth
                        />
                        <div
                        style={{
                            height:"16px"
                        }}
                        />
                        <BorderTxtField
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                            fullWidth
                            style={{ marginTop: "16px" }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            value={title}
                            onChange={onChangeTitle}
                            placeholder={"제목"}
                            fullWidth
                        />
                    </div>
                    <BoardTxtArea
                        value={content}
                        onChange={onChangeContent}
                        width={"96%"}
                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems:"center",
                            marginTop: "16px"
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                checked={radio === "0"}
                                value={"0"}
                                type='radio'
                                onClick={onChangeRadio}
                                style={{ accentColor: radio === "0" ? "#104A62" : undefined }}
                            /> <span>공개</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", }}>
                            <input
                                checked={radio === "1"}
                                value={"1"}
                                type='radio'
                                onClick={onChangeRadio}
                                style={{ accentColor: radio === "1" ? "#104A62" : undefined }}
                            /> <span>비공개</span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "center",
                            marginTop: "20px"
                        }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                border: "1px solid #104A62",
                                color: "#104A62",
                                fontSize: "16px",
                                width: "100%",
                                ":hover": {
                                    border: "1px solid #104A62",
                                    color: "#104A62",
                                    fontSize: "16px",
                                }
                            }}
                            onClick={writePost}
                        >등록</Button>
                    </div>
                </div>
            </Mobile>
        </div>
    );
}

export default InquiryWritePage;
