import { Typography, Tabs, Tab, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import FavoriteBtn from '../Btn/FavoriteBtn';

function MbFavoriteBar() {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (location.pathname.includes('delivery-table')) {
            setValue(0);
        } else if (location.pathname.includes('taxi-table')) {
            setValue(1);
        } else if (location.pathname.includes('community-table')) {
            setValue(2);
        } 
        // else {
        //     setValue(0);
        // }
    }, [location.pathname]);

    const handleChange = (newValue) => {
        setValue(newValue);
        switch (newValue) {
            // case 0:
            //     navigate(`/${params.fullAddress}/${params.si}/${params.dong}`);
            //     break;
            case 0:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/delivery-table/${params.postType}/1`);
                break;
            case 1:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/taxi-table/${params.postType}/1`);
                break;
            case 2:
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/community-table/${params.postType}/1`);
                break;
            default:
                break;
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
                style={{
                    width: '100%',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop:"10px",
                    marginBottom: '10px' // Add spacing between buttons and address button
                }}
            >
                <Button
                variant='text'
                    // sx={MbResAreaSx}
                    onClick={() => handleChange(0)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 0||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 0||value === -1 ? '#114962':"white",
                        color: value === 0||value === -1 ?"white":"#114962"
                    }}
                >
                    배달팟
                </Button>
                <Button
                    onClick={() => handleChange(1)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 1||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 1||value === -1 ? '#114962':"white",
                        color: value === 1||value === -1 ?"white":"#114962"
                    }}
                >
                    택시팟
                </Button>
                <Button
                    onClick={() => handleChange(2)}
                    style={{
                        textAlign:"center",
                        flex: 1,
                        margin: '0 5px',
                        border: value === 2||value === -1 ?   '1px solid white':'1px solid #114962',
                        backgroundColor:  value === 2||value === -1 ? '#114962':"white",
                        color: value === 2||value === -1 ?"white":"#114962"
                    }}
                >
                    커뮤니티
                </Button>
            </div>
            <div style={{ 
                width: "100%", 
                display: "flex", 
                flexDirection: "row", 
                alignItems: "center", 
                gap: "30px", 
                height: "50px", 
                marginBottom: "30px", 
                borderBottom: "1px solid #A5B2BA" 
            }}>
                <div style={{ flexShrink: 0 }}>
                    <FavoriteBtn />
                </div>
                <Typography
                    variant='h6'
                    fontWeight={600}
                    style={{
                        flexGrow: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {params.fullAddress}
                </Typography>
            </div>
        </div>
    );
}

export default MbFavoriteBar;
