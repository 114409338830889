import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import CustomLayout from './CustomLayout';
import { RecoilRoot } from 'recoil';
import Food from './Food';
import Write from './Write';
import WriteCompleted from './WriteCompleted';
import TogetherOrderPage from './pages/TogetherOrderPage';
import TaxiPotPage from './pages/TaxiPotPage';
import WriteDeliveryPage from './pages/WriteDeliveryPage';
import WriteTaxiPage from './pages/WriteTaxiPage';
import LoginPage from './pages/LoginPage';
import InitPage from './pages/InitPage';
import SignInPage from './pages/SignInPage';
import EmainContinuePage from './pages/EmainContinuePage';
import ShowDeliveryItemPage from './pages/ShowDeliveryItemPage';
import TypeSelectPage from './pages/TypeSelectPage';
import ReplyPage from './pages/ReplyDeliveryPage';
import CommunityPage from './pages/CommunityPage';
import TaxiPotPg from './pages/TaxiPotPg';
import DeliveryTablePage from './pages/DeliveryTablePage';
import TogetherRidePage from './pages/TogetherRidePage';
import TogetherDelivery from './pages/TogetherDelivery';
import WebLoginPage from './pages/WebLoginPage';
import WebSiginPage from './pages/WebSiginPage';
import ReplyDeliveryPage from './pages/ReplyDeliveryPage';
import ReplyTaxiPage from './pages/ReplyTaxiPage';
import ReplyCommunityPage from './pages/ReplyCommunityPage';
import TaxiTablePage from './pages/TaxiTablePage';
import CommunityTablePage from './pages/CommunityTablePage';
import TypeSelectTaxiPage from './pages/TypeSelectTaxiPage';
import TypeSelectCommunityPage from './pages/TypeSelectCommunityPage';
import LoadingPage from './pages/LoadingPage';
import NotiPage from './pages/NotiPage';
import NotiWritePage from './pages/NotiWritePage';
import InquiryPage from './pages/InquiryPage';
import InquiryWritePage from './pages/InquiryWritePage';
import MembershipPage from './pages/MembershipPage';
import SignUpPage from './pages/SignUpPage';
import DeliveryEditPage from './pages/DeliveryEditPage';
import TaxiEditPage from './pages/TaxiEditPage';
import CommunityEditPage from './pages/CommunityEditPage';
import NotiShowPage from './pages/NotiShowPage';
import InquiryShowPage from './pages/InquiryShowPage';
import TablePage from './pages/TablePage';
import WritePage from './pages/WritePage';
import ViewWritePage from './pages/ViewWritePage';
import EditDeliveryPage from './pages/EditDeliveryPage';
import EditPage from './pages/EditPage';
import FavPg from './pages/FavPg';


//1.라우터를 생성한다.
const router = createBrowserRouter(

  [  
    { 
      element: <LoadingPage/>, path: "/" },
{
  element:<CustomLayout />,
  //내부 화면만 교체
  //1.메인
  children:[
     {
      
  path: "/:fullAddress/:si/:dong",
  element: <App />,
},
//2. 테이블 화면
{
      
  path: "/:fullAddress/:si/:dong/:tableType/:postType/:pageId",
  element: <TablePage/>,
},
{
  path: "/:fullAddress/:si/:dong/:tableType/write",
  element: <WritePage/>
},
//글쓴거 보여주는 페이지
{
  path: "/:fullAddress/:si/:dong/:tableType/:postType/view-write/:writeId",
  element: <ViewWritePage/>
},
//글쓴거 보여주는 페이지
{
  path: "/:fullAddress/:si/:dong/:tableType/:postType/edit/:writeId",
  element: <EditPage/>
},
/////
{
      
  path: "/:area/taxi-table",
  element: <TaxiTablePage/>,
},
{
      
  path: "/:area/community-table",
  element: <CommunityTablePage/>,
},
{
      
  path: "/:area/taxi-table",
  element: <TaxiTablePage/>,
},
{
      
  path: "/:area/community-table",
  element: <CommunityTablePage/>,
},

//공지사항
{
  path: "/:fullAddress/:si/:dong/noti-page",
  element: <NotiPage/>
},
{
  path: "/:fullAddress/:si/:dong/noti-write",
  element: <NotiWritePage/>
}
,

{
  path: "/:fullAddress/:si/:dong/inquiry-page",
  element: <InquiryPage/>
},

{
  path: "/:area/membership-page",
  element: <MembershipPage/>
},
{
  path: "/:fullAddress/:si/:dong/inquiry-write",
  element: <InquiryWritePage/>
}
,
{
  path: "/:area/type-select-taxi",
  element: <TypeSelectTaxiPage/>
}
,
{
  path: "/:area/type-select-community",
  element: <TypeSelectCommunityPage/>
}
,
{
  path: "/:area/reply-delivery",
  element: <ReplyDeliveryPage/>
}
,
{
  path: "/:area/reply-taxi",
  element: <ReplyTaxiPage/>
},
{
  path: "/:area/reply-community",
  element: <ReplyCommunityPage/>
}
,
{
  path: "/:area/community",
  element: <CommunityPage/>
},
{
  path: "/:area/taxi-pot-pg",
  element: <TaxiPotPg/>
},
{
  path: "/:area/together-ride",
  element: <TogetherRidePage/>
},
{
  path: "/:area/together-delivery",
  element: <TogetherDelivery/>
}
,
{
  path: "/:area/sign-up",
  element: <SignUpPage/>
},
{
  path: "/:area/edit-delivery",
  element: <DeliveryEditPage/>
},

{
  path: "/:area/edit-taxi",
  element: <TaxiEditPage/>
},

{
  path: "/:area/edit-community",
  element: <CommunityEditPage/>
},

{
  path: "/:fullAddress/:si/:dong/noti-show",
  element: <NotiShowPage/>
},
{
  path: "/:fullAddress/:si/:dong/inquiry-show",
  element: <InquiryShowPage/>
},
{
  path: "/:fullAddress/:si/:dong/fav-page",
  element: <FavPg/>
},
]
}

],

);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<RecoilRoot >
          <RouterProvider   router={router} />
     
    </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
