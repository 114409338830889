import React from 'react';
import { IoIosArrowDown } from "react-icons/io";
function IconBtn({icon,onClick,color="white"}) {
    return (
        <button
        style={{
            fontSize:"12px",
            // width:"65px",
            height:"36px",
            display:"flex",
            gap:"4px",
            alignItems:"center",
            justifyContent:"center",
            backgroundColor:"transparent",
            color:color,
            border:"none",
            fontWeight:700,
            cursor:"pointer"
        }}
        onClick={onClick}
        >
            {icon}
        </button>
    );
}

export default IconBtn;