export const goBtnSx={
    width:"100px",
    border:"2px solid #A5B2BA",
    color:"black",
    ":hover":{
        border:"2px solid #A5B2BA",
        color:"black",
    }
}
export const goBtnActiveSx={
    width:"100px",
    border:"2px solid #114962",
    backgroundColor:"#114962",
    color:"white",
    ":hover":{
        border:"2px solid #114962",
        
    backgroundColor:"#114962",
        color:"white",
    }
}
export const goMbBtnSx={
    width:"60px",
    border:"2px solid #A5B2BA",
    color:"black",
    ":hover":{
        border:"2px solid #A5B2BA",
        color:"black",
    }
}
export const goMbBtnActiveSx={
    width:"60px",
    border:"2px solid #114962",
    backgroundColor:"#114962",
    color:"white",
    ":hover":{
        border:"2px solid #114962",
        
    backgroundColor:"#114962",
        color:"white",
    }
}
export const searchBtnSx={
    width:"160px",
    backgroundColor:"#002D82",
    color:"white",
    fontSize:"16px",
    ":hover":{
        
    backgroundColor:"#002D82",
        color:"white",
    }
}
export const PcResAreaSx={
    width:"100%",
    textAlign:"left",
    display: "block",
whiteSpace:"nowrap",
overflow:"hidden",
textOverflow:"ellipsis",
    fontSize:"20px",
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-start",
    color:"black",
    fontWeight:700}
    export const MbResAreaSx={
        // width:"100%",
        textAlign:"left",
        display: "block",
    whiteSpace:"nowrap",
    overflow:"hidden",
    textOverflow:"ellipsis",
        fontSize:"14px",
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start",
        color:"black",
        fontWeight:700}