import React from 'react';
import { Card, CardContent, Typography, Divider } from '@mui/material';

function CommunityBorderTable({ titleList, contentList , maxWidth=null,  minWidth="360px",}) {
    const handleLinkClick = (e, url) => {
        e.preventDefault(); // Prevent the default link behavior
        if (window.confirm("이동하시겠습니까?")) {
            window.location.href = url; // Navigate to the URL if the user confirms
        }
    };

    return (
        <Card
            sx={{
                width:"calc(100% - 16px)",
                minWidth: minWidth,
                maxWidth:maxWidth,
                borderRadius: '20px',
                border: "1px solid black",
                marginBottom: '16px',
                overflow: 'hidden'
            }}
        >
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', height: "36px", borderBottom: "1px solid black" }}>
                    <Typography
                        variant='h6'
                        sx={{ width: '120px', paddingRight: '8px', textAlign: 'center' }}>
                        카테고리
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: "black" }} />
                    <Typography
                        variant='h6'
                        sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px', textAlign: 'center' }}>
                        {contentList[0]}
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height: "36px" }}>
                    <Typography
                        variant='h6'
                        sx={{ width: '120px', paddingRight: '8px', textAlign: 'center' }}>
                        오픈채팅 링크
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: "black" }} />
                   {contentList[1]&& <Typography
                 variant={'h6'}
                    sx={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: '8px', textAlign: 'center' }}>
                        <a   href={contentList[1].startsWith('http') ? contentList[1] : `https://${contentList[1]}`}target="_blank" rel="noopener noreferrer">{contentList[1]}</a>
                    </Typography>}
                </div>
            </CardContent>
        </Card>
    );
}

export default CommunityBorderTable;
