import React from 'react';

function DottedHorizontalDivider({thick=true,width="100%"}) {
    return (
        <hr
        style={{
            border: "none",
            borderTop: "3px dotted #A5B2BA",
            color: "#fff",
            backgroundColor: thick?"#fff":null,
            height: "1px",
            width: width

        }}
    />
    );
}

export default DottedHorizontalDivider;