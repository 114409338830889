import { Button } from '@mui/material';
import React from 'react';

function TxtBtn({title,onClick,color="white"}) {
    return (
        <Button
        sx={{
          marginLeft:"16px",
          color:color,
          fontSize:"16px"
        }}
        onClick={onClick}
        >{title}</Button>
    );
}

export default TxtBtn;