import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC91ZulB_1chq4Hv3aJAaLeKhnX2cvCUWI",
    authDomain: "togethereat-bf6e8.firebaseapp.com",
    projectId: "togethereat-bf6e8",
    storageBucket: "togethereat-bf6e8.appspot.com",
    messagingSenderId: "1058157080540",
    appId: "1:1058157080540:web:ba8209070da1adcc7f6919"
  };
  
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db=getFirestore(app)
export const storage = getStorage(app)

// {
//   "hosting": {
//     "public": "build",
//     "headers": [ {
//       "source" : "**/*.@(eot|otf|ttf|ttc|woff|font.css)",
//       "headers" : [ {
//         "key" : "Access-Control-Allow-Origin",
//         "value" : "*"
//       } ]
//     }, {
//       "source" : "**/*.@(js|css)",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=604800"
//       } ]
//     }, {
//       "source" : "**/*.@(jpg|jpeg|gif|png)",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=604800"
//       } ]
//     }, {
//       "source" : "404.html",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=300"
//       } ]
//     } ],
//     "ignore": [
//       "firebase.json",
//       "**/.*",
//       "**/node_modules/**"
//     ],
//     "rewrites": [
//       {
//         "source": "**",
//         "destination": "/index.html"
//       }
//     ]
//   }
// }
