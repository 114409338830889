import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formattedOrderTime, formattedRegDate, postsPerPage } from '../../const/const';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../service/firebase';
import { useNavigate, useParams } from 'react-router-dom';



export default function BasicTaxiTable({tableHeads,rows,nowPageIdx}) {
  const [writers, setWriters] = React.useState([]);
  const navigate=useNavigate()
  const params=useParams()
  const fetchUserByDocId = async (docId) => {
    try {
      if (docId) {
          const docRef = doc(db, "users", docId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const res = docSnapshot.data();
            return res.nickname || res.email;
        } else {
            console.error("No such document!");
            return null;
        }
      }else{
        return ""
      }
      
    } catch (error) {
      window.confirm(docId)
        console.error("Error fetching user by doc id: ", error,docId);
        return null;
    }
};
// const getUserNickname=async(docId)=>{
//     const res=await fetchUsersByDocId(docId)
//     console.log("글쓴이를 찾자",res)
//     setWriter(res)
//     return res
// }
const formattedGoTime = (dateString) => {
    const currentDate = new Date();
    const postDate = new Date(dateString);
    if (dateString === ""||dateString===undefined) {
        return "-"
     }
    else {
        const year = postDate.getFullYear();
        const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
        const day = postDate.getDate().toString().padStart(2, '0');
        const hours = postDate.getHours().toString().padStart(2, '0');
        const minutes = postDate.getMinutes().toString().padStart(2, '0');

        // Check if year is different
        if (postDate.getFullYear() !== currentDate.getFullYear()) {
            return `${year}/${month}/${day} ${hours}:${minutes}`;
        } else {
            const firstList = dateString.split(" ")[0].split(".")
            const exceptYear = firstList[1] + "." + firstList[2]
            return exceptYear + " " + dateString.split(" ")[1]
        }

    }

}
React.useEffect(() => {
  // if (rows) {
  //    const fetchUserNicknames = async () => {
  //       const promises = rows.map((item) => fetchUserByDocId(item.ownerUserId));
  //       const nicknames = await Promise.all(promises);
  //       setWriters(nicknames);
  //   };

  //   fetchUserNicknames();
  // }
   
}, [rows]);
const goViewWritePage=async(row)=>{
  sessionStorage.setItem("viewWrite",JSON.stringify(row))
  navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`)
}
  return (
    <TableContainer 
    
    component={Paper}>
      <Table aria-label="simple table" sx={{
      }}>
        <TableHead
        sx={{
            backgroundColor:"#d9d9d9"
        }}
        >
          <TableRow>
            {tableHeads&&tableHeads.map(th=><TableCell>{th}</TableCell>)}
        
          </TableRow>
        </TableHead>
        <TableBody>
          {rows&&rows.map((row,idx) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },
            
            cursor:"pointer"}}
            >
              <TableCell component="th" scope="row"
              onClick={()=>{goViewWritePage(row)}}
              >
                {(rows.length-idx)+(postsPerPage*(nowPageIdx))}
              </TableCell>
              <TableCell align="left"
              onClick={()=>{goViewWritePage(row)}}
              >{row.registerDate}</TableCell>
              <TableCell align="left"
              onClick={()=>{goViewWritePage(row)}}
              >{row.title?row.title.split("~")[0]:"-"}~{row.title?row.title.split("~")[1]:"-"}{row.replies ? `(${row.replies.length})` : "(0)"}</TableCell>
              <TableCell align="left"
              onClick={()=>{goViewWritePage(row)}}
              >{row.id===undefined||!row.id?"-":row.id}</TableCell>
              <TableCell align="left"
              onClick={()=>{goViewWritePage(row)}}
              >{row.writeDate}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}