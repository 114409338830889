import React, { useEffect, useState } from 'react';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';

function WriterBox({item}) {
    const [userList, setUserList] = useState([]);
    const [ownerEmail, setOwnerEmail] = useState("");

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const users = [];
                querySnapshot.forEach((doc) => {
                    users.push({ id: doc.id, ...doc.data() });
                });
                setUserList(users);
            } catch (error) {
                console.error("Error fetching users: ", error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (userList.length > 0) {
            const owner = userList.find(user => user.id === item.ownerUserId);
            if (owner) {
                setOwnerEmail(owner.email);
            }
        }
    }, [userList, item.ownerUserId]);

    return (
        <span
        style={{
            // width:"50%"
        }}
        >{ownerEmail}</span>
    );
}

export default WriterBox;