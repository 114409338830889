import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../atom/atom';

function AllDayToggle({height,toggle,clickToggle,fontSize,}) {
   
    return (
        <ul
        style={{
            display:"flex",
            listStyle:"none",
            margin:0,
            padding:0,
            border:"1px solid #d9d9d9",
            height:height,
            overflow:"hidden",
           
        }}
        >
        <li><button
        id='0'
        style={{fontSize:fontSize, height:height,
            border:"none",
            backgroundColor:toggle==="0"?"#d9d9d9":"white"
        }}
        onClick={(e)=>{clickToggle(e)}}
        >전체글</button></li>
        <li><button
        id='1'
        style={{
            fontSize:fontSize, height:height,
            border:"none",
            backgroundColor:toggle==="1"?"#d9d9d9":"white"
            // border:"1px solid #d9d9d9"
        }}
        onClick={(e)=>{clickToggle(e)}}

        >일정순</button></li>
        </ul>
    );
}

export default AllDayToggle;