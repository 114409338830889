import { Button, Card, CardContent, List, ListItem } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../../atom/atom';
import { useNavigate, useParams } from 'react-router-dom';
import MoreBtn from '../Btn/MoreBtn';

function NotificationCard({list}) {
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
 const navigate=useNavigate()
 const params=useParams()
    return (
        <Card 
        variant='outlined'
        sx={{
          height:"100%"
        }}
        >
          <CardContent
           sx={{
            padding:"10px",
            margin:0,
          }}
          >
            <div
            style={{
                height:"50px",
                fontSize:"20px",
                display:"flex",
                alignItems:"center",
                fontWeight:600,
           
            }}
            >공지 사항
            
            <MoreBtn
            onClick={()=>{
              navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-page`)
            }}
            /></div>
              <List sx={{
                padding:0,
                margin:0,
              //  overflowY:"scroll",
               height:"calc(46px * 3)",
            width: '100%', bgcolor: 'background.paper' }}>
         
      {list.map((item,idx)=><ListItem
      key={idx}
      >
     
     <Button
     
     sx={{
        display:"flex",
justifyContent:"flex-start",
        textAlign:"left",
        width:"100%",
        color:"black",
        fontWeight:"600"
     }}
     onClick={()=>{ 
      // console.log("공지사항 item>>>",JSON.stringify(item))
      // if (params&&params.fullAddress && params.si && params.dong&&item) {
      //   sessionStorage.setItem("noti", JSON.stringify(item));
      //   navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-show`);
      // } else {
      //   console.error('URL 파라미터가 설정되지 않았습니다.');
      // }
      navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-page`)
        
      }}
     >
        <span>{item.title}</span>
        </Button>
      </ListItem>)}
  
    </List>
        </CardContent></Card>
    );
}

export default NotificationCard;