import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../atom/atom';
import { useNavigate, useParams } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server'; // ReactDOMServer 임포트
import { Card, CardContent, List, ListItem, Button, Typography, Box, Divider, Pagination } from '@mui/material';

const { kakao } = window ;

const CustomMap = (props) => {
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const [selected, setSelected] = useState("");
 const params=useParams()
  const navigate = useNavigate();
  let markers = [];

  useEffect(() => {
    const mapContainer = document.getElementById("map");
    const mapOption = {
      center: new kakao.maps.LatLng(37.566826, 126.9786567),
      level: 3
    };

    const map = new kakao.maps.Map(mapContainer, mapOption);
    const ps = new kakao.maps.services.Places();
    const infowindow = new kakao.maps.InfoWindow({ zIndex: 1 });

    searchPlaces();

    function searchPlaces() {
      let keyword = props.searchKeyword;

      if (!keyword.replace(/^\s+|\s+$/g, "")) {
        return false;
      }

      ps.keywordSearch(keyword, placesSearchCB);
    }

    function placesSearchCB(data, status, pagination) {
      if (status === kakao.maps.services.Status.OK) {
        displayPlaces(data);
        displayPagination(pagination);
      } else if (status === kakao.maps.services.Status.ZERO_RESULT) {
        alert('검색 결과가 존재하지 않습니다.');
        return;
      } else if (status === kakao.maps.services.Status.ERROR) {
        alert('검색 결과 중 오류가 발생했습니다.');
        return;
      }
    }

    function displayPlaces(places) {
      const listEl = document.getElementById('places-list');
      const resultEl = document.getElementById('search-result');
      const fragment = document.createDocumentFragment();
      const bounds = new kakao.maps.LatLngBounds();

      listEl && removeAllChildNods(listEl);
      removeMarker();

      for (var i = 0; i < places.length; i++) {
        let placePosition = new kakao.maps.LatLng(places[i].y, places[i].x);
        let marker = addMarker(placePosition, i, undefined);
        let itemEl = getListItem(i, places[i]);

        bounds.extend(placePosition);

        (function(marker, title) {
          kakao.maps.event.addListener(marker, 'mouseover', function() {
            displayInfowindow(marker, title);
          });

          kakao.maps.event.addListener(marker, 'mouseout', function() {
            infowindow.close();
          });

          itemEl.onmouseover = function () {
            displayInfowindow(marker, title);
          };

          itemEl.onmouseout = function () {
            infowindow.close();
          };

        })(marker, places[i].place_name);

        fragment.appendChild(itemEl);
      }

      listEl && listEl.appendChild(fragment);
      if (resultEl) {
        resultEl.scrollTop = 0;
      }

      map.setBounds(bounds);
    }

    function extractCityFromAddress(address) {
      const addressParts = address.split(' ');
      const cityIndex = addressParts.findIndex(part => part.match(/시$/));
      if (cityIndex !== -1) {
        return addressParts[cityIndex];
      } else {
        return addressParts[0];
      }
    }

    function extractDongFromAddress(address) {
      const addressParts = address.split(' ');
      const dongRegex = /[동|읍|면]$/;
      const dongIndex = addressParts.findIndex(part => dongRegex.test(part));
      if (dongIndex !== -1) {
        return addressParts[dongIndex];
      } else {
        // 만약 동, 읍, 면이 포함되지 않은 경우, 마지막 부분을 반환
        return addressParts[addressParts.length - 1];
      }
    }
    function getListItem(index, places) {
      const el = document.createElement('li');
      el.style.display = 'flex';
    
      const button = document.createElement('button');
      button.style.backgroundColor = 'transparent';
      button.style.color = 'black';
      button.style.border = 'none';
      button.style.padding = '10px';
      button.style.display = 'flex';
      button.style.flexDirection = 'column';
      button.style.width = '100%';
      button.style.alignItems = 'flex-start';
      button.style.cursor = 'pointer';
      button.style.borderTop = '1px solid grey';
    
      button.id = places.place_url;
      button.addEventListener('click', (e) => {
        const newAddress = {
          si: extractCityFromAddress(places.address_name),
          dong: extractDongFromAddress(places.address_name),
          fullAddress: places.place_name
        };
    
        // setNowAddress(newAddress);
        // setSelected(places.place_name);
        navigate(`/${newAddress.fullAddress}/${newAddress.si}/${newAddress.dong}/${"delivery-table"}/${"meeting-place"}/1`);
      });
    
      const itemContent = (
        <Box className="info" sx={{ width: '100%' }}>
          <Typography variant="body1" component="span" className={`marker marker_${index + 1}`} sx={{ fontWeight: 'bold', marginRight: 1 }}>
            {index + 1}
          </Typography>
          <Typography variant="h6" component="div" className="info-item place-name">
            {places.place_name}
          </Typography>
          {places.road_address_name ? (
            <>
              <Typography variant="body2" component="div" className="info-item road-address-name">
                {places.road_address_name}
              </Typography>
              <Typography variant="body2" component="div" className="info-item address-name">
                {places.address_name}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" component="div" className="info-item address-name">
              {places.address_name}
            </Typography>
          )}
          <Typography variant="body2" component="div" className="info-item tel">
            {places.phone}
          </Typography>
        </Box>
      );
    
      // Create a React element and render it to a string
      const reactElement = React.createElement(ListItem, { component: 'div' }, itemContent);
      button.innerHTML = ReactDOMServer.renderToString(reactElement);
    
      el.appendChild(button);
      el.className = 'item';
    
      return el;
    }

    function addMarker(position, idx, title) {
      var imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_number_blue.png',
          imageSize = new kakao.maps.Size(36, 37),
          imgOptions =  {
            spriteSize: new kakao.maps.Size(36, 691),
            spriteOrigin: new kakao.maps.Point(0, (idx * 46) + 10),
            offset: new kakao.maps.Point(13, 37)
          },
          markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions),
          marker = new kakao.maps.Marker({
            position: position,
            image: markerImage 
          });

      marker.setMap(map);
      markers.push(marker);

      return marker;
    }

    function removeMarker() {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      markers = [];
    }

    function displayPagination(pagination) {
      const paginationEl = document.getElementById('pagination');
      let fragment = document.createDocumentFragment();
      let i; 

      while (paginationEl.hasChildNodes()) {
        paginationEl.lastChild && paginationEl.removeChild(paginationEl.lastChild);
      }

      for (i = 1; i <= pagination.last; i++) {
        const el = document.createElement('a');
        el.style.padding = '16px';
        el.innerHTML = i.toString();

        if (i === pagination.current) {
          el.className = 'on';
        } else {
          el.onclick = (function(i) {
            return function() {
              pagination.gotoPage(i);
            }
          })(i);
        }

        fragment.appendChild(el);
      }
      paginationEl.appendChild(fragment);
    }

    function displayInfowindow(marker, title) {
      const content = '<div style="padding:5px;z-index:1;" class="marker-title">' + title + '</div>';

      infowindow.setContent(content);
      infowindow.open(map, marker);
    }

    function removeAllChildNods(el) {
      while (el.hasChildNodes()) {
        el.lastChild && el.removeChild(el.lastChild);
      }
    }

  }, [props.searchKeyword]);

  return (
    <Card>
      <CardContent className="map-container">
        <Box id="map" className="map" sx={{ height: '400px', marginBottom: '16px' }}></Box>
        <Box id="search-result">
          <Typography variant="h6" className="result-text">
            현재 선택된 지역 : {selected}
          </Typography>
          <Divider />
          <Box className="scroll-wrapper" sx={{ maxHeight: '300px', overflowY: 'auto' }}>
            <List id="places-list"></List>
          </Box>
          <Box id="pagination" sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomMap;
