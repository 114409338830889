import React, { useEffect, useState } from 'react';
import { IoMdArrowDropright } from "react-icons/io";
import PotBtn from '.././PotBtn';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../../atom/atom';
import GoDeliveryPotBtn from '../Btn/GoDeliveryPotBtn';
import GoTaxiPotBtn from '../Btn/GoTaxiPotBtn';
import GoCommunityPotBtn from '../Btn/GoCommunityPotBtn';
import { Button } from '@mui/material';
import { PcResAreaSx } from '../../const/BtnStyle';
import MoreBtn from '../Btn/MoreBtn';
import { mdWidth } from '../../const/const';
function PcResArea({padding="80px",noArrow=false,goDelivery,
componentType="md",
nowPotName,
goTaxi,
goCommunity}) {
  
    // const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const params=useParams()
const [actives,setActives]=useState([false,false,false])
const navigate=useNavigate()
const getNowType=()=>{
const nowTableType=params.tableType
console.log("get active",nowTableType)
    if (nowTableType==="delivery-table") {
        return [true,false,false]
    }
    else if(nowTableType==="taxi-table"){
        return [false,true,false]
    }else{
        return [false,false,true]
    }
}
useEffect(()=>{
  const res=  getNowType()
  setActives(res)
},[])
const goDeliveryPg = () => {
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType??"delivery-table"}/${params.postType??"meeting-place"}/1`);
  };
    return (
        <div
        style={{
            width:`calc(100% - 160px)`,
            minWidth:mdWidth,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"space-between"
        }}
        >
            <div
            style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"3px",
                cursor:"pointer"
            }}
        // onClick={
            
        //     ()=>{
                
        //         if (nowPotName==="delivery") {
        //             goDelivery()
        //         } else if(nowPotName==="taxi") {
        //             goTaxi()
        //         }else{
        //             goCommunity()
        //         }
        //         }
        //     }
            > 
            
                <Button
            sx={PcResAreaSx}
            onClick={goDeliveryPg}
            >
              {params.fullAddress===null?"주소를 선택해 주세요":params.fullAddress}
               {/* <IoMdArrowDropright size={20}/> */}
               <MoreBtn/>
               </Button>
           
           
           {/* <PotBtn 
           componentType={componentType}
           title={"배달팟"} onClick={goDelivery} active={nowPotName==="delivery"}/>
           <PotBtn 
           componentType={componentType}
           title={"택시팟"} onClick={goTaxi} active={nowPotName==="taxi"}/>
           <PotBtn 
           componentType={componentType}
           title={"커뮤니티"} onClick={goCommunity} active={nowPotName==="community"}/> */}
<GoDeliveryPotBtn
active={actives[0]}
/>
<GoTaxiPotBtn
active={actives[1]}
/>
<GoCommunityPotBtn
active={actives[2]}
/>
</div>
        </div>
    );
}

export default PcResArea;