import React from 'react';

function MainReply({reply}) {
    return (
        <li
        style={{
            width:"100%",
            height:"30px",
            display:"flex",
            flexDirection:"row",
            justifyContent:"space-between",
            marginTop:"3px"
        }}
        >
            <div> {reply.reply}</div>
            <div
            style={{
                display:"flex",
                flexDirection:"row",
                justifyContent:"flex-end"
            }}
            > {reply.registerDate}</div>
           
        </li>
    );
}

export default MainReply;