import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../atom/atom';
import { getRepliesStatus, postsPerPage } from '../const/const';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../service/firebase';

function TaxiPotTable({list,currentPage}) {
    const location=useLocation()
    const navigate=useNavigate()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  
    // const [nowPotName,setNowPotName]=useRecoilState(nowPotNameState)
    const [writers, setWriters] = useState([]);
    const fetchUserByDocId = async (docId) => {
        try {
            const docRef = doc(db, "users", docId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                const res = docSnapshot.data();
                return res.nickname || res.email;
            } else {
                console.error("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching user by doc id: ", error);
            return null;
        }
    };
  useEffect(() => {
        const fetchUserNicknames = async () => {
            const promises = list.map((item) => fetchUserByDocId(item.ownerUserId));
            const nicknames = await Promise.all(promises);
            setWriters(nicknames);
        };

        fetchUserNicknames();
    }, [list]);
    const formattedOrderTime = (dateString) => {
        const currentDate = new Date();
        const postDate = new Date(dateString);
        if (dateString === ""||dateString===undefined) {
            return "-"
         }
        else {
            const year = postDate.getFullYear();
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
            const day = postDate.getDate().toString().padStart(2, '0');
            const hours = postDate.getHours().toString().padStart(2, '0');
            const minutes = postDate.getMinutes().toString().padStart(2, '0');

            // Check if year is different
            if (postDate.getFullYear() !== currentDate.getFullYear()) {
                return `${year}/${month}/${day} ${hours}:${minutes}`;
            } else {
                const firstList = dateString.split(" ")[0].split(".")
                const exceptYear = firstList[1] + "." + firstList[2]
                return exceptYear + " " + dateString.split(" ")[1]
            }
   
        }

    }
    return (
        <table
        width="100%"
       
        // height="100px"
        cellspacing={0}
      
        >
        {/* 테이블 헤더 */}
        <thead
     style={{
       color:"white",
       
     }}
        >
            <tr align="center"
            
            style={{
                height:"36px",color:'black'
            }}
            bgcolor={"#D9D9D9"} >
                <td width="10%">글번호</td>
                <th width="16%">일정</th>
                <th width="30%" align='left'>제목</th>
                <th width="16%">글쓴이</th>
                <th width="16%">작성일</th>
            </tr>
        </thead>
        {/* 내용 */}
        <tbody
        style={{
          backgroundColor:"pink",
   display:"block",
   maxHeight:`calc(36px * 10)`,
   overflowY:"hidden"
        }}
        >
            {
                list.map((item,idx)=> <tr align="center"
             style={{
                height:"36px"
            }}
            onClick={()=>{
                sessionStorage.setItem("editRowId",item.docId)
                navigate(`/${nowAddress.fullAddress}/reply-taxi`,{
                    state:{
                        ...location.state,
                        write:item
                    }
                })
            }}
            bgcolor="white">
                   <td width="10%">{(list.length-idx)+(postsPerPage*(currentPage-1))}</td>
                   <th width="16%">{formattedOrderTime(item.goTime)}</th>
                <th width="30%" align='left'>{item.startAddress}~{item.endAddress}{getRepliesStatus(item.replies)}</th>
         
                <th width="16%">{writers[idx]===null?item.id??"-":writers[idx]}</th>
                <th width="16%">{item.registerDate}</th>
            </tr>
                )
            }
           
           
        </tbody>
    </table>
    );
}

export default TaxiPotTable;