import { Button, useMediaQuery } from '@mui/material';
import React from 'react';

function ToggleBtn({title,active,onClick,id}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    return (
        <Button
        id={id}
        sx={{
            border:"2px solid #D9D9D9",
            color:"black",
            fontSize:isMobile?"11px":"auto",
            minWidth:isMobile?"10px":"auto",
            borderRadius:isMobile?"0px":"auto",
            backgroundColor:active?"white":"#D9D9D9",
            ":hover":{
                color:"black",
                backgroundColor:active?"white":"#D9D9D9",
            }
        }}
        onClick={onClick}
        >{title}</Button>
    );
}

export default ToggleBtn;