import React from 'react';

function HorizontalDivider({width="100%"}) {
    return (
        <div
                style={{
                    marginTop:"6px",
                    marginBottom:"6px",
                    width:width,
                    height:"2px",
                    backgroundColor:"#d9d9d9"
                }}
                />
    );
}

export default HorizontalDivider;