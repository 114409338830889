import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { IoIosArrowDown } from 'react-icons/io';

function BoardSearchInput({type="pc",onChange,value}) {
    return (
    <div
    style={{
        backgroundColor:"#002D82",
        width:type==="mobile"?"120px":"200px",
        // height:"30px",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        overflow:"hidden",
        border:"3px solid #002D82"
    }}
    ><input 
    placeholder=''
    onChange={onChange}
    value={value}
    style={{
        height:"100%",
        width:"100%",
        // marginLeft:"8px"
        
    }}/>
    <button
    style={{
        backgroundColor:"#002D82",
        border:"none",
        backgroundColor:"transparent",
        cursor:"pointer"
    }}
    > <BiSearch size={16} color='white'/></button>
   </div>
        
    );
}

export default BoardSearchInput;