
import { MenuItem, Select } from '@mui/material';
import React from 'react';

function MinuteSelect({value,onChange,disabled}) {
  console.log("in selct>>>",value)
  const minutes = Array.from({ length: 12 }, (_, index) => (index * 5).toString().padStart(2, '0'));
    return (
        // <select 
        // disabled={disabled}
        // value={value} onChange={onChange}>
        //   {minutes.map((minute, index) => (
        //     <option key={index} value={minute}>
        //       {minute}
        //     </option>
        //   ))}
        // </select>
        <Select
// disabled
value={value}
onChange={onChange}
>
{minutes.map((minute, index) => (
            <MenuItem key={index} value={minute}>
              {minute}
            </MenuItem>
          ))}
</Select>
      );
}

export default MinuteSelect;
