import React, { useEffect, useState } from 'react';
import PageLayout from '../Layouts/PageLayout';
import TitleBar from '../components/TopBar/TitleBar';
import BorderTxtField from '../components/BorderTxtField';
import BoardTxtArea from '../components/BoardTxtArea';
import { formatForDbRgDate, mdWidth, smWidth, pcEmpty } from '../const/const';
import { db } from '../service/firebase';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Mobile, PC } from '../ReactiveLayout';
import { Button } from '@mui/material';
import moment from 'moment';

function NotiShowPage(props) {
    const [newComment, setNewComment] = useState(""); // 새로운 댓글 상태
    const [comments, setComments] = useState([]); // 댓글 목록 상태
    const [editMode, setEditMode] = useState(false); // 수정 모드 여부
    const [editTitle, setEditTitle] = useState(""); // 수정할 제목
    const [editContent, setEditContent] = useState(""); // 수정할 내용
    const [isAdmin, setIsAdmin] = useState(false); // Admin 여부를 확인하는 상태
    const location = useLocation();
    const navigate = useNavigate();
    const noti = JSON.parse(sessionStorage.getItem("noti"));

    useEffect(() => {
        const userId = sessionStorage.getItem("userId");
        if (userId === "Admin") {
            setIsAdmin(true);
        }

        setEditTitle(noti.title);
        setEditContent(noti.content);
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "notification", noti.docId), (doc) => {
            setComments(doc.data().replies || []);
        });

        return () => unsubscribe();
    }, [noti.docId]);

    const postComment = async () => {
        if (newComment.trim() === "") {
            alert("댓글을 입력하세요.");
            return;
        }

        const userId = sessionStorage.getItem("userId") || "ㅇㅇ";
        const newCommentData = {
            content: newComment,
            userId: userId,
            date: moment().format("YYYY.MM.DD hh:mm:ss"),
        };

        try {
            await updateDoc(doc(db, "notification", noti.docId), {
                replies: [...comments, newCommentData],
            });
            setNewComment("");
            alert("댓글 등록이 완료되었습니다.");
        } catch (error) {
            console.error("댓글 등록 실패:", error);
            alert("댓글 등록에 실패했습니다.");
        }
    };

    const updatePost = async () => {
        if (editTitle.trim() === "" || editContent.trim() === "") {
            alert("제목과 내용을 입력하세요.");
            return;
        }

        try {
            await updateDoc(doc(db, "notification", noti.docId), {
                title: editTitle,
                content: editContent,
            });
            alert("글이 수정되었습니다.");
            setEditMode(false);
            navigate(-1); // 페이지 새로고침
        } catch (error) {
            console.error("글 수정 실패:", error);
            alert("글 수정에 실패했습니다.");
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            {/* PC UI */}
            <PC>
                <div style={{ width: mdWidth, minWidth: "900px" }}>
                    <div style={{ height: pcEmpty }} />
                    <TitleBar title={"공지사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={!editMode}
                            placeholder={"아이디"}
                            value={noti.id || "알 수 없음"}
                        />
                        <BorderTxtField
                            readOnly={!editMode}
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={noti.pw || "****"}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={!editMode}
                            value={editTitle}
                            placeholder={"제목"}
                            width='calc(193px + 193px + 16px + 16px)'
                            onChange={(e) => setEditTitle(e.target.value)}
                        />
                    </div>
                    <BoardTxtArea
                        readOnly={!editMode}
                        value={editContent}
                        width={"60%"}
                        height={"calc(36px * 4)"}
                        onChange={(e) => setEditContent(e.target.value)}
                    />
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"row"
                    }}
                    > {isAdmin && !editMode && (
                        <Button
                            variant="contained"
                            sx={{ marginTop: "20px",backgroundColor: "#104A62", color: "white" ,
                                ":hover":{
                                    backgroundColor: "#104A62", color: "white" ,
                                } }}
                            onClick={() => setEditMode(true)}
                        >
                            글 수정
                        </Button>
                    )}
                    {editMode && (
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", gap: "16px" }}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#104A62", color: "white" ,
                                    ":hover":{
                                        backgroundColor: "#104A62", color: "white" ,
                                    }
                                }}
                                onClick={updatePost}
                            >
                                저장
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ color: "#104A62", borderColor: "#104A62",":hover":{
                                    borderColor: "#104A62", color: "#104A62" ,
                                    } }}
                                onClick={() => setEditMode(false)}
                            >
                                취소
                            </Button>
                        </div>
                    )}</div>
                    
                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <TitleBar title={"댓글"} useBtn={false} />
                    </div>
                   
                    <div style={{ marginTop: "20px", width: "100%" ,display:"flex",gap:"20px"}}>
                        <BoardTxtArea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            width={"60%"}
                            placeholder={"댓글을 작성하세요"}
                        />
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: "#104A62",
                                color: "white",
                                ":hover": {
                                    backgroundColor: "#104A62",
                                }
                            }}
                            onClick={postComment}
                        >
                            댓글 등록
                        </Button>
                    </div>
                    {comments.map((comment, index) => (
                        <div
                            key={index}
                            style={{
                                marginTop: "20px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                marginBottom: "10px",
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            <strong>{comment.userId}</strong>
                            <p>{comment.content}</p>
                            <span style={{ fontSize: "12px", color: "#888" }}>{comment.date}</span>
                        </div>
                    ))}
                </div>
            </PC>

            {/* Mobile UI */}
            <Mobile>
                <div style={{ width: smWidth, minWidth: "280px" }}>
                    <div style={{ height: pcEmpty }} />
                    <TitleBar title={"공지사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={!editMode}
                            placeholder={"아이디"}
                            value={noti.id || "알 수 없음"}
                        />
                        <BorderTxtField
                            readOnly={!editMode}
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={noti.pw || "****"}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={!editMode}
                            value={editTitle}
                            placeholder={"제목"}
                            width='96%'
                            onChange={(e) => setEditTitle(e.target.value)}
                        />
                    </div>
                    <BoardTxtArea
                        readOnly={!editMode}
                        value={editContent}
                        width={"96%"}
                        height={"calc(36px * 4)"}
                        onChange={(e) => setEditContent(e.target.value)}
                    />
                    {isAdmin && !editMode && (
                        <Button
                            variant="contained"
                            sx={{marginTop:"20px",
                                backgroundColor: "#104A62",
                                color: "white",
                                ":hover": {
                                    backgroundColor: "#104A62",
                                }
                            }}
                            onClick={() => setEditMode(true)}
                        >
                            글 수정
                        </Button>
                    )}
                    {editMode && (
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", gap: "16px" }}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#104A62", color: "white" }}
                                onClick={updatePost}
                            >
                                저장
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ color: "#104A62", borderColor: "#104A62" }}
                                onClick={() => setEditMode(false)}
                            >
                                취소
                            </Button>
                        </div>
                    )}
                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <BoardTxtArea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            width={"96%"}
                            placeholder={"댓글을 작성하세요"}
                        />
                        <Button
                            variant='contained'
                            sx={{
                                width: "100%",
                                marginTop: "10px",
                                backgroundColor: "#104A62",
                                color: "white",
                                ":hover": {
                                    backgroundColor: "#104A62",
                                }
                            }}
                            onClick={postComment}
                        >
                            댓글 등록
                        </Button>
                    </div>
                    {comments.map((comment, index) => (
                        <div
                            key={index}
                            style={{
                                marginTop: "20px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                padding: "10px",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                marginBottom: "10px",
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            <strong>{comment.userId}</strong>
                            <p>{comment.content}</p>
                            <span style={{ fontSize: "12px", color: "#888" }}>{comment.date}</span>
                        </div>
                    ))}
                </div>
            </Mobile>
        </div>
    );
}

export default NotiShowPage;
