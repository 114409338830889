import React, { useEffect, useState } from 'react';
import { lgFontSize } from '../const/const';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Table, TableCell, TableContainer, TableRow } from '@mui/material';

function MembershipPosts({nowUserNickname,
    nowUserEmail}) {
        const [postsPerUser,setPostsPerUser]=useState([])
        const postsCol = collection(db, "posts")
        const fetchPosts = async () => {
            try {
              const querySnapshot = await getDocs(postsCol);
              const posts = [];
              querySnapshot.forEach((doc) => {
                // 각 문서에서 데이터를 가져와서 배열에 추가
                posts.push(doc.data());
              });
              return posts;
            } catch (error) {
              console.error("Error fetching posts: ", error);
              return []; // 에러 발생 시 빈 배열 반환
            }
          };
          const fetchAll=async()=>{
                 // 로그인 됐으면, 오너 유저아이디나 아이디, 비번 매칭해서 글 가져오기
                 const nowUserId=sessionStorage.getItem("userId");
                 const nowUserUuid=sessionStorage.getItem("userUuid");
                 const nowUserPw=sessionStorage.getItem("userPw");
                 // const login=sessionStorage.getItem("login")
            const res=await fetchPosts()
            const filtered=res.filter(r=>r.ownerUserId===nowUserUuid)
            setPostsPerUser(filtered)
          }
        useEffect(()=>{
       fetchAll()

        },[])
    return (
        <div
style={{
    display:"flex",
    flexDirection:"column",
    overflowY:"scroll",
    height:"calc(20px * 20)"
}}
><div
style={{
    fontSize:lgFontSize,
    fontWeight:700,
    position:"fixed",
    backgroundColor:"white",
    width:"50%"
}}
>작성 글</div>
<Table
sx={{
 marginTop:"30px"
}}
>
    {postsPerUser.map(p=><TableRow
    sx={{
        width:"100%",
        height:"20px"
    }}
    // style={{
    //     display:"flex",
    //     flexDirection:"row",
    //     height:"30px",
    //     marginTop:8,
    //     backgroundColor:"#d9d9d9",
    //     // gap:"8px"
    //     // justifyContent:"space-between"
    // }}
    >
        <TableCell
        style={{
            width:"100px",
            height:"20px"
        }}
        >{p.registerDate===""||p.registerDate===undefined?"-":p.registerDate}</TableCell>
        <TableCell
        style={{
            width:"100px"
        }}
        >{p.meetingPlace===""||p.meetingPlace===undefined?"-":p.meetingPlace}</TableCell>
        <TableCell
        style={{
            width:"100px"
        }}
        >{p.title===""||p.title===undefined?"-":p.title}</TableCell>
        <TableCell
        style={{
            width:"100px"
        }}
        >{p.contents===""||p.contents===undefined?"-":p.contents}</TableCell>

    </TableRow>)}
</Table>
</div>




    );
}

export default MembershipPosts;