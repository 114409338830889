import { Button } from '@mui/material';
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';

function PageBtns({ length, nowIdx, onClick, handleFirstBtn, handlePrevBtn, handleLastBtn, handleNextBtn }) {
  const arr = Array.from(Array(length), (_, index) => index + 1);

  const buttonStyle = (isActive) => ({
    backgroundColor: isActive ? "#104A62" : "white",
    color: isActive ? "white" : "#104A62",
    border: `1px solid #104A62`,
    cursor: "pointer",
    '&:hover': {
      backgroundColor: isActive ? "#104A62" : "white",
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      }}
    >
      <Button 
        onClick={handleFirstBtn}
        size='small'
        sx={{
          backgroundColor: "transparent",
          border: `1px solid #104A62`,
          cursor: "pointer",
          '&:hover': {
            backgroundColor: "transparent",
          },
        }}
        variant="outlined"
      >
        <RxDoubleArrowLeft color="#104A62" />
      </Button>
      <Button
        onClick={handlePrevBtn}
        size='small'
        sx={{
          backgroundColor: "transparent",
          border: `1px solid #104A62`,
          cursor: "pointer",
          '&:hover': {
            backgroundColor: "transparent",
          },
        }}
        variant="outlined"
      >
        <IoIosArrowBack color="#104A62" />
      </Button>

      {arr && arr.map(i => (
        <Button
          key={i}
          size='small'
          sx={buttonStyle((nowIdx + 1) === i)}
          onClick={() => onClick(i - 1)}
          variant="contained"
        >
          {i}
        </Button>
      ))}
      <Button
        onClick={handleNextBtn}
        size='small'
        sx={{
          backgroundColor: "transparent",
          border: `1px solid #104A62`,
          cursor: "pointer",
          '&:hover': {
            backgroundColor: "transparent",
          },
        }}
        variant="outlined"
      >
        <IoIosArrowForward color="#104A62" />
      </Button>
      <Button
        onClick={handleLastBtn}
        size='small'
        sx={{
          backgroundColor: "transparent",
          border: `1px solid #104A62`,
          cursor: "pointer",
          '&:hover': {
            backgroundColor: "transparent",
          },
        }}
        variant="outlined"
      >
        <RxDoubleArrowRight color="#104A62" />
      </Button>
    </div>
  );
}

export default PageBtns;
