import React from 'react';
import { useParams } from 'react-router-dom';
import TypeSelectPage from './TypeSelectPage';
import WriteTaxiPage from './WriteTaxiPage';
import TypeSelectTaxiPage from './TypeSelectTaxiPage';
import TypeSelectCommunityPage from './TypeSelectCommunityPage';

function WritePage(props) {
    const params=useParams()
    if (params.tableType==="delivery-table") {
         return <TypeSelectPage/>
    
    } else if(params.tableType==="taxi-table"){
        return <TypeSelectTaxiPage/>
    }
    else if(params.tableType==="community-table"){
        return <TypeSelectCommunityPage/>
    }
   
}

export default WritePage;