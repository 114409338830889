import React from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import SmFavArea from '../components/SmFavArea';
import TaxiPotBox from '../components/TaxiPotBox';
import AllDayToggle from '../components/AllDayToggle';
import TaxiPotTable from '../components/TaxiPotTable';

function TaxiPotPg(props) {
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
          }}>
        <Mobile>
        <div
            style={{
                height:"55px"
            }}
            />
            <div
          style={{
            width: "calc(100% - 27px - 27px)",
          }}
        >
            
          <SmFavArea/>
        </div>
            
            <div
          style={{
            height: "2px"
          }}
        />
        <div
          style={{
            height: "1px",
            width: "calc(100% - 27px - 27px)",
            backgroundColor: "#D9D9D9"
          }}
        />
        <div
          style={{
            height: "13px"
          }}
        />
         <div
          style={{
            width: "calc(100% - 27px - 27px)",
          }}
        >   <TaxiPotBox type={"mobile"}/></div>
     <div
     style={{
        height:"100px"
     }}
     />
      <div
          style={{
            display:"flex",

            width: "calc(100% - 27px - 27px)",
          }}
        >  
        <AllDayToggle toggle={"0"}/>


        </div>
        <div
     style={{
        height:"30px"
     }}
     />
        <div
          style={{
            display:"flex",

            width: "calc(100% - 27px - 27px)",
          }}
        >  <TaxiPotTable /></div>
       

        </Mobile>
        <PC> <div
            style={{
                height:"55px"
            }}
            />
            <div
          style={{
            width: "calc(100% - 80px - 80px)",
          }}
        >
            
          <SmFavArea/>
        </div>
            
            <div
          style={{
            height: "2px"
          }}
        />
        <div
          style={{
            height: "1px",
            width: "calc(100% - 80px - 80px)",
            backgroundColor: "#D9D9D9"
          }}
        />
        <div
          style={{
            height: "13px"
          }}
        />
         <div
          style={{
            width: "calc(100% - 80px - 80px)",
          }}
        >   <TaxiPotBox/></div>
     <div
     style={{
        height:"100px"
     }}
     />
      <div
          style={{
            display:"flex",

            width: "calc(100% - 80px - 80px)",
          }}
        >  
        <AllDayToggle toggle={"0"} />


        </div>
        <div
     style={{
        height:"30px"
     }}
     />
        <div
          style={{
            display:"flex",

            width: "calc(100% - 80px - 80px)",
          }}
        >  <TaxiPotTable /></div>
       
        </PC>
            
        </div>
    );
}

export default TaxiPotPg;