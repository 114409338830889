import { Box, TextField } from '@mui/material';
import React from 'react';

function TitleTxtfield({value,onChange,type,placeholder,title,width}) {
    return (
        <Box
         sx={{
            display:"flex",
            width:width,
            borderRadius:"8px",
            border:"2px solid #104A62",
        }}
        > 
        <div
        style={{
            width:"100px",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            fontSize:"16px",
            borderRight:"2px solid #104A62"
        }}
        >{title}</div>
        <TextField
variant='standard'
autoComplete='new-password'
        // type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        sx={{
            marginLeft:"20px",
            width: "100%",
        
            '& .MuiOutlinedInput-root': { // outlined 스타일을 override 하기 위한 CSS
                
              border: 'none',
              '&:hover': {
                border: 'none',
              },
              '&.Mui-focused': {
                border: 'none',
              },
            },
          }}
          inputProps={{
            style:{
                height:"40px",
                
            }
          }}
       
        /></Box>
       
    );
}

export default TitleTxtfield;