import React, { useEffect, useState } from 'react';
import PageLayout from '../Layouts/PageLayout';
import TitleBar from '../components/TopBar/TitleBar';
import BorderTxtField from '../components/BorderTxtField';
import BoardTxtArea from '../components/BoardTxtArea';
import { completedWrite, formatForDbRgDate, noWriteTxtField, mdWidth, smWidth, pcEmpty } from '../const/const';
import { db } from '../service/firebase';
import { doc, setDoc } from 'firebase/firestore';
import uuid from 'react-uuid';
import { useRecoilState } from 'recoil';
import { mainLoadingState } from '../atom/atom';
import { useNavigate } from 'react-router-dom';
import { Mobile, PC } from '../ReactiveLayout';
import { Button } from '@mui/material';
import moment from 'moment';

function NotiWritePage(props) {
    const [id, setId] = useState("Admin");
    const [pw, setPw] = useState("1111");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
    const navigate = useNavigate();

    useEffect(() => {
        // 관리자인지 확인
        const userId = sessionStorage.getItem("userId");
        if (userId !== "Admin") {
            alert("관리자만 공지사항을 작성할 수 있습니다.");
            navigate("/"); // 다른 페이지로 리다이렉트
        }
    }, [navigate]);

    const writePost = async () => {
        setMainLoading(true);
        const writeCondition = title.trim() !== "" && content !== "";
        if (writeCondition) {
            const docId = uuid();
            await setDoc(doc(db, "notification", docId), {
                id,
                pw,
                title,
                content,
                replies: [],
                registerDate: moment().format("YYYY.MM.DD hh:mm:ss"),
            });
            navigate(-1);
            window.confirm(completedWrite);
        } else {
            window.confirm(noWriteTxtField);
        }

        setMainLoading(false);
    };

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const onChangeContent = (e) => {
        setContent(e.target.value);
    };

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            {/* PC UI */}
            <PC>
                <div style={{ width: mdWidth, minWidth: "900px" }}>
                    <div style={{ height: pcEmpty }} />
                    <TitleBar title={"공지사항"} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            placeholder={"아이디"}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                        <BorderTxtField
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            value={title}
                            onChange={onChangeTitle}
                            placeholder={"제목"}
                            width='calc(193px + 193px + 16px + 16px)'
                        />
                    </div>
                    <BoardTxtArea
                        value={content}
                        onChange={onChangeContent}
                        width={"60%"}
                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                border: "1px solid #104A62",
                                color: "#104A62",
                                fontSize: "16px",
                                width: "100px",
                                ":hover": {
                                    border: "1px solid #104A62",
                                    color: "#104A62",
                                    fontSize: "16px",
                                    width: "100px",
                                }
                            }}
                            onClick={writePost}
                        >
                            등록
                        </Button>
                    </div>
                </div>
            </PC>

            {/* Mobile UI */}
            <Mobile>
                <div style={{ width: smWidth, minWidth: "280px" }}>
                    <div style={{ height: pcEmpty }} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            placeholder={"아이디"}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                        />
                        <BorderTxtField
                            type={"password"}
                            placeholder={"비밀번호"}
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            value={title}
                            onChange={onChangeTitle}
                            placeholder={"제목"}
                            width='96%'
                        />
                    </div>
                    <BoardTxtArea
                        value={content}
                        onChange={onChangeContent}
                        width={"96%"}
                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                        }}
                    >
                        <Button
                            variant='outlined'
                            sx={{
                                border: "1px solid #104A62",
                                color: "#104A62",
                                fontSize: "16px",
                                width: "100%",
                                ":hover": {
                                    border: "1px solid #104A62",
                                    color: "#104A62",
                                    fontSize: "16px",
                                }
                            }}
                            onClick={writePost}
                        >
                            등록
                        </Button>
                    </div>
                </div>
            </Mobile>
        </div>
    );
}

export default NotiWritePage;
