import React from 'react';

function BorderTxtField({
    readOnly=false,
    type,placeholder,width="193px",value,onChange}) {
    return (
        <input
        disabled={readOnly}
        // type={type}
        autoComplete='new-password'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
            width:width,
            height:"36px",
            borderRadius:"8px",
            paddingLeft:"8px",
            border:"2px solid #104A62"
        }}
        />
    );
}

export default BorderTxtField;