import { Button, Card, CardContent, List, ListItem } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState } from '../../atom/atom';
import { useNavigate } from 'react-router-dom';

function TopTenCard({ list }) {
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const navigate = useNavigate();

    return (
        <Card variant='outlined'
        sx={{
            height:"100%"
        }}
        >
            <CardContent
            sx={{
              padding:"10px",
              margin:0,
              
            }}
            >
                <div
                    style={{
                        height: '50px',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 600,
                    }}
                >
                    인기 장소
                </div>
                <List
                    sx={{
                      padding:0,
                      margin:0,
                        overflowY: 'scroll',
                        height: 'calc(53px * 3)',
                        width: '100%',
                        bgcolor: 'background.paper',
                    }}
                >
                    {list &&
                        list.map((item) => (
                            <ListItem key={item.id}>
                                <Button
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        width: '100%',
                                        color: 'black',
                                        fontWeight: '600',
                                        whiteSpace: 'nowrap', // Prevents text wrapping
                                        overflow: 'hidden', // Hides overflow content
                                        textOverflow: 'ellipsis', // Displays ellipsis (...) when text overflows
                                    }}
                                    onClick={() => {
                                        setNowAddress({
                                            fullAddress: item.meetingPlace,
                                            si: item.si,
                                            dong: item.dong,
                                        });
                                        navigate(`/${item.meetingPlace}/${item.si}/${item.dong}`);
                                        window.location.reload()
                                    }}
                                >
                                    <span>{item.meetingPlace}</span>
                                </Button>
                            </ListItem>
                        ))}
                </List>
            </CardContent>
        </Card>
    );
}

export default TopTenCard;
