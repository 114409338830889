import React, { useEffect } from 'react';
import { IoMdArrowDropright } from "react-icons/io";
import PotBtn from './PotBtn';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { favListState, nowAreaState, nowPotNameState } from '../atom/atom';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import { BiHeart } from 'react-icons/bi';
import { BsFillHeartFill } from 'react-icons/bs';
function SmFavArea({componentType,width="100%",onClickDel,onClickTaxi,onClickCommunity,nowActive}) {
    const location=useLocation()
    // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const [favList,setFavList]=useRecoilState(favListState);
    const params=useParams()
    const navigate=useNavigate()
const getTxt=(type)=>{

    if (type==="si") {
        return nowAddress.si
    }else if(type==="sidong"){

        return nowAddress.si+" "+nowAddress.dong
    }
    else{

        return nowAddress.fullAddress
    }
}
    const fetchFavorites = async () => {
        try {
            // 해당 문서를 참조하여 데이터 가져오기
            const docRef = doc(db, "users", sessionStorage.getItem("userUuid"));
            const docSnap = await getDoc(docRef);
            
            // 문서가 존재하는 경우 favorites 데이터 가져오기
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const favorites = userData["favorites"] || [];
                return favorites;
            } else {
                console.log("Document not found!");
                return []; // 문서가 존재하지 않는 경우 빈 배열 반환
            }
        } catch (error) {
            console.error("Error fetching favorites: ", error);
            return []; // 에러 발생 시 빈 배열 반환
        }
    };

    const onRegisterFavList = async (newValue) => {
        try {
            // 해당 문서를 참조하여 데이터 가져오기
            console.log(sessionStorage)
            const docRef = doc(db, "users", sessionStorage.getItem("userUuid"));
            const docSnap = await getDoc(docRef);
    
            // 문서가 존재하는 경우 favorites 데이터 가져오기
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const favorites = userData["favorites"] || [];
    
                // 새로운 값 추가 후 업데이트
                // const updatedFavorites = [...favorites, newValue];
                
                    // 기존 favorites에 newValue가 없으면 추가
            if (!favorites.includes(newValue)) {
                const updatedFavorites = [...favorites, newValue];
                await updateDoc(docRef, { favorites: updatedFavorites });
                console.log("Favorites updated successfully!");
            } else {
                console.log("newValue already exists in favorites. Not adding again.");
            }
               
    
                console.log("Favorites updated successfully!");
            } else {
                console.log("Document not found!");
            }
            const favoritesData = await fetchFavorites();
                    setFavList(favoritesData)
        } catch (error) {
            console.error("Error updating favorites: ", error);
        }
    };
  console.log("in fav btn",location.state)
    return (
        <div
        style={{
            width:width,
            // minWidth:"350px",
            // width:"calc(100% - 27px - 27px)",
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            // justifyContent:"space-between"
        }}
        >
            <div
            style={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"3px"
            }}
            >   <button
            style={{
                border:componentType==="md"?"none":"1px solid #104A62",
                backgroundColor:componentType==="md"?"#A5B2BA":"white",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                cursor:"pointer",
                padding:"4px 6px",
                borderRadius:"6px",
                color:"white",
                height:"30px",
                fontSize:componentType==="md"?"20px":"14px",
                width:componentType==="md"?"100px":"36px",
                fontWeight:700}}
                onClick={(e)=>{onRegisterFavList(nowAddress.fullAddress)}}
            >{componentType==="md"?"즐겨찾기":<BsFillHeartFill color="#104A62"/>}</button><span
            style={{width:componentType==="md"?"800px":"70px",
            paddingTop:componentType==="md"?null:"8px",
            display: "block",
            // alignItems:"flex-end",
        whiteSpace:"nowrap",
        overflow:"hidden",
        textOverflow:"ellipsis",
            // backgroundColor:"red",
            // minWidth:componentType==="md"?"800px":"100px",
        height:"30px",
       
            fontSize:componentType==="md"?"20px":"12px",
            fontWeight:700}}
            >{getTxt(params.postType)}</span>
          </div>
            <div
            style={{
                flex:1
            }}
            />
           <div
           style={{
            display:"flex",
            gap:"8px"
           }}
           >
           <PotBtn title={"배달팟"}
           componentType={componentType}
           active={nowActive==="delivery"} onClick={onClickDel}/>
           <PotBtn title={"택시팟"}
           componentType={componentType}
           active={nowActive==="taxi"} onClick={onClickTaxi}/>
           <PotBtn title={"커뮤니티"}
           componentType={componentType}
           active={nowActive==="community"} onClick={onClickCommunity}/>

           </div>
        </div>
    );
}

export default SmFavArea;