//아래는 검색창 형태 서치바
// import React, { useEffect, useState } from 'react';
// import { Mobile, PC } from '../ReactiveLayout';
// import ResArea from '../components/ResArea';
// import SmResArea from '../components/SmResArea';
// import SmFavArea from '../components/SmFavArea';
// import AllDayToggle from '../components/AllDayToggle';
// import WriteBtn from '../components/WriteBtn';
// import AllWriteUl from '../components/SmAllWriteUl';
// import SmAllWriteUl from '../components/SmAllWriteUl';
// import BoardInput from '../components/BoardInput';
// import HorizontalDivider from '../components/HorizontalDivider'
// import BoardSearchInput from '../components/BoardSearchInput';
// import { MdDoubleArrow, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
// import MdAllWriteTable from '../components/MdAllWriteTable';
// import { BiArrowFromLeft, BiLeftArrow } from 'react-icons/bi';
// import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
// import { mainLoadingState, nowAreaState, nowPotNameState, openAreaDialogState } from '../atom/atom';
// import { useRecoilState } from 'recoil';
// import PotBtn from '../components/PotBtn';
// import { collection, doc, getDocs, limit, orderBy, query, setDoc, where } from 'firebase/firestore';
// import { db } from '../service/firebase';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { communityValues, deliveryValues, mdWidth, pcEmpty, postsPerPage, smWidth, taxiValues } from '../const/const';
// import MdDeliveryTable from '../components/MdAllWriteTable';
// import TaxiPotContent from '../components/TaxiPotContent';
// import CommunityContent from '../components/CommunityContent';
// import uuid from 'react-uuid';
// import SmDeliveryTable from '../components/SmDeliveryTable';
// import SmTaxiTable from '../components/SmTaxiTable';
// import SmCommunityTable from '../components/SmCommunityTable';
// import BoardBtns from '../components/BoardBtns';
// import BoardBtns2 from '../components/BoardBtns2';
// import SearchTypeDropdown from '../components/SearchTypeDropdown';
// import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Table, TextField } from '@mui/material';
// import TableSearchBar from '../components/TableSearchBar';
// import FavoriteBar from '../components/Bar/FavoriteBar';
// import AllDateToggle from '../components/Toggle/AllDateToggle';
// import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
// import BasicTable from '../components/BasicTable/BasicDeliveryTable';
// import BasicDeliveryTable from '../components/BasicTable/BasicDeliveryTable';
// import PageBtns from '../components/Btn/PageBtns';
// import MbFavoriteBar from '../components/Bar/MbFavoriteBar';
// import { formatDistanceToNow } from 'date-fns';
// import MbPageBtns from '../components/Btn/MbPageBtns';
// function DeliveryTablePage(props) {
//   const [selectedSearchType,setSelectedSearchType]=useState("수령장소")
//   const searchOptions=["수령장소","음식점","카테고리","글쓴이"]
//   const [inputValue,setInputValue]=useState("")

//   const postsCol = collection(db, "posts")
//   const communityCol = collection(db, "community")
//   const [currentDelPage, setCurrentDelPage] = useState(1);
//   const [currentTaxiPage, setCurrentTaxiPage] = useState(1);
//   const [currentComPage, setCurrentComPage] = useState(1);
//   const [toggle,setToggle]=useState("0")
//   // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
//   const [deliveryList,setDeliveryList]=useState([])
//   const [filterDeliveryList,setFilterDeliveryList]=useState([])
//   const [currentDeliveryList,setCurrentDeliveryList]=useState([])
//   //기존 택시리스트가 있고 조회하기 누르면 filterTaxiList에 담기게
//   const [taxiList,setTaxiList]=useState([])
//   const [filterTaxiList,setFilterTaxiList]=useState([])
//   const [currentTaxiList,setCurrentTaxiList]=useState([])
//   const [communityList,setCommunityList]=useState([])
//   const [currentCommunityList,setCurrentCommunityList]=useState([])
//   const [filterCommunityList,setFilterCommunityList]=useState([])
//   const [showList,setShowList]=useState([])
//   const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
//   //지역검색 다이얼로그 닫을 때마다 글 업데이트 돼야 함
//   const [openAreaDialog,setOpenDialog]=useRecoilState(openAreaDialogState)
//   //택시팟 리스트 검색용 변수
//   const [searchStartAddress,setSearchStartAddress]=useState("")
//   const [searchEndAddress,setSearchEndAddress]=useState("")
//   //택시팟 리스트 date, si bun 검색 변수
//   const nowTime = new Date()
//   const nextDay = new Date(nowTime);
// nextDay.setDate(nextDay.getDate() + 1); 
//   const [searchGoTime, setSearchGoTime] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
//   const [searchGoSiTime, setsearchGoSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
//   const [searchGoBunTime, setsearchGoBunTime] = useState("00")  
//   //커뮤니티 변수
//   const [reply,setReply]=useState("")
//  const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
//  const [communityReplyList,setCommunityReplyList]=useState([])
//  //allDay Toggle 일정조회 today , nextDay
//  const [searchToday,setSearchToday]=useState(nowTime)
//  const [searchNextDay,setSearchNextDay]=useState(nextDay)
//  //택시팟 테이블 토글타입
//  const [taxiPotToggle,setTaxiToggle]=useState("0")
//  //커뮤니티 테이블 토글타입
//  const [communityToggle,setCommunityToggle]=useState("전체")
//  const [checkSmInput,setCheckSmInput]=useState(false)
//  const [mainLoading,setMainLoading]=useRecoilState(mainLoadingState)

// ///아래부터 새로 작성한 변수
// const params=useParams()
// const [posts,setPosts]=useState([]) 
// const [nowPageIdx,setNowPageIdx]=useState(0)
// //전체글인지 일정순인지 토글
// const [postToggle,setPostToggle]=useState("all")

// const navigate=useNavigate()
// const onClickTaxiPotToggle=(e)=>{
//   setTaxiToggle(e.target.id)


// }
// const onChangeInput=(e)=>{

//   setInputValue(e.target.value)
// }
// //디비에서 서치해서 가져오는걸로 수정하자
// const onSearch=async(postToggle,searchType,inputValue)=>{
// // setMainLoading(true)

// let searchField="";
// if (searchType==="수령장소") {
// searchField="detailAddress"

 
// } 
// else if(searchType==="음식점"){
// searchField="restaurant"
// }
// else if(searchType==="카테고리"){
//   searchField="foodCategory"
// }
// // else if(searchType==="글쓴이"){
// // searchField="type"
// // }
// else{
//   searchField="type"
// }
// if (postToggle==="all") {
//   await  fetchSearchEveryPosts(inputValue,searchField)
// } else {
//   await  fetchSearchEveryDayPosts(searchToday,inputValue,searchField)
// }

// }
// // const onChangeSearchType=(e)=>{
// // setSelectedSearchType(e.target.id)

// // }
// const onChangeSearchType=(value)=>{
//   setSelectedSearchType(value)
  
//   }
// const getCurPosts=(currentPage,originList)=>{
//   const indexOfLastPost = currentPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     const currentPosts=originList.slice(indexOfFirstPost, indexOfLastPost);
// return currentPosts
//   }
// const onClickAllSearch=(nowPotName)=>{
//   if (nowPotName==="delivery") {
//     const filtered=deliveryList.filter(l=>l.orderTime && l.orderTime!="")
//     setFilterDeliveryList(filtered) 
// //  setFilterDeliveryList(deliveryList) 
//  const indexOfLastPost = currentDelPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     const currentPosts = sortedRegDate(deliveryList).slice(indexOfFirstPost, indexOfLastPost);
//     setCurrentDeliveryList(currentPosts)
// //  setCurrentDeliveryList(curPs)
//  }
 
//  }

//  const location=useLocation()
//   const clickToggle=(e,nowPotName)=>{
//     setToggle(e.target.id)
//     if (e.target.id==="0") {
//       onClickAllSearch("delivery")
//     }
//     else{
//       onClickTodaySearch("delivery",searchToday)
//     }
//     // setNowAddress(newNowAddress)
//   }
//   const onClickDel=()=>{
//     // setNowPotName("delivery")
//     // setShowList(deliveryList)
//     navigate(`/${nowAddress.fullAddress}/delivery-table`,{state:{
//       nowAddress:nowAddress,
//      ...location.state
//   }})

// } 
// const onClickTaxi=()=>{
//   navigate(`/${nowAddress.fullAddress}/taxi-table`,{state:{
//     nowAddress:nowAddress,  
//     nowPotName:"taxi",
//   postType:"meetingPlace",
//    ...location.state
// }})

// } 
// const onClickCommunity=()=>{
//     // setNowPotName("community")
//     navigate(`/${nowAddress.fullAddress}/community-table`,{state:{
//       nowAddress:nowAddress,  
//       nowPotName:"taxi",
//     postType:"meetingPlace",
//      ...location.state
//   }})
// }
// //전체글일때 글 불러오는거
// const fetchMPosts = async () => {
//   try {
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // 각 문서에서 데이터를 가져와서 배열에 추가
//       posts.push({...doc.data(), docId: doc.id});
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// //전체글일때 글 불러오는거->시
// const fetchSiPosts = async () => {
//   try {
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         // where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       const data=doc.data()
    
//       if (data.hasOwnProperty("si")) {
//            // 각 문서에서 데이터를 가져와서 배열에 추가
//            if (data.si===params.si) {
//              posts.push({...data, docId: doc.id});
//            }
     
//       }
   
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// //전체글일때 글 불러오는거->동
// const fetchDongPosts = async () => {
//   try {
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         // where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       const data=doc.data()
    
//       if (data.hasOwnProperty("dong")) {
//            // 각 문서에서 데이터를 가져와서 배열에 추가
//            if (data.dong===params.dong) {
//              posts.push({...data, docId: doc.id});
//            }
     
//       }
   
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// //일정순일때 글 불러오는거
// const fetchMDayPosts = async (meetingPlace, searchToday) => {
//   try {
    // const formatDate = (dateObject) => {
    //   const year = dateObject.getFullYear();
    //   const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    //   const day = ('0' + dateObject.getDate()).slice(-2);
    //   return `${year}.${month}.${day}`;
    // };
//     console.log("search today>>>",formatDate(new Date(searchToday)))
//     const nowMeetingPlace = meetingPlace; // params.fullAddress로부터 가져오는 방식이라 가정

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues)
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       if (doc.data().hasOwnProperty('orderTime')) {
//         if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
//              posts.push({...doc.data(), docId: doc.id});
//         }
     
//       }
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchSiDayPosts = async (meetingPlace, searchToday) => {
//   try {
//     const formatDate = (dateObject) => {
//       const year = dateObject.getFullYear();
//       const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//       const day = ('0' + dateObject.getDate()).slice(-2);
//       return `${year}.${month}.${day}`;
//     };

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues)
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       const data=doc.data()
//       if (data.hasOwnProperty("si")) {
//         if (data.si===params.si) {
//             if (data.hasOwnProperty('orderTime')) {
//         if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//              posts.push({...data, docId: doc.id});
//         }
     
//       }
//         }
//       }
    
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchDongDayPosts = async (meetingPlace, searchToday) => {
//   try {
//     const formatDate = (dateObject) => {
//       const year = dateObject.getFullYear();
//       const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//       const day = ('0' + dateObject.getDate()).slice(-2);
//       return `${year}.${month}.${day}`;
//     };

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues)
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       const data=doc.data()
//       if (data.hasOwnProperty("dong")) {
//         if (data.dong===params.dong) {
//             if (data.hasOwnProperty('orderTime')) {
//         if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//              posts.push({...data, docId: doc.id});
//         }
     
//       }
//         }
//       }
    
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// //전체글일때 글 불러오는거-검색
// const fetchSearchMPosts = async (inputValue,searchField) => {
//   try {
//     console.log("in onSearch>>>",searchField,inputValue)
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues),
//         // where("detailAddress", '==', inputValue),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // 각 문서에서 데이터를 가져와서 배열에 추가

//       if (doc.data()[searchField].includes(inputValue)) {      
//         console.log("doc data>>>",doc.data()[searchField].includes(inputValue),searchField,doc.data()[searchField])
//           posts.push({...doc.data(), docId: doc.id});
//       }
    
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchSearchSiPosts = async (inputValue,searchField) => {
//   try {
//     console.log("in onSearch>>>",searchField,inputValue)
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues),
//         // where("detailAddress", '==', inputValue),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // 각 문서에서 데이터를 가져와서 배열에 추가
// const data=doc.data()
// if (data.hasOwnProperty("si")) {
//   if (data.si===params.si) {
//      if (data[searchField].includes(inputValue)) {      
//           posts.push({...data, docId: doc.id});
//       }
//   }
// }
     
    
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchSearchDongPosts = async (inputValue,searchField) => {
//   try {
//     console.log("in onSearch>>>",searchField,inputValue)
//     const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues),
//         // where("detailAddress", '==', inputValue),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // 각 문서에서 데이터를 가져와서 배열에 추가
// const data=doc.data()
// if (data.hasOwnProperty("dong")) {
//   if (data.dong===params.dong) {
//      if (data[searchField].includes(inputValue)) {      
//           posts.push({...data, docId: doc.id});
//       }
//   }
// }
     
    
//     });

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// //일정순일때 글 불러오는거
// const fetchSearchMDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
//   try {
//     const formatDate = (dateObject) => {
//       const year = dateObject.getFullYear();
//       const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//       const day = ('0' + dateObject.getDate()).slice(-2);
//       return `${year}.${month}.${day}`;
//     };
//     console.log("search today>>>",formatDate(new Date(searchToday)))
//     const nowMeetingPlace = meetingPlace; // params.fullAddress로부터 가져오는 방식이라 가정

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('meetingPlace', '==', nowMeetingPlace),
//         where('foodCategory', 'in', deliveryValues),
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       if (doc.data().hasOwnProperty('orderTime')) {
//         if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
         
//           if (doc.data()[searchField].includes(inputValue)) {
//              console.log("search data in day>>>",searchField,inputValue)
//              posts.push({...doc.data(), docId: doc.id});
//           }
            
//         }
     
//       }
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchSearchSiDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
//   try {
//     const formatDate = (dateObject) => {
//       const year = dateObject.getFullYear();
//       const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//       const day = ('0' + dateObject.getDate()).slice(-2);
//       return `${year}.${month}.${day}`;
//     };
//     console.log("search today>>>",formatDate(new Date(searchToday)))

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues),
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       const data=doc.data()
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       if (data.hasOwnProperty("si")) {
//          if (data.si===params.si) {
//          if (data.hasOwnProperty('orderTime')) {
//         if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
         
//           if (data[searchField].includes(inputValue)) {
//              console.log("search data in day>>>",searchField,inputValue)
//              posts.push({...data, docId: doc.id});
//           }
            
//         }
     
//       }
//       }
//       }
     
     
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// const fetchSearchDongDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
//   try {
//     const formatDate = (dateObject) => {
//       const year = dateObject.getFullYear();
//       const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//       const day = ('0' + dateObject.getDate()).slice(-2);
//       return `${year}.${month}.${day}`;
//     };
//     console.log("search today>>>",formatDate(new Date(searchToday)))

//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('foodCategory', 'in', deliveryValues),
//       )
//     );

//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       const data=doc.data()
//       // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
//       if (data.hasOwnProperty("dong")) {
//          if (data.dong===params.dong) {
//          if (data.hasOwnProperty('orderTime')) {
//         if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
         
//           if (data[searchField].includes(inputValue)) {
//              console.log("search data in day>>>",searchField,inputValue)
//              posts.push({...data, docId: doc.id});
//           }
            
//         }
     
//       }
//       }
//       }
     
     
//     });

//     // goTime 필드가 존재하는 경우에만 정렬합니다
//     if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
//       posts.sort((a, b) => {
//         // 날짜 형식이 문자열인 경우
//         const dateA = new Date(a.orderTime);
//         const dateB = new Date(b.orderTime);
//         return dateB - dateA; // 내림차순 정렬 (최신 순)
//       });
//     }

//     return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
// // const sortedRegDate=(dataList)=>{
// //   return dataList.slice().sort((a, b) => {
   
// //  const dateA = new Date(a.registerDate).getTime();
// //  const dateB = new Date(b.registerDate).getTime();
// // //  console.log("비교 날짜>>>",dataList) 
// //  return dateB-dateA;
// // })};
// // const sortedRegDate = (dataList) => {
// //   const currentDate = new Date();
// //   return dataList.slice().sort((a, b) => {
// //     const dateStringA = a.registerDate;
// //     const postDateA = new Date(dateStringA);
// //     const diffTimeA = Math.abs(currentDate - postDateA);
// //     const diffMinutesA = Math.floor(diffTimeA / (1000 * 60));

// //     const dateStringB = b.registerDate;
// //     const postDateB = new Date(dateStringB);
// //     const diffTimeB = Math.abs(currentDate - postDateB);
// //     const diffMinutesB = Math.floor(diffTimeB / (1000 * 60));

// //     return diffMinutesB - diffMinutesA;
// //   });
// // };
// // const sortedRegDate = (dataList) => {
// //   const currentDate = new Date();
// //   return dataList.slice().sort((a, b) => {
// //     const dateStringA = a.registerDate;
// //     const postDateA = new Date(dateStringA);
// //     // const diffTimeA = Math.abs(currentDate - postDateA);
// //     // const diffMinutesA = Math.floor(diffTimeA / (1000 * 60));

// //     const dateStringB = b.registerDate;
// //     const postDateB = new Date(dateStringB);
// //     // const diffTimeB = Math.abs(currentDate - postDateB);
// //     // const diffMinutesB = Math.floor(diffTimeB / (1000 * 60));

// //     return postDateB-postDateA  ;
// //   });
// // };
// // const sortedRegDate = (dataList) => {
// //   // const now = new Date().getTime();
// //   return dataList.slice().sort((a, b) => {
// //     const currentDate = new Date();
// //     const dateStringA=a.registerDate;
// //   const postDateA = new Date(dateStringA);
// //   const diffTimeA = Math.abs(currentDate - postDateA);
// //   const diffSecondsA = Math.ceil(diffTimeA / 1000);
// //   const diffMinutesA = Math.floor(diffSecondsA / 60);
// //   const dateStringB=b.registerDate;
// //   const postDateB = new Date(dateStringB);
// //   const diffTimeB = Math.abs(currentDate - postDateB);
// //   const diffSecondsB = Math.ceil(diffTimeB/ 1000);
// //   const diffMinutesB = Math.floor(diffSecondsB / 60);
// //     // const dateA = new Date(a.registerDate).getTime();
// //     // const dateB = new Date(b.registerDate).getTime();
// //     // const diffA = Math.abs(now - dateA);
// //     // const diffB = Math.abs(now - dateB);
// //     return diffMinutesB-diffMinutesA ;
// //   });
  
  
// // };
// const sortedRegDate  = (dataList) => {
//   const now = new Date().getTime();

//   return dataList.slice().sort((a, b) => {
//     const dateA = new Date(a.registerDate).getTime();
//     const dateB = new Date(b.registerDate).getTime();
//     const diffA = dateA - now;
//     const diffB = dateB - now;

//     console.log("비교 날짜>>>", dateA, dateB);
//     console.log("현재 시간과의 차이>>>", diffA, diffB);

//     return diffB-diffA  ;
//   });
// };
// const sortedOrderTime=(dataList)=>{
//   return dataList.slice().sort((a, b) => {
   
//  const dateA = new Date(a.orderTime).getTime();
//  const dateB = new Date(b.orderTime).getTime();
// //  console.log("비교 날짜>>>",dateB,dateA) 
//  return dateA-dateB;
// })};
// const sortItemsByProximityToNow  = (dataList) => {
//   const now = new Date().getTime();

//   return dataList.slice().sort((a, b) => {
//     const dateA = new Date(a.orderTime).getTime();
//     const dateB = new Date(b.orderTime).getTime();
//     const diffA = Math.abs(dateA - now);
//     const diffB = Math.abs(dateB - now);

//     console.log("비교 날짜>>>", dateA, dateB);
//     console.log("현재 시간과의 차이>>>", diffA, diffB);

//     return diffA - diffB;
//   });
// };
// // const fetchEveryPosts = async () => {
// //   // 위치 이름이 존재하지 않을때는 최신팟 넣고 
// //   // 존재 할 때는 택시팟 배달팟 넣기 
// //   if (location.state.nowAddress.si==="")return
// //   if (location.state.nowAddress.dong==="")return
// //   //버튼 눌러서 넘어오는 글은 meetingPlace가 같으면 무조건
// //   //ㅇㅇ시 최신글 글은 시만 같으면 무조건
// //   //ㅇㅇ시 ㅇㅇ동 최신글 글은 시 동 같으면 무조건
// //   let matchedList=[]
// //   const originPosts = await fetchPosts();
// //   // console.log("글순서 뒤죽박죽", sortedDateList(allPosts))
// //   const allPosts=sortedRegDate(originPosts)
// //   if (location.state.postType==="meetingPlace") {
// //       matchedList = allPosts.filter(p =>p.meetingPlace &&p.meetingPlace===nowAddress.fullAddress)

  
// //   }
// //  else if (location.state.postType==="si") {
// //   matchedList = allPosts.filter(p =>(p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))||p.si===nowAddress.si)
// //   } else if(location.state.postType==="sidong"){
// //     matchedList = allPosts.filter(p =>(p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))&&p.meetingPlace.includes(nowAddress.dong)||p.dong===nowAddress.dong)
// //   }

// //   setDeliveryList(matchedList.filter(p => p.orderTime&&p.orderTime!=""&&p.foodCategory && deliveryValues.includes(p.foodCategory)));
// //   setFilterDeliveryList(matchedList.filter(p =>p.orderTime&&p.orderTime!=""&& p.foodCategory && deliveryValues.includes(p.foodCategory)))
// // //   const curPs=getCurPosts(currentDelPage,matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)))
// // //  setCurrentDeliveryList(curPs)
// // const indexOfLastPost = currentDelPage * postsPerPage;
// //     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //     const currentPosts = sortedRegDate(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory))).slice(indexOfFirstPost, indexOfLastPost);
// //     setCurrentDeliveryList(currentPosts)
// //   setTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
// //   setFilterTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
// //   const indexOfLastTPost = currentTaxiPage * postsPerPage;
// //   const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
// //   const currentTPosts = sortedRegDate(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory))).slice(indexOfFirstTPost, indexOfLastTPost);
// //   setCurrentTaxiList(currentTPosts)
 
// //   setCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
// //   setFilterCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
// //   const indexOfLastCPost = currentComPage * postsPerPage;
// //   const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
// // const currentCPosts = sortedRegDate(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory))).slice(indexOfFirstCPost, indexOfLastCPost);
// // setCurrentCommunityList(currentCPosts)
// // };


// // useEffect(()=>{
// //   if (!openAreaDialog) {
// //     fetchEveryPosts(); // 최신 게시물 가져오기
    
// //   }else{
// //     //다이얼로그 켰을때
// //     setDeliveryList([])
// //       setTaxiList([])
// //       setCommunityList([])
// //   }

  

// // },[openAreaDialog])

// const onChangeStart=(e)=>{
//   setSearchStartAddress(e.target.value)
// }
//  const onChangeEnd=(e)=>{
//   setSearchEndAddress(e.target.value)
//  }
// const onSearchTaxiList=()=>{
// //조회하기 클릭하는거->taxiList에 필터링해서 데이터 들어가게
// const filterList=taxiList.filter(item=>item.startAddress===searchStartAddress&&item.endAddress===searchEndAddress)

// setFilterTaxiList(filterList)
// const indexOfLastPost = currentTaxiPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// const currentTPosts = sortedRegDate(filterList).slice(indexOfFirstPost, indexOfLastPost);
// setCurrentTaxiList(currentTPosts)

// }
// const onAlllTaxiList=()=>{
//   setFilterTaxiList(taxiList)
//   const indexOfLastPost = currentTaxiPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// const currentTPosts = sortedRegDate(taxiList).slice(indexOfFirstPost, indexOfLastPost);
// setCurrentTaxiList(currentTPosts)
// }
// function inputFormatDate(date) {
//   // 날짜를 받아와서 원하는 형식으로 변환합니다.
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
//   const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

//   // 원하는 형식으로 날짜를 조합하여 반환합니다.
//   return `${year}-${month}-${day}`;
// }
// function handleInputGoBunChange(e) {
//   let inputValue = e.target.value;

// // 입력된 값이 숫자인지 확인하는 정규 표현식
// const regex = /^[0-9]*$/;

// // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
// if (inputValue.length > 2) {
// inputValue = inputValue.slice(-2);
// }

// // 입력된 값이 숫자이고 최대 두 자리인지 확인
// if (regex.test(inputValue)) {
// // 입력된 값을 state에 설정
// setsearchGoBunTime(inputValue);
// }
// }
// function handleInputGoSiChange(e) {
//   let inputValue = e.target.value;

// // 입력된 값이 숫자인지 확인하는 정규 표현식
// const regex = /^[0-9]*$/;

// // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
// if (inputValue.length > 2) {
// inputValue = inputValue.slice(-2);
// }

// // 입력된 값이 숫자이고 최대 두 자리인지 확인
// if (regex.test(inputValue)) {
// // 입력된 값을 state에 설정
// setsearchGoSiTime(inputValue);
// }
// }



// const getBoardBtns=(nowType)=>{
//   if (nowType==="delivery") {
//    return <BoardBtns 
//    margin={"30px"}
//    totalPageLength={filterDeliveryList.length}
//    table={currentDeliveryList} setCurrentPage={setCurrentDelPage} currentPage={currentDelPage}/>
//   }
//   if (nowType==="taxi") {
//     return <BoardBtns
//     filtered={filterTaxiList}
//     // onBtn={onBtnTaxi}
//     table={filterTaxiList} setCurrentPage={setCurrentTaxiPage} currentPage={currentTaxiPage}/>
//   }
//   if (nowType==="community") {
//     return <BoardBtns table={filterCommunityList} setCurrentPage={setCurrentComPage} currentPage={currentComPage}/>
//   }
// }

// function inputFormatDate(date) {
//   // 날짜를 받아와서 원하는 형식으로 변환합니다.
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
//   const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

//   // 원하는 형식으로 날짜를 조합하여 반환합니다.
//   return `${year}-${month}-${day}`;
// }
// const formatSearchDate = (date) => {
//   // const date = new Date(isoDateTime);
 
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
//   const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
//   const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
//   const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩
//   return `${year}.${month}.${day}`;
// };
// const onClickTodaySearch=(nowPotName,searchToday)=>{
//  if (nowPotName==="delivery") {
//   const filtered=deliveryList.filter(l=>l.orderTime && l.orderTime!=""&&l.orderTime.includes(formatSearchDate(searchToday)))
// setFilterDeliveryList(filtered) 
// const indexOfLastPost = currentDelPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     // const currentPosts = sortedOrderTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
//     const currentPosts = sortItemsByProximityToNow(filtered).slice(indexOfFirstPost, indexOfLastPost);
//     setCurrentDeliveryList(currentPosts)
// }

// }
// const formattedSeconds = (dateString) => {
//   const currentDate = new Date();
//   const postDate = new Date(dateString);
//   const diffTime = Math.abs(currentDate - postDate);
//   //이거랑 현재시간의 초를 비교해서 현재 시간이랑 제일 가까운 순이 제일 위로 오게 소팅
//   const diffSeconds = Math.ceil(diffTime / 1000);
 
     
// };
//  const getSmTable=(nowType)=>{
//   if (nowType==="delivery") {
//     return <SmDeliveryTable 
//     toggle={toggle}
//     list={currentDeliveryList}/>
//   }
//   if (nowType==="taxi") {
//     return <SmTaxiTable 
//     toggle={toggle}
//     list={currentTaxiList}/>
//   }
//   if (nowType==="community") {
//     return <SmCommunityTable
//     toggle={toggle}
//     list={currentCommunityList}/>
//   }
//  }
//  //페이지네이션
// //  useEffect(()=>{
// //   const indexOfLastPost = currentDelPage * postsPerPage;
// //   const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //   const currentPosts = sortedRegDate(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
// //   setCurrentDeliveryList(currentPosts)

// //  },[filterDeliveryList])
// //  useEffect(()=>{
// //   const indexOfLastPost = currentDelPage * postsPerPage;
// //     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //     const currentPosts = sortedRegDate(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
// //     console.log(currentPosts)
// //     setCurrentDeliveryList(currentPosts)
// //  },[currentDelPage])
// //   //페이지네이션
// //   useEffect(()=>{
// //     const indexOfLastPost = currentTaxiPage * postsPerPage;
// //     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //     const currentPosts = sortedRegDate(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
// //     setCurrentTaxiList(currentPosts)
// //      },[filterTaxiList])
    
// //      useEffect(()=>{
// //       const indexOfLastPost = currentTaxiPage * postsPerPage;
// //         const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //         const currentPosts = sortedRegDate(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
// //         setCurrentTaxiList(currentPosts)
// //      },[currentTaxiPage])
// //      //페이지네이션
// //   useEffect(()=>{
// //     const indexOfLastPost = currentComPage * postsPerPage;
// //     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //     const currentPosts = sortedRegDate(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
// //     setCurrentCommunityList(currentPosts)
// //      },[filterCommunityList])
// //      useEffect(()=>{
    
// //       const indexOfLastPost = currentComPage * postsPerPage;
// //         const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //         const currentPosts = sortedRegDate(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
// //         setCurrentCommunityList(currentPosts)
// //      },[currentComPage])
// //      useEffect(()=>{
// //       if (nowPotName==="taxi") {

// //         setCurrentTaxiPage("1")
// //       }
// //       if (nowPotName==="delivery") {
// //         setCurrentDelPage("1")
// //       }
// // if (nowPotName==="community") {
// //   setCurrentComPage("1")
// // }


// //      },[nowPotName])
// //전체글일때
// const fetchEveryPosts = async () => {
//   let originPosts = [];

//   if (params.postType === "meeting-place") {
//     originPosts = await fetchMPosts(); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }else if(params.postType==="si-place"){

//     originPosts=await fetchSiPosts()
//   }
//   else if(params.postType==="dong-place"){
//     originPosts=await fetchDongPosts()
//   }
   


//   // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
//   const batchSize = 20;
//   const doubleList = [];
//   for (let i = 0; i < originPosts.length; i += batchSize) {
//     doubleList.push(originPosts.slice(i, i + batchSize));
//   }
//   console.log("doubleList>>>", doubleList);
//   setPosts(doubleList);
// };
// //전체글 검색할때
// const fetchSearchEveryPosts = async (inputValue,searchField) => {
//   let originPosts = [];

//   if (params.postType === "meeting-place") {
//     originPosts = await fetchSearchMPosts(inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }
//   else if(params.postType === "si-place"){
//     originPosts = await fetchSearchSiPosts(inputValue,searchField);
//   }
//   else if(params.postType==="dong-place"){
//     originPosts = await fetchSearchDongPosts(inputValue,searchField);
 

//   }
   


//   // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
//   const batchSize = 20;
//   const doubleList = [];
//   for (let i = 0; i < originPosts.length; i += batchSize) {
//     doubleList.push(originPosts.slice(i, i + batchSize));
//   }
//   console.log("doubleList>>>", doubleList);
//   setPosts(doubleList);
// };
// //일정순일때
// const fetchEveryDayPosts = async (searchToday) => {
//   let originPosts = [];

//   if (params.postType === "meeting-place") {
//     originPosts = await fetchMDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }
//   else if(params.postType==="si-place"){
//     originPosts = await fetchSiDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  
//   }
   
//   else if(params.postType==="dong-place"){
//     originPosts = await fetchDongDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  
//   }


//   // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
//   const batchSize = 20;
//   const doubleList = [];
//   for (let i = 0; i < originPosts.length; i += batchSize) {
//     doubleList.push(originPosts.slice(i, i + batchSize));
//   }
//   console.log("doubleList dd>>>", doubleList);
//   setPosts(doubleList);
// };
// const fetchSearchEveryDayPosts = async (searchToday,inputValue,searchField) => {
//   let originPosts = [];
// console.log("fetch dong 서치")
//   if (params.postType === "meeting-place") {
//     originPosts = await fetchSearchMDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }
//   else if (params.postType === "si-place") {
//     originPosts = await fetchSearchSiDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }if (params.postType === "dong-place") {
//     console.log("dong 서치")
//     originPosts = await fetchSearchDongDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }


//   // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
//   const batchSize = 20;
//   const doubleList = [];
//   for (let i = 0; i < originPosts.length; i += batchSize) {
//     doubleList.push(originPosts.slice(i, i + batchSize));
//   }
//   console.log("doubleList>>>", doubleList);
//   setPosts(doubleList);
// };
// useEffect(()=>{
//   console.log("post toggle>>>",postToggle)
//   if (postToggle==="all") {
//     fetchEveryPosts()
//   } else if(postToggle==="day")
// {
//   fetchEveryDayPosts(searchToday)
// }
// },[postToggle,searchToday])
// const handlePageBtn=(idx)=>{
// setNowPageIdx(idx)
// }
// const onChangePostToggle=(event, newAlignment)=>{
//   setNowPageIdx(0)
//   setPostToggle(newAlignment)
// }
// useEffect(()=>{
//   setNowPageIdx(parseInt(params.pageId)-1)
// },[])
// const handleFirstBtn=()=>{
//   setNowPageIdx(0)
// }
// const handlePrevBtn=()=>{
//   if (nowPageIdx===0)return
//   setNowPageIdx(p=>p-1)
 
// }
// const handleLastBtn=()=>{
// setNowPageIdx(posts.length-1)
// }
// const handleNextBtn=()=>{
  
//   if (nowPageIdx+1===posts.length)return
//   setNowPageIdx(p=>p+1)
// }
// useEffect(()=>{
//   navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${nowPageIdx+1}`)
// },[nowPageIdx])
// const formatDate = (date) => {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const day = String(date.getDate()).padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };
// const goViewWritePage=async(row)=>{
//   sessionStorage.setItem("viewWrite",JSON.stringify(row))
//   navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`)
// }
//     return (
//         <div
//         style={{
//             display: 'flex',
//             flexDirection: "column",
//             alignItems: "center"
//           }}
        
//         >
//             <Mobile>
//         <div style={{
//           width: 'calc(100% - 12px)',
 
          
//           marginLeft: '6px', marginRight: '6px' ,
//           marginBottom:"30px"}}>
//             <MbFavoriteBar/>
//           <div
//           style={{
//             display:"flex",
//               alignItems:"center",
//             justifyContent:"space-between"
//           }}
//           >  <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
//           {postToggle === "all" ? (
//             <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
//           ) : (
//             <TextField
//               size='small'
//               type='date'
//               value={formatDate(searchToday)}
//               onChange={(e) => {
//                 const day = new Date(e.target.value);
//                 setSearchToday(day);
//               }}
//             />
//           )}</div>
        
//            <List>
//           {posts[nowPageIdx]?.map((post) => (
//             <React.Fragment key={post.id}>
//               <ListItem alignItems="flex-start"
//               onClick={()=>{goViewWritePage(post)}}
//               >
           
//                 <ListItemText
//                   primary={post.restaurant}
//                   secondary={
//                     <>
//                       <span>{post.detailAddress}</span>
//                       <br />
//                       <span>{post.type}</span>
//                       <br />
//                       <span>{post.orderTime}</span>
//                       <br />
//                       <span>{formatDistanceToNow(new Date(post.registerDate))} ago</span>
//                     </>
//                   }
//                 />
//               </ListItem>
//               <Divider  component="li" />
//             </React.Fragment>
//           ))}
//         </List>
//         <TableSearchBar
//      onChangeSearchType={onChangeSearchType}
//      searchOptions={searchOptions}
//      selectedSearchType={selectedSearchType}
//      inputValue={inputValue}
//      onChangeInput={onChangeInput}
//      onSearch={(e)=>{
 
//       onSearch(postToggle,selectedSearchType,inputValue)}}
//       goWrite={()=>{
//         // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
//         navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
//        }}
//      />
      
//       <div
//       style={{
//         display:"flex",
//         width:"100%",
//         alignItems:"center",
//         justifyContent:"center"
//       }}
//       >  {/* {getBoardBtns("delivery")} */}
//         <MbPageBtns  length={posts.length} nowIdx={nowPageIdx} 
//         onClick={handlePageBtn} 
//         handleFirstBtn={handleFirstBtn}
//         handlePrevBtn={handlePrevBtn}
//         handleLastBtn={handleLastBtn}
//         handleNextBtn={handleNextBtn}
//         /></div>
      
//         </div>
       
//       </Mobile>
//             <PC> 
         
//             <div
//                 style={{
//                     height:pcEmpty,
//                 }}
//                 />
//             {/* <SmFavArea 
//               width={mdWidth}
//               nowActive={"delivery"}
//               componentType={"md"}
//               onClickDel={onClickDel} onClickCommunity={onClickCommunity} onClickTaxi={onClickTaxi}/>
//                <HorizontalDivider width={mdWidth}/> */}
//                <FavoriteBar />
//                {/* 전체글 일정순 / 글쓰기 버튼 */}
//                <div
//                style={{
          
//                 width:mdWidth,
//                 minWidth:"900px",
//                 display:"flex",
//                 justifyContent:"space-between"
//                }}
//                ><AllDateToggle
//                type={postToggle}
//                onChange={onChangePostToggle}
//                />
//                {postToggle==="all"?<CustomWriteBtn
//                onClick={()=>{
//                 navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
//                }}
//                />:<TextField
//                size='small'
//                type='date'
//                value={inputFormatDate(searchToday)}
//                onChange={
//                  (e)=>{
//                    const day=new Date(e.target.value)
//                    setSearchToday(day)
//                  }
//                }
//                />}
//                </div>
               

// {/* 
//            <div
//         style={{
//             width: mdWidth,
//             display:"flex",
//             flexDirection:"row"
//           }}
//         >
//           <AllDayToggle toggle={toggle} clickToggle={(e)=>{clickToggle(e,"delivery")}}/>
//         <div
//         style={{
//             flex:1
//         }}
//         />
//         {toggle==="0"?params.postType==="si"?<div>시 최신글은 글 작성이 불가능 합니다.</div>:<WriteBtn
//         pageName={"delivery"}
//         />:<div
//         style={{
//           display:"flex",
//           flexDirection:"row",
//           alignItems:"center"
//         }}
//         >< IoIosArrowBack/> <input type='date'
//         value={inputFormatDate(searchToday)}
//         onChange={
//           (e)=>{
//             const day=new Date(e.target.value)
//             const nextDay = new Date(day);
//             nextDay.setDate(nextDay.getDate() + 1); 
//             setSearchToday(day)
//             setSearchNextDay(nextDay)
//             onClickTodaySearch("delivery",day)
//           }
//         }
      
       
//         />< IoIosArrowForward/></div>}
//         </div> */}
//         <div
//         style={{
//           height:"22px"
//         }}
//         />
        
//         <div
//         style={{
//           width:mdWidth,
//           minWidth:"900px",
//         }}
//         >

// <BasicDeliveryTable
// tableHeads={["글번호","주문시간","수령장소","음식점","카테고리","글쓴이","작성일"]}
// rows={posts[nowPageIdx]}
// nowPageIdx={nowPageIdx}
// />

//         </div>
//        {/* 서치 창 & 글쓰기 버튼  */}
//        {/* <select
//        style={{
//         width:"100px",
//         border:"3px solid #002D82",
        
//        }}
//        >

//        <option value={"title"}>제목</option>
//        <option value={"content"}>내용</option>
//        <option value={"tc"}>제목+내용</option>
//        <option value={"writer"}>작성자</option>
//        </select> */}
//      <TableSearchBar
//      onChangeSearchType={onChangeSearchType}
//      searchOptions={searchOptions}
//      selectedSearchType={selectedSearchType}
//      inputValue={inputValue}
//      onChangeInput={onChangeInput}
//      onSearch={(e)=>{
 
//       onSearch(postToggle,selectedSearchType,inputValue)}}
//       goWrite={()=>{
//         // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
//         navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
//        }}
//      />
//         {/* {getBoardBtns("delivery")} */}
//         <PageBtns  length={posts.length} nowIdx={nowPageIdx} 
//         onClick={handlePageBtn} 
//         handleFirstBtn={handleFirstBtn}
//         handlePrevBtn={handlePrevBtn}
//         handleLastBtn={handleLastBtn}
//         handleNextBtn={handleNextBtn}
//         />
//         </PC>
//         </div>
//     );
// }

// export default DeliveryTablePage;
//아래는 서치바 형태 기능 보완한거
// import React, { useEffect, useState } from 'react';
// import { Mobile, PC } from '../ReactiveLayout';
// import SmResArea from '../components/SmResArea';
// import SmFavArea from '../components/SmFavArea';
// import AllDayToggle from '../components/AllDayToggle';
// import WriteBtn from '../components/WriteBtn';
// import BoardInput from '../components/BoardInput';
// import HorizontalDivider from '../components/HorizontalDivider'
// import BoardSearchInput from '../components/BoardSearchInput';
// import { MdDoubleArrow, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
// import MdAllWriteTable from '../components/MdAllWriteTable';
// import { BiArrowFromLeft, BiLeftArrow } from 'react-icons/bi';
// import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
// import { mainLoadingState, nowAreaState, openAreaDialogState } from '../atom/atom';
// import { useRecoilState } from 'recoil';
// import PotBtn from '../components/PotBtn';
// import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
// import { db } from '../service/firebase';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { deliveryValues, mdWidth, pcEmpty, postsPerPage } from '../const/const';
// import MdDeliveryTable from '../components/MdAllWriteTable';
// import TaxiPotContent from '../components/TaxiPotContent';
// import CommunityContent from '../components/CommunityContent';
// import uuid from 'react-uuid';
// import SmDeliveryTable from '../components/SmDeliveryTable';
// import SmTaxiTable from '../components/SmTaxiTable';
// import SmCommunityTable from '../components/SmCommunityTable';
// import BoardBtns from '../components/BoardBtns';
// import SearchTypeDropdown from '../components/SearchTypeDropdown';
// import { Divider, List, ListItem, ListItemText, TextField } from '@mui/material';
// import TableSearchBar from '../components/TableSearchBar';
// import FavoriteBar from '../components/Bar/FavoriteBar';
// import AllDateToggle from '../components/Toggle/AllDateToggle';
// import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
// import BasicDeliveryTable from '../components/BasicTable/BasicDeliveryTable';
// import PageBtns from '../components/Btn/PageBtns';
// import MbFavoriteBar from '../components/Bar/MbFavoriteBar';
// import { formatDistanceToNow } from 'date-fns';
// import MbPageBtns from '../components/Btn/MbPageBtns';

// function DeliveryTablePage(props) {
//   const [selectedSearchType, setSelectedSearchType] = useState("수령장소");
//   const searchOptions = ["수령장소", "음식점", "카테고리", "글쓴이"];
//   const [inputValue, setInputValue] = useState("");

//   const postsCol = collection(db, "posts");
//   const [currentDelPage, setCurrentDelPage] = useState(1);
//   const [currentTaxiPage, setCurrentTaxiPage] = useState(1);
//   const [currentComPage, setCurrentComPage] = useState(1);
//   const [toggle, setToggle] = useState("0");
//   const [deliveryList, setDeliveryList] = useState([]);
//   const [filterDeliveryList, setFilterDeliveryList] = useState([]);
//   const [currentDeliveryList, setCurrentDeliveryList] = useState([]);
//   const [taxiList, setTaxiList] = useState([]);
//   const [filterTaxiList, setFilterTaxiList] = useState([]);
//   const [currentTaxiList, setCurrentTaxiList] = useState([]);
//   const [communityList, setCommunityList] = useState([]);
//   const [currentCommunityList, setCurrentCommunityList] = useState([]);
//   const [filterCommunityList, setFilterCommunityList] = useState([]);
//   const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
//   const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
//   const [searchStartAddress, setSearchStartAddress] = useState("");
//   const [searchEndAddress, setSearchEndAddress] = useState("");
//   const nowTime = new Date();
//   const nextDay = new Date(nowTime);
//   nextDay.setDate(nextDay.getDate() + 1);
//   const [searchGoTime, setSearchGoTime] = useState(nowTime);
//   const [searchGoSiTime, setsearchGoSiTime] = useState("00");
//   const [searchGoBunTime, setsearchGoBunTime] = useState("00");
//   const [reply, setReply] = useState("");
//   const [newRegisterDate, setNewRegisterDate] = useState(nowTime);
//   const [communityReplyList, setCommunityReplyList] = useState([]);
//   const [searchToday, setSearchToday] = useState(nowTime);
//   const [searchNextDay, setSearchNextDay] = useState(nextDay);
//   const [taxiPotToggle, setTaxiToggle] = useState("0");
//   const [communityToggle, setCommunityToggle] = useState("전체");
//   const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
//   const [posts, setPosts] = useState([]);
//   const [nowPageIdx, setNowPageIdx] = useState(0);
//   const [postToggle, setPostToggle] = useState("all");

//   const params = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();

//   const onChangeInput = (e) => {
//     setInputValue(e.target.value);
//   };

//   const onSearch = async (postToggle, searchType, inputValue) => {
//     let searchField = "";
//     if (searchType === "수령장소") {
//       searchField = "detailAddress";
//     } else if (searchType === "음식점") {
//       searchField = "restaurant";
//     } else if (searchType === "카테고리") {
//       searchField = "foodCategory";
//     } else if (searchType === "글쓴이") {
//       searchField = "type";
//     }

//     if (postToggle === "all") {
//       await fetchSearchEveryPosts(inputValue, searchField);
//     } else {
//       await fetchSearchEveryDayPosts(searchToday, inputValue, searchField);
//     }
//   };

//   const onChangeSearchType = (value) => {
//     setSelectedSearchType(value);
//   };

//   const getCurPosts = (currentPage, originList) => {
//     const indexOfLastPost = currentPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     const currentPosts = originList.slice(indexOfFirstPost, indexOfLastPost);
//     return currentPosts;
//   };

//   const onClickAllSearch = (nowPotName) => {
//     if (nowPotName === "delivery") {
//       const filtered = deliveryList.filter((l) => l.orderTime && l.orderTime != "");
//       setFilterDeliveryList(filtered);
//       const indexOfLastPost = currentDelPage * postsPerPage;
//       const indexOfFirstPost = indexOfLastPost - postsPerPage;
//       const currentPosts = sortedRegDate(deliveryList).slice(indexOfFirstPost, indexOfLastPost);
//       setCurrentDeliveryList(currentPosts);
//     }
//   };

//   const clickToggle = (e, nowPotName) => {
//     setToggle(e.target.id);
//     if (e.target.id === "0") {
//       onClickAllSearch("delivery");
//     } else {
//       onClickTodaySearch("delivery", searchToday);
//     }
//   };

//   const onClickDel = () => {
//     navigate(`/${nowAddress.fullAddress}/delivery-table`, { state: { nowAddress: nowAddress, ...location.state } });
//   };
//   const formatSearchDate = (date) => {
//     // const date = new Date(isoDateTime);
   
//     const year = date.getFullYear();
//     const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
//     const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
//     const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
//     const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩
//     return `${year}.${month}.${day}`;
//   };
//   const onClickTaxi = () => {
//     navigate(`/${nowAddress.fullAddress}/taxi-table`, {
//       state: {
//         nowAddress: nowAddress,
//         nowPotName: "taxi",
//         postType: "meetingPlace",
//         ...location.state,
//       },
//     });
//   };

//   const onClickCommunity = () => {
//     navigate(`/${nowAddress.fullAddress}/community-table`, {
//       state: {
//         nowAddress: nowAddress,
//         nowPotName: "taxi",
//         postType: "meetingPlace",
//         ...location.state,
//       },
//     });
//   };

//   const fetchMPosts = async () => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(
//         query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         posts.push({ ...doc.data(), docId: doc.id });
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSiPosts = async () => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc")));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("si")) {
//           if (data.si === params.si) {
//             posts.push({ ...data, docId: doc.id });
//           }
//         }
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchDongPosts = async () => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc")));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("dong")) {
//           if (data.dong === params.dong) {
//             posts.push({ ...data, docId: doc.id });
//           }
//         }
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };
//   // const formatDate = (dateObject) => {
//   //   const year = dateObject.getFullYear();
//   //   const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//   //   const day = ("0" + dateObject.getDate()).slice(-2);
//   //   return `${year}.${month}.${day}`;
//   // };
//   const fetchMDayPosts = async (meetingPlace, searchToday) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const nowMeetingPlace = meetingPlace;

//       const querySnapshot = await getDocs(
//         query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues))
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         if (doc.data().hasOwnProperty("orderTime")) {
//           if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
//             posts.push({ ...doc.data(), docId: doc.id });
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };
//   const onClickTodaySearch=(nowPotName,searchToday)=>{
//     if (nowPotName==="delivery") {
//      const filtered=deliveryList.filter(l=>l.orderTime && l.orderTime!=""&&l.orderTime.includes(formatSearchDate(searchToday)))
//    setFilterDeliveryList(filtered) 
//    const indexOfLastPost = currentDelPage * postsPerPage;
//        const indexOfFirstPost = indexOfLastPost - postsPerPage;
//        // const currentPosts = sortedOrderTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
//        const currentPosts = sortItemsByProximityToNow(filtered).slice(indexOfFirstPost, indexOfLastPost);
//        setCurrentDeliveryList(currentPosts)
//    }
   
//    }
//   const fetchSiDayPosts = async (meetingPlace, searchToday) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("si")) {
//           if (data.si === params.si) {
//             if (data.hasOwnProperty("orderTime")) {
//               if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//                 posts.push({ ...data, docId: doc.id });
//               }
//             }
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchDongDayPosts = async (meetingPlace, searchToday) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("dong")) {
//           if (data.dong === params.dong) {
//             if (data.hasOwnProperty("orderTime")) {
//               if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//                 posts.push({ ...data, docId: doc.id });
//               }
//             }
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchMPosts = async (inputValue, searchField) => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(
//         query(
//           postsCol,
//           where("meetingPlace", "==", nowMeetingPlace),
//           where("foodCategory", "in", deliveryValues),
//           orderBy("registerDate", "desc")
//         )
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         if (doc.data()[searchField].includes(inputValue)) {
//           posts.push({ ...doc.data(), docId: doc.id });
//         }
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchSiPosts = async (inputValue, searchField) => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(
//         query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("si")) {
//           if (data.si === params.si) {
//             if (data[searchField].includes(inputValue)) {
//               posts.push({ ...data, docId: doc.id });
//             }
//           }
//         }
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchDongPosts = async (inputValue, searchField) => {
//     try {
//       const nowMeetingPlace = params.fullAddress;
//       const querySnapshot = await getDocs(
//         query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("dong")) {
//           if (data.dong === params.dong) {
//             if (data[searchField].includes(inputValue)) {
//               posts.push({ ...data, docId: doc.id });
//             }
//           }
//         }
//       });

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchMDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const nowMeetingPlace = meetingPlace;

//       const querySnapshot = await getDocs(
//         query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues))
//       );

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         if (doc.data().hasOwnProperty("orderTime")) {
//           if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
//             if (doc.data()[searchField].includes(inputValue)) {
//               posts.push({ ...doc.data(), docId: doc.id });
//             }
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchSiDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("si")) {
//           if (data.si === params.si) {
//             if (data.hasOwnProperty("orderTime")) {
//               if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//                 if (data[searchField].includes(inputValue)) {
//                   posts.push({ ...data, docId: doc.id });
//                 }
//               }
//             }
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const fetchSearchDongDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
//     try {
//       const formatDate = (dateObject) => {
//         const year = dateObject.getFullYear();
//         const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
//         const day = ("0" + dateObject.getDate()).slice(-2);
//         return `${year}.${month}.${day}`;
//       };

//       const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

//       const posts = [];
//       querySnapshot.forEach((doc) => {
//         const data = doc.data();
//         if (data.hasOwnProperty("dong")) {
//           if (data.dong === params.dong) {
//             if (data.hasOwnProperty("orderTime")) {
//               if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
//                 if (data[searchField].includes(inputValue)) {
//                   posts.push({ ...data, docId: doc.id });
//                 }
//               }
//             }
//           }
//         }
//       });

//       if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
//         posts.sort((a, b) => {
//           const dateA = new Date(a.orderTime);
//           const dateB = new Date(b.orderTime);
//           return dateB - dateA;
//         });
//       }

//       return posts;
//     } catch (error) {
//       console.error("게시물 가져오기 오류: ", error);
//       return [];
//     }
//   };

//   const sortedRegDate = (dataList) => {
//     const now = new Date().getTime();

//     return dataList.slice().sort((a, b) => {
//       const dateA = new Date(a.registerDate).getTime();
//       const dateB = new Date(b.registerDate).getTime();
//       const diffA = dateA - now;
//       const diffB = dateB - now;

//       return diffB - diffA;
//     });
//   };

//   const sortedOrderTime = (dataList) => {
//     return dataList.slice().sort((a, b) => {
//       const dateA = new Date(a.orderTime).getTime();
//       const dateB = new Date(b.orderTime).getTime();
//       return dateA - dateB;
//     });
//   };

//   const sortItemsByProximityToNow = (dataList) => {
//     const now = new Date().getTime();

//     return dataList.slice().sort((a, b) => {
//       const dateA = new Date(a.orderTime).getTime();
//       const dateB = new Date(b.orderTime).getTime();
//       const diffA = Math.abs(dateA - now);
//       const diffB = Math.abs(dateB - now);

//       return diffA - diffB;
//     });
//   };

//   const fetchEveryPosts = async () => {
//     let originPosts = [];

//     if (params.postType === "meeting-place") {
//       originPosts = await fetchMPosts();
//     } else if (params.postType === "si-place") {
//       originPosts = await fetchSiPosts();
//     } else if (params.postType === "dong-place") {
//       originPosts = await fetchDongPosts();
//     }

//     const batchSize = 20;
//     const doubleList = [];
//     for (let i = 0; i < originPosts.length; i += batchSize) {
//       doubleList.push(originPosts.slice(i, i + batchSize));
//     }
//     setPosts(doubleList);
//   };

//   const fetchSearchEveryPosts = async (inputValue, searchField) => {
//     let originPosts = [];

//     if (params.postType === "meeting-place") {
//       originPosts = await fetchSearchMPosts(inputValue, searchField);
//     } else if (params.postType === "si-place") {
//       originPosts = await fetchSearchSiPosts(inputValue, searchField);
//     } else if (params.postType === "dong-place") {
//       originPosts = await fetchSearchDongPosts(inputValue, searchField);
//     }

//     const batchSize = 20;
//     const doubleList = [];
//     for (let i = 0; i < originPosts.length; i += batchSize) {
//       doubleList.push(originPosts.slice(i, i + batchSize));
//     }
//     setPosts(doubleList);
//   };

//   const fetchEveryDayPosts = async (searchToday) => {
//     let originPosts = [];

//     if (params.postType === "meeting-place") {
//       originPosts = await fetchMDayPosts(params.fullAddress, searchToday);
//     } else if (params.postType === "si-place") {
//       originPosts = await fetchSiDayPosts(params.fullAddress, searchToday);
//     } else if (params.postType === "dong-place") {
//       originPosts = await fetchDongDayPosts(params.fullAddress, searchToday);
//     }

//     const batchSize = 20;
//     const doubleList = [];
//     for (let i = 0; i < originPosts.length; i += batchSize) {
//       doubleList.push(originPosts.slice(i, i + batchSize));
//     }
//     setPosts(doubleList);
//   };
//   const formatDate = (dateObject) => {
//     const year = dateObject.getFullYear();
//     const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
//     const day = ('0' + dateObject.getDate()).slice(-2);
//     return `${year}.${month}.${day}`;
//   };
//   const fetchSearchEveryDayPosts = async (searchToday, inputValue, searchField) => {
//     let originPosts = [];

//     if (params.postType === "meeting-place") {
//       originPosts = await fetchSearchMDayPosts(params.fullAddress, searchToday, inputValue, searchField);
//     } else if (params.postType === "si-place") {
//       originPosts = await fetchSearchSiDayPosts(params.fullAddress, searchToday, inputValue, searchField);
//     } else if (params.postType === "dong-place") {
//       originPosts = await fetchSearchDongDayPosts(params.fullAddress, searchToday, inputValue, searchField);
//     }

//     const batchSize = 20;
//     const doubleList = [];
//     for (let i = 0; i < originPosts.length; i += batchSize) {
//       doubleList.push(originPosts.slice(i, i + batchSize));
//     }
//     setPosts(doubleList);
//   };

//   useEffect(() => {
//     if (postToggle === "all") {
//       fetchEveryPosts();
//     } else if (postToggle === "day") {
//       fetchEveryDayPosts(searchToday);
//     }
//   }, [postToggle, searchToday]);

//   const handlePageBtn = (idx) => {
//     setNowPageIdx(idx);
//   };

//   const onChangePostToggle = (event, newAlignment) => {
//     setNowPageIdx(0);
//     setPostToggle(newAlignment);
//   };

//   useEffect(() => {
//     setNowPageIdx(parseInt(params.pageId) - 1);
//   }, []);
//   function inputFormatDate(date) {
//     // 날짜를 받아와서 원하는 형식으로 변환합니다.
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
//     const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.
  
//     // 원하는 형식으로 날짜를 조합하여 반환합니다.
//     return `${year}-${month}-${day}`;
//   }
//   const handleFirstBtn = () => {
//     setNowPageIdx(0);
//   };

//   const handlePrevBtn = () => {
//     if (nowPageIdx === 0) return;
//     setNowPageIdx((p) => p - 1);
//   };

//   const handleLastBtn = () => {
//     setNowPageIdx(posts.length - 1);
//   };

//   const handleNextBtn = () => {
//     if (nowPageIdx + 1 === posts.length) return;
//     setNowPageIdx((p) => p + 1);
//   };

//   useEffect(() => {
//     navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${nowPageIdx + 1}`);
//   }, [nowPageIdx]);

//   const goViewWritePage = async (row) => {
//     sessionStorage.setItem("viewWrite", JSON.stringify(row));
//     navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`);
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
//       <Mobile>
//         <div style={{ width: 'calc(100% - 12px)', marginLeft: '6px', marginRight: '6px', marginBottom: '30px' }}>
//           <MbFavoriteBar />
//           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//             <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
//             {postToggle === 'all' ? (
//               <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
//             ) : (
//               <TextField
//                 size="small"
//                 type="date"
//                 value={formatDate(searchToday)}
//                 onChange={(e) => {
//                   const day = new Date(e.target.value);
//                   setSearchToday(day);
//                 }}
//               />
//             )}
//           </div>

//           <List>
//             {posts[nowPageIdx]?.map((post) => (
//               <React.Fragment key={post.id}>
//                 <ListItem alignItems="flex-start" onClick={() => { goViewWritePage(post) }}>
//                   <ListItemText
//                     primary={post.restaurant}
//                     secondary={
//                       <>
//                         <span>{post.detailAddress}</span>
//                         <br />
//                         <span>{post.type}</span>
//                         <br />
//                         <span>{post.orderTime}</span>
//                         <br />
//                         <span>{formatDistanceToNow(new Date(post.registerDate))} ago</span>
//                       </>
//                     }
//                   />
//                 </ListItem>
//                 <Divider component="li" />
//               </React.Fragment>
//             ))}
//           </List>
//           <TableSearchBar
//             onChangeSearchType={onChangeSearchType}
//             searchOptions={searchOptions}
//             selectedSearchType={selectedSearchType}
//             inputValue={inputValue}
//             onChangeInput={onChangeInput}
//             onSearch={(e) => onSearch(postToggle, selectedSearchType, inputValue)}
//             goWrite={() => {
//               navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`);
//             }}
//           />

//           <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
//             <MbPageBtns
//               length={posts.length}
//               nowIdx={nowPageIdx}
//               onClick={handlePageBtn}
//               handleFirstBtn={handleFirstBtn}
//               handlePrevBtn={handlePrevBtn}
//               handleLastBtn={handleLastBtn}
//               handleNextBtn={handleNextBtn}
//             />
//           </div>
//         </div>
//       </Mobile>
//       <PC>
//         <div style={{ height: pcEmpty }} />
//         <FavoriteBar />
//         <div style={{ width: mdWidth, minWidth: '900px', display: 'flex', justifyContent: 'space-between' }}>
//           <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
//           {postToggle === 'all' ? (
//             <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
//           ) : (
//             <TextField
//               size="small"
//               type="date"
//               value={inputFormatDate(searchToday)}
//               onChange={(e) => {
//                 const day = new Date(e.target.value);
//                 setSearchToday(day);
//               }}
//             />
//           )}
//         </div>

//         <div style={{ height: '22px' }} />

//         <div style={{ width: mdWidth, minWidth: '900px' }}>
//           <BasicDeliveryTable tableHeads={['글번호', '주문시간', '수령장소', '음식점', '카테고리', '글쓴이', '작성일']} rows={posts[nowPageIdx]} nowPageIdx={nowPageIdx} />
//         </div>

//         <TableSearchBar
//           onChangeSearchType={onChangeSearchType}
//           searchOptions={searchOptions}
//           selectedSearchType={selectedSearchType}
//           inputValue={inputValue}
//           onChangeInput={onChangeInput}
//           onSearch={(e) => onSearch(postToggle, selectedSearchType, inputValue)}
//           goWrite={() => {
//             navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`);
//           }}
//         />

//         <PageBtns
//           length={posts.length}
//           nowIdx={nowPageIdx}
//           onClick={handlePageBtn}
//           handleFirstBtn={handleFirstBtn}
//           handlePrevBtn={handlePrevBtn}
//           handleLastBtn={handleLastBtn}
//           handleNextBtn={handleNextBtn}
//         />
//       </PC>
//     </div>
//   );
// }

// export default DeliveryTablePage;

//아래는 팝업 검색창
import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import SmResArea from '../components/SmResArea';
import SmFavArea from '../components/SmFavArea';
import AllDayToggle from '../components/AllDayToggle';
import WriteBtn from '../components/WriteBtn';
import BoardInput from '../components/BoardInput';
import HorizontalDivider from '../components/HorizontalDivider';
import BoardSearchInput from '../components/BoardSearchInput';
import { MdDoubleArrow, MdFilterList, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdSearch } from 'react-icons/md';
import MdAllWriteTable from '../components/MdAllWriteTable';
import { BiArrowFromLeft, BiLeftArrow } from 'react-icons/bi';
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { mainLoadingState, nowAreaState, openAreaDialogState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import PotBtn from '../components/PotBtn';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deliveryValues, formattedRegDate, mdWidth, pcEmpty, postsPerPage } from '../const/const';
import MdDeliveryTable from '../components/MdAllWriteTable';
import TaxiPotContent from '../components/TaxiPotContent';
import CommunityContent from '../components/CommunityContent';
import uuid from 'react-uuid';
import SmDeliveryTable from '../components/SmDeliveryTable';
import SmTaxiTable from '../components/SmTaxiTable';
import SmCommunityTable from '../components/SmCommunityTable';
import BoardBtns from '../components/BoardBtns';
import SearchTypeDropdown from '../components/SearchTypeDropdown';
import { Divider, Fab, List, ListItem, ListItemText, TextField } from '@mui/material';
import TableSearchBar from '../components/TableSearchBar';
import FavoriteBar from '../components/Bar/FavoriteBar';
import AllDateToggle from '../components/Toggle/AllDateToggle';
import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
import BasicDeliveryTable from '../components/BasicTable/BasicDeliveryTable';
import PageBtns from '../components/Btn/PageBtns';
import MbFavoriteBar from '../components/Bar/MbFavoriteBar';
import { formatDistanceToNow } from 'date-fns';
import MbPageBtns from '../components/Btn/MbPageBtns';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function DeliveryTablePage(props) {
  const [selectedSearchType, setSelectedSearchType] = useState("수령장소");
  const searchOptions = ["수령장소", "음식점", "카테고리", "글쓴이"];
  const [inputValue, setInputValue] = useState("");
  const postsCol = collection(db, "posts");
  const [currentDelPage, setCurrentDelPage] = useState(1);
  const [currentTaxiPage, setCurrentTaxiPage] = useState(1);
  const [currentComPage, setCurrentComPage] = useState(1);
  const [toggle, setToggle] = useState("0");
  const [deliveryList, setDeliveryList] = useState([]);
  const [filterDeliveryList, setFilterDeliveryList] = useState([]);
  const [currentDeliveryList, setCurrentDeliveryList] = useState([]);
  const [taxiList, setTaxiList] = useState([]);
  const [filterTaxiList, setFilterTaxiList] = useState([]);
  const [currentTaxiList, setCurrentTaxiList] = useState([]);
  const [communityList, setCommunityList] = useState([]);
  const [currentCommunityList, setCurrentCommunityList] = useState([]);
  const [filterCommunityList, setFilterCommunityList] = useState([]);
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
  const [searchStartAddress, setSearchStartAddress] = useState("");
  const [searchEndAddress, setSearchEndAddress] = useState("");
  const nowTime = new Date();
  const nextDay = new Date(nowTime);
  nextDay.setDate(nextDay.getDate() + 1);
  const [searchGoTime, setSearchGoTime] = useState(nowTime);
  const [searchGoSiTime, setsearchGoSiTime] = useState("00");
  const [searchGoBunTime, setsearchGoBunTime] = useState("00");
  const [reply, setReply] = useState("");
  const [newRegisterDate, setNewRegisterDate] = useState(nowTime);
  const [communityReplyList, setCommunityReplyList] = useState([]);
  const [searchToday, setSearchToday] = useState(nowTime);
  const [searchNextDay, setSearchNextDay] = useState(nextDay);
  const [taxiPotToggle, setTaxiToggle] = useState("0");
  const [communityToggle, setCommunityToggle] = useState("전체");
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
  const [posts, setPosts] = useState([]);
  const [nowPageIdx, setNowPageIdx] = useState(0);
  const [postToggle, setPostToggle] = useState("all");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // 필터 다이얼로그 상태 및 핸들러
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [filterInput, setFilterInput] = useState('');

  const handleClickOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterChange = (event) => {
    setSelectedFilterType(event.target.value);
  };

  const handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const applyFilter = () => {
    onSearch(postToggle, selectedFilterType, filterInput);
    handleClose();
  };

  const onChangeInput = (e) => {
    setInputValue(e.target.value);
  };

  const onSearch = async (postToggle, searchType, inputValue) => {
    setNowPageIdx(0)
    let searchField = "";
    if (searchType === "수령장소") {
      searchField = "detailAddress";
    } else if (searchType === "음식점") {
      searchField = "restaurant";
    } else if (searchType === "카테고리") {
      searchField = "foodCategory";
    } else if (searchType === "글쓴이") {
      searchField = "id";
    }

    if (postToggle === "all") {
      await fetchSearchEveryPosts(inputValue, searchField);
    } else {
      await fetchSearchEveryDayPosts(searchToday, inputValue, searchField);
    }
  };

  const onChangeSearchType = (value) => {
    setSelectedSearchType(value);
  };

  const getCurPosts = (currentPage, originList) => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = originList.slice(indexOfFirstPost, indexOfLastPost);
    return currentPosts;
  };

  const onClickAllSearch = (nowPotName) => {
    if (nowPotName === "delivery") {
      const filtered = deliveryList.filter((l) => l.orderTime && l.orderTime != "");
      setFilterDeliveryList(filtered);
      const indexOfLastPost = currentDelPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const currentPosts = sortedRegDate(deliveryList).slice(indexOfFirstPost, indexOfLastPost);
      setCurrentDeliveryList(currentPosts);
    }
  };

  const clickToggle = (e, nowPotName) => {
    setToggle(e.target.id);
    if (e.target.id === "0") {
      onClickAllSearch("delivery");
    } else {
      onClickTodaySearch("delivery", searchToday);
    }
  };

  const onClickDel = () => {
    navigate(`/${nowAddress.fullAddress}/delivery-table`, { state: { nowAddress: nowAddress, ...location.state } });
  };

  const formatSearchDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
    const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
    return `${year}.${month}.${day}`;
  };

  const onClickTaxi = () => {
    navigate(`/${nowAddress.fullAddress}/taxi-table`, {
      state: {
        nowAddress: nowAddress,
        nowPotName: "taxi",
        postType: "meetingPlace",
        ...location.state,
      },
    });
  };

  const onClickCommunity = () => {
    navigate(`/${nowAddress.fullAddress}/community-table`, {
      state: {
        nowAddress: nowAddress,
        nowPotName: "taxi",
        postType: "meetingPlace",
        ...location.state,
      },
    });
  };

  const fetchMPosts = async () => {
    try {
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(
        query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
        posts.push({ ...doc.data(), docId: doc.id });
      });

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSiPosts = async () => {
    try {
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc")));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("si")) {
          if (data.si === params.si) {
            posts.push({ ...data, docId: doc.id });
          }
        }
      });

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchDongPosts = async () => {
    try {
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc")));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("dong")) {
          if (data.dong === params.dong) {
            posts.push({ ...data, docId: doc.id });
          }
        }
      });

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchMDayPosts = async (meetingPlace, searchToday) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const nowMeetingPlace = meetingPlace;

      const querySnapshot = await getDocs(
        query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues))
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().hasOwnProperty("orderTime")) {
          if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
            posts.push({ ...doc.data(), docId: doc.id });
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const onClickTodaySearch = (nowPotName, searchToday) => {
    if (nowPotName === "delivery") {
      const filtered = deliveryList.filter((l) => l.orderTime && l.orderTime != "" && l.orderTime.includes(formatSearchDate(searchToday)));
      setFilterDeliveryList(filtered);
      const indexOfLastPost = currentDelPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      // const currentPosts = sortedOrderTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
      const currentPosts = sortItemsByProximityToNow(filtered).slice(indexOfFirstPost, indexOfLastPost);
      setCurrentDeliveryList(currentPosts);
    }
  };

  const fetchSiDayPosts = async (meetingPlace, searchToday) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("si")) {
          if (data.si === params.si) {
            if (data.hasOwnProperty("orderTime")) {
              if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
                posts.push({ ...data, docId: doc.id });
              }
            }
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchDongDayPosts = async (meetingPlace, searchToday) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("dong")) {
          if (data.dong === params.dong) {
            if (data.hasOwnProperty("orderTime")) {
              if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
                posts.push({ ...data, docId: doc.id });
              }
            }
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchMPosts = async (inputValue, searchField) => {
    try {
      console.log("필터링>>>",searchField,inputValue)
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(
        query(
          postsCol,
          where("meetingPlace", "==", nowMeetingPlace),
          where("foodCategory", "in", deliveryValues),
          orderBy("registerDate", "desc")
        )
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
       const data=doc.data()
        // if (doc.data().detailAddress.includes("국립")) {
        //   console.log('국립 포함>>>',doc.data())
        // }
      
        if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
          posts.push({ ...data, docId: doc.id });
        }
      });
      console.log("데이터들>>>",posts)
      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchSiPosts = async (inputValue, searchField) => {
    try {
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(
        query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("si")) {
          if (data.si === params.si) {
            if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
              posts.push({ ...data, docId: doc.id });
            }
          }
        }
      });

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchDongPosts = async (inputValue, searchField) => {
    try {
      const nowMeetingPlace = params.fullAddress;
      const querySnapshot = await getDocs(
        query(postsCol, where("foodCategory", "in", deliveryValues), orderBy("registerDate", "desc"))
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("dong")) {
          if (data.dong === params.dong) {
            if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
              posts.push({ ...data, docId: doc.id });
            }
          }
        }
      });

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchMDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const nowMeetingPlace = meetingPlace;

      const querySnapshot = await getDocs(
        query(postsCol, where("meetingPlace", "==", nowMeetingPlace), where("foodCategory", "in", deliveryValues))
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().hasOwnProperty("orderTime")) {
          if (doc.data().orderTime.includes(formatDate(new Date(searchToday)))) {
            if (doc.data()[searchField].includes(inputValue)) {
              posts.push({ ...doc.data(), docId: doc.id });
            }
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchSiDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("si")) {
          if (data.si === params.si) {
            if (data.hasOwnProperty("orderTime")) {
              if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
                if (data[searchField].includes(inputValue)) {
                  posts.push({ ...data, docId: doc.id });
                }
              }
            }
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const fetchSearchDongDayPosts = async (meetingPlace, searchToday, inputValue, searchField) => {
    try {
      const formatDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}.${month}.${day}`;
      };

      const querySnapshot = await getDocs(query(postsCol, where("foodCategory", "in", deliveryValues)));

      const posts = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.hasOwnProperty("dong")) {
          if (data.dong === params.dong) {
            if (data.hasOwnProperty("orderTime")) {
              if (data.orderTime.includes(formatDate(new Date(searchToday)))) {
                if (data[searchField].includes(inputValue)) {
                  posts.push({ ...data, docId: doc.id });
                }
              }
            }
          }
        }
      });

      if (posts.length > 0 && posts[0].hasOwnProperty("orderTime")) {
        posts.sort((a, b) => {
          const dateA = new Date(a.orderTime);
          const dateB = new Date(b.orderTime);
          return dateB - dateA;
        });
      }

      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };

  const sortedRegDate = (dataList) => {
    const now = new Date().getTime();

    return dataList.slice().sort((a, b) => {
      const dateA = new Date(a.registerDate).getTime();
      const dateB = new Date(b.registerDate).getTime();
      const diffA = dateA - now;
      const diffB = dateB - now;

      return diffB - diffA;
    });
  };

  const sortedOrderTime = (dataList) => {
    return dataList.slice().sort((a, b) => {
      const dateA = new Date(a.orderTime).getTime();
      const dateB = new Date(b.orderTime).getTime();
      return dateA - dateB;
    });
  };

  const sortItemsByProximityToNow = (dataList) => {
    const now = new Date().getTime();

    return dataList.slice().sort((a, b) => {
      const dateA = new Date(a.orderTime).getTime();
      const dateB = new Date(b.orderTime).getTime();
      const diffA = Math.abs(dateA - now);
      const diffB = Math.abs(dateB - now);

      return diffA - diffB;
    });
  };

  const fetchEveryPosts = async () => {
    let originPosts = [];

    if (params.postType === "meeting-place") {
      originPosts = await fetchMPosts();
    } else if (params.postType === "si-place") {
      originPosts = await fetchSiPosts();
    } else if (params.postType === "dong-place") {
      originPosts = await fetchDongPosts();
    }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    setPosts(doubleList);
  };

  const fetchSearchEveryPosts = async (inputValue, searchField) => {
    let originPosts = [];
 
    if (params.postType === "meeting-place") {
      originPosts = await fetchSearchMPosts(inputValue, searchField);
    } else if (params.postType === "si-place") {
      originPosts = await fetchSearchSiPosts(inputValue, searchField);
    } else if (params.postType === "dong-place") {
      originPosts = await fetchSearchDongPosts(inputValue, searchField);
    }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    console.log("서치한 후 이중배열 데이터",doubleList)
    setPosts(doubleList);
  };

  const fetchEveryDayPosts = async (searchToday) => {
    let originPosts = [];

    if (params.postType === "meeting-place") {
      originPosts = await fetchMDayPosts(params.fullAddress, searchToday);
    } else if (params.postType === "si-place") {
      originPosts = await fetchSiDayPosts(params.fullAddress, searchToday);
    } else if (params.postType === "dong-place") {
      originPosts = await fetchDongDayPosts(params.fullAddress, searchToday);
    }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    setPosts(doubleList);
  };

  const formatDate = (dateObject) => {
    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    const day = ('0' + dateObject.getDate()).slice(-2);
    return `${year}.${month}.${day}`;
  };

  const fetchSearchEveryDayPosts = async (searchToday, inputValue, searchField) => {
    let originPosts = [];

    if (params.postType === "meeting-place") {
      originPosts = await fetchSearchMDayPosts(params.fullAddress, searchToday, inputValue, searchField);
    } else if (params.postType === "si-place") {
      originPosts = await fetchSearchSiDayPosts(params.fullAddress, searchToday, inputValue, searchField);
    } else if (params.postType === "dong-place") {
      originPosts = await fetchSearchDongDayPosts(params.fullAddress, searchToday, inputValue, searchField);
    }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    setPosts(doubleList);
  };

  useEffect(() => {
    if (postToggle === "all") {
      fetchEveryPosts();
    } else if (postToggle === "day") {
      fetchEveryDayPosts(searchToday);
    }
  }, [postToggle, searchToday]);

  const handlePageBtn = (idx) => {
    setNowPageIdx(idx);
  };

  const onChangePostToggle = (event, newAlignment) => {
    setNowPageIdx(0);
    setPostToggle(newAlignment);
  };

  useEffect(() => {
    setNowPageIdx(parseInt(params.pageId) - 1);
  }, []);

  function inputFormatDate(date) {
    // 날짜를 받아와서 원하는 형식으로 변환합니다.
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
    const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

    // 원하는 형식으로 날짜를 조합하여 반환합니다.
    return `${year}-${month}-${day}`;
  }

  const handleFirstBtn = () => {
    setNowPageIdx(0);
  };

  const handlePrevBtn = () => {
    if (nowPageIdx === 0) return;
    setNowPageIdx((p) => p - 1);
  };

  const handleLastBtn = () => {
    setNowPageIdx(posts.length - 1);
  };

  const handleNextBtn = () => {
    if (nowPageIdx + 1 === posts.length) return;
    setNowPageIdx((p) => p + 1);
  };

  useEffect(() => {
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${nowPageIdx + 1}`);
  }, [nowPageIdx]);

  const goViewWritePage = async (row) => {
    sessionStorage.setItem("viewWrite", JSON.stringify(row));
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`);
  };
console.log("posts>>>",posts)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Mobile>
      <Fab
          color="primary"
          aria-label="filter"
          onClick={handleClickOpen}
          sx={{
            backgroundColor:"#002D82",
            ":hover":{
              backgroundColor:"#002D82",
            },
            position: 'fixed', bottom: 16, right: 16 }}
        >
          <MdSearch size={20}/>
        </Fab>
        <Dialog open={openFilterDialog} onClose={handleClose}>
          <DialogTitle>게시글 검색</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel>검색 기준</InputLabel>
              <Select value={selectedFilterType} onChange={handleFilterChange}>
                <MenuItem value={"수령장소"}>수령장소</MenuItem>
                <MenuItem value={"음식점"}>음식점</MenuItem>
                <MenuItem value={"카테고리"}>카테고리</MenuItem>
                <MenuItem value={"글쓴이"}>글쓴이</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="필터 입력"
              type="text"
              fullWidth
              value={filterInput}
              onChange={handleFilterInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button 
            variant='outlined'
             sx={{
            borderColor:"#002D82",
            color:"#002D82",
            ":hover":{
              borderColor:"#002D82",
              color:"#002D82",
            },}}
            onClick={handleClose} color="secondary">
              취소
            </Button>
            <Button 
            variant='contained'
             sx={{
            backgroundColor:"#002D82",
            ":hover":{
              backgroundColor:"#002D82",
            },}}
            onClick={applyFilter} color="primary">
              적용
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ width: 'calc(100% - 12px)', marginLeft: '6px', marginRight: '6px', marginBottom: '30px' }}>
          <MbFavoriteBar />
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
            {postToggle === 'all' ? (
              <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
            ) : (
              <TextField
                size="small"
                type="date"
                value={formatDate(searchToday)}
                onChange={(e) => {
                  const day = new Date(e.target.value);
                  setSearchToday(day);
                }}
              />
            )}
          </div>

          <List>
            {posts[nowPageIdx] && posts[nowPageIdx].map((post) => (
              <>
                <ListItem alignItems="flex-start" onClick={() => { goViewWritePage(post) }}>
                  <ListItemText
                    primary={'수령장소 : ' +post.detailAddress}
                    secondary={
                      <>
                        <span>음식점 : {post.title??"-"}</span>
                        <br />
                  
                        <span>주문시간 :{post.registerDate??"-"}</span>
                        <br />
                        <span>글쓴이 : {post.id===undefined||!post.id?"-":post.id}</span>
                        <br />
                        <span>{formattedRegDate(post.writeDate) ?? "-"} ago</span>
                      </>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </>
            ))}
          </List>
          {/* <TableSearchBar
            onChangeSearchType={onChangeSearchType}
            searchOptions={searchOptions}
            selectedSearchType={selectedSearchType}
            inputValue={inputValue}
            onChangeInput={onChangeInput}
            onSearch={(e) => onSearch(postToggle, selectedSearchType, inputValue)}
            goWrite={() => {
              navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`);
            }}
          /> */}

          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',marginTop:"30px" }}>
            <MbPageBtns
              length={posts.length}
              nowIdx={nowPageIdx}
              onClick={handlePageBtn}
              handleFirstBtn={handleFirstBtn}
              handlePrevBtn={handlePrevBtn}
              handleLastBtn={handleLastBtn}
              handleNextBtn={handleNextBtn}
            />
          </div>
        </div>
      </Mobile>
      <PC>
        <div style={{ height: pcEmpty }} />
        <FavoriteBar />
        <div style={{ width: mdWidth, minWidth: '900px', display: 'flex', justifyContent: 'space-between' }}>
          <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
          {postToggle === 'all' ? (
            <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
          ) : (
            <TextField
              size="small"
              type="date"
              value={inputFormatDate(searchToday)}
              onChange={(e) => {
                const day = new Date(e.target.value);
                setSearchToday(day);
              }}
            />
          )}
        </div>

        <div style={{ height: '22px' }} />

        <div style={{ width: mdWidth, minWidth: '900px' }}>
          <BasicDeliveryTable tableHeads={['글번호', '주문시간', '수령장소', '음식점', '글쓴이', '작성일']} rows={posts[nowPageIdx]} nowPageIdx={nowPageIdx} />
        </div>

        <TableSearchBar
          onChangeSearchType={onChangeSearchType}
          searchOptions={searchOptions}
          selectedSearchType={selectedSearchType}
          inputValue={inputValue}
          onChangeInput={onChangeInput}
          onSearch={(e) => onSearch(postToggle, selectedSearchType, inputValue)}
          goWrite={() => {
            navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`);
          }}
        />

        <PageBtns
          length={posts.length}
          nowIdx={nowPageIdx}
          onClick={handlePageBtn}
          handleFirstBtn={handleFirstBtn}
          handlePrevBtn={handlePrevBtn}
          handleLastBtn={handleLastBtn}
          handleNextBtn={handleNextBtn}
        />
      </PC>

      
    </div>
  );
}

export default DeliveryTablePage;
