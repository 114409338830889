import React, { useEffect } from 'react'
import GoogleAdsense from 'react-adsense-google'

const DisplayAds = () => {
  
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
        // (adsbygoogle = window.adsbygoogle || []).push({});
      //   window.onload = function() {
      //     (adsbygoogle = window.adsbygoogle || []).push({});
      // }
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  // useEffect(() => {
  //   // <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  //   (window.adsbygoogle = window.adsbygoogle || []).push({})
  // }, []);
  return  <ins className="adsbygoogle"
style={{
  display:"block",
  width:"200px",height:"600px"
}}
data-ad-client="ca-pub-1562679258658460"
data-ad-slot="1954666101"
data-ad-format="auto"
data-full-width-responsive="true"></ins> 
//   <GoogleAdsense
//   adClient='ca-pub-1562679258658460'
//   adSlot='1954666101'
//   adFormat='auto'
//   fullWidthResponsive='true'
// />

  
}

export default DisplayAds