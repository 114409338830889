import { Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';

function TaxiBorderTable({
    minWidth="360px",
    goTime
,foodCategory,
startAddress,endAddress,chatLink
}) {
    return (
        <Card
        sx={{
            width:"calc(100% - 16px)",
            minWidth:minWidth,
            borderRadius: '20px',
             border:"1px solid #104A62",
            marginBottom: '16px',
            overflow: 'hidden'
        }}
    >
        <CardContent>
        <div style={{ display: 'flex', alignItems: 'center', height:"36px" ,borderBottom:"1px solid #104A62"}}>
                    <Typography 
                    variant='h6'
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        가는날
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                    variant='h6'
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{goTime}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height:"36px" ,borderBottom:"1px solid #104A62"}}>
                    <Typography 
                    variant='h6'
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        카테고리
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                    variant='h6'
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{foodCategory}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height:"36px" ,borderBottom:"1px solid #104A62"}}>
                    <Typography 
                    variant='h6'
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        출발지
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                    variant='h6'
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{startAddress}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height:"36px" ,borderBottom:"1px solid #104A62"}}>
                    <Typography 
                    variant='h6'
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        도착지
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                    variant='h6'
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{endAddress}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', height:"36px" }}>
                    <Typography 
                    variant='h6'
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        오픈채팅 링크
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                  <Typography
                 variant={'h6'}
                    sx={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: '8px' }}>
                        <a   href={chatLink.startsWith('http') ? chatLink : `https://${chatLink}`}target="_blank" rel="noopener noreferrer">{chatLink}</a>
                    </Typography>
                </div>
            </CardContent></Card>
        // <div
        // style={{
        //     display:"flex"
        //     ,flexDirection:"column",
        //     border:"1px solid black",
        //     borderRadius:"20px",
        //     overflow:"hidden"
        // }}
        // ><div
        // style={{
        //     height:"36px",
        //     display:"flex",
        //     alignItems:"center",
        //     flexDirection:"row",
        //     borderBottom:"1px solid black"
        // }}
        // >
            
        //     <div style={{ height:"36px",
        //         width:"120px", display:"flex",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         borderRight:"1px solid black"
        //     }}>가는날</div>
        //     <div
        //     style={{ height:"36px", display:"flex",
        //     alignItems:"center",
        //     justifyContent:"center",
        //         width:"200px", 
        //     }}
        //     >{contentList[0]}</div></div>
        // <div
        // style={{
        //     height:"36px",
        //     display:"flex",
        //     alignItems:"center",
        //     flexDirection:"row",
        //     borderBottom:"1px solid black"
        // }}
        // >
        //     <div style={{ height:"36px",
        //         width:"120px", display:"flex",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         borderRight:"1px solid black"
        //     }}>카테고리</div>
        //     <div
        //     style={{ height:"36px", display:"flex",
        //     alignItems:"center",
        //     justifyContent:"center",
        //         width:"200px", 
        //     }}
        //     >{contentList[1]}</div></div>
        //     <div
        // style={{
        //     height:"36px",
        //     display:"flex",
        //     alignItems:"center",
        //     flexDirection:"row",
        //     borderBottom:"1px solid black"
        // }}
        // >
        //     <div style={{ height:"36px",
        //         width:"120px", display:"flex",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         borderRight:"1px solid black"
        //     }}>출발지</div>
        //     <div
        //     style={{ height:"36px", display:"flex",
        //     alignItems:"center",
        //     justifyContent:"center",
        //         width:"200px", 
        //     }}
        //     >{contentList[2]}</div></div>
        //     <div
        // style={{
        //     height:"36px",
        //     display:"flex",
        //     alignItems:"center",
        //     flexDirection:"row",
        //     borderBottom:"1px solid black"
        // }}
        // >
        //     <div style={{ height:"36px",
        //         width:"120px", display:"flex",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         borderRight:"1px solid black"
        //     }}>도착지</div>
        //     <div
        //     style={{ height:"36px", display:"flex",
        //     alignItems:"center",
        //     justifyContent:"center",
        //         width:"200px", 
        //     }}
        //     >{contentList[3]}</div></div>
        //     <div
        // style={{
        //     height:"36px",
        //     display:"flex",
        //     alignItems:"center",
        //     flexDirection:"row",
        // }}
        // >
        //     <div style={{ height:"36px",
        //         width:"120px", display:"flex",
        //         alignItems:"center",
        //         justifyContent:"center",
        //         borderRight:"1px solid black"
        //     }}>오픈채팅 링크</div>
        //     <div
        //     style={{ height:"36px", display:"flex",
        //     alignItems:"center",
        //     justifyContent:"center",
        //         width:"200px", 
        //         overflow:"hidden"
        //     }}
        //     >
        //         <a href={contentList[4]}>{contentList[4]}</a>
        //         </div></div>
        // </div>
    );
}

export default TaxiBorderTable;