import { Card, CardContent, Chip, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { mdWidth } from '../../const/const';

function ViewWriteTitleCard({chipTxt,title,width=mdWidth}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    return (
        <Card
        variant='outlined'
        sx={{
            width:width,
            marginBottom:"16px",
            backgroundColor:"#BDFBFF",
            border:"1px solid #104A62",
        }}
        >
            <CardContent
            sx={{
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"16px",
            }}
            >

                {/* <Chip
                sx={{
                    backgroundColor:"#104A62",
                    color:"white"
                }}
                label={chipTxt}
                /> */}
                <Typography
                
                variant={isMobile?'h9':'h6'}
                >{title}</Typography>
            </CardContent>
        </Card>
    );
}

export default ViewWriteTitleCard;