import React, { useEffect, useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaDialogState, nowAreaState } from '../atom/atom';
import CustomMap from './CustomMap';
import Button from '@mui/material/Button';

const { Tmapv2 } = window;
export const PostCodePopup = () => {
  const navigate = useNavigate();
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const [nowDialog, setNowDialog] = useRecoilState(nowAreaDialogState);
  const [tempSearchKeyword, setTempSearchKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    // 컴포넌트가 렌더링된 후에 실행될 코드
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <input
          placeholder='건물명/역 이름을 입력 해 주세요'
          onChange={(e) => {
            setTempSearchKeyword(e.target.value);
          }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#002D82",
            color: "white",
            '&:hover': {
              backgroundColor: "#001B57",
            },
          }}
          onClick={(e) => {
            setSearchKeyword(tempSearchKeyword);
          }}
        >
          검색하기
        </Button>
      </div>
      <CustomMap searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
    </div>
  );
};
