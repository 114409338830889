import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MainLogo from "../../images/main-logo.png";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { appbarColor, mdWidth } from '../../const/const';
import IconBtn from '../IconBtn';
import { CiSearch } from 'react-icons/ci';
import { IoIosNotifications } from 'react-icons/io';
import { IoMenu, IoPerson } from 'react-icons/io5';
import { loginDialogState, nowAreaState, openAreaDialogState } from '../../atom/atom';
import { useRecoilState } from 'recoil';
import TxtBtn from '../../components/Btn/TxtBtn';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../service/firebase';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function CustomAppbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
  const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
  const params = useParams();
  const [nowUser, setNowUser] = React.useState("");
  const [favorites, setFavorites] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (!loginDialog) {
      const user = sessionStorage.getItem("userId");
      setNowUser(user);
      loadFavorites(user);
    }
  }, [loginDialog]);

  const loadFavorites = async (userId) => {
    try {
      const q = query(collection(db, 'favorites'), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const favs = querySnapshot.docs.map(doc => doc.data());
      setFavorites(favs);
    } catch (e) {
      console.error("Error loading favorites: ", e);
    }
  };

  const openDialog = () => {
    setOpenDialog(true);
  };

  const goNotiPage = () => {
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-page`);
  };

  const goInquiryPage = () => {
    navigate(`/${params.fullAddress}/${params.si}/${params.dong}/inquiry-page`);
  };

  const goMembershipPage = (nowUser) => {
    if (nowUser === "" || nowUser === null) {
      setLoginDialog(true);
    } else {
      navigate(`/${nowAddress.fullAddress}/membership-page`, {
        state: {
          userEmail: nowUser
        }
      });
    }
  };

  const handleFavoritesClick = (event) => {
    setAnchorEl(event.currentTarget);
    loadFavorites(sessionStorage.getItem("userId")); // 버튼 클릭 시 즐겨찾기 데이터를 새로 불러옴
  };

  const handleFavoritesClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'favorites-popover' : undefined;

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: appbarColor, paddingLeft: "250px", paddingRight: "250px" }}>
        <Toolbar
        sx={{
          // width:mdWidth,
          minWidth:"768px"
        }}
        >
          <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
            <img src={MainLogo} width={"35px"} onClick={() => {
              navigate(`/${params.fullAddress}/${params.si}/${params.dong}`)
            }} />
          </IconButton>

          <Search>
            <SearchIconWrapper />
            <StyledInputBase onClick={openDialog} placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
          </Search>
          <TxtBtn title={"즐겨찾기"} onClick={handleFavoritesClick} style={{ color: 'white' }}/>
          <div style={{ flex: 1 }} />
          <div style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: "12px" }}>
            <TxtBtn title={"공지사항"} onClick={goNotiPage} />
            <TxtBtn title={"문의사항"} onClick={goInquiryPage} />
            <IconBtn icon={<IoPerson size={20} />} onClick={() => goMembershipPage(sessionStorage.getItem("userId"))} />
          </div>
        </Toolbar>
      </AppBar>

      {/* 즐겨찾기 Popover */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleFavoritesClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, minWidth: 200 }}>
          {favorites.length > 0 ? (
            <Box>
              {favorites.map((fav, index) => (
                <Box key={index} sx={{ mb: 1, p: 1, border: '1px solid #ccc', borderRadius: 1, backgroundColor: '#fff' }}>
                  <Typography variant="body1">{fav.fullAddress}</Typography>
                  <Typography variant="body2">{`${fav.si} ${fav.dong}`}</Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body1">즐겨찾기 항목이 없습니다.</Typography>
          )}
        </Box>
      </Popover>
    </Box>
  );
}
