import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import ResArea from '../components/ResArea';
import BlueRow from '../components/BlueRow';
import BorderTable from '../components/DeliveryBorderTable';
import ReplyTitle from '../components/ReplyTitle';
import Reply from '../components/Reply';
import ReplyWriteBox from '../components/ReplyWriteBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DeliveryBorderTable from '../components/DeliveryBorderTable';
import { db } from '../service/firebase';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import TaxiBorderTable from '../components/TaxiBorderTable';
import CommunityBorderTable from '../components/CommunityBorderTable';
import {extractLinkFromString, mdWidth, pcEmpty, smWidth} from "../const/const"
import { loginDialogState, nowAreaState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import GoLoginDialog from '../components/Dialog/GoLoginDialog';
import PcResArea from '../components/Bar/PcResArea';
import HorizontalDivider from '../components/HorizontalDivider';
import ViewWriteTitleCard from '../components/Card/ViewWriteTitleCard';
import { Card, CardContent } from '@mui/material';
import moment from 'moment';
import MbResArea from '../components/Bar/MbResArea';
//배달팟 택시팟 커뮤니티별로 페이지 따로 만들자
//ex: ReplyDeliveryPage
function ReplyCommunityPage(props) {
    const navigate = useNavigate()
    const params=useParams()
    const location = useLocation()
    // const write=location.state.write
    const docId =sessionStorage.getItem("editRowId")
    const [newWrite, setNewWrite] = useState([])
    const ownerUserId= sessionStorage.getItem("userUuid")//location.state.ownerUserId
    // const registerEmail=location.state.email
    const [reply,setReply]=useState("")
    const [allReplies,setAllReplies]=useState([])
    const nowTime = new Date()
    const [registerDate, setRegisterDate] = useState(nowTime) 
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const [goLoginDialog,setGoLoginDialog]=useState(false)
    const [loginDialog,setLoginDialog]=useRecoilState(loginDialogState)
    const newViewWrite=JSON.parse(sessionStorage.getItem("viewWrite"))
    const [viewWrite,setViewWrite]=useState(newViewWrite)
    useEffect(()=>{

  
        setViewWrite(newViewWrite)
    },[])
    const onChangeReply=(e)=>{
        setReply(e.target.value)
    }
    const formatForDbRgDate = (date) => {
        // const date = new Date(isoDateTime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
        const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
        const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
        const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩
      
        return `${year}.${month}.${day} ${hours}:${minutes}`;
      };
    const fetchRepliesPerDocId = async () => {
        try {
            // 해당 문서를 참조하여 데이터 가져오기
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);
            
            // 문서가 존재하는 경우 favorites 데이터 가져오기
            if (docSnap.exists()) {
                const userData = docSnap.data();
                setNewWrite(userData)
                const replies = userData["replies"] || [];
                return replies;
            } else {
                console.log("Document not found!");
                return []; // 문서가 존재하지 않는 경우 빈 배열 반환
            }
        } catch (error) {
            console.error("Error fetching favorites: ", error);
            return []; // 에러 발생 시 빈 배열 반환
        }
    };
    const onRegisterReply=async(newReply,registerEmail,pw)=>{
        try {
            // console.log("write id>>>",newWrite.docId,newReply)
            // 해당 문서를 참조하여 데이터 가져오기
            const docRef = doc(db, "posts", newViewWrite.docId);
            // const registerEmail=sessionStorage.getItem("userId")
            const docSnap = await getDoc(docRef);
            console.log("doc snap>>>",docSnap.exists())
            // 문서가 존재하는 경우 favorites 데이터 가져오기
            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                const login=sessionStorage.getItem("login")
                // 새로운 값 추가 후 업데이트
                // const updatedFavorites = [...favorites, newValue];
                
                    // 기존 favorites에 newValue가 없으면 추가
                    const formattedregDate = moment().format('YYYY.MM.DD HH:mm:ss');
                   
                    const newReplyObj={
                        reply:newReply,
                        ownerUserId:ownerUserId,
                        registerEmail:registerEmail,
                        pw:pw,
                        isLogin:login==="success"?true:false,
                        registerDate:formattedregDate,
                    }
            if (newReply.trim()!="") {
                const updatedReplies = [...replies, newReplyObj];
                await updateDoc(docRef, { replies: updatedReplies });
                console.log("replies updated successfully!");
                window.confirm("댓글이 업데이트 되었습니다.")
                setReply("")
                const allReplies=await fetchRepliesPerDocId();
                setAllReplies(allReplies)
            } else {
                console.log("newValue already exists in replies. Not adding again.");
            }
               
    
                console.log("Favorites updated successfully!");
            } else {
                console.log("Document not found!");
            }
            // const favoritesData = await fetchFavorites();
            //         setFavList(favoritesData)
        } catch (error) {
            console.error("Error updating favorites: ", error);
        }
       
    }
    const fetchR=async()=>{
        const allReplies=await fetchRepliesPerDocId();
                setAllReplies(allReplies)
    }
    useEffect(()=>{
        fetchR()
    },[])
    const goDelivery = () => {
        navigate(`/${nowAddress.fullAddress}/delivery-table`,{state:{
            nowAddress:nowAddress,
            nowPotName:"delivery",
            postType:"meetingPlace",
            ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           // ...location.state
        }})
    }
    const goCommunity = () => {

        navigate(`/${nowAddress.fullAddress}/community-table`,{state:{
            nowAddress:nowAddress,
            nowPotName:"community",
            postType:"meetingPlace",
            ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           // ...location.state
        }})
    }
    const goTaxi = () => {

        navigate(`/${nowAddress.fullAddress}/taxi-table`,{state:{
            nowAddress:nowAddress,
            nowPotName:"taxi",
            postType:"meetingPlace",
            ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           // ...location.state
        }})
    }
    const goEditPage = (write) => {
        const loginStatus=sessionStorage.getItem("login")
        if (loginStatus==="success") {
          navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/edit/${viewWrite.docId}`)
   
        }else{
            setGoLoginDialog(true)
        }
    }
    const   onDeleteDoc=async(nowDocId)=>{
        try {
            const loginStatus=sessionStorage.getItem("login")
            if (loginStatus==="success") {
                  await deleteDoc(doc(db, "posts", newViewWrite.docId));
            navigate(-1)
window.confirm("삭제 성공")
            } else {
                setGoLoginDialog(true)
            }
 
        } catch (error) {
            window.confirm("삭제 실패",error)   
        }
        
    }
    const yesEdit=newWrite.ownerUserId===sessionStorage.userUuid
    console.log("in community>>>",newWrite)
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        spacing: {
            height: '17px'
        },
        divider: {
            width: smWidth,
            backgroundColor: '#D9D9D9'
        },
        list: {
            width: '90%',
            // height: `calc(50px * ${allReplies.length})`,
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: '22px',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }
    };
    const onUpdateReply = async (replyIndex, updatedReply) => {
        try {
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                replies[replyIndex].reply = updatedReply;

                await updateDoc(docRef, { replies });
                console.log("reply updated successfully!");
                window.confirm("댓글이 수정되었습니다.");
                const allReplies = await fetchRepliesPerDocId();
                setAllReplies(allReplies);
            }
        } catch (error) {
            console.error("Error updating reply: ", error);
        }
    };
    const onDeleteReply = async (replyIndex) => {
        try {
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                const updatedReplies = replies.filter((_, index) => index !== replyIndex);

                await updateDoc(docRef, { replies: updatedReplies });
                console.log("reply deleted successfully!");
                window.confirm("댓글이 삭제되었습니다.");
                const allReplies = await fetchRepliesPerDocId();
                setAllReplies(allReplies);
            }
        } catch (error) {
            console.error("Error deleting reply: ", error);
        }
    };

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
          }}>
                 <GoLoginDialog
                goLoginDialog={goLoginDialog}
                setGoLoginDialog={setGoLoginDialog}
                setLoginDialog={setLoginDialog}
                />
        <Mobile>

      
        <MbResArea/>
        <ViewWriteTitleCard
                       width='calc(100% - 32px)'
                        chipTxt={viewWrite.foodCategory}
                        title={viewWrite.restaurant + " " + viewWrite.registerDate}
                    />
       
       <CommunityBorderTable
       minWidth='280px'
        titleList={["주문시간","카테고리","수령장소","오픈채팅 링크"]}
        contentList={[newWrite.foodCategory,newWrite.chatLink,]}
        />
          <div style={styles.spacing} />
       <ReplyTitle 
         minWidth='266px'
       length={allReplies.length}
        
        goEditPage={()=>{goEditPage(newWrite)}} onDeleteDoc={()=>{onDeleteDoc(docId)}}/>
        <div style={styles.spacing} />
        <ReplyWriteBox
        reply={reply}
        onChangeReply={onChangeReply}
        onRegisterReply={onRegisterReply}
        />
        <ul
       style={styles.list}
        
        > {allReplies.map((r,index)=><li
        style={{
            width: "100%",
            display:"flex",
            gap:"22px",
            alignItems:"center",
            justifyContent:"flex-start",
        }}
        ><Reply
        yesEdit={r.registerEmail
            === sessionStorage.userId}
        email={r.registerEmail}
        registerDate={r.registerDate}
        reply={(r.reply)}
        onDeleteReply={() => onDeleteReply(index)}
        onUpdateReply={(updatedReply) => onUpdateReply(index, updatedReply)}
        /></li>)}</ul>
     
        </Mobile>
        <PC>

        <div
                style={{
                    height:pcEmpty,
                }}
                />
             <PcResArea/>
             <HorizontalDivider width={mdWidth}/>
             
         
     
             <ViewWriteTitleCard
              chipTxt={viewWrite.foodCategory}
              title={viewWrite.contents+"     "+viewWrite.registerDate}
              />
         <div
        style={{
           
            height:"17px"
        }}
        />
        <div
        style={{
            width: "calc(100% - 80px - 80px)",
            display:"flex",
            gap:"22px",
            alignItems:"center",
            justifyContent:"flex-start",
        }}
        ><CommunityBorderTable
        maxWidth={"500px"}
        titleList={["주문시간","카테고리","수령장소","오픈채팅 링크"]}
        contentList={[viewWrite.foodCategory,viewWrite.chatLink]}
        /></div>
           <Card
             
             variant='outlined'
             sx={{
                width:mdWidth
             }}
             >
<CardContent>
 <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><ReplyTitle 
                emailToReport={newWrite.id}
                yesEdit={yesEdit}
                length={allReplies.length} 
                onDeleteDoc={()=>{onDeleteDoc(newWrite.docId)


                }}
                goEditPage={goEditPage} /></div>
      
    <div
                    style={{
                        height: "16px"
                    }}
                />          <ReplyWriteBox

reply={reply}
onChangeReply={onChangeReply}
onRegisterReply={onRegisterReply}
/>
                <div
                    style={{
                        // height: "1px",
                        width: "calc(100% - 80px - 80px)",
                        backgroundColor: "#D9D9D9"
                    }}
                />
           
                <ul
                    style={{
                        // width: "calc(100% - 80px - 80px)",
                        // height: allReplies.length===0?null:"calc(50px * 3)",
                        // overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}

                > {allReplies.map((r,index) => <li
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><Reply
                yesEdit={r.registerEmail
                    === sessionStorage.userId}
                        email={r.registerEmail}
                        registerDate={r.registerDate}
                        reply={(r.reply)}
                        pw={r.pw}
                        onDeleteReply={() => onDeleteReply(index)}
                        onUpdateReply={(updatedReply) => onUpdateReply(index, updatedReply)}


                    /></li>)}</ul>   
                    <div
                    style={{

                        height: "17px"
                    }}
                />
             
</CardContent>

             </Card>
               
      
        </PC>
            
        </div>
    );
}

export default ReplyCommunityPage;