import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';

import { mainLoadingState, nowAreaState, nowPotNameState, openAreaDialogState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import { collection, doc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deliveryValues, formattedRegDate, mdWidth, pcEmpty, postsPerPage, smWidth, taxiValues } from '../const/const';
import MdDeliveryTable from '../components/MdAllWriteTable';
import TaxiPotContent from '../components/TaxiPotContent';
import CommunityContent from '../components/CommunityContent';
import uuid from 'react-uuid';
import SmDeliveryTable from '../components/SmDeliveryTable';
import SmTaxiTable from '../components/SmTaxiTable';
import SmCommunityTable from '../components/SmCommunityTable';
import BoardBtns from '../components/BoardBtns';
import TableSearchBar from '../components/TableSearchBar';
import FavoriteBar from '../components/Bar/FavoriteBar';
import TaxiSearchCard from '../components/Card/TaxiSearchCard';
import BasicTaxiTable from '../components/BasicTable/BasicTaxiTable';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
import AllDateToggle from '../components/Toggle/AllDateToggle';
import moment from 'moment';
import PageBtns from '../components/Btn/PageBtns';
import MbFavoriteBar from '../components/Bar/MbFavoriteBar'
import { formatDistanceToNow } from 'date-fns';
import MbPageBtns from '../components/Btn/MbPageBtns';
import { MdClose, MdSearch } from 'react-icons/md';
import TaxiSearchCardMini from '../components/Card/TaxiSearchCardMini';
import { searchBtnSx } from '../const/BtnStyle';
function TaxiTablePage(props) {
  const postsCol = collection(db, "posts")
  const communityCol = collection(db, "community")
  const [currentDelPage, setCurrentDelPage] = useState(1);
  const [currentTaxiPage, setCurrentTaxiPage] = useState(1);
  const [currentComPage, setCurrentComPage] = useState(1);
  const [toggle,setToggle]=useState("0")
  // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
  const [deliveryList,setDeliveryList]=useState([])
  const [filterDeliveryList,setFilterDeliveryList]=useState([])
  const [currentDeliveryList,setCurrentDeliveryList]=useState([])
  //기존 택시리스트가 있고 조회하기 누르면 filterTaxiList에 담기게
  const [taxiList,setTaxiList]=useState([])
  const [filterTaxiList,setFilterTaxiList]=useState([])
  const [currentTaxiList,setCurrentTaxiList]=useState([])
  const [communityList,setCommunityList]=useState([])
  const [currentCommunityList,setCurrentCommunityList]=useState([])
  const [filterCommunityList,setFilterCommunityList]=useState([])
  const [showList,setShowList]=useState([])
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  //지역검색 다이얼로그 닫을 때마다 글 업데이트 돼야 함
  const [openAreaDialog,setOpenDialog]=useRecoilState(openAreaDialogState)
  //택시팟 리스트 검색용 변수
  const [searchStartAddress,setSearchStartAddress]=useState("")
  const [searchEndAddress,setSearchEndAddress]=useState("")
  //택시팟 리스트 date, si bun 검색 변수
  const nowTime = new Date()
  const nextDay = new Date(nowTime);
nextDay.setDate(nextDay.getDate() + 1); 
  const [searchGoTime, setSearchGoTime] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
  const [searchGoSiTime, setsearchGoSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
  const [searchGoBunTime, setsearchGoBunTime] = useState("00")  
  //커뮤니티 변수
  const [reply,setReply]=useState("")
 const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
 const [communityReplyList,setCommunityReplyList]=useState([])
 //allDay Toggle 일정조회 today , nextDay
 const [searchToday,setSearchToday]=useState(nowTime)
 const [searchNextDay,setSearchNextDay]=useState(nextDay)
 //택시팟 테이블 토글타입
 const [taxiPotToggle,setTaxiToggle]=useState("0")
 //커뮤니티 테이블 토글타입
 const [communityToggle,setCommunityToggle]=useState("전체")
 const [checkSmInput,setCheckSmInput]=useState(false)
const navigate=useNavigate()
const [selectedSearchType,setSelectedSearchType]=useState("제목")
const searchOptions=["제목","글쓴이"]
const [inputValue,setInputValue]=useState("")
const [mainLoading,setMainLoading]=useRecoilState(mainLoadingState)
//아래부터 새로 작성한 변수
const params=useParams()
const [nowPageIdx,setNowPageIdx]=useState(0)
const [posts,setPosts]=useState([]) 
const [postToggle,setPostToggle]=useState("all")
const [startAddress,setStartAddress]=useState("")
const [endAddress,setEndAddress]=useState("")
const [searchAuthor, setSearchAuthor] = useState("")  
const [selectedFields, setSelectedFields] = useState({
  startAddress: false,
  endAddress: false,
  goTime: false,
  author: false,
});
const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [openFilter,setOpenFilter]=useState(false)
  const handleClickOpen = () => {
    setOpenFilterDialog(true);
  };
  const handleFilterOpen=()=>{
    setOpenFilter(true)
  }
  const handleFilterClose=()=>{
    setOpenFilter(false)
  }

  const handleClose = () => {
    setOpenFilterDialog(false);
  };

  const handleFilterChange = (event) => {
    setSelectedFilterType(event.target.value);
  };

  const handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };
const onChangePostToggle=(event, newAlignment)=>{
  setNowPageIdx(0)
  setPostToggle(newAlignment)
}
const onChangeStartAddress=(e)=>{
  setStartAddress(e.target.value)
}
const onChangeEndAddress=(e)=>{
  setEndAddress(e.target.value)
}
const onChangeAuthor=(e)=>{
  setSearchAuthor(e.target.value)
}
//전체글일때 글 불러오는거
const fetchMPosts = async () => {
  try {
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', 'in', taxiValues),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...doc.data(), docId: doc.id});
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchSiPosts = async () => {
  try {
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', taxiValues),
        orderBy('registerDate', 'desc'),
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      const data=doc.data()
      if (data.hasOwnProperty("si")) {
        if (data.si===params.si) {
            posts.push({...data, docId: doc.id});
        }
       
      }
     
    });
console.log("useEffect 결과>>>",posts)
    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchDongPosts = async () => {
  try {
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(postsCol, 
        // where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', 'in', deliveryValues),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
    
      if (data.hasOwnProperty("dong")) {
           // 각 문서에서 데이터를 가져와서 배열에 추가
           if (data.dong===params.dong) {
             posts.push({...data, docId: doc.id});
           }
     
      }
   
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
//일정순일때 글 불러오는거
const fetchMDayPosts = async (meetingPlace, searchToday) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };
    console.log("search today 택시팟>>>",formatDate(new Date(searchToday)))
    const nowMeetingPlace = meetingPlace; // params.fullAddress로부터 가져오는 방식이라 가정

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', 'in', taxiValues),
        // orderBy('goTime', 'desc'),
      )
    );

    const posts = [];
    const formattedDate=moment(new Date(searchToday)).format("YYYY.MM.DD")
   
    querySnapshot.forEach((doc) => {
       console.log("search 택시팟>>>",formattedDate,doc.data())
      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (doc.data().hasOwnProperty('goTime')) {
        if (doc.data().goTime.includes(formattedDate)) {
             posts.push({...doc.data(), docId: doc.id});
        }
      }
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('goTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.goTime);
        const dateB = new Date(b.goTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchSiDayPosts = async (meetingPlace, searchToday) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };
    console.log("search today 택시팟>>>",formatDate(new Date(searchToday)))

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', taxiValues),
        // orderBy('goTime', 'desc'),
      )
    );

    const posts = [];
    const formattedDate=moment(new Date(searchToday)).format("YYYY.MM.DD")
   
    querySnapshot.forEach((doc) => {
const data=doc.data()

      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (data.hasOwnProperty("si")) {
        if (data.si===params.si) {
             if (data.hasOwnProperty('goTime')) {
        if (data.goTime.includes(formattedDate)) {
             posts.push({...data, docId: doc.id});
        }
      } 
        }
     
      }
     
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('goTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.goTime);
        const dateB = new Date(b.goTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchDongDayPosts = async (meetingPlace, searchToday) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };
    console.log("search today 택시팟>>>",formatDate(new Date(searchToday)))

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', taxiValues),
        // orderBy('goTime', 'desc'),
      )
    );

    const posts = [];
    const formattedDate=moment(new Date(searchToday)).format("YYYY.MM.DD")
   
    querySnapshot.forEach((doc) => {
const data=doc.data()

      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (data.hasOwnProperty("dong")) {
        if (data.dong===params.dong) {
             if (data.hasOwnProperty('goTime')) {
        if (data.goTime.includes(formattedDate)) {
             posts.push({...data, docId: doc.id});
        }
      } 
        }
     
      }
     
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('goTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.goTime);
        const dateB = new Date(b.goTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchEveryPosts = async () => {
  let originPosts = [];

  if (params.postType === "meeting-place") {
    originPosts = await fetchMPosts(); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }
  else if(params.postType==="si-place"){
    originPosts=await fetchSiPosts()
  }
  else if(params.postType==="dong-place"){
    originPosts=await fetchDongPosts()
  }
   


  // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
  const batchSize = 20;
  const doubleList = [];
  for (let i = 0; i < originPosts.length; i += batchSize) {
    doubleList.push(originPosts.slice(i, i + batchSize));
  }
setPosts(doubleList);
};
const fetchEveryDayPosts = async (searchToday) => {
  let originPosts = [];

  if (params.postType === "meeting-place") {
    originPosts = await fetchMDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }
   
  else if (params.postType === "si-place") {
    originPosts = await fetchSiDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }
  else if (params.postType === "dong-place") {
    originPosts = await fetchDongDayPosts(params.fullAddress, searchToday); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }


  // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
  const batchSize = 20;
  const doubleList = [];
  for (let i = 0; i < originPosts.length; i += batchSize) {
    doubleList.push(originPosts.slice(i, i + batchSize));
  }
  console.log("doubleList>>>", doubleList);
  setPosts(doubleList);
};
const onChangeInput=(e)=>{

setInputValue(e.target.value)
}
// const onSearch=(searchType,originRows,inputValue)=>{
// setMainLoading(true)
// let newRows=[]
// if (searchType==="제목") {
// newRows= originRows.filter(row=>{
//   const match=row.startAddress.includes(inputValue)||row.endAddress.includes(inputValue)
// return match
// })

// } 

// else if(searchType==="글쓴이"){
// newRows= originRows.filter(row=>{
//   const match=row.type.includes(inputValue)
// return match
// })
// }
// // else if(){}

// // const indexOfLastPost = currentDelPage * postsPerPage;
// //   const indexOfFirstPost = indexOfLastPost - postsPerPage;
// //   const currentPosts = sortedOrderTime(newRows.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory))).slice(indexOfFirstPost, indexOfLastPost);
// //   setCurrentDeliveryList(currentPosts)
// setFilterTaxiList(newRows)
// setMainLoading(false)
// }
const onChangeSearchType=(val)=>{
setSelectedSearchType(val)

}
const onClickTaxiPotToggle=(e)=>{
  setTaxiToggle(e.target.id)


}
const sortedGoTime=(dataList)=>{
  
  return dataList.slice().sort((a, b) => {
 const dateA = new Date(a.goTime).getTime();
 const dateB = new Date(b.goTime).getTime();
 return dateB-dateA;
})};
const getCurPosts=(currentPage,originList)=>{
  const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts=originList.slice(indexOfFirstPost, indexOfLastPost);
return currentPosts
  }
const onClickAllSearch=(nowPotName)=>{
  if (nowPotName==="delivery") {
  //  const curPs=getCurPosts(currentDelPage,deliveryList)
 setFilterDeliveryList(deliveryList) 
 const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(deliveryList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
//  setCurrentDeliveryList(curPs)
 }
  else if(nowPotName==="taxi"){
   setFilterTaxiList(taxiList)
   const indexOfLastTPost = currentTaxiPage * postsPerPage;
   const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
   const currentTPosts = sortedGoTime(taxiList).slice(indexOfFirstTPost, indexOfLastTPost);
   setCurrentTaxiList(currentTPosts)
  }
  else if(nowPotName==="community"){
    setFilterCommunityList(communityList)
    const indexOfLastCPost = currentComPage * postsPerPage;
    const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
    const currentCPosts = sortedGoTime(communityList).slice(indexOfFirstCPost, indexOfLastCPost);
    setCurrentCommunityList(currentCPosts)
   }
 //  else if(nowPotName==="community"){
 //   const filtered=communityList.filter(l=>l.registerDate.includes(formatSearchDate(searchToday)))
 //   setFilterCommunityList(filtered)
 //  }
  else{
   
  }
 }
const onClickCommunityToggle=(e)=>{
  setCommunityToggle(e.target.id)
  if (e.target.id==="전체") {
    setFilterCommunityList(communityList)
    const indexOfLastPost = currentComPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentCPosts = sortedGoTime(communityList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentCommunityList(currentCPosts)
  } else {
    const filtered=communityList.filter(item=>item.foodCategory===e.target.id)
  setFilterCommunityList(filtered)
  const indexOfLastPost = currentComPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentCPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
setCurrentCommunityList(currentCPosts)
  }
  
}
 const location=useLocation()
  const clickToggle=(e,nowPotName)=>{
    console.log("택시팟 토글")
    setToggle(e.target.id)
    if (e.target.id==="0") {
      onClickAllSearch("taxi")
    }
    else{
      onClickTodaySearch("taxi",searchToday)
    }
    // setNowAddress(newNowAddress)
  }
  const onClickDel=()=>{
    // setNowPotName("delivery")
    // setShowList(deliveryList)
    navigate(`/${nowAddress.fullAddress}/delivery-table`,{state:{
      nowAddress:nowAddress,
      nowPotName:"delivery",
      postType:"meetingPlace",
     ...location.state
  }})

} 
const onClickTaxi=()=>{
  navigate(`/${nowAddress.fullAddress}/taxi-table`,{state:{
    nowAddress:nowAddress,  
    nowPotName:"taxi",
  postType:"meetingPlace",
   ...location.state
}})

 //  ownerUserId:location.state.ownerUserId,
 //  favorites:location.state.favorites
    // setNowPotName("taxi")
} 
const onClickCommunity=()=>{
    // setNowPotName("community")
    navigate(`/${nowAddress.fullAddress}/community-table`,{state:{
      nowAddress:nowAddress,  
      nowPotName:"taxi",
    postType:"meetingPlace",
     ...location.state
  }})
}
const fetchPosts = async () => {
  try {
    const querySnapshot = await getDocs(postsCol);
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...doc.data(),docId:doc.id});

    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchCommunityPosts = async () => {
  try {
    const q = query(collection(db, "community"), orderBy("registerDate", "desc"));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push(doc.data());

    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchEveryCommunityPosts = async () => {

  const allCommunityPosts = await fetchCommunityPosts();
  setCommunityReplyList(allCommunityPosts)


}
// const sortedDateList=(dataList)=>{
  
//    return dataList.slice().sort((a, b) => {
//   const dateA = new Date(a.registerDate).getTime();
//   console.log("date A",dateA)
//   const dateB = new Date(b.registerDate).getTime();
//   return dateB-dateA;
// })};

// const fetchEveryPosts = async () => {
//   // 위치 이름이 존재하지 않을때는 최신팟 넣고 
//   // 존재 할 때는 택시팟 배달팟 넣기 
//   if (location.state.nowAddress.si==="")return
//   if (location.state.nowAddress.dong==="")return
//   //버튼 눌러서 넘어오는 글은 meetingPlace가 같으면 무조건
//   //ㅇㅇ시 최신글 글은 시만 같으면 무조건
//   //ㅇㅇ시 ㅇㅇ동 최신글 글은 시 동 같으면 무조건
//   let matchedList=[]
//   const originPosts = await fetchPosts();
//   // console.log("글순서 뒤죽박죽", sortedDateList(allPosts))
//   const allPosts=sortedGoTime(originPosts)
//   if (params.postType==="meetingPlace") {
//       matchedList = allPosts.filter(p =>p.meetingPlace &&p.meetingPlace===nowAddress.fullAddress)

  
//   }
//  else if (params.postType==="si") {
//   matchedList = allPosts.filter(p =>p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))
//   } else if(params.postType==="sidong"){
//     matchedList = allPosts.filter(p =>(p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))&&p.meetingPlace.includes(nowAddress.dong)||p.dong===nowAddress.dong)
//   }

//   setDeliveryList(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)));
//   setFilterDeliveryList(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)))
// //   const curPs=getCurPosts(currentDelPage,matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)))
// //  setCurrentDeliveryList(curPs)
// const indexOfLastPost = currentDelPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
//     const currentPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory))).slice(indexOfFirstPost, indexOfLastPost);
//     setCurrentDeliveryList(currentPosts)
//   setTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
//   setFilterTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
//   const indexOfLastTPost = currentTaxiPage * postsPerPage;
//   const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
//   const currentTPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory))).slice(indexOfFirstTPost, indexOfLastTPost);
//   setCurrentTaxiList(currentTPosts)
 
//   setCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
//   setFilterCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
//   const indexOfLastCPost = currentComPage * postsPerPage;
//   const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
// const currentCPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory))).slice(indexOfFirstCPost, indexOfLastCPost);
// setCurrentCommunityList(currentCPosts)
// };

// useEffect(()=>{
// // console.log("뒤로가기 할때",location.state.nowPotName)
  
//   // setNowPotName(location.state.nowPotName)
//   fetchEveryCommunityPosts()
// },[])
// useEffect(()=>{
//   if (!openAreaDialog) {
//     // fetchEveryPosts(); // 최신 게시물 가져오기
    
//   }else{
//     //다이얼로그 켰을때
//     setDeliveryList([])
//       setTaxiList([])
//       setCommunityList([])
//   }

  

// },[openAreaDialog])
useEffect(()=>{
  console.log("here>>>")
  fetchEveryPosts()
},[])
const onChangeStart=(e)=>{
  setSearchStartAddress(e.target.value)
}
 const onChangeEnd=(e)=>{
  setSearchEndAddress(e.target.value)
 }
const onSearchTaxiList=()=>{
//조회하기 클릭하는거->taxiList에 필터링해서 데이터 들어가게
const filterList=taxiList.filter(item=>item.startAddress.includes(searchStartAddress)&&item.endAddress.includes(searchEndAddress))

setFilterTaxiList(filterList)
const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(filterList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)

}
const onAlllTaxiList=()=>{
  setFilterTaxiList(taxiList)
  const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(taxiList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)
}
function inputFormatDate(date) {
  // 날짜를 받아와서 원하는 형식으로 변환합니다.
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

  // 원하는 형식으로 날짜를 조합하여 반환합니다.
  return `${year}-${month}-${day}`;
}
function handleInputGoBunChange(e) {
  let inputValue = e.target.value;

// 입력된 값이 숫자인지 확인하는 정규 표현식
const regex = /^[0-9]*$/;

// 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
if (inputValue.length > 2) {
inputValue = inputValue.slice(-2);
}

// 입력된 값이 숫자이고 최대 두 자리인지 확인
if (regex.test(inputValue)) {
// 입력된 값을 state에 설정
setsearchGoBunTime(inputValue);
}
}
function handleInputGoSiChange(e) {
  let inputValue = e.target.value;

// 입력된 값이 숫자인지 확인하는 정규 표현식
const regex = /^[0-9]*$/;

// 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
if (inputValue.length > 2) {
inputValue = inputValue.slice(-2);
}

// 입력된 값이 숫자이고 최대 두 자리인지 확인
if (regex.test(inputValue)) {
// 입력된 값을 state에 설정
setsearchGoSiTime(inputValue);
}
}

const onChangeReply=(e)=>{

  setReply(e.target.value)
}
const formatForDbRgDate = (date) => {
  // const date = new Date(isoDateTime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
  const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
  const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
  const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};
const onEnterReply=async(e)=>{
  const docId = uuid()
  if(e.key === 'Enter') {
    await setDoc(doc(db, "community", docId), {
    //temp
    //댓글 쓴사람 아이디
    ownerUserId:location.state.ownerUserId,
    // nickname:"",
    registerDate:formatForDbRgDate(newRegisterDate),
    reply:reply,

  })
  fetchEveryCommunityPosts()
  setReply("")
  }

}
// const onBtnTaxi=(filtered)=>{
//   const indexOfLastPost = currentDelPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// const currentTPosts = filtered.slice(indexOfFirstPost, indexOfLastPost);
// console.log("on btn taxi",filtered,currentTPosts)
// setCurrentTaxiList(currentTPosts)
// }
const getBoardBtns=(nowType)=>{
  if (nowType==="delivery") {
   return <BoardBtns table={filterDeliveryList} setCurrentPage={setCurrentDelPage} currentPage={currentDelPage}/>
  }
  if (nowType==="taxi") {
    return <BoardBtns
    totalPageLength={filterTaxiList.length}
    filtered={filterTaxiList}
    // onBtn={onBtnTaxi}
    table={currentTaxiList} setCurrentPage={setCurrentTaxiPage} currentPage={currentTaxiPage}/>
  }
  if (nowType==="community") {
    return <BoardBtns table={filterCommunityList} setCurrentPage={setCurrentComPage} currentPage={currentComPage}/>
  }
}
const getTable=(nowType)=>{

  if (nowType==="delivery") {
   const sorted=sortedGoTime(currentDeliveryList)
    return <MdDeliveryTable list={sorted}/>
  } 
  else if(nowType==="taxi"){return <TaxiPotContent
  currentPage={currentTaxiPage}
  nowPotName={"taxi"}
  searchNextDay={searchNextDay}
  searchToday={searchToday}
  setSearchNextDay={setSearchNextDay}
  setSearchToday={setSearchToday}
  onClickTodaySearch={(e)=>onClickTodaySearch("taxi",searchToday)}
  onClickNextSearch={(e)=>onClickNextSearch("taxi",searchNextDay)}
    onClickTaxiPotToggle={onClickTaxiPotToggle}
    taxiPotToggle={taxiPotToggle}
  onAlllTaxiList={onAlllTaxiList}
  handleInputBunChange={handleInputGoBunChange}
  handleInputSiChange={handleInputGoSiChange}
  inputFormatDate={inputFormatDate}
  searchGoBunTime={searchGoBunTime}
  searchGoSiTime={searchGoSiTime}
  searchGoTime={searchGoTime}
  setSearchGoTime={setSearchGoTime}
  searchStartAddress={searchStartAddress}
  searchEndAddress={searchEndAddress}
  onChangeStart={onChangeStart}
  onChangeEnd={onChangeEnd}
  onSearchTaxiList={onSearchTaxiList}
  list={currentTaxiList}

  />;}
  else if(nowType==="community"){
    return <CommunityContent
    onClickCommunityToggle={onClickCommunityToggle}
    communityToggle={communityToggle}
    communityList={currentCommunityList}
    communityReplyList={communityReplyList}
  onEnterReply={onEnterReply}
  onChangeReply={onChangeReply}
  reply={reply}
  />;}
  else {
    return <></>;
  }
}
function inputFormatDate(date) {
  // 날짜를 받아와서 원하는 형식으로 변환합니다.
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

  // 원하는 형식으로 날짜를 조합하여 반환합니다.
  return `${year}-${month}-${day}`;
}
const formatSearchDate = (date) => {
  // const date = new Date(isoDateTime);
 
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
  const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
  const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
  const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩
  return `${year}.${month}.${day}`;
};
const onClickTodaySearch=(nowPotName,searchToday)=>{

 if (nowPotName==="delivery") {
  const filtered=deliveryList.filter(l=>l.registerDate && l.registerDate.includes(formatSearchDate(searchToday)))
setFilterDeliveryList(filtered) 
const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
}
 if(nowPotName==="taxi"){ 
const tfiltered=taxiList.filter(l=>
  l.goTime&&
  l.goTime.includes(formatSearchDate(searchToday)))
  setFilterTaxiList(tfiltered)
  const indexOfLastTPost = currentTaxiPage * postsPerPage;
    const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
const currentTPosts = sortedGoTime(tfiltered).slice(indexOfFirstTPost, indexOfLastTPost);
setCurrentTaxiList(currentTPosts)
 }
 else if(nowPotName==="community"){
  const cfiltered=communityList.filter(l=>l.registerDate.includes(formatSearchDate(searchToday)))
  setFilterCommunityList(cfiltered)
  const indexOfLastCPost = currentComPage * postsPerPage;
  const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
const currentCPosts = sortedGoTime(cfiltered).slice(indexOfFirstCPost, indexOfLastCPost);
setCurrentTaxiList(currentCPosts)
 }
 else{
  
 }
}
const onClickNextSearch=(nowPotName,searchNext)=>{
  if (nowPotName==="delivery") {
    const filtered=deliveryList.filter(l=>l.registerDate.includes(formatSearchDate(searchNext)))
   setFilterDeliveryList(filtered)
   const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
  }
  else if(nowPotName==="taxi"){
 const filtered=taxiList.filter(l=>l.goTime.includes(formatSearchDate(searchNext)))
   setFilterTaxiList(filtered)
   const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)
  }
  // else if(nowPotName==="community"){
  //   const filtered=communityList.filter(l=>l.registerDate.includes(formatSearchDate(searchNext)))
  //  setFilterCommunityList(filtered)
  // }
  else{
 
  }
 }
 const getSmTable=(nowType)=>{
  if (nowType==="delivery") {
    return <SmDeliveryTable 
    toggle={toggle}
    list={currentDeliveryList}/>
  }
  if (nowType==="taxi") {
    return <SmTaxiTable 
    toggle={toggle}
    list={toggle==="0"?sortedRegDate(currentTaxiList):sortItemsByProximityToNow(currentTaxiList)}/>
  }
  if (nowType==="community") {
    return <SmCommunityTable
    toggle={toggle}
    list={currentCommunityList}/>
  }
 }
 //페이지네이션
 useEffect(()=>{
  const indexOfLastPost = currentDelPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedGoTime(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
  setCurrentDeliveryList(currentPosts)

 },[filterDeliveryList])
 useEffect(()=>{
  const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
 },[currentDelPage])
  //페이지네이션
  useEffect(()=>{
    const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentTaxiList(currentPosts)
     },[filterTaxiList])
    
     useEffect(()=>{
      const indexOfLastPost = currentTaxiPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = sortedGoTime(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
        setCurrentTaxiList(currentPosts)
     },[currentTaxiPage])
     //페이지네이션
  useEffect(()=>{
    const indexOfLastPost = currentComPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentCommunityList(currentPosts)
     },[filterCommunityList])
     useEffect(()=>{
    
      const indexOfLastPost = currentComPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = sortedGoTime(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
        setCurrentCommunityList(currentPosts)
     },[currentComPage])
//      useEffect(()=>{
//       if (nowPotName==="taxi") {

//         setCurrentTaxiPage("1")
//       }
//       if (nowPotName==="delivery") {
//         setCurrentDelPage("1")
//       }
// if (nowPotName==="community") {
//   setCurrentComPage("1")
// }


//      },[nowPotName])
const sortedRegDate  = (dataList) => {
  const now = new Date().getTime();

  return dataList.slice().sort((a, b) => {
    const dateA = new Date(a.registerDate).getTime();
    const dateB = new Date(b.registerDate).getTime();
    const diffA = dateA - now;
    const diffB = dateB - now;

    console.log("비교 날짜>>>", dateA, dateB);
    console.log("현재 시간과의 차이>>>", diffA, diffB);

    return diffB-diffA  ;
  });
};
const sortItemsByProximityToNow  = (dataList) => {
  const now = new Date().getTime();

  return dataList.slice().sort((a, b) => {
    const dateA = new Date(a.goTime).getTime();
    const dateB = new Date(b.goTime).getTime();
    const diffA = Math.abs(dateA - now);
    const diffB = Math.abs(dateB - now);

    console.log("비교 날짜>>>", dateA, dateB);
    console.log("현재 시간과의 차이>>>", diffA, diffB);

    return diffA - diffB;
  });
};
console.log("토글 in taxi",toggle)
//전체글일때 글 불러오는거-검색
// const fetchSearchMPosts = async (startAddress,endAddress,goTime,goSiTime,goBunTime,searchField) => {
//   try {
//     console.log("서치 필드",searchField)
//     const formattedGoTime = moment(goTime).format("YYYY.MM.DD");
//     const formattedDate=`${formattedGoTime} ${goSiTime}:${goBunTime}`
//      console.log("in onSearch>>>",formattedDate) 
//      const nowMeetingPlace=params.fullAddress
//     const querySnapshot = await getDocs(
//       query(postsCol, 
//         where('meetingPlace', '==', nowMeetingPlace),
//         // where('startAddress', '==', startAddress),
//         // where('endAddress', '==', endAddress),
//         where('foodCategory', 'in', taxiValues),
//         // where("goTime", '==', formattedDate),
//         orderBy('registerDate', 'desc'),
//         // limit(20)
//       )
//     );
    
//     const posts = [];
//     querySnapshot.forEach((doc) => {
//       const data=doc.data()
//       console.log("data field val>>>",data,formattedDate)
//       // 각 문서에서 데이터를 가져와서 배열에 추가
//       //가는일시 ~~이후를 여기다가 조건 걸면 될 듯
 
//     if (searchField!=null) {
//       for (let i = 0; i < searchField.length; i++) {
//   const s = searchField[i];
//   if (data.hasOwnProperty(s)) {
//      if (data[s].includes(inputValue)) {      
//         console.log("searchfield list>>>",s,data[s])
//           posts.push({...data, docId: doc.id});
//       }
//   }
 
// }
//     } else{
      
//      //search box
//      if (data.hasOwnProperty('startAddress')&&data.hasOwnProperty('endAddress')&&data.hasOwnProperty('goTime')) {
      
//       if ((data.goTime.toString()===formattedDate.toString())) {
//         console.log("검색 일치>>>",data.goTime,"///",formattedDate)
//               console.log("in if scope>>>",data)
//               // if (data.goTime==formattedDate) {
//                 posts.push({...data, docId: doc.id});
//               // }
                    
//             }
      
//             }
//     }
      
    
//     });
    
// // const addressFiltered=posts.filter(post=>post.startAddress.includes(startAddress)||post.endAddress.includes(endAddress))
    
// console.log("address filter>>>",posts,inputValue)
// return posts;
//   } catch (error) {
//     console.error("게시물 가져오기 오류: ", error);
//     return []; // 에러 발생 시 빈 배열 반환
//   }
// };
const fetchSearchMPosts = async (placeType) => {
  try {
    console.log("필터링>>>", searchGoTime);
    const formattedGoTime = moment(searchGoTime).format("YYYY.MM.DD");
    const formattedDate = `${formattedGoTime} ${searchGoSiTime}:${searchGoBunTime}`;
    const nowMeetingPlace = params.fullAddress;
    const nowSi = params.si;
    const nowDong = params.dong;

    // 모든 데이터를 가져오기 위한 기본 쿼리
    const qRes = query(
      postsCol,
      where("foodCategory", "in", taxiValues),
      orderBy("registerDate", "desc")
    );

    // 쿼리 실행
    const querySnapshot = await getDocs(qRes);
    const posts = [];

    // 클라이언트 측에서 필터링
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let match = true;

      // 필터링 로직
      if (placeType === "meeting-place") {
        if (data.meetingPlace !== nowMeetingPlace) match = false;
      } else if (placeType === "si-place") {
        if (data.si !== nowSi) match = false;
      } else if (placeType === "dong-place") {
        if (data.si !== nowSi || data.dong !== nowDong) match = false;
      }

      // 추가적인 필터링 조건
      if (selectedFields.startAddress) {
        if (!data.hasOwnProperty("startAddress") || !data.startAddress.includes(startAddress)) {
          match = false;
        }
      }
      if (selectedFields.endAddress) {
        if (!data.hasOwnProperty("endAddress") || !data.endAddress.includes(endAddress)) {
          match = false;
        }
      }
      if (selectedFields.goTime) {
        console.log("디비에서 goTime>>>",data.goTime)
        if (!data.hasOwnProperty("goTime") || !data.goTime.includes(formattedGoTime)) {
          match = false;
        }
      }
      if (selectedFields.author) {
        if (!data.hasOwnProperty("id") || !data.id.includes(searchAuthor)) {
          match = false;
        }
      }

      if (match) {
        posts.push({ ...data, docId: doc.id });
      }
    });

    console.log("데이터들>>>", posts);
    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return [];
  }
};



//일정순일때 글 불러오는거
const fetchSearchMDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };
    console.log("search today>>>",formatDate(new Date(searchToday)))
    const nowMeetingPlace = meetingPlace; // params.fullAddress로부터 가져오는 방식이라 가정

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', 'in', deliveryValues),
      )
    );

    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (data.hasOwnProperty('goTime')) {
        if (data.goTime.includes(formatDate(new Date(searchToday)))) {
         
          if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
             console.log("search data in day>>>",searchField,inputValue)
             posts.push({...data, docId: doc.id});
          }
            
        }
     
      }
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.orderTime);
        const dateB = new Date(b.orderTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchSearchSiDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', deliveryValues),
      )
    );

    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (data.si===params.si) {
          if (data.hasOwnProperty('goTime')) {
        if (data.goTime.includes(formatDate(new Date(searchToday)))) {
         
          if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
             console.log("search data in day>>>",searchField,inputValue)
             posts.push({...data, docId: doc.id});
          }
            
        }
     
      }
      }
    
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.orderTime);
        const dateB = new Date(b.orderTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchSearchDongDayPosts = async (meetingPlace, searchToday,inputValue,searchField) => {
  try {
    const formatDate = (dateObject) => {
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
      const day = ('0' + dateObject.getDate()).slice(-2);
      return `${year}.${month}.${day}`;
    };

    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', deliveryValues),
      )
    );

    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
      // goTime 필드가 존재하는 경우에만 데이터를 추가합니다
      if (data.hasOwnProperty("dong")&&data.dong===params.dong) {
          if (data.hasOwnProperty('goTime')) {
        if (data.goTime.includes(formatDate(new Date(searchToday)))) {
         
          if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
             console.log("search data in day>>>",searchField,inputValue)
             posts.push({...data, docId: doc.id});
          }
            
        }
     
      }
      }
    
    });

    // goTime 필드가 존재하는 경우에만 정렬합니다
    if (posts.length > 0 && posts[0].hasOwnProperty('orderTime')) {
      posts.sort((a, b) => {
        // 날짜 형식이 문자열인 경우
        const dateA = new Date(a.orderTime);
        const dateB = new Date(b.orderTime);
        return dateB - dateA; // 내림차순 정렬 (최신 순)
      });
    }
console.log("동 데이터>>>",posts)
    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
//전체글 검색할때
// const fetchSearchEveryPosts = async (inputValue,goTime,
//   goSiTime,
//   goBunTime ,searchField) => {
//   let originPosts = [];

//   if (params.postType === "meeting-place") {
//     originPosts = await fetchSearchMPosts(inputValue,goTime,
//       goSiTime,
//       goBunTime ,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
//   }
//    console.log("검색 결과>>>",originPosts)


//   // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
//   const batchSize = 20;
//   const doubleList = [];
//   for (let i = 0; i < originPosts.length; i += batchSize) {
//     doubleList.push(originPosts.slice(i, i + batchSize));
//   }
//   console.log("doubleList in taxi>>>", doubleList);
//   setPosts(doubleList);
// };
const fetchSearchEveryPosts = async (inputValue, searchField) => {
  let originPosts = [];

  // if (params.postType === "meeting-place") {
    originPosts = await fetchSearchMPosts(params.postType);
  // } 
  // else if (params.postType === "si-place") {
  //   originPosts = await fetchSearchSiPosts(inputValue, searchField);
  // } else if (params.postType === "dong-place") {
  //   originPosts = await fetchSearchDongPosts(inputValue, searchField);
  // }

  const batchSize = 20;
  const doubleList = [];
  for (let i = 0; i < originPosts.length; i += batchSize) {
    doubleList.push(originPosts.slice(i, i + batchSize));
  }
  console.log("서치한 후 이중배열 데이터",doubleList)
  setPosts(doubleList);
};


const fetchSearchEveryDayPosts = async (searchToday,inputValue,searchField) => {
  let originPosts = [];

  if (params.postType === "meeting-place") {
    originPosts = await fetchSearchMDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }
  else if(params.postType==="si-place"){
    originPosts = await fetchSearchSiDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  
  }
  else if(params.postType==="dong-place"){
    originPosts = await fetchSearchDongDayPosts(params.fullAddress, searchToday,inputValue,searchField); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  
  }


  // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
  const batchSize = 20;
  const doubleList = [];
  for (let i = 0; i < originPosts.length; i += batchSize) {
    doubleList.push(originPosts.slice(i, i + batchSize));
  }
  console.log("doubleList>>>", doubleList);
  setPosts(doubleList);
};
//서치 박스용 search함수 따로 만들기

// const onSearch=async(inputStart,inputEnd,goTime,goSiTime,goBunTime,postToggle,searchType)=>{
//   // setMainLoading(true)
//   setNowPageIdx(0)
//   let searchField="";
//   if (searchType==="제목") {
//   searchField=["startAddress","endAddress"]
  
   
//   } 
//   else if(searchType==="글쓴이"){
//   searchField=["id"]
//   }

//   if (postToggle==="all") {
//     console.log("택시팟 전체글 서치>>>",postToggle)
//     await  fetchSearchEveryPosts(inputStart,inputEnd,goTime,goSiTime,goBunTime,searchField)
//   } else {
//     console.log("토글>>>",postToggle)
//     await  fetchSearchEveryDayPosts(searchToday,inputValue,searchField)
//   }
  
//   }
// const onSearch = async (postToggle, searchType,goTime,
//   goSiTime,
//   goBunTime , inputValue) => {
//   setNowPageIdx(0)
//   let searchField = "";
//   if (searchType === "제목") {
//     searchField = "startEnd";

//   } else if (searchType === "글쓴이") {
//     searchField = "id";
//   }

//   if (postToggle === "all") {
//     await fetchSearchEveryPosts(inputValue,goTime,
//       goSiTime,
//       goBunTime , searchField);
//   } else {
//     await fetchSearchEveryDayPosts(searchToday, inputValue, searchField);
//   }
// };
const onSearch = async (postToggle, searchType, inputValue) => {
  setNowPageIdx(0)
  let searchField = "";
  if (searchType === "수령장소") {
    searchField = "detailAddress";
  } else if (searchType === "음식점") {
    searchField = "restaurant";
  } else if (searchType === "카테고리") {
    searchField = "foodCategory";
  } else if (searchType === "글쓴이") {
    searchField = "id";
  }
console.log("전체글??",postToggle)
  if (postToggle === "all") {
    await fetchSearchEveryPosts(inputValue, searchField);
  } else {
    await fetchSearchEveryDayPosts(searchToday, inputValue, searchField);
  }
};

  const handleGoTime=(e)=>{
    console.log("new go time>>>",e)
    setSearchGoTime(new Date(e.target.value))
  }
  useEffect(()=>{
    if (postToggle==="all") {
      console.log("useEffect 1>>>")
      fetchEveryPosts()
    } else if(postToggle==="day")
  {
    fetchEveryDayPosts(searchToday)
  }
  },[postToggle,searchToday])
  const handlePageBtn=(idx)=>{
    setNowPageIdx(idx)
    }
    const handleFirstBtn=()=>{
      setNowPageIdx(0)
    }
    const handlePrevBtn=()=>{
      if (nowPageIdx===0)return
      setNowPageIdx(p=>p-1)
     
    }
    const handleLastBtn=()=>{
    setNowPageIdx(posts.length-1)
    }
    const handleNextBtn=()=>{
      
      if (nowPageIdx+1===posts.length)return
      setNowPageIdx(p=>p+1)
    }
    useEffect(()=>{
      navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${nowPageIdx+1}`)
    },[nowPageIdx])
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    const goViewWritePage=async(row)=>{
      sessionStorage.setItem("viewWrite",JSON.stringify(row))
      navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`)
    }
    const applyFilter = () => {
      onSearch(postToggle, selectedFilterType, filterInput);
      handleClose();
    };
    console.log("동 데이터>>>",posts)
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
          }}
        
        >
            <Mobile
            >
           
                  <div style={{
          width: 'calc(100% - 12px)',
          
          marginLeft: '6px', marginRight: '6px' ,
          marginBottom:"30px"}}>
<Dialog
fullWidth
open={openFilter}
>
  
  <DialogTitle
  sx={{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  }}
  >
<Typography variant='h9'>검색필터 적용</Typography>
    <IconButton>
      <MdClose
      size={20}
      />
    </IconButton>
  </DialogTitle>
  <DialogContent><TaxiSearchCardMini
  handleFilterClose={handleFilterClose}
   selectedFields={selectedFields}
   setSelectedFields={setSelectedFields}
    author={searchAuthor}
    onChangeAuthor={onChangeAuthor}
        searchGoTime={searchGoTime}
        searchGoSiTime={searchGoSiTime}
        searchGoBunTime={searchGoBunTime}
        onChangeGoTime={handleGoTime}
        onChangeSearchGoSiTime={handleInputGoSiChange}
onChangeSearchGoBunTime={handleInputGoBunChange}
        startAddress={startAddress}
        endAddress={endAddress}
        onChangeStartAddress={onChangeStartAddress}
onChangeEndAddress={onChangeEndAddress}
onSearch={() => onSearch(postToggle, selectedSearchType, searchGoTime, searchGoSiTime, searchGoBunTime, inputValue)}    /></DialogContent>
</Dialog>
        
   <MbFavoriteBar/>

   {/* <TaxiSearchCardMini
   selectedFields={selectedFields}
   setSelectedFields={setSelectedFields}
    author={searchAuthor}
    onChangeAuthor={onChangeAuthor}
        searchGoTime={searchGoTime}
        searchGoSiTime={searchGoSiTime}
        searchGoBunTime={searchGoBunTime}
        onChangeGoTime={handleGoTime}
        onChangeSearchGoSiTime={handleInputGoSiChange}
onChangeSearchGoBunTime={handleInputGoBunChange}
        startAddress={startAddress}
        endAddress={endAddress}
        onChangeStartAddress={onChangeStartAddress}
onChangeEndAddress={onChangeEndAddress}
onSearch={() => onSearch(postToggle, selectedSearchType, searchGoTime, searchGoSiTime, searchGoBunTime, inputValue)}    /> */}
    <Button variant='contained'
    fullWidth
    sx={{...searchBtnSx,width:null}}
            onClick={handleFilterOpen}
    >검색</Button>
   <div style={{ height: '30px' }} />
          <div
          style={{
            display:"flex",
          alignItems:"center",
            justifyContent:"space-between"
          }}
          >  <AllDateToggle type={postToggle} onChange={onChangePostToggle} />
          
          {postToggle === "all" ? (
            <CustomWriteBtn onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)} />
          ) : (
            <TextField
              size='small'
              type='date'
              value={formatDate(searchToday)}
              onChange={(e) => {
                const day = new Date(e.target.value);
                setSearchToday(day);
              }}
            />
          )}</div>
            <List>
          {posts[nowPageIdx]?.map((post) => (
            <>
              <ListItem alignItems="flex-start"
              onClick={()=>{goViewWritePage(post)}}
              >
           
                <ListItemText
                  primary={post.restaurant}
                  secondary={
                    <>
                      <span>출발지 : {post.title?post.title.split("~")[0]:"-"}</span>
                      <br />
                      <span>도착지 : {post.title?post.title.split("~")[1]:"-"}</span>
                      <br />
                      <span>일정 : {post.registerDate}</span>
                      <br />
                      <span>글쓴이 : {post.id===undefined||!post.id?"-":post.id}</span>
                      <br />
                      <span>{formattedRegDate(post.writeDate) ?? "-"}</span>
                    </>
                  }
                />
              </ListItem>
              <Divider  component="li" />
            </>
          ))}
        </List>  
      
      <div
     style={{
       display:"flex",
       width:"100%",
       alignItems:"center",
       justifyContent:"center",
       marginTop:"30px"
     }}
     > <MbPageBtns  length={posts.length} nowIdx={nowPageIdx} onClick={handlePageBtn}
          handleFirstBtn={handleFirstBtn}
          handleLastBtn={handleLastBtn}
          handleNextBtn={handleNextBtn}
          handlePrevBtn={handlePrevBtn}
          /></div>
         
          </div>
           
       
 
               </Mobile>
            <PC> 
            <div
                style={{
                    height:pcEmpty,
                }}
                />
   <FavoriteBar />

        <TaxiSearchCard
   author={searchAuthor}
   onChangeAuthor={onChangeAuthor}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        searchGoTime={searchGoTime}
        searchGoSiTime={searchGoSiTime}
        searchGoBunTime={searchGoBunTime}
        onChangeGoTime={handleGoTime}
        onChangeSearchGoSiTime={handleInputGoSiChange}
onChangeSearchGoBunTime={handleInputGoBunChange}
        startAddress={startAddress}
        endAddress={endAddress}
        onChangeStartAddress={onChangeStartAddress}
onChangeEndAddress={onChangeEndAddress}
        onSearch={()=>{onSearch(postToggle,"",inputValue)}}
        />
    

{/* <TaxiPotContent
  currentPage={currentTaxiPage}
  nowPotName={"taxi"}
  searchNextDay={searchNextDay}
  searchToday={searchToday}
  setSearchNextDay={setSearchNextDay}
  setSearchToday={setSearchToday}
  onClickTodaySearch={(e)=>onClickTodaySearch("taxi",searchToday)}
  onClickNextSearch={(e)=>onClickNextSearch("taxi",searchNextDay)}
  // onClickTaxiPotToggle={onClickTaxiPotToggle}
  onClickTaxiPotToggle={clickToggle}
  taxiPotToggle={toggle}
  onAlllTaxiList={onAlllTaxiList}
  handleInputBunChange={handleInputGoBunChange}
  handleInputSiChange={handleInputGoSiChange}
  inputFormatDate={inputFormatDate}
  searchGoBunTime={searchGoBunTime}
  searchGoSiTime={searchGoSiTime}
  searchGoTime={searchGoTime}
  setSearchGoTime={setSearchGoTime}
  searchStartAddress={searchStartAddress}
  searchEndAddress={searchEndAddress}
  onChangeStart={onChangeStart}
  onChangeEnd={onChangeEnd}
  onSearchTaxiList={onSearchTaxiList}
  list={toggle==="0"?sortedRegDate(currentTaxiList):sortItemsByProximityToNow(currentTaxiList)}

  /> */}
   <div
               style={{      marginTop:"30px",
                marginBottom:"10px",
                width:mdWidth,
                minWidth:"900px",
                display:"flex",
                justifyContent:"space-between"
               }}
               >
                <AllDateToggle
               type={postToggle}
               onChange={onChangePostToggle}
               />
               {postToggle==="all"?<CustomWriteBtn
               onClick={()=>{
                // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
               }}
               />:<TextField
               size='small'
               type='date'
               value={inputFormatDate(searchToday)}
               onChange={
                 (e)=>{
                   const day=new Date(e.target.value)
                   setSearchToday(day)
                 }
               }
               />}
               </div>
               <div
        style={{
          height:"22px"
        }}
        />
          {/* 테이블 */}
        <div
        style={{
          width:mdWidth,
          minWidth:"900px",
        }}
        ><BasicTaxiTable
  tableHeads={["글번호",
    "일정",
    "제목",
    "글쓴이",
    "작성일",]}
    nowPageIdx={nowPageIdx}
    rows={posts[nowPageIdx]}
  /></div>
  

  
        {/* <TableSearchBar
     onChangeSearchType={onChangeSearchType}
     searchOptions={searchOptions}
     selectedSearchType={selectedSearchType}
     inputValue={inputValue}
     onChangeInput={onChangeInput}
     onSearch={(e)=>{onSearch(selectedSearchType,taxiList,inputValue)}}
     />
        {getBoardBtns("taxi")} */}
         {/* <TableSearchBar
     onChangeSearchType={onChangeSearchType}
     searchOptions={searchOptions}
     selectedSearchType={selectedSearchType}
     inputValue={inputValue}
     onChangeInput={onChangeInput}
     onSearch={() => onSearch(postToggle, selectedSearchType, searchGoTime, searchGoSiTime, searchGoBunTime, inputValue)}
      goWrite={()=>{
        // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
        navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
       }}
     /> */}
     <div
     style={{
      marginTop:"30px"
     }}
     >  <PageBtns  length={posts.length} nowIdx={nowPageIdx} onClick={handlePageBtn}
          handleFirstBtn={handleFirstBtn}
          handleLastBtn={handleLastBtn}
          handleNextBtn={handleNextBtn}
          handlePrevBtn={handlePrevBtn}
          /></div>
        
        </PC>
        </div>
    );
}

export default TaxiTablePage;