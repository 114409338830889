import React from 'react';
import { getBeforeTimeOrDeadLineTime, minutesAgo } from '../const/const';
import WriterBox from './WriterBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../atom/atom';

function SmCommunityTable({list,toggle}) {
    const location=useLocation()
    const navigate=useNavigate()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    // const [nowPotName,setNowPotName]=useRecoilState(nowPotNameState)
    return (
        <ul
        style={{
            width:"calc(100% - 27px - 27px)",
            listStyle:"none",
            margin:0,
            padding:0
        }}
        >
            {
                list.map(item=> <li
                    style={{
                        paddingTop:"8px",
                        paddingBottom:"8px",
                        height:"50px",
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        borderBottom:"1px solid #A5B2BA"
                    }}
                    onClick={()=>{
                        navigate(`/${nowAddress.fullAddress}/reply-community`,{
                            state:{
                                ...location.state,
                                write:item
                            }
                        })
                    
                    }}
                    >
                    
                        <div
                        style={{
                            display:"flex",
                            height:"100%",
                            width:"100%",
                            alignItems:"center"
                        }}
                        >
                            {item.contents}
                        </div>
                        <div
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            width:"300px"
                        }}
                        >
                            <div
                            style={{
                                display:"flex",
                                justifyContent:"flex-end"
                            }}>
                                {item.registerDate}
                            </div>
                            <div
                            style={{
                                
                                display:"flex",
                                flexDirection:"row",
                                // gap:"6px"
                                justifyContent:"space-between"
                            }}
                            >
                                <WriterBox item={item}/>
                                <div
                                style={{
                                    // width:"50%"
                                    fontSize:"14px"
                                }}
                                >{item.foodCategory}</div>
                                </div>

                        </div>
                    </li>)
            }
           
           
           
        </ul>
    );
}

export default SmCommunityTable;