import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

function GoLoginDialog({ goLoginDialog, setGoLoginDialog, setLoginDialog }) {
    return (
        <Dialog
            open={goLoginDialog}
            fullWidth
            maxWidth="xs"
            PaperProps={{
                sx: {
                    m: 1,
                    borderRadius: '20px',
                },
            }}
        >
            <DialogContent
                sx={{
                    // border: '3px solid #104A62',
                    backgroundColor: '#BDFBFF',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    padding: '16px',
                }}
            >
                <span>로그인 해 주세요.</span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '16px',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <button
                        style={{
                            width: '60px',
                            height: '30px',
                            borderRadius: '6px',
                            border: 'none',
                            cursor: 'pointer',
                            backgroundColor: '#104A62',
                            color: 'white',
                        }}
                        onClick={() => {
                            setGoLoginDialog(false);
                        }}
                    >
                        취소
                    </button>
                    <button
                        style={{
                            width: '180px',
                            height: '30px',
                            borderRadius: '6px',
                            border: 'none',
                            cursor: 'pointer',
                            backgroundColor: '#104A62',
                            color: 'white',
                        }}
                        onClick={() => {
                            setGoLoginDialog(false);
                            setLoginDialog(true);
                        }}
                    >
                        로그인 하러 가기
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default GoLoginDialog;
