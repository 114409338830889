import React from 'react';
import { PcResAreaSx } from '../../const/BtnStyle';
import { IoMdArrowDropright } from 'react-icons/io';
import { Button, useMediaQuery } from '@mui/material';
import MoreBtn from '../Btn/MoreBtn';
import MbMoreBtn from '../Btn/MbMoreBtn';

function MainWriteResBar({title,onClick}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    return (
        <div
        style={{
            width:"100%",
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"space-between"
        }}
        >
        <div
            style={{
                width:"100%",
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                gap:"3px",
                cursor:"pointer",
              
            }}
    
            > 
            
                <Button
                style={{
                    fontSize:isMobile?"16px":null
                }}
            sx={PcResAreaSx}
            onClick={onClick}
            >
              {title}
               {/* <IoMdArrowDropright size={20}/> */}
              {isMobile?<MbMoreBtn/>: <MoreBtn/>}
               </Button></div>
        </div>
    );
}

export default MainWriteResBar;