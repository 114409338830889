import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import ResArea from '../components/ResArea';
import SmResArea from '../components/SmResArea';
import SmFavArea from '../components/SmFavArea';
import AllDayToggle from '../components/AllDayToggle';
import WriteBtn from '../components/WriteBtn';
import AllWriteUl from '../components/SmAllWriteUl';
import SmAllWriteUl from '../components/SmAllWriteUl';
import BoardInput from '../components/BoardInput';
import BoardSearchInput from '../components/BoardSearchInput';
import { MdDoubleArrow, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import MdAllWriteTable from '../components/MdAllWriteTable';
import { BiArrowFromLeft, BiLeftArrow } from 'react-icons/bi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { loginDialogState, mainLoadingState, nowAreaState, nowPotNameState, openAreaDialogState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import PotBtn from '../components/PotBtn';
import { collection, doc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import { db } from '../service/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { communityValues, deliveryValues, mdWidth, postsPerPage, smWidth, taxiValues } from '../const/const';
import MdDeliveryTable from '../components/MdAllWriteTable';
import TaxiPotContent from '../components/TaxiPotContent';
import CommunityContent from '../components/CommunityContent';
import uuid from 'react-uuid';
import SmDeliveryTable from '../components/SmDeliveryTable';
import SmTaxiTable from '../components/SmTaxiTable';
import SmCommunityTable from '../components/SmCommunityTable';
import BoardBtns from '../components/BoardBtns';
import BoardBtns2 from '../components/BoardBtns2';
import HorizontalDivider from '../components/HorizontalDivider';
import TableSearchBar from '../components/TableSearchBar';
import { Dialog, DialogContent } from '@mui/material';

function CommunityTablePage(props) {
  const postsCol = collection(db, "posts")
  const communityCol = collection(db, "community")
  const [currentDelPage, setCurrentDelPage] = useState(1);
  const [currentTaxiPage, setCurrentTaxiPage] = useState(1);
  const [currentComPage, setCurrentComPage] = useState(1);
  const [toggle,setToggle]=useState("0")
  // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
  const [deliveryList,setDeliveryList]=useState([])
  const [filterDeliveryList,setFilterDeliveryList]=useState([])
  const [currentDeliveryList,setCurrentDeliveryList]=useState([])
  //기존 택시리스트가 있고 조회하기 누르면 filterTaxiList에 담기게
  const [taxiList,setTaxiList]=useState([])
  const [filterTaxiList,setFilterTaxiList]=useState([])
  const [currentTaxiList,setCurrentTaxiList]=useState([])
  const [communityList,setCommunityList]=useState([])
  const [currentCommunityList,setCurrentCommunityList]=useState([])
  const [filterCommunityList,setFilterCommunityList]=useState([])
  const [showList,setShowList]=useState([])
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  //지역검색 다이얼로그 닫을 때마다 글 업데이트 돼야 함
  const [openAreaDialog,setOpenDialog]=useRecoilState(openAreaDialogState)
  //택시팟 리스트 검색용 변수
  const [searchStartAddress,setSearchStartAddress]=useState("")
  const [searchEndAddress,setSearchEndAddress]=useState("")
  //택시팟 리스트 date, si bun 검색 변수
  const nowTime = new Date()
  const nextDay = new Date(nowTime);
nextDay.setDate(nextDay.getDate() + 1); 
  const [searchGoTime, setSearchGoTime] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
  const [searchGoSiTime, setsearchGoSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
  const [searchGoBunTime, setsearchGoBunTime] = useState("00")  
  //커뮤니티 변수
  const [reply,setReply]=useState("")
 const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
 const [communityReplyList,setCommunityReplyList]=useState([])
 //allDay Toggle 일정조회 today , nextDay
 const [searchToday,setSearchToday]=useState(nowTime)
 const [searchNextDay,setSearchNextDay]=useState(nextDay)
 //택시팟 테이블 토글타입
 const [taxiPotToggle,setTaxiToggle]=useState("0")
 //커뮤니티 테이블 토글타입
 const [communityToggle,setCommunityToggle]=useState("전체")
 const [checkSmInput,setCheckSmInput]=useState(false)
 const [selectedSearchType,setSelectedSearchType]=useState("제목")
const searchOptions=["제목","글쓴이"]
const [inputValue,setInputValue]=useState("")
const [mainLoading,setMainLoading]=useRecoilState(mainLoadingState)
const [goLoginDialog,setGoLoginDialog]=useState(false)
const [loginDialog,setLoginDialog]=useRecoilState(loginDialogState)

const onChangeInput=(e)=>{

setInputValue(e.target.value)
}
const onSearch=(searchType,originRows,inputValue)=>{
setMainLoading(true)
let newRows=[]
if (searchType==="제목") {
newRows= originRows.filter(row=>{
  const match=row.contents.includes(inputValue)
return match
})

} 

else if(searchType==="글쓴이"){
newRows= originRows.filter(row=>{
  const match=row.type.includes(inputValue)
return match
})
}
// else if(){}

// const indexOfLastPost = currentDelPage * postsPerPage;
//   const indexOfFirstPost = indexOfLastPost - postsPerPage;
//   const currentPosts = sortedOrderTime(newRows.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory))).slice(indexOfFirstPost, indexOfLastPost);
//   setCurrentDeliveryList(currentPosts)
setFilterCommunityList(newRows)
setMainLoading(false)
}
const onChangeSearchType=(e)=>{
setSelectedSearchType(e.target.id)

}
const navigate=useNavigate()
const onClickTaxiPotToggle=(e)=>{
  setTaxiToggle(e.target.id)


}

const getCurPosts=(currentPage,originList)=>{
  const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts=originList.slice(indexOfFirstPost, indexOfLastPost);
return currentPosts
  }
const onClickAllSearch=(nowPotName)=>{
  if (nowPotName==="delivery") {
  //  const curPs=getCurPosts(currentDelPage,deliveryList)
 setFilterDeliveryList(deliveryList) 
 const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(deliveryList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
//  setCurrentDeliveryList(curPs)
 }
  else if(nowPotName==="taxi"){
   setFilterTaxiList(taxiList)
   const indexOfLastTPost = currentTaxiPage * postsPerPage;
   const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
   const currentTPosts = sortedGoTime(taxiList).slice(indexOfFirstTPost, indexOfLastTPost);
   setCurrentTaxiList(currentTPosts)
  }
  else if(nowPotName==="community"){
    setFilterCommunityList(communityList)
    const indexOfLastCPost = currentComPage * postsPerPage;
    const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
    const currentCPosts = sortedGoTime(communityList).slice(indexOfFirstCPost, indexOfLastCPost);
    setCurrentCommunityList(currentCPosts)
   }
 //  else if(nowPotName==="community"){
 //   const filtered=communityList.filter(l=>l.registerDate.includes(formatSearchDate(searchToday)))
 //   setFilterCommunityList(filtered)
 //  }
  else{
   
  }
 }
const onClickCommunityToggle=(e)=>{
  setCommunityToggle(e.target.id)
  if (e.target.id==="전체") {
    setFilterCommunityList(communityList)
    const indexOfLastPost = currentComPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentCPosts = sortedGoTime(communityList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentCommunityList(currentCPosts)
  } else {
    const filtered=communityList.filter(item=>item.foodCategory===e.target.id)
  setFilterCommunityList(filtered)
  const indexOfLastPost = currentComPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentCPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
setCurrentCommunityList(currentCPosts)
  }
  
}
 const location=useLocation()
  const clickToggle=(e,nowPotName)=>{
    setToggle(e.target.id)
    if (e.target.id==="0") {
      onClickAllSearch("community")
    }
    else{
      onClickTodaySearch("community",searchToday)
    }
    // setNowAddress(newNowAddress)
  }
  const onClickDel=()=>{
    // setNowPotName("delivery")
    // setShowList(deliveryList)
    navigate(`/${nowAddress.fullAddress}/delivery-table`,{state:{
      nowAddress:nowAddress,
      nowPotName:"delivery",
      postType:"meetingPlace",
     ...location.state
  }})

} 
const onClickTaxi=()=>{
  navigate(`/${nowAddress.fullAddress}/taxi-table`,{state:{
    nowAddress:nowAddress,  
    nowPotName:"taxi",
  postType:"meetingPlace",
   ...location.state
}})

 //  ownerUserId:location.state.ownerUserId,
 //  favorites:location.state.favorites
    // setNowPotName("taxi")
} 
const onClickCommunity=()=>{
    // setNowPotName("community")
    navigate(`/${nowAddress.fullAddress}/community-table`,{state:{
      nowAddress:nowAddress,  
      nowPotName:"taxi",
    postType:"meetingPlace",
     ...location.state
  }})
}
const fetchPosts = async () => {
  try {
    const querySnapshot = await getDocs(postsCol);
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...doc.data(),docId:doc.id});

    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchCommunityPosts = async () => {
  try {
    const q = query(collection(db, "community"), orderBy("registerDate", "desc"));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push(doc.data());

    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchEveryCommunityPosts = async () => {

  const allCommunityPosts = await fetchCommunityPosts();
  const filtered=allCommunityPosts.filter(p=>p.meetingPlace&&p.meetingPlace===nowAddress.fullAddress)
  const sorted=sortedGoTime(filtered)
  setCommunityReplyList(sorted.reverse())


}
// const sortedDateList=(dataList)=>{
  
//    return dataList.slice().sort((a, b) => {
//   const dateA = new Date(a.registerDate).getTime();
//   console.log("date A",dateA)
//   const dateB = new Date(b.registerDate).getTime();
//   return dateB-dateA;
// })};
const sortedGoTime=(dataList)=>{
  
  return dataList.slice().sort((a, b) => {
 const dateA = new Date(a.goTime).getTime();
 const dateB = new Date(b.goTime).getTime();
 return dateB-dateA;
})};
const fetchEveryPosts = async () => {
  // 위치 이름이 존재하지 않을때는 최신팟 넣고 
  // 존재 할 때는 택시팟 배달팟 넣기 
  if (location.state.nowAddress.si==="")return
  if (location.state.nowAddress.dong==="")return
  //버튼 눌러서 넘어오는 글은 meetingPlace가 같으면 무조건
  //ㅇㅇ시 최신글 글은 시만 같으면 무조건
  //ㅇㅇ시 ㅇㅇ동 최신글 글은 시 동 같으면 무조건
  let matchedList=[]
  const originPosts = await fetchPosts();
  // console.log("글순서 뒤죽박죽", sortedDateList(allPosts))
  const allPosts=sortedGoTime(originPosts)
  if (location.state.postType==="meetingPlace") {
      matchedList = allPosts.filter(p =>p.meetingPlace &&p.meetingPlace===nowAddress.fullAddress)

  
  }
 else if (location.state.postType==="si") {
  matchedList = allPosts.filter(p =>p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))
  } else if(location.state.postType==="sidong"){
    matchedList =allPosts.filter(p =>(p.meetingPlace &&p.meetingPlace.includes(nowAddress.si))&&p.meetingPlace.includes(nowAddress.dong)||p.dong===nowAddress.dong)
  }

  setDeliveryList(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)));
  setFilterDeliveryList(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)))
//   const curPs=getCurPosts(currentDelPage,matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory)))
//  setCurrentDeliveryList(curPs)
const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && deliveryValues.includes(p.foodCategory))).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
  setTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
  setFilterTaxiList(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory)))
  const indexOfLastTPost = currentTaxiPage * postsPerPage;
  const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
  const currentTPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && taxiValues.includes(p.foodCategory))).slice(indexOfFirstTPost, indexOfLastTPost);
  setCurrentTaxiList(currentTPosts)
 
  setCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
  setFilterCommunityList(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory)))
  const indexOfLastCPost = currentComPage * postsPerPage;
  const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
const currentCPosts = sortedGoTime(matchedList.filter(p => p.foodCategory && communityValues.includes(p.foodCategory))).slice(indexOfFirstCPost, indexOfLastCPost);
setCurrentCommunityList(currentCPosts)
};

useEffect(()=>{
// console.log("뒤로가기 할때",location.state.nowPotName)
  
  // setNowPotName(location.state.nowPotName)
  fetchEveryCommunityPosts()
},[])
useEffect(()=>{
  if (!openAreaDialog) {
    fetchEveryPosts(); // 최신 게시물 가져오기
    
  }else{
    //다이얼로그 켰을때
    setDeliveryList([])
      setTaxiList([])
      setCommunityList([])
  }

  

},[openAreaDialog])

const onChangeStart=(e)=>{
  setSearchStartAddress(e.target.value)
}
 const onChangeEnd=(e)=>{
  setSearchEndAddress(e.target.value)
 }
const onSearchTaxiList=()=>{
//조회하기 클릭하는거->taxiList에 필터링해서 데이터 들어가게
const filterList=taxiList.filter(item=>item.startAddress===searchStartAddress&&item.endAddress===searchEndAddress)

setFilterTaxiList(filterList)
const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(filterList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)

}
const onAlllTaxiList=()=>{
  setFilterTaxiList(taxiList)
  const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(taxiList).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)
}
function inputFormatDate(date) {
  // 날짜를 받아와서 원하는 형식으로 변환합니다.
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

  // 원하는 형식으로 날짜를 조합하여 반환합니다.
  return `${year}-${month}-${day}`;
}
function handleInputGoBunChange(e) {
  let inputValue = e.target.value;

// 입력된 값이 숫자인지 확인하는 정규 표현식
const regex = /^[0-9]*$/;

// 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
if (inputValue.length > 2) {
inputValue = inputValue.slice(-2);
}

// 입력된 값이 숫자이고 최대 두 자리인지 확인
if (regex.test(inputValue)) {
// 입력된 값을 state에 설정
setsearchGoBunTime(inputValue);
}
}
function handleInputGoSiChange(e) {
  let inputValue = e.target.value;

// 입력된 값이 숫자인지 확인하는 정규 표현식
const regex = /^[0-9]*$/;

// 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
if (inputValue.length > 2) {
inputValue = inputValue.slice(-2);
}

// 입력된 값이 숫자이고 최대 두 자리인지 확인
if (regex.test(inputValue)) {
// 입력된 값을 state에 설정
setsearchGoSiTime(inputValue);
}
}

const onChangeReply=(e)=>{

  setReply(e.target.value)
}
const formatForDbRgDate = (date) => {
  // const date = new Date(isoDateTime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
  const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
  const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
  const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};
const onEnterReply=async(e)=>{
  const docId = uuid()
  if(e.key === 'Enter') {
    const login=sessionStorage.getItem("login")
    if (login!="success") {
      setGoLoginDialog(true)}
      else{
         await setDoc(doc(db, "community", docId), {
    //temp
    //댓글 쓴사람 아이디
    ownerUserId:"temp",//location.state.ownerUserId,
    // nickname:"",
    registerDate:formatForDbRgDate(newRegisterDate),
    reply:reply,
    meetingPlace:nowAddress.fullAddress,
            si:nowAddress.si,
            dong:nowAddress.dong,

  })
  fetchEveryCommunityPosts()
  setReply("")
      }
   
  }

}
// const onBtnTaxi=(filtered)=>{
//   const indexOfLastPost = currentDelPage * postsPerPage;
//     const indexOfFirstPost = indexOfLastPost - postsPerPage;
// const currentTPosts = filtered.slice(indexOfFirstPost, indexOfLastPost);
// console.log("on btn taxi",filtered,currentTPosts)
// setCurrentTaxiList(currentTPosts)
// }
const getBoardBtns=(nowType)=>{
  if (nowType==="delivery") {
   return <BoardBtns table={filterDeliveryList} setCurrentPage={setCurrentDelPage} currentPage={currentDelPage}/>
  }
  if (nowType==="taxi") {
    return <BoardBtns
    filtered={filterTaxiList}
    // onBtn={onBtnTaxi}
    table={filterTaxiList} setCurrentPage={setCurrentTaxiPage} currentPage={currentTaxiPage}/>
  }
  if (nowType==="community") {
    return <BoardBtns 
    totalPageLength={filterCommunityList.length}
    table={currentCommunityList} setCurrentPage={setCurrentComPage} currentPage={currentComPage}/>
  }
}
const sortedRegDate  = (dataList) => {
  const now = new Date().getTime();

  return dataList.slice().sort((a, b) => {
    const dateA = new Date(a.registerDate).getTime();
    const dateB = new Date(b.registerDate).getTime();
    const diffA = dateA - now;
    const diffB = dateB - now;

    console.log("비교 날짜>>>", dateA, dateB);
    console.log("현재 시간과의 차이>>>", diffA, diffB);

    return diffB-diffA  ;
  });
};
const sortItemsByProximityToNow  = (dataList) => {
  const now = new Date().getTime();

  return dataList.slice().sort((a, b) => {
    const dateA = new Date(a.orderTime).getTime();
    const dateB = new Date(b.orderTime).getTime();
    const diffA = Math.abs(dateA - now);
    const diffB = Math.abs(dateB - now);

    console.log("비교 날짜>>>", dateA, dateB);
    console.log("현재 시간과의 차이>>>", diffA, diffB);

    return diffA - diffB;
  });
};
const getTable=(nowType)=>{

  if (nowType==="delivery") {
   const sorted=sortedGoTime(currentDeliveryList)
    return <MdDeliveryTable list={sorted}/>
  } 
  else if(nowType==="taxi"){return <TaxiPotContent
  nowPotName={"delivery"}
  searchNextDay={searchNextDay}
  searchToday={searchToday}
  setSearchNextDay={setSearchNextDay}
  setSearchToday={setSearchToday}
  onClickTodaySearch={(e)=>onClickTodaySearch("delivery",searchToday)}
  onClickNextSearch={(e)=>onClickNextSearch("delivery",searchNextDay)}
    onClickTaxiPotToggle={onClickTaxiPotToggle}
    taxiPotToggle={taxiPotToggle}
  onAlllTaxiList={onAlllTaxiList}
  handleInputBunChange={handleInputGoBunChange}
  handleInputSiChange={handleInputGoSiChange}
  inputFormatDate={inputFormatDate}
  searchGoBunTime={searchGoBunTime}
  searchGoSiTime={searchGoSiTime}
  searchGoTime={searchGoTime}
  setSearchGoTime={setSearchGoTime}
  searchStartAddress={searchStartAddress}
  searchEndAddress={searchEndAddress}
  onChangeStart={onChangeStart}
  onChangeEnd={onChangeEnd}
  onSearchTaxiList={onSearchTaxiList}
  list={currentTaxiList}

  />;}
  else if(nowType==="community"){
    return <CommunityContent
    currentPage={currentComPage}
    onClickCommunityToggle={onClickCommunityToggle}
    communityToggle={communityToggle}
    communityList={communityToggle==="전체"?sortedRegDate(currentCommunityList):sortItemsByProximityToNow(currentCommunityList)  }
    communityReplyList={communityReplyList}
  onEnterReply={onEnterReply}
  onChangeReply={onChangeReply}
  reply={reply}
  />;}
  else {
    return <></>;
  }
}
function inputFormatDate(date) {
  // 날짜를 받아와서 원하는 형식으로 변환합니다.
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.

  // 원하는 형식으로 날짜를 조합하여 반환합니다.
  return `${year}-${month}-${day}`;
}
const formatSearchDate = (date) => {
  // const date = new Date(isoDateTime);
 
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
  const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
  const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
  const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩
  return `${year}.${month}.${day}`;
};
const onClickTodaySearch=(nowPotName,searchToday)=>{
 if (nowPotName==="delivery") {
  const filtered=deliveryList.filter(l=>l.registerDate.includes(formatSearchDate(searchToday)))
setFilterDeliveryList(filtered) 
const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
}
 if(nowPotName==="taxi"){
const tfiltered=taxiList.filter(l=>
  l.registerDate.includes(formatSearchDate(searchToday)))
  setFilterTaxiList(tfiltered)
  const indexOfLastTPost = currentTaxiPage * postsPerPage;
    const indexOfFirstTPost = indexOfLastTPost - postsPerPage;
const currentTPosts = sortedGoTime(tfiltered).slice(indexOfFirstTPost, indexOfLastTPost);
setCurrentTaxiList(currentTPosts)
 }
 else if(nowPotName==="community"){
  const cfiltered=communityList.filter(l=>l.goTime.includes(formatSearchDate(searchToday)))
  setFilterCommunityList(cfiltered)
  const indexOfLastCPost = currentComPage * postsPerPage;
  const indexOfFirstCPost = indexOfLastCPost - postsPerPage;
const currentCPosts = sortedGoTime(cfiltered).slice(indexOfFirstCPost, indexOfLastCPost);
setCurrentTaxiList(currentCPosts)
 }
 else{
  
 }
}
const onClickNextSearch=(nowPotName,searchNext)=>{
  if (nowPotName==="delivery") {
    const filtered=deliveryList.filter(l=>l.registerDate.includes(formatSearchDate(searchNext)))
   setFilterDeliveryList(filtered)
   const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
  }
  else if(nowPotName==="taxi"){
 const filtered=taxiList.filter(l=>l.registerDate.includes(formatSearchDate(searchNext)))
   setFilterTaxiList(filtered)
   const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
const currentTPosts = sortedGoTime(filtered).slice(indexOfFirstPost, indexOfLastPost);
setCurrentTaxiList(currentTPosts)
  }
  // else if(nowPotName==="community"){
  //   const filtered=communityList.filter(l=>l.registerDate.includes(formatSearchDate(searchNext)))
  //  setFilterCommunityList(filtered)
  // }
  else{
 
  }
 }
 const getSmTable=(nowType)=>{
  if (nowType==="delivery") {
    return <SmDeliveryTable 
    toggle={toggle}
    list={currentDeliveryList}/>
  }
  if (nowType==="taxi") {
    return <SmTaxiTable 
    toggle={toggle}
    list={currentTaxiList}/>
  }
  if (nowType==="community") {
    return <SmCommunityTable
    toggle={toggle}
    list={currentCommunityList}/>
  }
 }
 console.log("커뮤니티 토글",communityToggle)
 //페이지네이션
 useEffect(()=>{
  const indexOfLastPost = currentDelPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedGoTime(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
  setCurrentDeliveryList(currentPosts)

 },[filterDeliveryList])
 useEffect(()=>{
  const indexOfLastPost = currentDelPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterDeliveryList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentDeliveryList(currentPosts)
 },[currentDelPage])
  //페이지네이션
  useEffect(()=>{
    const indexOfLastPost = currentTaxiPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentTaxiList(currentPosts)
     },[filterTaxiList])
    
     useEffect(()=>{
      const indexOfLastPost = currentTaxiPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = sortedGoTime(filterTaxiList).slice(indexOfFirstPost, indexOfLastPost);
        setCurrentTaxiList(currentPosts)
     },[currentTaxiPage])
     //페이지네이션
  useEffect(()=>{
    const indexOfLastPost = currentComPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = sortedGoTime(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
    setCurrentCommunityList(currentPosts)
     },[filterCommunityList])
     useEffect(()=>{
    
      const indexOfLastPost = currentComPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        const currentPosts = sortedGoTime(filterCommunityList).slice(indexOfFirstPost, indexOfLastPost);
        setCurrentCommunityList(currentPosts)
     },[currentComPage])
//      useEffect(()=>{
//       if (nowPotName==="taxi") {

//         setCurrentTaxiPage("1")
//       }
//       if (nowPotName==="delivery") {
//         setCurrentDelPage("1")
//       }
// if (nowPotName==="community") {
//   setCurrentComPage("1")
// }


//      },[nowPotName])

    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center",
            // paddingTop:"70px"
          }}
        
        >
            <Mobile
            >
                 <div
                style={{
                    height:"70px"
                }}
                />
                <div
                style={{
                    height:"55px"
                }}
                />
                <div
              style={{
                width:smWidth
              }}
              ><SmFavArea 
              componentType={"sm"}
              onClickDel={onClickDel} onClickCommunity={onClickCommunity} onClickTaxi={onClickTaxi}
              nowActive={"community"}
              /></div>
                
                <div
          style={{
            height: "2px"
          }}
        />
        <div
          style={{
            height: "1px",
            width: smWidth,
            backgroundColor: "#D9D9D9"
          }}
        />
        <div
        style={{
            height:"22px"
        }}
        />
        <div
        style={{
            width: smWidth,
            display:"flex",
            flexDirection:"row"
          }}
        >
          <AllDayToggle 
             height={"20px"}
             fontSize={"10px"}
          toggle={toggle} clickToggle={(e)=>{clickToggle(e,"community")}}/>
        <div
        style={{
            flex:1
        }}
        />
        {toggle==="0"?<WriteBtn
         fontSize={"10px"}
        pageName={"community"}
        />:<div
        style={{
          display:"flex",
          flexDirection:"row",
          alignItems:"center"
        }}
        >< IoIosArrowBack/> <input type='date'
        value={inputFormatDate(searchToday)}
        onChange={
          (e)=>{
            const day=new Date(e.target.value)
            const nextDay = new Date(day);
            nextDay.setDate(nextDay.getDate() + 1); 
            setSearchToday(day)
            setSearchNextDay(nextDay)
            onClickTodaySearch("community",day)
          }
        }
      
       
        />< IoIosArrowForward/></div>}
        </div>
        <div
        style={{
            height:"8px"
        }}
        />
      {getSmTable("community")}
      
     {getBoardBtns("community")}
            </Mobile>
            <PC> 
            <Dialog

open={goLoginDialog}
        sx={{
        }}
        >
<DialogContent
sx={{
// borderRadius:"20px",
overflow:"hidden",
  border:"3px solid #104A62",
  backgroundColor:"#BDFBFF",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  gap:"16px"
}}
>
<span>로그인 해 주세요.</span>
<div
style={{
  display:"flex",
  flexDirection:"row",
gap:"16px"
}}
>
  <button
  style={{
    width:"60px",
    height:"30px",
    borderRadius:"6px",
    border:"none",
    cursor:"pointer",
    backgroundColor:"#104A62",
    color:"white"
  }}
  onClick={()=>{setGoLoginDialog(false)

    
        }}
  >취소</button>
  
  <button
    style={{
      width:"180px",
      height:"30px",
      borderRadius:"6px",
      border:"none",
      cursor:"pointer",
      backgroundColor:"#104A62",
      color:"white"
    }}
    onClick={()=>{setGoLoginDialog(false)
setLoginDialog(true)

    }}
  >로그인 하러 가기</button>
</div>
</DialogContent>
        </Dialog>
        <div
                style={{
                    height:"70px"
                }}
                />
                <div
                style={{
                    height:"55px"
                }}
                />
             <SmFavArea 
             width={mdWidth}
              nowActive={"community"}
              componentType={"md"}
              onClickDel={onClickDel} onClickCommunity={onClickCommunity} onClickTaxi={onClickTaxi}/>
           <HorizontalDivider
           width={mdWidth}
           />
         <div
        style={{
            height:"22px"
        }}
        /> 

        <div
        style={{
          width:mdWidth
        }}
        >

          {getTable("community")}
        </div>
        <TableSearchBar
     onChangeSearchType={onChangeSearchType}
     searchOptions={searchOptions}
     selectedSearchType={selectedSearchType}
     inputValue={inputValue}
     onChangeInput={onChangeInput}
     onSearch={(e)=>{onSearch(selectedSearchType,communityList,inputValue)}}
     />
        </PC>
        </div>
    );
}

export default CommunityTablePage;