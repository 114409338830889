import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaLock } from 'react-icons/fa'; // 자물쇠 아이콘 추가

function NotiCustomTable({ list, currentPage, heads, prevPgs }) {
  const navigate = useNavigate();
  const params = useParams();
  const postsPerPage = 20;

  // 전체 글 수를 기준으로 글 번호 계산
  const totalPosts = currentPage * postsPerPage + list.length;
  const startNumber = totalPosts;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="custom table">
        <TableHead sx={{ backgroundColor: "#D9D9D9" }}>
          <TableRow>
            {heads.map((h, idx) => (
              <TableCell key={idx} align={h.align} sx={{ width: h.width }}>
                {h.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ backgroundColor: "pink", maxHeight: `calc(36px * 10)`, overflowY: "hidden" }}>
          {list.map((item, idx) => (
            <TableRow
              key={idx}
              align="center"
              sx={{ height: "36px", cursor: "pointer", backgroundColor: "white" }}
              onClick={() => {
                sessionStorage.setItem("noti", JSON.stringify(item));
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-show`);
              }}
            >
              <TableCell width="6%" align='center'>{startNumber - (currentPage * postsPerPage + idx) + prevPgs}</TableCell>
              <TableCell
                width="60%"
                align="left"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.type === '1' ? (
                  <strong style={{ display: "flex", alignItems: "center",width:"100%" }}>
                    비공개 <FaLock style={{ marginLeft: 4, fontSize: 16 }} />
                  </strong>
                ) : (
                  <>{item.title} {"("}{item.replies ? item.replies.length : 0}{")"}</>
                  
                )}
              </TableCell>
              <TableCell align="center" width="10%">
                {item.id}
              </TableCell>
              <TableCell align="center" width="30%">
                {item.registerDate}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NotiCustomTable;
