import { Button } from '@mui/material';
import React from 'react';

function GreyBtn({title,onClick}) {
    return (
        <Button
variant='contained'
color='inherit'

onClick={onClick}
>{title}</Button>
    );
}

export default GreyBtn;