import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

function Reply({ email,pw, registerDate, reply, onDeleteReply, onUpdateReply,yesEdit }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedReply, setEditedReply] = useState(reply);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditedReply(reply);
    };

    const handleSaveEdit = () => {
        onUpdateReply(editedReply);
        setIsEditing(false);
    };
console.log(email)
    return (
        <div
            style={{
                padding: '8px 0',
                borderBottom: "1px solid #A5B2BA",
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: '4px'
                }}
            >
                <span>({email??"ㅇㅇ"})</span>
               <div>
                    <Button 
                     sx={{color:"#002D82",
                              
                     }}
                    variant="text" color="error" onClick={
                        ()=>{
                            // const login=sessionStorage.getItem("login")
                            // const nowUserId=sessionStorage.getItem("userId");
                            // console.log("nowUserId",nowUserId)
                            const inputPw=window.prompt("비밀번호를 입력하세요")
                            if (inputPw===pw) {
                             onDeleteReply()
                            }else{
                                window.confirm("비밀번호가 맞지 않습니다.")
                            }
                        //     if (login==="success"&&nowUserId===email) {
                        //         onDeleteReply()
                        //     }else{
                        //          const inputPw=window.prompt("비밀번호를 입력하세요")
                        //    if (inputPw===pw) {
                        //     onDeleteReply()
                        //    }
                        //     }
                           
                        }
                        }>삭제</Button>
                    {isEditing ? (
                        <>
                            <Button 
                             sx={{color:"#002D82",
                              
                             }}
                            variant="text" color="primary" onClick={handleSaveEdit}>저장</Button>
                            <Button 
                             sx={{color:"#002D82",
                              
                             }}
                            variant="text" color="secondary" onClick={handleCancelEdit}>취소</Button>
                        </>
                    ) : (
                        <Button 
                        sx={{color:"#002D82",
                              
                        }}
                        variant="text" color="primary" onClick={
                            ()=>{
                                console.log("수정 비번",pw)
                                const inputPw=window.prompt("비밀번호를 입력하세요")
                                if (inputPw===pw) {
                                    handleEdit()
                                   }else{
                                       window.confirm("비밀번호가 맞지 않습니다.")
                                   }
                            }
                            }>수정</Button>
                    )}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: '8px'
                }}
            >
                <span>{registerDate}</span>
            </div>
            <div
                style={{
                    paddingLeft: "20px",
                    width: "100%"
                }}
            >
                {isEditing ? (
                    <TextField
                        value={editedReply}
                        onChange={(e) => setEditedReply(e.target.value)}
                        // fullWidth
                        variant="outlined"
                        size="small"
                    />
                ) : (
                    <span>{reply}</span>
                )}
            </div>
        </div>
    );
}

export default Reply;
