import { Button, Chip } from '@mui/material';
import React from 'react';

function MbMoreBtn({onClick}) {
    return (
        <Chip
        variant='outlined'
         label="+"
         sx={{
            cursor:"pointer",
            marginLeft:"30px"
         }}
         onClick={onClick}
        />
    );
}

export default MbMoreBtn;