import React from 'react';

function WithLabelTxtField({label,onChange,value}) {
    return (
        <div
        style={{
            width:"calc(193px*2 + 36px)",
            height:"36px",
            borderRadius:"8px",
            paddingLeft:"8px",
            border:"2px solid #104A62",
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            overflow:"hidden"
        }}
        >
            <span
            style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                height:"100%",
                width:"100px",
                borderRight:"2px solid #104A62"
            }}
            >{label}</span>
            <input
            autoComplete='new-password'
            value={value}
            onChange={onChange}
        placeholder={`( 직접입력 )`}
        style={{
            paddingLeft:"8px",
          border:"none",
          height:"36px",
          width:"100%"
           
        }}
        /></div>
        
    );
}

export default WithLabelTxtField;