import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

function FavPg() {
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadFavorites = async () => {
      const userId = sessionStorage.getItem("userId");
      if (userId) {
        try {
          const q = query(collection(db, 'favorites'), where('userId', '==', userId));
          const querySnapshot = await getDocs(q);
          const favs = querySnapshot.docs.map(doc => doc.data());
          setFavorites(favs);
        } catch (error) {
          console.error("Error loading favorites: ", error);
        }
      }
    };

    loadFavorites();
  }, []);

  const handleFavoriteClick = (fav) => {
    navigate(`/${fav.fullAddress}/${fav.si}/${fav.dong}`);
  };

  return (
    <Box sx={{ padding: '16px' }}>
      <Typography variant="h6" sx={{ marginBottom: '16px' }}>
        즐겨찾기
      </Typography>
      {favorites.length > 0 ? (
        <List>
          {favorites.map((fav, index) => (
            <ListItem 
              key={index} 
              button 
              onClick={() => handleFavoriteClick(fav)}
              sx={{
                mb: 1,
                p: 1,
                border: '1px solid #ccc',
                borderRadius: 1,
                backgroundColor: '#fff',
              }}
            >
              <ListItemText 
                primary={fav.fullAddress}
                secondary={`${fav.si} ${fav.dong}`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">즐겨찾기 항목이 없습니다.</Typography>
      )}
    </Box>
  );
}

export default FavPg;
