import React from 'react';
import { Button, Typography } from '@mui/material';
import emailjs from '@emailjs/browser';

function ReplyTitle({
    minWidth="360px",
    emailToReport,
    length,
    onDeleteDoc,
    goEditPage,
    yesEdit
}) {
    const onReport = () => {
        const templateParams = {
            to_email: "itshonigo@gmail.com",
            to_name : emailToReport,
        };

        emailjs.send('service_dgp5g76', 'template_pgfkcrz', templateParams, 'l7UyWMF2iSVcIQNHn')
            .then((response) => {
                window.confirm("신고가 완료되었습니다.");
                console.log('이메일이 성공적으로 보내졌습니다:', response);
            })
            .catch((error) => {
                console.error('이메일 보내기 실패:', error);
            });
    };

    return (
        <div style={{ minWidth, width:"calc(100% - 32px)", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between" }}>
            <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap:"3px" }}>
                <Typography variant='h7'>댓글 ({length})</Typography>
            </div>
            <div style={{ display:"flex", flexDirection:"row", gap:"8px" }}>
                { yesEdit && (
                    <>
                        <Button
                            sx={{ color:"black", border:"2px solid #A5B2BA", backgroundColor:"white", borderRadius:"8px", cursor:"pointer" }}
                            onClick={goEditPage}
                        >수정</Button>
                        <Button
                            sx={{ color:"black", border:"2px solid #A5B2BA", backgroundColor:"white", borderRadius:"8px", cursor:"pointer" }}
                            onClick={onDeleteDoc}
                        >삭제</Button>
                    </>
                )}
                <Button
                    sx={{ color:"black", border:"2px solid #A5B2BA", backgroundColor:"white", borderRadius:"8px", cursor:"pointer" }}
                    onClick={onReport}
                >신고</Button>
            </div>
        </div>
    );
}

export default ReplyTitle;
