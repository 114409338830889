import { Button, useMediaQuery } from '@mui/material';
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx';

function MbPageBtns({ length, nowIdx, onClick, handleFirstBtn, handlePrevBtn, handleLastBtn, handleNextBtn }) {
  const isMobile = useMediaQuery('(max-width:767px)');
  const arr = Array.from(Array(length), (_, index) => index + 1);

  const buttonStyle = (isActive) => ({
    width: isMobile ? "30px" : "auto",
    minWidth: isMobile ? "30px" : "auto",
    minHeight: isMobile ? "30px" : "auto",
    fontSize: isMobile ? "12px" : "16px",
    backgroundColor: isActive ? "#104A62" : "white",
    color: isActive ? "white" : "#104A62",
    border: `1px solid #104A62`,
    cursor: "pointer",
    '&:hover': {
      backgroundColor: isActive ? "#104A62" : "white",
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Button 
        onClick={handleFirstBtn}
        size='small'
        sx={buttonStyle(false)}
        variant="contained"
      >
        <RxDoubleArrowLeft color="#104A62" />
      </Button>
      <Button
        onClick={handlePrevBtn}
        size='small'
        sx={buttonStyle(false)}
        variant="contained"
      >
        <IoIosArrowBack color="#104A62" />
      </Button>

      {arr && arr.map(i => (
        <Button
          key={i}
          size='small'
          sx={buttonStyle((nowIdx + 1) === i)}
          onClick={() => onClick(i - 1)}
          variant="contained"
        >
          {i}
        </Button>
      ))}
      <Button
        onClick={handleNextBtn}
        size='small'
        sx={buttonStyle(false)}
        variant="contained"
      >
        <IoIosArrowForward color="#104A62" />
      </Button>
      <Button
        onClick={handleLastBtn}
        size='small'
        sx={buttonStyle(false)}
        variant="contained"
      >
        <RxDoubleArrowRight color="#104A62" />
      </Button>
    </div>
  );
}

export default MbPageBtns;
