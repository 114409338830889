import React from 'react';
import BorderTxtField from './BorderTxtField';
import WithLabelTxtField from './WithLabelTxtField';
import { Checkbox } from '@mui/material';
import { mdWidth } from '../const/const';
import MinuteSelect from './MinuteSelect';
import HourSelect from './HourSelect';
import DottedHorizontalDivider from './DottedHorizontalDivider';
import { useLocation } from 'react-router-dom';

function DeliveryWriteContent({
    newRestaurant,onChangeRes,
    newDetailAddress,onChangeDetailAddress,
    newType,onClickTypeToggle,
    newChatLink,
    onChangeOpenChat,
    useOrderT,
    setUseOrderT,
    inputFormatDate,
    newOrderTime,
    setNewOrderTime,
    newOrderSiTime,
    handleInpuSiChange,
    handleInpuBunChange,newOrderBunTime,
    newContent,
onChangeNewContent,
allWidth,
id,
onChangeId,
pw,
onChangePw,
}) {
    const location=useLocation()
    return (
        <div
        style={{
            width: allWidth,
            display: "flex",
            flexDirection:"column"
        }}
        
        >
                    <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField 
                value={id}
                onChange={onChangeId}
                width='100%'
                placeholder={"아이디"} />
                    <BorderTxtField 
                    type={"password"}
                    value={pw}
                    onChange={onChangePw}
                    width='100%'
                    placeholder={"비밀번호"} />
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><WithLabelTxtField
                        label={"음식점"}
                        value={newRestaurant}
                        onChange={onChangeRes}
                    /></div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><WithLabelTxtField
                        label={"수령장소"}
                        value={newDetailAddress}
                        onChange={onChangeDetailAddress}
                    />
                   {/* {location.state.postType==="sidong"&& <div>음식 수령지를 건물명으로 적어주세요</div>} */}
                    </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                     
             
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: allWidth===mdWidth?"22px":"3px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >    
                 <span>(주문시간)</span>
                    
                    <input type='date'
              style={{
                minWidth:"100px"
              }}
                value={inputFormatDate(newOrderTime)}
                onChange={(e)=>{
                    setNewOrderTime(new Date(e.target.value))
                }}
                />
                <HourSelect
                value={newOrderSiTime}
               onChange={handleInpuSiChange}
                
                />시
                <MinuteSelect
                value={newOrderBunTime}
                onChange={handleInpuBunChange}
                />
                분
                </div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                     <DottedHorizontalDivider/>
                     <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <button
                    id='같이시켜'
                        style={{
                            borderRadius: "8px",
                            height: "36px",
                            backgroundColor: newType==="같이시켜"?"#104A62":"white",
                            color: newType!="같이시켜"?"#104A62":"white",
                            border: newType!="같이시켜"?"1px solid #104A62":"1px solid white",
                            // color: "white"
                        }}
                        onClick={onClickTypeToggle}
                    >같이시켜</button>
                    <button
                    id='하나시켜 나눠먹어요'
                        style={{
                         
                            borderRadius: "8px",
                            height: "36px",
                            backgroundColor: newType==="하나시켜 나눠먹어요"?"#104A62":"white",
                            color: newType!="하나시켜 나눠먹어요"?"#104A62":"white",
                            border: newType!="하나시켜 나눠먹어요"?"1px solid #104A62":"1px solid white",
                        }}
                        onClick={onClickTypeToggle}
                    >하나시켜 나눠먹어요</button>
                </div>

                <div
                    style={{
                        height: "22px"
                    }}
                />
           
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><BorderTxtField

                        placeholder={"(선택) 오픈채팅 링크"}
                        value={newChatLink}
                        onChange={onChangeOpenChat}
                    /></div>
                <div
                    style={{
                        height: "22px"
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "22px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                ><textarea
                value={newContent}
                onChange={onChangeNewContent}
                        style={{
                            height: "calc(36px * 3)",
                            width: "100%",
                            border:"2px solid #104A62",
                            borderRadius:"8px",
                            resize:"none"
                          
                        }}
                    />
                 
                    </div>
              
                <div
                    style={{
                        height: "22px"
                    }}
                />
          
        </div>
    );
}

export default DeliveryWriteContent;