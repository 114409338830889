import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import SmFavArea from '../components/SmFavArea';
import CommunityBox from '../components/CommunityBox';
import CommunityTable from '../components/CommunityTable';
import PotBtn from '../components/PotBtn';
import { communityValues, formatForDbRgDate, formattedRegDate, pcEmpty } from '../const/const';
import FavoriteBar from '../components/Bar/FavoriteBar';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import ToggleBtn from '../components/Btn/ToggleBtn';
import BasicCommunityTable from '../components/BasicTable/BasicCommunityTable';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../service/firebase';
import TableSearchBar from '../components/TableSearchBar';
import PageBtns from '../components/Btn/PageBtns';
import Flex from '../components/Flex/Flex';
import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
import uuid from 'react-uuid';
import { useRecoilState } from 'recoil';
import { loginDialogState } from '../atom/atom';
import moment from 'moment';
import MbFavoriteBar from '../components/Bar/MbFavoriteBar';
import { formatDistanceToNow } from 'date-fns';
import MbPageBtns from '../components/Btn/MbPageBtns';
import { MdSearch } from 'react-icons/md';
function CommunityPage(props) {
  ///아래부터 새로 작성한 변수
const params=useParams()
const [posts,setPosts]=useState([]) 
const [nowPageIdx,setNowPageIdx]=useState(0)
//전체글인지 일정순인지 토글
const [postToggle,setPostToggle]=useState("all")//all ,  basic , shopping , ott , help
const [selectedSearchType,setSelectedSearchType]=useState("제목")
const [inputValue,setInputValue]=useState("")
const searchOptions=["제목","글쓴이"]
const postsCol = collection(db, "posts")
const replysCol = collection(db, "community")
const [replys,setReplys]=useState([])
const [newReply,setNewReply]=useState("")
const [goLoginDialog,setGoLoginDialog]=useState(false)
const nowTime = new Date()
const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
const [loginDialog,setLoginDialog]=useRecoilState(loginDialogState)
const [openFilterDialog, setOpenFilterDialog] = useState(false);
const [selectedFilterType, setSelectedFilterType] = useState('');
const [filterInput, setFilterInput] = useState('');

const handleClickOpen = () => {
  setOpenFilterDialog(true);
};

const handleClose = () => {
  setOpenFilterDialog(false);
};

const handleFilterChange = (event) => {
  setSelectedFilterType(event.target.value);
};

const handleFilterInputChange = (event) => {
  setFilterInput(event.target.value);
};

const applyFilter = () => {
  onSearch(postToggle, selectedFilterType, filterInput);
  handleClose();
};

const navigate=useNavigate()
const handlePageBtn=(idx)=>{
  setNowPageIdx(idx)
  navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${idx+1}`)
  }
//전체글일때
//전체글일때 글 불러오는거
const fetchMPosts = async () => {
  try {
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', 'in', communityValues),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...doc.data(), docId: doc.id});
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchSiPosts = async () => {
  try {
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', communityValues),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
      if (data.hasOwnProperty("si")) {
        if (data.si===params.si) {
            // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...data, docId: doc.id});
        }
      }
    
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchDongPosts = async () => {
  try {
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('foodCategory', 'in', communityValues),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      const data=doc.data()
      if (data.hasOwnProperty("dong")) {
        if (data.dong===params.dong) {
            // 각 문서에서 데이터를 가져와서 배열에 추가
      posts.push({...data, docId: doc.id});
        }
      }
    
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const getCommunity = async () => {
  try {
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(replysCol, 
        // where('meetingPlace', '==', nowMeetingPlace),
        orderBy('registerDate', 'desc'),
      )
    );
    
    const posts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      const data=doc.data()
      if (data.hasOwnProperty("meetingPlace")) {
        if (data.meetingPlace===nowMeetingPlace) {
           posts.push({...doc.data(), docId: doc.id});
        }
        
      }
     
    });

    return posts;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchCommunity=async()=>{
  const res=await getCommunity()
  setReplys(res.reverse())
}
const fetchTogglePosts = async (toggle) => {
  try {
    console.log("토글>>>",toggle)
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', '==', toggle),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
    
    const originPosts = [];
    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가
      const data=doc.data()
              originPosts.push({...data, docId: doc.id});

    });
    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    console.log("doubleList>>>", doubleList);
    return doubleList;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const fetchToggleSearchPosts = async (toggle,inputValue, searchField) => {
  try {
    const nowMeetingPlace=params.fullAddress
    const querySnapshot = await getDocs(
      query(postsCol, 
        where('meetingPlace', '==', nowMeetingPlace),
        where('foodCategory', '==', toggle),
        orderBy('registerDate', 'desc'),
        // limit(20)
      )
    );
   
    const originPosts = []; 

    querySnapshot.forEach((doc) => {
      // 각 문서에서 데이터를 가져와서 배열에 추가 
      const data=doc.data()
     
      
      if (data.hasOwnProperty("contents")&&data["contents"].includes(inputValue)) {
              originPosts.push({...data, docId: doc.id});
      }

    });
    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    } 
    console.log("전체 아닐때>>>",doubleList)
    return doubleList;
  } catch (error) {
    console.error("게시물 가져오기 오류: ", error);
    return []; // 에러 발생 시 빈 배열 반환
  }
};
const  fetchEveryPosts = async () => {
  let originPosts = [];

  if (params.postType === "meeting-place") {
    originPosts = await fetchMPosts(); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }
  else if (params.postType === "si-place") {
    originPosts = await fetchSiPosts(); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }else if (params.postType === "dong-place") {
    originPosts = await fetchDongPosts(); // params.fullAddress와 deliveryValues는 적절히 설정해야 합니다.
  }


  // originPosts를 20개씩 끊어서 이중 리스트로 만듭니다.
  const batchSize = 20;
  const doubleList = [];
  for (let i = 0; i < originPosts.length; i += batchSize) {
    doubleList.push(originPosts.slice(i, i + batchSize));
  }
  console.log("doubleList>>>", doubleList);
  setPosts(doubleList);
};
useEffect(()=>{
  if (postToggle==="all") {
    fetchEveryPosts()
  } 
//   else if(postToggle==="day")
// {
//   fetchEveryDayPosts(searchToday)
// }

},[postToggle])
const onChangeSearchType=(value)=>{
  setSelectedSearchType(value)
  
  }
  const onChangeInput=(e)=>{

    setInputValue(e.target.value)
  }
  const fetchSearchEveryPosts = async (toggle,inputValue, searchField) => {
    let originPosts = [];
 
    // if (params.postType === "meeting-place") {
      originPosts = await fetchSearchMPosts(toggle,inputValue, searchField);
    // }
    //  else if (params.postType === "si-place") {
    //   originPosts = await fetchSearchSiPosts(inputValue, searchField);
    // } else if (params.postType === "dong-place") {
    //   originPosts = await fetchSearchDongPosts(inputValue, searchField);
    // }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    console.log("서치한 후 이중배열 데이터",doubleList)
    setPosts(doubleList);
  };
  const fetchSearchMPosts = async (toggle,inputValue, searchField) => {
    try {
      console.log("필터링>>>",searchField,inputValue)
      const nowMeetingPlace = params.fullAddress;
      const allQ= query(
        postsCol,
        where("meetingPlace", "==", nowMeetingPlace),
        where("foodCategory", "in", communityValues),
        orderBy("registerDate", "desc")
      )
      const q= query(
        postsCol,
        // where("meetingPlace", "==", nowMeetingPlace),
        where("foodCategory", "in", communityValues),
        // where('foodCategory', '==', toggle),
        orderBy("registerDate", "desc")
      )
      const querySnapshot = await getDocs(
       toggle==="all"?allQ:q
      );

      const posts = [];
      querySnapshot.forEach((doc) => {
       const data=doc.data()
        // if (doc.data().detailAddress.includes("국립")) {
        //   console.log('국립 포함>>>',doc.data())
        // }
      if (params.postType==="meeting-place") {
         if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
          posts.push({ ...data, docId: doc.id });
        }
      }else if(params.postType==="si-place"){
        if (data.hasOwnProperty("si")&&data.si===params.si) {
           if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
          posts.push({ ...data, docId: doc.id });
        }
        }
       
      }
      else if(params.postType==="dong-place"){
        if ((data.hasOwnProperty("si")&&data.si===params.si)&&(data.hasOwnProperty("dong")&&data.dong===params.dong)) {
           if (data.hasOwnProperty(searchField)&&data[searchField].includes(inputValue)) {
          posts.push({ ...data, docId: doc.id });
        }
        }
       
      }
       
      });
      console.log("데이터들>>>",posts)
      return posts;
    } catch (error) {
      console.error("게시물 가져오기 오류: ", error);
      return [];
    }
  };
  const fetchEveryDayPosts = async (searchToday) => {
    let originPosts = [];

    if (params.postType === "meeting-place") {
      // originPosts = await fetchMDayPosts(params.fullAddress, searchToday);
    }
    //  else if (params.postType === "si-place") {
    //   originPosts = await fetchSiDayPosts(params.fullAddress, searchToday);
    // } else if (params.postType === "dong-place") {
    //   originPosts = await fetchDongDayPosts(params.fullAddress, searchToday);
    // }

    const batchSize = 20;
    const doubleList = [];
    for (let i = 0; i < originPosts.length; i += batchSize) {
      doubleList.push(originPosts.slice(i, i + batchSize));
    }
    setPosts(doubleList);
  };
  const onSearch = async (postToggle, searchType, inputValue) => {
    setNowPageIdx(0)
    let searchField = "";
    let toggle="";
    if (searchType === "제목") {
      searchField = "contents";
    }else if (searchType === "글쓴이") {
      searchField = "id";
    }
    if (postToggle==="all") {
      toggle="all"
    }else if(postToggle==="basic"){
      toggle="일반"
    }else if(postToggle==="shopping"){
      toggle="쇼핑"
    }else if(postToggle==="ott"){
      toggle="OTT"
    }else if(postToggle==="help"){
      toggle="도움/심부름"
    }
   await fetchSearchEveryPosts(toggle,inputValue, searchField);
    // if (postToggle === "all") {
   
    // } else {
    //   let toggle=""
    //   if (postToggle==="basic") {
    //     toggle="일반"
    //   }
    //   await fetchToggleSearchPosts(toggle,inputValue, searchField)
    // }
  };
    const onToggleSearchPosts=async(toggle)=>{
      let type=""
      if (toggle==="all") {
    type="전체"
    fetchEveryPosts()
      }else if(toggle==="basic"){
        type="일반"
        const res=await fetchTogglePosts(type)
    setPosts(res)
      }else if(toggle==="shopping"){
        type="쇼핑"
        const res=await fetchTogglePosts(type)
    setPosts(res)
      }else if(toggle==="ott"){type="OTT"
        const res=await fetchTogglePosts(type)
        setPosts(res)

      }
      else if(toggle==="help")
      {
        type="도움/심부름"
        const res=await fetchTogglePosts(type)
    setPosts(res)
      }


    }
    useEffect(() => {
      // Fetch initially
      fetchCommunity();
  
      // Set up interval to fetch every second
      const intervalId = setInterval(() => {
        fetchCommunity();
      }, 1000);
  
      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }, []);
  
    const onChangeReply=(e)=>{

      setNewReply(e.target.value)
    }
    const onEnterReply=async(e)=>{

      const docId = uuid()
      if(e.key === 'Enter') {
        const login=sessionStorage.getItem("login")
        if (login!="success") {
          setGoLoginDialog(true)}
          else{
            const formattedDate = moment().format('YYYY.MM.DD HH:mm:ss');
            console.log("댓글 쓴사람 아이디",sessionStorage)
            const userId=sessionStorage.userId
            const userUuid=sessionStorage.userUuid
             await setDoc(doc(db, "community", docId), {
        //temp
        //댓글 쓴사람 아이디
        ownerUserId:userUuid,//location.state.ownerUserId,
        id:userId,
        // nickname:"",
        registerDate:formattedDate,
        reply:newReply,
        meetingPlace:params.fullAddress,
                si:params.si,
                dong:params.dong,
    
      })
      fetchCommunity()
      setNewReply("")
          }
       
      }
    
    }
    const handleFirstBtn=()=>{
      setNowPageIdx(0)
    }
    const handlePrevBtn=()=>{
      if (nowPageIdx===0)return
      setNowPageIdx(p=>p-1)
     
    }
    const handleLastBtn=()=>{
    setNowPageIdx(posts.length-1)
    }
    const handleNextBtn=()=>{
      
      if (nowPageIdx+1===posts.length)return
      setNowPageIdx(p=>p+1)
    }
    useEffect(()=>{
      navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/${nowPageIdx+1}`)
    },[nowPageIdx])
    const goViewWritePage=async(row)=>{
      sessionStorage.setItem("viewWrite",JSON.stringify(row))
      navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/view-write/1`)
    }
    return (
        <div
        style={{
            display: 'flex',
            flexDirection: "column",
            alignItems: "center"
          }}>
             <Dialog

open={goLoginDialog}
        sx={{
        }}
        >
<DialogContent
sx={{
// borderRadius:"20px",
overflow:"hidden",
  border:"3px solid #104A62",
  backgroundColor:"#BDFBFF",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  gap:"16px"
}}
>
<span>로그인 해 주세요.</span>
<div
style={{
  display:"flex",
  flexDirection:"row",
gap:"16px"
}}
>
  <button
  style={{
    width:"60px",
    height:"30px",
    borderRadius:"6px",
    border:"none",
    cursor:"pointer",
    backgroundColor:"#104A62",
    color:"white"
  }}
  onClick={()=>{setGoLoginDialog(false)

    
        }}
  >취소</button>
  
  <button
    style={{
      width:"180px",
      height:"30px",
      borderRadius:"6px",
      border:"none",
      cursor:"pointer",
      backgroundColor:"#104A62",
      color:"white"
    }}
    onClick={()=>{setGoLoginDialog(false)
setLoginDialog(true)

    }}
  >로그인 하러 가기</button>
</div>
</DialogContent>
        </Dialog>
        <Mobile>
        <Fab
          color="primary"
          aria-label="filter"
          onClick={handleClickOpen}
          sx={{
            backgroundColor:"#002D82",
            ":hover":{
              backgroundColor:"#002D82",
            },
            position: 'fixed', bottom: 16, right: 16 }}
        >
          <MdSearch size={20}/>
        </Fab>
        <Dialog open={openFilterDialog} onClose={handleClose}>
          <DialogTitle>게시글 검색</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <InputLabel>검색 기준</InputLabel>
              <Select value={selectedFilterType} onChange={handleFilterChange}>
                <MenuItem value={"제목"}>제목</MenuItem>
     
                <MenuItem value={"글쓴이"}>글쓴이</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              label="필터 입력"
              type="text"
              fullWidth
              value={filterInput}
              onChange={handleFilterInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button 
            variant='outlined'
             sx={{
            borderColor:"#002D82",
            color:"#002D82",
            ":hover":{
              borderColor:"#002D82",
              color:"#002D82",
            },}}
            onClick={handleClose} color="secondary">
              취소
            </Button>
            <Button 
            variant='contained'
             sx={{
            backgroundColor:"#002D82",
            ":hover":{
              backgroundColor:"#002D82",
            },}}
            onClick={applyFilter} color="primary">
              적용
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{
          width: 'calc(100% - 12px)',
          
          marginLeft: '6px', marginRight: '6px' ,
          marginBottom:"30px"}}>
 <MbFavoriteBar/>
 <div
          style={{
            width: "100%",
            backgroundColor: "#D9D9D9"
          }}
        ><CommunityBox
        communityReplyList={replys}
        onChangeReply={onChangeReply}
        onEnterReply={onEnterReply}
        reply={newReply}
        /></div>
         <div
          style={{
            height: "37px"
          }}
        />
             <ul
          style={{
            width: "100%",
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            flexWrap:"wrap",
            margin: 0,
            padding: 0
          }}
        >
          <li>
            <ToggleBtn
              active={postToggle === "all"}
              id={"all"}
              title={"전체"}
              onClick={(e) => {
                setPostToggle(e.target.id)
                onToggleSearchPosts(e.target.id)
              }}
            />
          </li>
          <li>
            <ToggleBtn
              active={postToggle === "basic"}
              id={"basic"}
              title={"일반"}
              onClick={(e) => {
                setPostToggle(e.target.id)
                onToggleSearchPosts(e.target.id)
              }}
            />
          </li>
          <li>
            <ToggleBtn
              active={postToggle === "shopping"}
              id={"shopping"}
              title={"쇼핑"}
              onClick={(e) => {
                setPostToggle(e.target.id)
                onToggleSearchPosts(e.target.id)
              }}
            />
          </li>
          <li>
            <ToggleBtn
              active={postToggle === "ott"}
              id={"ott"}
              title={"OTT"}
              onClick={(e) => {
                setPostToggle(e.target.id)
                onToggleSearchPosts(e.target.id)
              }}
            />
          </li>
          <li>
            <ToggleBtn
              active={postToggle === "help"}
              id={"help"}
              title={"도움,심부름"}
              onClick={(e) => {
                setPostToggle(e.target.id)
                onToggleSearchPosts(e.target.id)
              }}
            />
          </li>
          <Flex />
          <CustomWriteBtn onClick={()=>{
            navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
          }}/>
        </ul>
        <List
        sx={{
          width:"100%"
        }}
        >
          {posts[nowPageIdx]?.map((post) => (
            <div >
              <ListItem alignItems="flex-start"
              onClick={()=>{goViewWritePage(post)}}
              >
           
                <ListItemText
                  primary={'제목 : '+post.contents??"-"}
                  secondary={
                    <>
                      <span>글쓴이 : {post.id===undefined||!post.id?"-":post.id}</span>
                      <br />
                      <span>{'카테고리 : '+post.foodCategory}</span>
                      <br />
                      <span>일정 : {post.goTime||post.goTime===""?"-":post.goTime}</span>
                      <br />
                      <span>{post.registerDate===""?"-":formattedRegDate(post.registerDate)+" ago"}</span>
                    </>
                  }
                />
              </ListItem>
              <Divider  component="li" />
            </div>
          ))}
        </List>
        {/* <TableSearchBar
     onChangeSearchType={onChangeSearchType}
     searchOptions={searchOptions}
     selectedSearchType={selectedSearchType}
     inputValue={inputValue}
     onChangeInput={onChangeInput}
     onSearch={(e)=>{
 
      onSearch(postToggle,selectedSearchType,inputValue)}}
      goWrite={()=>{
        // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
        navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
       }}
     /> */}
        {/* {getBoardBtns("delivery")} */}
        <div
      style={{
        display:"flex",
        width:"100%",
        alignItems:"center",
        justifyContent:"center",
        marginTop:"30px"
      }}
      >    <MbPageBtns  length={posts.length} nowIdx={nowPageIdx} 
        onClick={handlePageBtn} 
        handleFirstBtn={handleFirstBtn}
        handlePrevBtn={handlePrevBtn}
        handleLastBtn={handleLastBtn}
        handleNextBtn={handleNextBtn}
        /></div>
     

          </div>

        </Mobile>
        <PC>
       
{/* 
        <div
            style={{
                height:"55px"
            }}
            />
            <div
          style={{
            width: "calc(100% - 80px - 80px)",
          }}
        ><SmFavArea/></div> */}
                    <div
                style={{
                    height:pcEmpty,
                }}
                />
   <FavoriteBar />
    
        <div
          style={{
            width: "calc(100% - 80px - 80px)",
            backgroundColor: "#D9D9D9"
          }}
        ><CommunityBox
        communityReplyList={replys}
        onChangeReply={onChangeReply}
        onEnterReply={onEnterReply}
        reply={newReply}
        /></div>
        <div
          style={{
            height: "37px"
          }}
        />
             <ul
                        style={{
                            width: "calc(100% - 80px - 80px)",
                            listStyle:"none",
                            display:"flex",
                            gap:"8px",
                            flexDirection:"row",
                            alignItems:"flex-start",
                            justifyContent:"flex-start",
                            margin:0,
                            padding:0
                        }}
                        >
                            <li><ToggleBtn
                            active={postToggle==="all"}
                            id={"all"}
                            title={"전체"}
                            onClick={(e)=>{
                              setPostToggle(e.target.id)
                              onToggleSearchPosts(e.target.id)
                            }}
                            /></li>
                            <li><ToggleBtn
                            active={postToggle==="basic"}
                            id={"basic"}
                            title={"일반"}
                            onClick={(e)=>{
                              setPostToggle(e.target.id)
                                 onToggleSearchPosts(e.target.id)
                            }}
                            /></li>
                            <li><ToggleBtn
                              active={postToggle==="shopping"}
                              id={"shopping"}
                            title={"쇼핑"}
                            onClick={(e)=>{
                              setPostToggle(e.target.id)
                                 onToggleSearchPosts(e.target.id)
                            }}
                            /></li>
                            <li><ToggleBtn
                              active={postToggle==="ott"}
                              id={"ott"}
                            title={"OTT"}
                            onClick={(e)=>{
                              setPostToggle(e.target.id)
                                 onToggleSearchPosts(e.target.id)
                            }}
                            /></li>
                            <li><ToggleBtn
                              active={postToggle==="help"}
                              id={"help"}
                            title={"도움,심부름"}
                            onClick={(e)=>{
                              setPostToggle(e.target.id)
                                 onToggleSearchPosts(e.target.id)
                            }}
                            /></li>
                            <Flex/>
                            <CustomWriteBtn onClick={()=>{
                              navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
                            }}/>
                        </ul>
                        <div
          style={{
            height: "17px"
          }}
        />
        {/* <div
        style={{
            width: "calc(100% - 80px - 80px)",
        }}
        ><CommunityTable/></div> */}
          <div
        style={{
            width: "calc(100% - 80px - 80px)",
        }}
        > <BasicCommunityTable
        tableHeads={["글번호","일정","제목","글쓴이","작성일"]}
        nowPageIdx={nowPageIdx}
        rows={posts[nowPageIdx]}
        /></div>
         <TableSearchBar
     onChangeSearchType={onChangeSearchType}
     searchOptions={searchOptions}
     selectedSearchType={selectedSearchType}
     inputValue={inputValue}
     onChangeInput={onChangeInput}
     onSearch={(e)=>{
 
      onSearch(postToggle,selectedSearchType,inputValue)}}
      goWrite={()=>{
        // /:fullAddress/:si/:dong/:tableType/:postType/type-select-delivery
        navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/write`)
       }}
     />
        {/* {getBoardBtns("delivery")} */}
        <PageBtns  length={posts.length} nowIdx={nowPageIdx} 
        onClick={handlePageBtn}
        handleFirstBtn={handleFirstBtn}
        handleLastBtn={handleLastBtn}
        handleNextBtn={handleNextBtn}
        handlePrevBtn={handlePrevBtn}
        />
        {/* /// 새로운 ui */}
        
        </PC>

            
        </div>
    );
}

export default CommunityPage;