// import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Box, Typography, Link, FormControlLabel } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { Outlet, useNavigate } from 'react-router-dom';
// import { Mobile, PC } from './ReactiveLayout';
// import CustomAppbar from './components/Appbar/CustomAppbar';
// import CustomSmAppBar from './components/CustomSmAppBar';
// import { PostCodePopup } from './components/PostCodePopup';
// import { useRecoilState } from 'recoil';
// import { loginDialogState, mainLoadingState, nowAreaState, openAreaDialogState } from './atom/atom';
// import DisplayAds from './components/DisplayAds';
// import { appbarColor } from './const/const';
// import { CgClose } from 'react-icons/cg';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { db } from './service/firebase';
// import AppstoreSrc from "./images/appstore.png";

// function CustomLayout(props) {
//   const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
//   const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
//   const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
//   const [nowAddress] = useRecoilState(nowAreaState);
//   const [id, setId] = useState("");
//   const [pw, setPw] = useState("");
//   const [email, setEmail] = useState(""); // ID/PW 찾기용 이메일
//   const [foundId, setFoundId] = useState(""); // 찾은 ID
//   const [showIdDialog, setShowIdDialog] = useState(false); // ID 찾기 다이얼로그
//   const [showPwDialog, setShowPwDialog] = useState(false); // PW 찾기 다이얼로그
//   const [pwResetSuccess, setPwResetSuccess] = useState(false); // 비밀번호 재설정 성공 여부
//   const navigate = useNavigate();

//   const closeDialog = () => setOpenDialog(false);
//   const closeLoginDialog = () => setLoginDialog(false);
//   const closeIdDialog = () => setShowIdDialog(false);
//   const closePwDialog = () => setShowPwDialog(false);

  // const fetchUsersByEmail = async (email, pw) => {
  //   try {
  //     const querySnapshot = await getDocs(query(collection(db, "users")));
  //     let user = {};
  //     querySnapshot.forEach((doc) => {
  //       if (doc.data().email === email && doc.data().pw === pw) {
  //         user = { id: doc.id, ...doc.data() };
  //       }
  //     });
  //     return user;
  //   } catch (error) {
  //     console.error("Error fetching users by email: ", error);
  //     return null;
  //   }
  // };

  // const goLogin = async (email, pw) => {
  //   const user = await fetchUsersByEmail(email, pw);
  //   if (email.trim() === "" || pw.trim() === "") {
  //     window.confirm("이메일과 비밀번호를 입력해주세요.");
  //   } else {
  //     if (user.email === email && user.pw === pw) {
  //       sessionStorage.setItem("userNickname", user.nickname);
  //       sessionStorage.setItem("userId", user.email);
  //       sessionStorage.setItem("userPw", user.pw);
  //       sessionStorage.setItem("userUuid", user.id);
  //       sessionStorage.setItem("login", "success");
  //       sessionStorage.setItem("favorites", JSON.stringify(user.favorites));
  //       alert("로그인이 완료되었습니다.");
  //       window.location.reload();
  //     } else {
  //       alert("로그인 실패.");
  //     }
  //   }
  // };

//   const onChangeId = (e) => setId(e.target.value);
//   const onChangePw = (e) => setPw(e.target.value);
//   const onChangeEmail = (e) => setEmail(e.target.value);

//   const goSignupPg = () => {
//     navigate(`/${nowAddress.fullAddress}/sign-up`);
//     setLoginDialog(false);
//   };

  // const findUserId = async () => {
  //   try {
  //     const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
  //     if (!querySnapshot.empty) {
  //       const userData = querySnapshot.docs[0].data();
  //       setFoundId(userData.email);
  //       setShowIdDialog(true);
  //     } else {
  //       alert("해당 이메일로 가입된 계정이 없습니다.");
  //     }
  //   } catch (error) {
  //     console.error("ID 찾기 실패:", error);
  //     alert("ID 찾기 실패.");
  //   }
  // };

  // const resetUserPassword = async () => {
  //   try {
  //     const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
  //     if (!querySnapshot.empty) {
  //       // 비밀번호 재설정 로직 (ex. 임시 비밀번호 발급 또는 재설정 메일 발송)
  //       // 임시로 성공 처리
  //       setPwResetSuccess(true);
  //       alert("비밀번호 재설정 메일이 발송되었습니다.");
  //     } else {
  //       alert("해당 이메일로 가입된 계정이 없습니다.");
  //     }
  //   } catch (error) {
  //     console.error("비밀번호 재설정 실패:", error);
  //     alert("비밀번호 재설정 실패.");
  //   }
  // };

//   return (
//     <div className='App' style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
//       {/* 로그인창 */}
//       <Dialog
//         open={loginDialog}
//         fullWidth
//         maxWidth="xs"
//         PaperProps={{
//           sx: {
//             borderRadius: 0,
//             backgroundColor: "#000000",
//           },
//         }}
//       >
//         <DialogContent
//           sx={{
//             padding: 2,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//             gap: 2,
//           }}
//         >
//           <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
//             <CgClose color='white' size={30} onClick={closeLoginDialog} style={{ cursor: "pointer" }} />
//           </div>
//           <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
//             <Typography variant="h5" style={{ color: "white" }}>LOGIN</Typography>
//           </div>
//           <TextField
//             label="이메일 주소"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={id}
//             onChange={onChangeId}
//             InputProps={{
//               style: {
//                 backgroundColor: "white",
//               },
//             }}
//           />
//           <TextField
//             label="비밀번호"
//             type="password"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={pw}
//             onChange={onChangePw}
//             InputProps={{
//               style: {
//                 backgroundColor: "white",
//               },
//             }}
//           />
//           {/* <FormControlLabel
//             control={<Checkbox />}
//             label="로그인 유지"
//             style={{ color: "white" }}
//           /> */}
//           <Button
//             variant="contained"
//             fullWidth
//             onClick={() => {
//               goLogin(id, pw);
//               closeLoginDialog();
//             }}
//             sx={{ mt: 2, backgroundColor: "#4B4A4A" }}
//           >
//             로그인
//           </Button>
//           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px", marginBottom: "8px", gap: "8px" }}>
//             <Button
//               variant="text"
//               onClick={() => setShowIdDialog(true)}
//               sx={{ color: "#4B4A4A", fontSize: "16px", fontWeight: 600 }}
//             >
//               ID 찾기
//             </Button>
//             <Button
//               variant="text"
//               onClick={() => setShowPwDialog(true)}
//               sx={{ color: "#4B4A4A", fontSize: "16px", fontWeight: 600 }}
//             >
//               PW 찾기
//             </Button>
//             <Button
//               variant="text"
//               onClick={goSignupPg}
//               sx={{ color: "#4B4A4A", fontSize: "16px", fontWeight: 600 }}
//             >
//               회원가입
//             </Button>
//           </div>
//         </DialogContent>
//       </Dialog>

//       {/* ID 찾기 다이얼로그 */}
//       <Dialog open={showIdDialog} onClose={closeIdDialog}>
//         <DialogContent>
//           <Typography variant="h6" gutterBottom>
//             ID 찾기
//           </Typography>
//           <TextField
//             label="가입된 이메일 주소"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={email}
//             onChange={onChangeEmail}
//           />
//           <Button variant="contained" fullWidth onClick={findUserId} sx={{ mt: 2 }}>
//             찾기
//           </Button>
//           {foundId && (
//             <Typography variant="body1" sx={{ mt: 2 }}>
//               가입된 이메일(ID): {foundId}
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeIdDialog}>닫기</Button>
//         </DialogActions>
//       </Dialog>

//       {/* PW 찾기 다이얼로그 */}
//       <Dialog open={showPwDialog} onClose={closePwDialog}>
//         <DialogContent>
//           <Typography variant="h6" gutterBottom>
//             PW 찾기
//           </Typography>
//           <TextField
//             label="가입된 이메일 주소"
//             variant="outlined"
//             fullWidth
//             margin="normal"
//             value={email}
//             onChange={onChangeEmail}
//           />
//           <Button variant="contained" fullWidth onClick={resetUserPassword} sx={{ mt: 2 }}>
//             찾기
//           </Button>
//           {pwResetSuccess && (
//             <Typography variant="body1" sx={{ mt: 2 }}>
//               비밀번호 재설정 메일이 발송되었습니다.
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closePwDialog}>닫기</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 로딩 다이얼로그 */}
//       <Dialog open={mainLoading}>
//         <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//           <CircularProgress sx={{ color: appbarColor }} />
//         </DialogContent>
//       </Dialog>

//       {/* 지역 설정 다이얼로그 */}
//       <Dialog open={openAreaDialog} onClose={closeDialog}>
//         <DialogContent>
//           <PostCodePopup />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeDialog}>닫기</Button>
//         </DialogActions>
//       </Dialog>

//       {/* 모바일용 앱바 */}
//       <Mobile>
//         <CustomSmAppBar />
//         <Outlet />
//       </Mobile>

//       {/* PC용 앱바 */}
//       <PC>
//         <CustomAppbar />
//         <div style={{ display: "flex", flexDirection: "row" }}>
//           <div className='grid place-content-center' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "200px", height: "100%" }}>
//             <DisplayAds />
//           </div>
//           <div style={{ width: "100%", height: "100%" }}>
//             <Outlet />
//           </div>
//           <div className='grid place-content-center' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "200px", height: "100%" }}>
//             <DisplayAds />
//           </div>
//         </div>
//       </PC>
//       <Box
//         component="footer"
//         sx={{
//           bgcolor: 'background.paper',
//           py: 3,
//           px: 2,
//           mt: 'auto',
//           borderTop: '1px solid #e0e0e0',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//         }}
//       >
//         <a href='https://apps.apple.com/kr/app/%EA%B0%99%EC%9D%B4%EC%8B%9C%EC%BC%9C/id6469504867'>
//           <img src={AppstoreSrc} width={"200px"} style={{ marginBottom: '10px' }} />
//         </a>
//         <Typography variant="body2" color="textSecondary" align="center">
//           {'© '}
//           <Link color="inherit" href="">
//             Your Website
//           </Link>{' '}
//           {new Date().getFullYear()}
//           {'.'}
//         </Typography>
//       </Box>
//     </div>
//   );
// }

// export default CustomLayout;
//위 코드는 위치기반 없는거 , 아래는 위치기반
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginDialogState, mainLoadingState, nowAreaState, openAreaDialogState } from './atom/atom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Box, Typography, Link } from '@mui/material';
import { CgClose } from 'react-icons/cg';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './service/firebase';
import CustomAppbar from './components/Appbar/CustomAppbar';
import CustomSmAppBar from './components/CustomSmAppBar';
import { PostCodePopup } from './components/PostCodePopup';
import DisplayAds from './components/DisplayAds';
import { Mobile, PC } from './ReactiveLayout';
import AppstoreSrc from "./images/appstore.png";
import { appbarColor } from './const/const';
import emailjs from '@emailjs/browser';
const { kakao } = window;

function CustomLayout(props) {
  const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
  const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
  const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState); // 현재 주소 상태
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [email, setEmail] = useState(""); // ID/PW 찾기용 이메일

  const [userPassword, setUserPassword] = useState(''); // 찾은 비밀번호
  const [foundId, setFoundId] = useState("");
  const [verificationCode, setVerificationCode] = useState(''); // 사용자가 입력한 인증번호
  const [sentCode, setSentCode] = useState(''); // 전송된 인증번호
  const [isCodeSent, setIsCodeSent] = useState(false); // 인증번호 전송 여부
  const [showIdDialog, setShowIdDialog] = useState(false);
  const [showPwDialog, setShowPwDialog] = useState(false);
  const [pwResetSuccess, setPwResetSuccess] = useState(false);
  const navigate = useNavigate();
    // Dialog 닫기
     // 사용자가 입력한 인증번호 확인 및 비밀번호 표시
  const handleVerifyCode = async () => {
    if (verificationCode !== sentCode) {
      alert('인증번호가 일치하지 않습니다.');
      return;
    }

    // Firebase에서 사용자 정보 가져오기
    try {
      const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setUserPassword(userData.pw);
      } else {
        alert('해당 이메일로 가입된 계정이 없습니다.');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      alert('비밀번호를 찾을 수 없습니다.');
    }
  };
    const closePwDialog = () => setShowPwDialog(false);
  const searchUsersByEmail = async (email) => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
    } catch (error) {
      console.error("Error fetching users by email: ", error);
    }
    return null;
  };

  const handleSendCode = () => {
    if (!email) {
      alert('이메일 주소를 입력해주세요.');
      return;
    }

    const code = Math.floor(100000 + Math.random() * 900000).toString(); // 6자리 인증번호 생성
    setSentCode(code);

    const templateParams = {
      to_email: email,
      message: code,
    };

    emailjs.send(
      'service_dgp5g76', // 서비스 ID
        'template_xqcp0gh', // 템플릿 ID
      templateParams,
      'l7UyWMF2iSVcIQNHn' // public-key
    )
    .then((response) => {
      alert('인증번호가 전송되었습니다. 이메일을 확인해주세요.');
      console.log('인증번호 전송 성공:', response);
      setIsCodeSent(true);
    })
    .catch((error) => {
      console.error('인증번호 전송 실패:', error);
      alert('인증번호 전송에 실패했습니다.');
    });
  };
  const resetUserPassword = async () => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
      if (!querySnapshot.empty) {
        // 비밀번호 재설정 로직 (ex. 임시 비밀번호 발급 또는 재설정 메일 발송)
        // 임시로 성공 처리
        setPwResetSuccess(true);
        alert("비밀번호 찾기 인증 메일이 발송되었습니다.");
      } else {
        alert("해당 이메일로 가입된 계정이 없습니다.");
      }
    } catch (error) {
      console.error("비밀번호 재설정 실패:", error);
      alert("비밀번호 재설정 실패.");
    }
  };

  const goLogin = async (email, pw) => {
    const user = await fetchUsersByEmail(email, pw);
    if (email.trim() === "" || pw.trim() === "") {
      window.confirm("이메일과 비밀번호를 입력해주세요.");
    } else {
      if (user.email === email && user.pw === pw) {
        sessionStorage.setItem("userNickname", user.nickname);
        sessionStorage.setItem("userId", user.email);
        sessionStorage.setItem("userPw", user.pw);
        sessionStorage.setItem("userUuid", user.id);
        sessionStorage.setItem("login", "success");
        sessionStorage.setItem("favorites", JSON.stringify(user.favorites));
        alert("로그인이 완료되었습니다.");
        window.location.reload();
      } else {
        alert("로그인 실패.");
      }
    }
  };
  const fetchUsersByEmail = async (email, pw) => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "users")));
      let user = {};
      querySnapshot.forEach((doc) => {
        if (doc.data().email === email && doc.data().pw === pw) {
          user = { id: doc.id, ...doc.data() };
        }
      });
      return user;
    } catch (error) {
      console.error("Error fetching users by email: ", error);
      return null;
    }
  };
  const findUserId = async () => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", email)));
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setFoundId(userData.email);
        setShowIdDialog(true);
      } else {
        alert("해당 이메일로 가입된 계정이 없습니다.");
      }
    } catch (error) {
      console.error("ID 찾기 실패:", error);
      alert("ID 찾기 실패.");
    }
  };
  // useEffect(() => {
  //   // 사용자의 현재 위치를 가져오는 함수
  //   const fetchCurrentLocation = () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           const { latitude, longitude } = position.coords;
  //           // 좌표를 주소로 변환
  //           const geocoder = new kakao.maps.services.Geocoder();
  //           const coord = new kakao.maps.LatLng(latitude, longitude);
  //           const callback = function(result, status) {
  //             if (status === kakao.maps.services.Status.OK) {
  //               // 전체 주소를 받아옵니다.
  //               const fullAddress = result[0].address.address_name;
  //               const si = result[0].address.region_1depth_name;
  //               const dong = result[0].address.region_3depth_name;
  //               setNowAddress({ fullAddress, si, dong });
  //               navigate(`/${fullAddress}/${si}/${dong}`)
  //             } else {
  //               // Geocoder 실패 시 기본 주소 설정
  //               setNowAddress({ fullAddress: "국립강릉원주대학교 강릉캠퍼스", si: "강릉시", dong: "지변동" });
  //               navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`)
  //             }
  //           };
  //           geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
  //         },
  //         () => {
  //           // 위치 접근이 거부된 경우 기본 위치 설정
  //           console.log("위치접근 거부")
  //           // setNowAddress({ fullAddress: "국립강릉원주대학교 강릉캠퍼스", si: "강릉시", dong: "지변동" });
  //                navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`)
  //         }
  //       );
  //     } else {
  //       alert("이 브라우저에서는 위치를 찾을 수 없습니다.");
  //       // setNowAddress({ fullAddress: "국립강릉원주대학교 강릉캠퍼스", si: "강릉시", dong: "지변동" });
  //            navigate(`/국립강릉원주대학교 강릉캠퍼스/강릉시/지변동`)
  //     }
  //   };

  //   fetchCurrentLocation();
  // }, [setNowAddress]);

  // 기타 함수들 (로그인, 다이얼로그 관리 등)

  return (
    <div className='App' style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      {/* 로그인 다이얼로그 */}
      <Dialog open={loginDialog} fullWidth maxWidth="xs" PaperProps={{ sx: { borderRadius: 0, backgroundColor: "#000000" } }}>
        <DialogContent sx={{ padding: 2, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <CgClose color='white' size={30} onClick={() => setLoginDialog(false)} style={{ cursor: "pointer" }} />
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" style={{ color: "white" }}>LOGIN</Typography>
          </div>
          <TextField label="이메일 주소" variant="outlined" fullWidth margin="normal" value={id} onChange={(e) => setId(e.target.value)} InputProps={{ style: { backgroundColor: "white" } }} />
          <TextField label="비밀번호" type="password" variant="outlined" fullWidth margin="normal" value={pw} onChange={(e) => setPw(e.target.value)} InputProps={{ style: { backgroundColor: "white" } }} />
          <Button variant="contained" fullWidth onClick={() => goLogin(id, pw)} sx={{ mt: 2, backgroundColor: "#4B4A4A" }}>로그인</Button>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "16px", marginBottom: "8px", gap: "8px" }}>
            <Button variant="text" onClick={() => setShowPwDialog(true)} sx={{ color: "#4B4A4A", fontSize: "16px", fontWeight: 600 }}>PW 찾기</Button>
            <Button variant="text" onClick={() => navigate(`/${nowAddress.fullAddress}/sign-up`)} sx={{ color: "#4B4A4A", fontSize: "16px", fontWeight: 600 }}>회원가입</Button>
          </div>
        </DialogContent>
      </Dialog>

    

      {/* PW 찾기 다이얼로그 */}
      <Dialog open={showPwDialog} onClose={closePwDialog}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>PW 찾기</Typography>
          <TextField
            label="가입된 이메일 주소"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!sentCode}
          />
          {!sentCode ? (
            <Button variant="contained" fullWidth onClick={handleSendCode} sx={{ mt: 2 }}>인증번호 전송</Button>
          ) : (
            <>
              <TextField
                label="인증번호"
                variant="outlined"
                fullWidth
                margin="normal"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <Button variant="contained" fullWidth onClick={handleVerifyCode} sx={{ mt: 2 }}>인증번호 확인</Button>
            </>
          )}
          {userPassword && (
            <Typography variant="body1" sx={{ mt: 2 }}>
              비밀번호: {userPassword}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closePwDialog}>닫기</Button>
        </DialogActions>
      </Dialog>
      {/* 로딩 다이얼로그 */}
      <Dialog open={mainLoading}>
        <DialogContent sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress sx={{ color: appbarColor }} />
        </DialogContent>
      </Dialog>

      {/* 지역 설정 다이얼로그 */}
      <Dialog open={openAreaDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <PostCodePopup />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>닫기</Button>
        </DialogActions>
      </Dialog>

      {/* 모바일용 앱바 */}
      <Mobile>
        <CustomSmAppBar />
        <Outlet />
      </Mobile>

      {/* PC용 앱바 */}
      <PC>
        <CustomAppbar />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className='grid place-content-center' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "200px", height: "100%" }}>
            <DisplayAds />
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            <Outlet />
          </div>
          <div className='grid place-content-center' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "200px", height: "100%" }}>
            <DisplayAds />
          </div>
        </div>
      </PC>

      <Box
        component="footer"
        sx={{
          bgcolor: 'background.paper',
          py: 3,
          px: 2,
          mt: 'auto',
          borderTop: '1px solid #e0e0e0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop:"30px"
        }}
      >
        <a href='https://apps.apple.com/kr/app/%EA%B0%99%EC%9D%B4%EC%8B%9C%EC%BC%9C/id6469504867'>
          <img src={AppstoreSrc} width={"200px"} style={{ marginBottom: '10px' }} />
        </a>
        <Typography variant="body2" color="textSecondary" align="center">
          {'© '}
          <Link color="inherit" href="">
            Your Website
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </div>
  );
}

export default CustomLayout;
