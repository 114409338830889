import React from 'react';
import { getBeforeTimeOrDeadLineTime, minutesAgo } from '../const/const';
import { useLocation, useNavigate } from 'react-router-dom';
import { nowAreaState, nowPotNameState } from '../atom/atom';
import { useRecoilState } from 'recoil';

function SmDeliveryTable({list,toggle}) {
    // const [nowPotName,setNowPotName]=useRecoilState(nowPotNameState)
    const location=useLocation()
    const navigate=useNavigate()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const formattedRegDate = (dateString) => {
        const currentDate = new Date();
        const postDate = new Date(dateString);
        const diffTime = Math.abs(currentDate - postDate);
        const diffSeconds = Math.ceil(diffTime / 1000);
        const diffMinutes = Math.floor(diffSeconds / 60);
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
 // More than 1 day
 const year = postDate.getFullYear();
 const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
 const day = postDate.getDate().toString().padStart(2, '0');
 const hours = postDate.getHours().toString().padStart(2, '0');
 const minutes = postDate.getMinutes().toString().padStart(2, '0');
        if (diffDays === 1) {
            // Less than 1 day
            if (diffMinutes < 60) {
                // Less than 1 hour, show "00분전"
                return `${diffMinutes}분전`;
            } else {
                // 1 day, show in HH:MM format
                const hours = postDate.getHours().toString().padStart(2, '0');
                const minutes = postDate.getMinutes().toString().padStart(2, '0');
                return `${hours}:${minutes}`;
            }
        } else if ((diffDays > 1)&&postDate.getFullYear() === currentDate.getFullYear()) {
            // More than 1 day, show in MM/DD format
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${month}/${day}`;
        } else  if (postDate.getFullYear() !== currentDate.getFullYear()) {
           
return `${year}/${month}/${day} ${hours}:${minutes}`;
            // Check if year is different
           
        }
    };
    const formattedOrderTime = (dateString) => {
        const currentDate = new Date();
        const postDate = new Date(dateString);
        if (dateString === "") { }
        else {
            const year = postDate.getFullYear();
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
            const day = postDate.getDate().toString().padStart(2, '0');
            const hours = postDate.getHours().toString().padStart(2, '0');
            const minutes = postDate.getMinutes().toString().padStart(2, '0');

            // Check if year is different
            if (postDate.getFullYear() !== currentDate.getFullYear()) {
                return `${year}/${month}/${day} ${hours}:${minutes}`;
            } else {
                const firstList = dateString.split(" ")[0].split(".")
                const exceptYear = firstList[1] + "." + firstList[2]
                return exceptYear + " " + dateString.split(" ")[1]
            }
   
        }

    }
    
    return (
        <ul
        style={{
            width:"calc(100% - 27px - 27px)",
            listStyle:"none",
            margin:0,
            padding:0
        }}
        >
            {
                list.map(item=> <li
                    style={{
                        width:"100%",
                        paddingTop:"8px",
                        paddingBottom:"8px",

                        height:"50px",
                        display:"flex",
                       
                        flexDirection:"column",
                        gap:"8px",
                        borderBottom:"1px solid #A5B2BA"
                    }}
                    onClick={()=>{
                        navigate(`/${nowAddress.fullAddress}/reply-delivery`,{
                            state:{
                                ...location.state,
                                write:item
                            }
                        })
                    
                    }}
                    >
                        {/* <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px"
                    }}
                    > <div
                    style={{
                    justifyContent:"space-between",
                    width:"60%",
                    height:"20px",
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        fontSize:"12px"
                    }}
                    >
                        <div
                        style={{
                            overflow:"hidden",
                            textOverflow:"ellipsis",
                             fontSize:"14px",
                        }}
                        >{item.detailAddress}</div>
                        <div
                        style={{  overflow:"hidden",
                        textOverflow:"ellipsis",
                             fontSize:"14px",
                        }}
                        >{item.registerDate}</div>
                        
                    </div>
                    <div style={{
                        flex:1
                    }}/>
                    <div
                    style={{
                        width:"40%",
                        justifyContent:"flex-end",
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        fontSize:"12px"
                    }}
                    >
                        <div
                         style={{
                            fontSize:"14px",
                       }}
                        >{item.registerDate}</div>
                    </div></div>
                       <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px"
                    }}
                    > <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        fontSize:"14px"
                    }}
                    >
                        <div
                         style={{
                            fontSize:"14px",
                       }}
                        >{item.restaurant}</div>
                        
                    </div>
                    <div style={{
                        flex:1
                    }}/>
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px"
                    }}
                    >
                        <div
                         style={{
                            fontSize:"14px",
                       }}
                        >{item.type}</div>
                        
                    </div></div> */}
                    <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        width:"100%",
                    }}
                    >
                        {/* 수령장소 */}
                        <div
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        width:"120px",
                        height:"50px",
                        overflow:"hidden",
                        textAlign:"center"
                    }}
                    ><div
                     style={{display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    height:"25px",fontSize:"10px"}}
                    >수령장소</div>
                    <div
                     style={{display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    height:"25px",fontSize:"10px"}}
                    >{item.detailAddress}</div></div>
                    <div
                    style={{
                        width:"100%",
                        textAlign:"center",
                        
                    }}
                    ><span
                    style={{
                        fontSize:"18px"
                    }}
                    >{item.restaurant}</span></div>
                        <div
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        width:"120px",
                        height:"50px",
                        overflow:"hidden",
                        textAlign:"center"
                    }}
                    ><div
                    style={{display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    height:"25px",fontSize:"10px"}}
                    >{item.type==="하나시켜 나눠먹어요"?"나눠먹어요":"-"}</div>
                    <div
                      style={{display:"flex",
                      alignItems:"center",
                      justifyContent:"center",
                      height:"25px",fontSize:"10px"}}
                    >{formattedRegDate(item.registerDate)}</div></div>
                    </div>
                 
                    </li>)
            }
           
           
           
        </ul>
    );
}

export default SmDeliveryTable;