import React from 'react';
import CommunityBox from "../components/CommunityBox"
import CommunityTable from "../components/CommunityTable"
import WriteBtn from './WriteBtn';
import { useLocation } from 'react-router-dom';
function CommunityContent({currentPage,onClickCommunityToggle,communityToggle,onChangeReply,reply,onEnterReply,communityList,communityReplyList}) {
  const location=useLocation() 
  return (
         <div
        style={{
            display:"flex",
            flexDirection:"column",
            width:"100%"
        }}
        >
             <div
          style={{
            height: "13px"
          }}
        />
        <div
          style={{
            width: "100%",
            backgroundColor: "#D9D9D9"
          }}
        ><CommunityBox
        
        communityReplyList={communityReplyList}
        onEnterReply={onEnterReply}
        onChangeReply={onChangeReply}
        reply={reply}
        /></div>
        <div
          style={{
            height: "37px"
          }}
        />
             <ul
                        style={{
                          margin:0,
                          padding:0,
                            // width: "calc(100% - 27px)",
                            listStyle:"none",
                            display:"flex",
                            gap:"8px",
                            flexDirection:"row",
                            alignItems:"flex-start",
                            justifyContent:"flex-start"
                        }}
                        >
                            <li><button
                            id="전체"
                            style={{
                                border:"2px solid #A5B2BA",
                                backgroundColor:communityToggle==="전체"?"#d9d9d9":"white",
                            }}
                            onClick={onClickCommunityToggle}
                            >전체</button></li>
                            <li><button
                            id="일반"
                            style={{
                                border:"2px solid #d9d9d9",
                                backgroundColor:communityToggle==="일반"?"#d9d9d9":"white",
                            }}
                            onClick={onClickCommunityToggle}
                            >일반</button></li>
                            <li><button
                            id="쇼핑"
                            style={{
                                border:"2px solid #d9d9d9",
                                backgroundColor:communityToggle==="쇼핑"?"#d9d9d9":"white",
                            }}
                            onClick={onClickCommunityToggle}
                            >쇼핑</button></li>
                            <li><button
                            id="OTT"
                            style={{
                                border:"2px solid #d9d9d9",
                                backgroundColor:communityToggle==="OTT"?"#d9d9d9":"white",
                            }}
                            onClick={onClickCommunityToggle}
                            >OTT</button></li>
                            <li><button
                            id="도움/심부름"
                            style={{
                                border:"2px solid #d9d9d9",
                                backgroundColor:communityToggle==="도움/심부름"?"#d9d9d9":"white",
                            }}
                            onClick={onClickCommunityToggle}
                            >도움,심부름</button></li>
                            <div
                            style={{
                              flex:1
                              
                            }}
                            ></div>
                          { location.state.postType==="si"?<div></div>: <WriteBtn
                            pageName={"community"}
                            />}
                        </ul>
                        <div
          style={{
            height: "17px"
          }}
        />
        <div
        style={{
            width: "100%",
        }}
        ><CommunityTable list={communityList} currentPage={currentPage}/></div>
        </div>
    );
}

export default CommunityContent;