import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../service/firebase';
import { Chip, ListItem, useMediaQuery } from '@mui/material';
import { format, isToday } from 'date-fns';

function CommunityReply({ reply, last }) {
    const [userList, setUserList] = useState([]);
    const [ownerEmail, setOwnerEmail] = useState("");
    const isMobile = useMediaQuery('(max-width:767px)');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const users = [];
                querySnapshot.forEach((doc) => {
                    users.push({ id: doc.id, ...doc.data() });
                });
                setUserList(users);
            } catch (error) {
                console.error("Error fetching users: ", error);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        if (userList.length > 0) {
            const owner = userList.find(user => user.id === reply.ownerUserId);
            if (owner) {
                setOwnerEmail(owner.email);
            }
        }
    }, [userList, reply.ownerUserId]);

    const formattedDate = isToday(new Date(reply.registerDate))
        ? format(new Date(reply.registerDate), 'HH:mm')
        : format(new Date(reply.registerDate), 'yyyy-MM-dd');

    return (
        <ListItem 
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: last ? "50px" : null,
                borderBottom: last ? null : "1px solid #d9d9d9",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "6px",
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                height: "100%",
            }}>
                <Chip
                    sx={{
                        '& .MuiChip-label': {
                            fontSize: isMobile ? "12px" : "auto"
                        }
                    }}
                    label={ownerEmail === "" ? "ㅇㅇ" : ownerEmail}
                />
                <span
                    style={{
                        fontSize: isMobile ? "12px" : "auto"
                    }}
                >
                    {formattedDate}
                </span>
            </div>
            <div
                style={{
                    textIndent: "16px"
                }}
            >
                {reply.reply}
            </div>
        </ListItem>
    );
}

export default CommunityReply;
