import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { nowAreaState, nowPotNameState } from '../atom/atom';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRepliesStatus, postsPerPage } from '../const/const';
import { db } from '../service/firebase';
import { doc, getDoc } from 'firebase/firestore';
import moment from 'moment/moment';

function MdDeliveryTable({ list = [], currentPage }) {
    // const [nowPotName,setNowPotName]=useRecoilState(nowPotNameState)
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const [writers, setWriters] = useState([]);
    const location = useLocation()
    const navigate = useNavigate()
    const formattedRegDate = (dateString) => {
        const currentDate = moment();
        const postDate = moment(dateString);
        const diffMinutes = currentDate.diff(postDate, 'minutes');
        const diffHours = currentDate.diff(postDate, 'hours');
        const diffDays = currentDate.diff(postDate, 'days');
    
        if (diffDays < 1) {
            // 1일 미만
            if (diffMinutes < 60) {
                // 1시간 미만, "00분전"
                return `${diffMinutes}분전`;
            } else {
                // 1일 미만, 시간 HH:MM 형식으로 표시
                return postDate.format('HH:mm');
            }
        } else if (diffDays < 365 && postDate.year() === currentDate.year()) {
            // 같은 해에 1일 이상, "MM/DD" 형식으로 표시
            return postDate.format('MM/DD');
        } else {
            // 다른 해인 경우, "YYYY/MM/DD HH:mm" 형식으로 표시
            return postDate.format('YYYY/MM/DD HH:mm');
        }
    };
    
    const getBeforeMinutes = (dateString) => {
        const currentDate = new Date();
        const postDate = new Date(dateString);
        const diffTime = Math.abs(currentDate - postDate);
        const diffSeconds = Math.ceil(diffTime / 1000);
        const diffMinutes = Math.floor(diffSeconds / 60);
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
 // More than 1 day
 const year = postDate.getFullYear();
 const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
 const day = postDate.getDate().toString().padStart(2, '0');
 const hours = postDate.getHours().toString().padStart(2, '0');
 const minutes = postDate.getMinutes().toString().padStart(2, '0');
   
                return diffMinutes;
          
    };
    const formattedOrderTime = (dateString) => {
        const currentDate = new Date();
        const postDate = new Date(dateString);
        if (dateString === ""||dateString===undefined) {
            return "-"
         }
        else {
            const year = postDate.getFullYear();
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
            const day = postDate.getDate().toString().padStart(2, '0');
            const hours = postDate.getHours().toString().padStart(2, '0');
            const minutes = postDate.getMinutes().toString().padStart(2, '0');

            // Check if year is different
            if (postDate.getFullYear() !== currentDate.getFullYear()) {
                return `${year}/${month}/${day} ${hours}:${minutes}`;
            } else {
                const firstList = dateString.split(" ")[0].split(".")
                const exceptYear = firstList[1] + "." + firstList[2]
                return exceptYear + " " + dateString.split(" ")[1]
            }
   
        }

    }
   
    console.log("작성일 뒤죽박죽>>>",list)
    return (
        <table
            width="100%"

            // height="100px"
            cellspacing={0}

        >
            {/* 테이블 헤더 */}
            <thead
                style={{
                    color: "white",

                }}
            >
                <tr align="center"

                    style={{
                        height: "36px", color: 'black'
                    }}
                    bgcolor={"#D9D9D9"} >
                    <td width="10%">글번호</td>
                    <td width="16%">주문 시간</td>
                    <th width="16%">수령 장소</th>
                    <th width="20%" align='left'>음식점</th>
                    <th width="16%">카테고리</th>
                    <th width="16%">글쓴이</th>
                    <th width="16%">작성일</th>
                </tr>
            </thead>
            {/* 내용 */}
            <tbody
                style={{
                    backgroundColor: "pink",
                    display: "block",
                    maxHeight: `calc(36px * 10)`,
                    overflowY: "hidden"
                }}
            >
                {
                    list.map(

                        (item, idx) => <tr align="center"
                            style={{
                                height: "36px"
                            }}
                            bgcolor="white"
                            onClick={() => {
                                sessionStorage.setItem("editRowId",item.docId)
                                navigate(`/${nowAddress.fullAddress}/reply-delivery`, {
                                    state: {
                                        ...location.state,
                                        write: item,
                                        docId: item.docId
                                    }
                                })
                            }}
                        >
                            {/* 10이 slice하는 숫자 2가 currentPage*/}
                            <td width="10%">{(list.length - idx) + (postsPerPage * (currentPage - 1))}</td>
                            <th width="16%">{formattedOrderTime(item.orderTime)}</th>
                            <th width="16%">{item.detailAddress}</th>
                            <th width="20%" align='left'>{item.restaurant}{getRepliesStatus(item.replies)}</th>
                            <th width="16%"><button
                                style={{
                                    backgroundColor: "#104A62",
                                    borderRadius: 6, color: "white",
                                    border: "none",
                                    height: "26px",
                                    cursor: "pointer"
                                }}
                            >{item.type}</button></th>
                            <th width="16%">{writers[idx]===null?item.id??"-":writers[idx]}</th>
                            <th width="16%">{formattedRegDate(item.registerDate)}</th>
                            <th width="16%">{item.registerDate}</th>
                            <th width="16%">{getBeforeMinutes(item.registerDate)}</th>
                         
                        </tr>
                    )
                }


            </tbody>
        </table>
    );
}

export default MdDeliveryTable;