import React from 'react';
import { Card, CardContent, Typography, Divider, useMediaQuery } from '@mui/material';
import { extractLinkFromString } from '../const/const';

function DeliveryBorderTable({
    minWidth="360px",
    maxWidth=null,
    titleList,
    orderTime,
    toggleEdit,
    foodCategory,
    detailAddress,
    chatLink
}) {
    console.log("chatLink>>>",chatLink)
    const formattedLink = extractLinkFromString(chatLink);
    const isMobile = useMediaQuery('(max-width:767px)');

    return (
        <Card
            sx={{
                width:"calc(100% - 16px)",
                minWidth:minWidth,
                maxWidth:maxWidth,
                borderRadius: '20px',
                 border:"1px solid #104A62",
                marginBottom: '16px',
                overflow: 'hidden'
            }}
        >
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', height:"36px" ,borderBottom:"1px solid #104A62"}}>
                    <Typography 
                    variant={isMobile?'h9':'h6'}
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        주문시간
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                 variant={isMobile?'h9':'h6'}
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{orderTime}</Typography>
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', height:"36px",borderBottom:"1px solid #104A62" }}>
                    <Typography
                 variant={isMobile?'h9':'h6'}
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        카테고리
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography 
                 variant={isMobile?'h9':'h6'}
                    sx={{ flex: '1', overflow: 'hidden', paddingLeft: '8px' }}>{foodCategory}</Typography>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center', height:"36px",borderBottom:"1px solid #104A62" }}>
                    <Typography
                 variant={isMobile?'h9':'h6'}
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        수령장소
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                 variant={isMobile?'h9':'h6'}
                    sx={{ flex: '1', paddingLeft: '8px' }}>{detailAddress}</Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' , height:"36px"}}>
                    <Typography 
                 variant={isMobile?'h9':'h6'}
                    sx={{ width: '120px', paddingRight: '8px' }}>
                        오픈채팅 링크
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{
                        backgroundColor:"#104A62"
                    }}/>
                    <Typography
                 variant={isMobile?'h9':'h6'}
                    sx={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: '8px' }}>
                        <a   href={chatLink.startsWith('http') ? chatLink : `https://${chatLink}`}target="_blank" rel="noopener noreferrer">{chatLink}</a>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
}

export default DeliveryBorderTable;
