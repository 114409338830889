import { Button, useMediaQuery, Chip } from '@mui/material';
import React from 'react';
import { FaPenAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

function CustomWriteBtn({ onClick }) {
  const isMobile = useMediaQuery('(max-width:767px)');
  const params = useParams();

  if (params.postType === "si-place") {
    return <Chip label={isMobile?"글 작성 불가":"시 최신글은 글 작성이 불가능 합니다."} 
    sx={{
      // backgroundColor:"#104A62"
    }}
    />;
  } else if (params.postType === "dong-place") {
    return <Chip label={isMobile?"글 작성 불가":"동 최신글은 글 작성이 불가능 합니다."} 
    sx={{
      // backgroundColor:"#104A62"
    }}
    />;
  } else {
    return (
      <Button
        size='small'
        sx={{
          width:  "auto",
          minWidth:  "auto",
          minHeight:  "auto",
          fontSize: "16px",
          border: "1px solid #104A62",
          color: "#104A62",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onClick}
      >
        <FaPenAlt />
        글쓰기
      </Button>
    );
  }
}

export default CustomWriteBtn;
