import { Card,TextField,Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function ReplyWriteBox({reply,onChangeReply,onRegisterReply}) {
     const location=useLocation()
    // const nowUserEmail=location.state.email
    const [nowUserId,setNowUserId]=useState("ㅇㅇ")
    const [nowUserPw,setNowUserPw]=useState("1111")
    const onChangeId=(e)=>{
        setNowUserId(e.target.value)
    }
    const onChangePw=(e)=>{
        setNowUserPw(e.target.value)
    }
    useEffect(() => {
        const nowUserId=sessionStorage.getItem("userId");
        const nowUserPw=sessionStorage.getItem("userPw");
        const login=sessionStorage.getItem("login")
        if (login==="success") {
            
          setNowUserId(nowUserId)
            setNowUserPw(nowUserPw)  
            
        }else{
            setNowUserId("ㅇㅇ")
            setNowUserPw("1111")
        //  getUserData(location.state.ownerUserId)
        }},[])
        console.log("now user id>>>",nowUserId)
    return (
        <Card
        variant="outlined"
        sx={{
            display:"flex",
            flexDirection:"column",
            width:"calc(100% - 40px)",
            gap:"16px",
            backgroundColor:"#D9D9D9",
            padding:"16px",
            // border:"1px solid black",
            alignItems:"flex-end"
        }}
        > <div
        style={{
            display:"flex",
            flexDirection:"row",
            width:"100%",
            gap:"8px",
         
        }}
        >
            <div
            style={{
                display:"flex",
                flexDirection:"column",
                gap:"8px"
            }}
            >
            <TextField 
            size="small"
            placeholder='아이디' 
            inputProps={{
                style:{
                    height:"26px",
                    backgroundColor:"white"
                }
            }}
            disabled={sessionStorage.getItem("login")==="success"}
           value={nowUserId}
           onChange={onChangeId}
           />    
            <TextField 
            size="small"
            placeholder='비밀번호' 
            inputProps={{
                style:{height:"26px",
                    backgroundColor:"white"
                }
            }}
            disabled={sessionStorage.getItem("login")==="success"}
            value={nowUserPw}
            onChange={onChangePw}            
            />    
            {/* <input placeholder='비밀번호'/>     */}
            </div>
            <TextField
            maxRows={3}
            minRows={3}
            multiline
            sx={{     
                    backgroundColor:"white",
                width:"100%",
            }}
            inputProps={{
                style:{
                    
                    backgroundColor:"white"
                }
            }}
            value={reply}
            onChange={onChangeReply}
            />
        </div>
        
        <Button
        style={{
            color:"white",
            border:"none",
            borderRadius:"3px",
            width:"100px",
            backgroundColor:"#002D82",
            cursor:"pointer"
        }}
        onClick={()=>{onRegisterReply(reply,nowUserId,nowUserPw)}}
        >확인</Button>
        </Card>
       
    );
}

export default ReplyWriteBox;