import React from 'react';

function Flex(props) {
    return (
        <div
        style={{
            flex:1
        }}
        />
    );
}

export default Flex;