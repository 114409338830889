import { Button, useMediaQuery } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

function FilledDeliveryBtn({onClick}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    const params=useParams()
    return (
        <Button
            variant='contained'
            size='small'
            onClick={onClick}
            sx={{width:"120px",
                height:"40px",
                color: "white",
                fontSize: isMobile?"14px":"16px",
                backgroundColor: params.tableType==="delivery-table" ? "#104A6E" : "white", // 선택됐을 때 배경색 변경
                color:params.tableType==="delivery-table" ?"white" :"#104A6E"  ,
                border:"1px solid #104A6E",
                ":hover": {
                    backgroundColor: "#104A6E",
                    color: "white",
                }
            }}
        >
            배달팟
        </Button>
    );
}

export default FilledDeliveryBtn;