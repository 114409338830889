import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import ResArea from '../components/ResArea';
import SmResArea from '../components/SmResArea';
import BorderTxtField from '../components/BorderTxtField';
import HorizontalDivider from '../components/HorizontalDivider';
import { useRecoilState } from 'recoil';
import { loginDialogState, nowAreaState, nowPotNameState } from '../atom/atom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { db } from '../service/firebase';
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { communityValues, completedWrite, mdWidth, noWriteTxtField, smWidth } from '../const/const';
import uuid from 'react-uuid';
import { Button, Checkbox, MenuItem, Radio, Select, TextField, TextareaAutosize } from '@mui/material';
import DeliveryWriteContent from '../components/DeliveryWriteContent';
import TaxiWriteContent from '../components/TaxiWriteContent';
import CommunityWriteContent from '../components/CommunityWriteContent';
import SmDeliveryWriteContent from '../components/SmDeliveryWriteContent';
import PcResArea from '../components/Bar/PcResArea';
import WriteTxtfield from '../components/Txtfield/WriteTxtfield';
import TitleTxtfield from '../components/Txtfield/TitleTxtfield';
import MinuteSelect from '../components/MinuteSelect';
import HourSelect from '../components/HourSelect';
import ColorToggle from '../components/Toggle/ColorToggle';
import DottedHorizontalDivider from '../components/DottedHorizontalDivider';
import CustomWriteBtn from '../components/Btn/CustomWriteBtn';
import EditCompleteBtn from '../components/Btn/EditCompleteBtn';
import GoLoginDialog from '../components/Dialog/GoLoginDialog';

function EditDeliveryPage(props) {
    const [loginDialog,setLoginDialog]=useRecoilState(loginDialogState)
    const location = useLocation()
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const [id,setId]=useState("")
    const [pw,setPw]=useState("")
    // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
    const [newRestaurant, setNewRestaurant] = useState("")
    const [newDetailAddress, setNewDetailAddress] = useState("")
    const [newType, setNewType] = useState("같이시켜")
    //(선택) 주문시간->새로 추가 된 필드값->시작
    const nowTime = new Date()
    const [newOrderTime, setNewOrderTime] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newOrderSiTime, setNewOrderSiTime] = useState(nowTime.getHours())    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newOrderBunTime, setNewOrderBunTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
    const [newRegisterDate, setNewRegisterDate] = useState(nowTime)
    //(선택)오픈채팅 링크
    const [newChatLink, setNewChatLink] = useState("")
    //(선택)내용
    const [newContents, setNewContents] = useState("")
    const [useOrderT,setUseOrderT]=useState(false)
    //출발지
    const [startAddress,setStartAddress]=useState("")
    //도착지
    const [endAddress,setEndAddress]=useState("")
//가는날
const [goDate, setGoDate] = useState(nowTime)    //(선택) 주문시간->새로 추가 된 필드값->시작
const [goSiTime, setGoSiTime] = useState("00")    //(선택) 주문시간->새로 추가 된 필드값->시작
const [goBunTime, setGoBunTime] = useState("00")  
//community
const [foodCategory,setFoodCategory]=useState("")
const [nowCommunityCategory,setNowCommunityCategory]=useState(communityValues[0])
const params=useParams()
const [goLoginDialog,setGoLoginDialog]=useState(false)
const newViewWrite=JSON.parse(sessionStorage.getItem("viewWrite"))
    const navigate = useNavigate()
    function inputFormatDate(date) {
        // 날짜를 받아와서 원하는 형식으로 변환합니다.
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고, 2자리로 만듭니다.
        const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 만듭니다.
      
        // 원하는 형식으로 날짜를 조합하여 반환합니다.
        return `${year}-${month}-${day}`;
      }
      
      const goDelivery=(postType)=>{
        navigate(`/${nowAddress.fullAddress}/delivery-table`,{state:{
                   nowAddress:nowAddress,
                   nowPotName:"delivery",
                   postType:"meetingPlace",
                  //  ownerUserId:location.state.ownerUserId,
                  //  favorites:location.state.favorites
                  ...location.state
               }})
           
         }
    const goTaxi = () => {
        navigate(`/${nowAddress.fullAddress}/taxi-table`,{state:{
            nowAddress:nowAddress,
            nowPotName:"taxi",
            postType:"meetingPlace",
           //  ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           ...location.state
        }})
    
    }
    const goCommunity = () => {
        navigate(`/${nowAddress.fullAddress}/community-table`,{state:{
            nowAddress:nowAddress,
            nowPotName:"community",
            postType:"meetingPlace",
           //  ownerUserId:location.state.ownerUserId,
           //  favorites:location.state.favorites
           ...location.state
        }})
    
    }
    const fetchUsersByDocId = async (docId) => {
        try {
            const docRef = doc(db, "users", docId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                return docSnapshot.data() ;
            } else {
                console.error("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching user by doc id: ", error);
            return null;
        }
    };
    const getUserData=async(userId)=>{
        const data=await fetchUsersByDocId(userId)
        setId(data.email)
        setPw(data.pw)
    }
    useEffect(() => {
const nowUserId=sessionStorage.getItem("userId");
const nowUserPw=sessionStorage.getItem("userPw");
const login=sessionStorage.getItem("login")
//원래정보
setNewRestaurant(newViewWrite.restaurant)
setNewDetailAddress(newViewWrite.detailAddress)

const splitted=newViewWrite.orderTime.split(" ")
const date=splitted[0]
const siTime=splitted[1].split(":")[0].padStart(2, '0')
const bunTime=splitted[1].split(":")[1].padStart(2, '0')
console.log("주문시간>>>",newViewWrite.type)
setNewOrderTime(new Date(date))
setNewOrderSiTime(siTime)
setNewOrderBunTime(bunTime)
console.log("reg date>>>",newViewWrite.registerDate)
setNewRegisterDate(newViewWrite.registerDate)
setNewType(newViewWrite.type)
setNewChatLink(newViewWrite.chatLink)
setNewContents(newViewWrite.contents)

if (login==="success") {
     setId(nowUserId)
    setPw(nowUserPw)
    
}else{
   setId("ㅇㅇ")
    setPw("1111")
//  getUserData(location.state.ownerUserId)
}
        // setNowPotName(location.state.nowPotName)
    }, [])

    const formatForDbRgDate = (date) => {
        // const date = new Date(isoDateTime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
        const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
        const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
        const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };
    const formatForDbDate = (date,hours,minutes) => {
        // const date = new Date(isoDateTime);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더하고 문자열을 두 자리로 패딩
        const day = date.getDate().toString().padStart(2, '0'); // 일도 문자열을 두 자리로 패딩
        // const hours = date.getHours().toString().padStart(2, '0'); // 시간을 문자열로 변환하고 두 자리로 패딩
        // const minutes = date.getMinutes().toString().padStart(2, '0'); // 분을 문자열로 변환하고 두 자리로 패딩

        return `${year}.${month}.${day} ${hours}:${minutes}`;
    };
    const getFoodCategory = (potName) => {
        if (potName === "delivery") {
            return "한식"
        } else if (potName === "taxi") {
            return "같이타요"
        } else if (potName === "community") {
            return "일반"
        }
        else {
            return ""
        }
    }
    const writeDel=async(nowPotName,nowAddress)=>{
        const writeCondition =
        // newFoodCategory.trim() != "" &&
        // nowPotName.trim() != "" &&
        newRestaurant.trim() != "" &&
        newDetailAddress.trim() != "" &&
        newType.trim() != ""

        const formattedRgTime = formatForDbRgDate(newRegisterDate)
        const formattedOrderTime = formatForDbDate(newOrderTime,newOrderSiTime,newOrderBunTime)
        if (writeCondition) {
        const docId = uuid()
        await setDoc(doc(db, "posts", docId), {
            chatLink: newChatLink,
            contents: newContents,
            //
            deadline: false,
            detailAddress: newDetailAddress,
            foodCategory: getFoodCategory(nowPotName),
            //
            foodLink:"",
            //
            isReported:false,
            //주소 가져올때 넣기
            latitude:1,
            longitude:1,
            si:nowAddress.si,
            dong:nowAddress.dong,
            meetingPlace:nowAddress.fullAddress,
            members:[],
            ownerUserId: 
            sessionStorage.getItem("login")==="success"?
            sessionStorage.getItem("userUuid"):"" ,
            id:id,
            pw:pw,
            orderTime:  formattedOrderTime,
            registerDate:formattedRgTime,
            restaurant: newRestaurant,
            //로그인 할 때 화면 구현후 가지고 오기
            type: newType,
            replies:[]
        })
        window.confirm(completedWrite)
        setNewRestaurant("")
        setNewDetailAddress("")
        setNewType("같이시켜")
        setNewOrderSiTime("")
        setNewOrderBunTime("")
        setNewChatLink("")
        setNewContents("")
        setStartAddress("")
        setEndAddress("")
        setGoSiTime("")
        setGoBunTime("")
        setFoodCategory("")
    } else {
        window.confirm(noWriteTxtField)
    }
    }
    //글을 디비에 저장
    const saveWrite=async()=>{
        
        const writeCondition =
        // newFoodCategory.trim() != "" &&
        // nowPotName.trim() != "" &&
        newRestaurant.trim() != "" &&
        newDetailAddress.trim() != "" &&
        newType.trim() != ""

        const formattedRgTime = formatForDbRgDate(newRegisterDate)
        const formattedOrderTime = formatForDbDate(newOrderTime,newOrderSiTime,newOrderBunTime)
        if (writeCondition) {
        const docId = uuid()
        await setDoc(doc(db, "posts", docId), {
            chatLink: newChatLink,
            contents: newContents,
            deadline: false,
            detailAddress: newDetailAddress,
            foodCategory: getFoodCategory("delivery"),
            foodLink:"",
            isReported:false,
            latitude:1,
            longitude:1,
            si:params.si,
            dong:params.dong,
            meetingPlace:params.fullAddress,
            members:[],
            ownerUserId: 
            sessionStorage.getItem("login")==="success"?
            sessionStorage.getItem("userUuid"):"" ,
            id:id,
            pw:pw,
            //가는 시간은 배달팟에 없음
            goTime:"",
            orderTime:  formattedOrderTime,
            registerDate:formattedRgTime,
            restaurant: newRestaurant,
            //로그인 할 때 화면 구현후 가지고 오기
            type: newType,
            replies:[],
            startAddress:"",
            endAddress:""
        })
        window.confirm(completedWrite)
        setNewRestaurant("")
        setNewDetailAddress("")
        setNewType("같이시켜")
        setNewOrderSiTime("")
        setNewOrderBunTime("")
        setNewChatLink("")
        setNewContents("")
        setStartAddress("")
        setEndAddress("")
        setGoSiTime("")
        setGoBunTime("")
        setFoodCategory("")
    } else {
        window.confirm(noWriteTxtField)
    }
    }
  
    const onChangeRes = (e) => {
        setNewRestaurant(e.target.value)
    }
    const onChangeDetailAddress = (e) => {
        setNewDetailAddress(e.target.value)
    }
    const onChangeOpenChat = (e) => {
        setNewChatLink(e.target.value)
    }
    function handleInputGoSiChange(e) {
        let inputValue = e.target.value;

  // 입력된 값이 숫자인지 확인하는 정규 표현식
  const regex = /^[0-9]*$/;

  // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
  if (inputValue.length > 2) {
    inputValue = inputValue.slice(-2);
  }

  // 입력된 값이 숫자이고 최대 두 자리인지 확인
  if (regex.test(inputValue)) {
    // 입력된 값을 state에 설정
    setGoSiTime(inputValue);
  }
      }
      function handleInputGoBunChange(e) {
        let inputValue = e.target.value;

        // 입력된 값이 숫자인지 확인하는 정규 표현식
        const regex = /^[0-9]*$/;
      
        // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
        if (inputValue.length > 2) {
          inputValue = inputValue.slice(-2);
        }
      
        // 입력된 값이 숫자이고 최대 두 자리인지 확인
        if (regex.test(inputValue)) {
          // 입력된 값을 state에 설정
          setGoBunTime(inputValue);
        }
      }
    function handleInpuSiChange(e) {
        let inputValue = e.target.value;

  // 입력된 값이 숫자인지 확인하는 정규 표현식
  const regex = /^[0-9]*$/;

  // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
  if (inputValue.length > 2) {
    inputValue = inputValue.slice(-2);
  }

  // 입력된 값이 숫자이고 최대 두 자리인지 확인
  if (regex.test(inputValue)) {
    // 입력된 값을 state에 설정
    setNewOrderSiTime(inputValue);
  }
      }
      function handleInpuBunChange(e) {
        let inputValue = e.target.value;

        // 입력된 값이 숫자인지 확인하는 정규 표현식
        const regex = /^[0-9]*$/;
      
        // 입력된 값이 두 자리 이상인 경우 마지막 두 자리만 사용
        if (inputValue.length > 2) {
          inputValue = inputValue.slice(-2);
        }
      
        // 입력된 값이 숫자이고 최대 두 자리인지 확인
        if (regex.test(inputValue)) {
          // 입력된 값을 state에 설정
          setNewOrderBunTime(inputValue);
        }
      }
      const onClickTypeToggle=(e)=>{
setNewType(e.target.id)
      }
      const onChangeStartAddress=(e)=>{
        setStartAddress(e.target.value)
      }
      const onChangeEndAddress=(e)=>{
        setEndAddress(e.target.value)
      }
      const onChangeFoodCategory=(e)=>{
        setFoodCategory(e.target.value)
      }
const onChangeNewContent=(e)=>{
    setNewContents(e.target.value)
}
const onChangeCommunityCategory=(e)=>{
    setNowCommunityCategory(e.target.id)
}
const onChangeId=(e)=>{
    setId(e.target.value)
}
const onChangePw=(e)=>{
    setPw(e.target.value)
}
      const getWriteContent=(potType,widthType)=>{

        if (potType==="delivery") {
            return <DeliveryWriteContent 
            id={id}
            pw={pw}
            onChangeId={onChangeId}
            onChangePw={onChangePw}
            allWidth={widthType==="md"?mdWidth:smWidth}
            newContent={newContents}
            onChangeNewContent={onChangeNewContent}
            handleInpuBunChange={handleInpuBunChange}
                        handleInpuSiChange={handleInpuSiChange}
                        inputFormatDate={inputFormatDate}
                        newChatLink={newChatLink}
                        newDetailAddress={newDetailAddress}
                        newOrderBunTime={newOrderBunTime}
                        newOrderSiTime={newOrderSiTime}
                        newOrderTime={newOrderTime}
                        newRestaurant={newRestaurant}
                        newType={newType}
                        onChangeDetailAddress={onChangeDetailAddress}
                        onChangeOpenChat={onChangeOpenChat}
                        onChangeRes={onChangeRes}
                        onClickTypeToggle={onClickTypeToggle}
                        setNewOrderTime={setNewOrderTime}
                        setUseOrderT={setUseOrderT}
                        useOrderT={useOrderT}/>
        }
        else if (potType==="taxi") {
            return <TaxiWriteContent
            allWidth={widthType==="md"?mdWidth:smWidth}
            newContent={newContents}
            onChangeNewContent={onChangeNewContent}
            startAddress={startAddress}
            onChangeStartAddress={onChangeStartAddress}
            endAddress={endAddress}
            onChangeEndAddress={onChangeEndAddress}
            handleInpuBunChange={handleInputGoBunChange}
                        handleInpuSiChange={handleInputGoSiChange}
                        inputFormatDate={inputFormatDate}
                        newChatLink={newChatLink}
                       goBunTime={goBunTime}
                        goSiTime={goSiTime}
                        goDate={goDate}
                        setGoDate={setGoDate}
                        onChangeOpenChat={onChangeOpenChat}
            />
        } else if (potType==="community"){
            return <CommunityWriteContent
            allWidth={widthType==="md"?mdWidth:smWidth}
            nowCommunityCategory={nowCommunityCategory}
            onChangeCommunityCategory={onChangeCommunityCategory}
            foodCategory={foodCategory}
            goBunTime={goBunTime}
            goSiTime={goSiTime}
            goDate={goDate}
            handleInpuBunChange={handleInputGoBunChange}
            handleInpuSiChange={handleInputGoSiChange}
            inputFormatDate={inputFormatDate}
            newChatLink={newChatLink}
            newContent={newContents}
            onChangeFoodCategory={onChangeFoodCategory}
            onChangeNewContent={onChangeNewContent}
            onChangeOpenChat={onChangeOpenChat}
            setGoDate={setGoDate}

            />
        }
      }
      const   onDeleteDoc=async()=>{
        try {
            const loginStatus=sessionStorage.getItem("login")
            if (loginStatus==="success") {
                  await deleteDoc(doc(db, "posts", newViewWrite.docId));
                  navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/1`)
          
window.confirm("삭제 성공")
            } else {
                setGoLoginDialog(true)
            }
 
        } catch (error) {
            window.confirm("삭제 실패",error)   
        }
        
    }
      const editComplete = async (docId, nowPotName, nowAddress) => {
        // console.log("현재 시 동>>>", nowAddress);
        const writeCondition =
            // newFoodCategory.trim() != "" &&
            // nowPotName.trim() != "" &&
            newRestaurant.trim() != "" &&
            newDetailAddress.trim() != "" &&
            newType.trim() != "";
    
        // const formattedRgTime = formatForDbRgDate(newRegisterDate);
        const formattedOrderTime = formatForDbDate(newOrderTime, newOrderSiTime, newOrderBunTime);
       const newWrite={
        chatLink: newChatLink,
        contents: newContents,
        deadline: false,
        detailAddress: newDetailAddress,
        foodCategory: getFoodCategory("delivery"),
        foodLink: "",
        isReported: false,
        latitude: 1,
        longitude: 1,
        si: params.si,
        dong: params.dong,
        meetingPlace: params.fullAddress,
        members: [],
        ownerUserId: sessionStorage.getItem("login") === "success" ? sessionStorage.getItem("userUuid") : "",
        id: id,
        pw: pw,
        goTime:"",
        orderTime: formattedOrderTime,
        registerDate: newRegisterDate,
        restaurant: newRestaurant,
        // 로그인 할 때 화면 구현 후 가지고 오기
        type: newType,
        replies: [],
           startAddress:"",
    endAddress:""
    }
        if (writeCondition) {
            await updateDoc(doc(db, "posts",   newViewWrite.docId),newWrite );
            window.confirm("수정이 완료되었습니다");
            sessionStorage.setItem("viewWrite",JSON.stringify({...newWrite,docId:newViewWrite.docId}))
           navigate(-1)
        } else {
            window.confirm("에러 발생");
        }
    };
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: "center"
            }}>
            <Mobile>
                <div
                    style={{
                        height: "55px"
                    }}
                />
                 <ResArea
                 componentType='sm'
                 padding='16px'
                    nowPotName={"delivery"}
                    nowAddress={nowAddress} goDelivery={goDelivery} goTaxi={goTaxi} goCommunity={goCommunity} />
                <div
                    style={{
                        height: "2px"
                    }}
                />
                <div
                    style={{
                        height: "1px",
                        width: smWidth,
                        backgroundColor: "#D9D9D9"
                    }}
                />
                <div
                    style={{

                        height: "17px"
                    }}
                />
             {getWriteContent("delivery","sm")}
             <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: smWidth,
                        justifyContent: "flex-end"
                    }}
                >
                    <button
                        style={{
                            border: "1px solid #104A62",
                            color: "#104A62"
                        }}
                        onClick={(e)=>{
                            writeDel("delivery",nowAddress)
                        //글쓰고 나면 변수들 초기화
                        navigate(-1)
                        }}
                    >글쓰기</button></div>
            </Mobile>
            <PC>
            <GoLoginDialog
                goLoginDialog={goLoginDialog}
                setGoLoginDialog={setGoLoginDialog}
                setLoginDialog={setLoginDialog}
                />
                <div
                    style={{
                        height: "55px"
                    }}
                />
                {/* <ResArea
                    nowPotName={"delivery"}
                    nowAddress={nowAddress} goDelivery={goDelivery} goTaxi={goTaxi} goCommunity={goCommunity} /> */}
            <PcResArea
            
            />
            
             <HorizontalDivider
             width={mdWidth}
             />
                <div
                    style={{

                        height: "17px"
                    }}
                />
        {/* nowPotName에 따라 내용이 변경될 곳 */}
        {/* <DeliveryWriteContent 
            id={id}
            pw={pw}
            onChangeId={onChangeId}
            onChangePw={onChangePw}
            allWidth={mdWidth}
            newContent={newContents}
            onChangeNewContent={onChangeNewContent}
            handleInpuBunChange={handleInpuBunChange}
                        handleInpuSiChange={handleInpuSiChange}
                        inputFormatDate={inputFormatDate}
                        newChatLink={newChatLink}
                        newDetailAddress={newDetailAddress}
                        newOrderBunTime={newOrderBunTime}
                        newOrderSiTime={newOrderSiTime}
                        newOrderTime={newOrderTime}
                        newRestaurant={newRestaurant}
                        newType={newType}
                        onChangeDetailAddress={onChangeDetailAddress}
                        onChangeOpenChat={onChangeOpenChat}
                        onChangeRes={onChangeRes}
                        onClickTypeToggle={onClickTypeToggle}
                        setNewOrderTime={setNewOrderTime}
                        setUseOrderT={setUseOrderT}
                        useOrderT={useOrderT}/> */}
        {/* 아래가 새거 */}
        
        <div
        style={{
            width:mdWidth,
            display:"flex",
            flexDirection:"column",
            gap:"20px"
        }}
        >
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%"
}}
>
<WriteTxtfield
placeholder={"아이디"}
value={id}
onChange={onChangeId}
/>
<WriteTxtfield
placeholder={"아이디"}
value={pw}
onChange={onChangePw}
// type={"password"}
/>

</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%"
}}
>
<TitleTxtfield
width={"calc(50% - 10px)"}
title={"음식점"}
placeholder={"(직접입력)"}
value={newRestaurant}
onChange={onChangeRes}
/>


</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%"
}}
>
<TitleTxtfield
width={"calc(50% - 10px)"}
title={"수령장소"}
placeholder={"(직접입력)"}
value={newDetailAddress}
onChange={onChangeDetailAddress}
/>


</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%",
    
    alignItems:"center"
}}
>
<div
style={{
    fontSize:"16px",
    width:"76px"
}}
>(주문시간)</div>
<TextField
sx={{
   
    minWidth:"100px"
}}
inputProps={{
    style:{
        height:"16px",
    }
}}
type='date'
value={inputFormatDate(newOrderTime)}
                onChange={(e)=>{
                    setNewOrderTime(new Date(e.target.value))
                }}
/>
<HourSelect
value={newOrderSiTime}
onChange={handleInpuSiChange}
/>시
<MinuteSelect
value={newOrderBunTime}
onChange={handleInpuBunChange}
/>분
</div>
<DottedHorizontalDivider/>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%",
    
    alignItems:"center"
}}
>
<ColorToggle
active={newType==="같이시켜"}
title={"같이시켜"}
onClick={onClickTypeToggle}
/><ColorToggle
active={newType==="하나시켜 나눠먹어요"}
title={"하나시켜 나눠먹어요"}
onClick={onClickTypeToggle}
/>

</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%"
}}
>
<WriteTxtfield
width={"calc(25% - 10px)"}
title={"오픈채팅 링크"}
placeholder={"(선택)오픈채팅 링크"}
value={newChatLink}
onChange={onChangeOpenChat}
/>


</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    gap:"20px",
    width:"100%"
}}
>
<WriteTxtfield
rows={5}
value={newContents}
onChange={onChangeNewContent}
/>


</div>
<div
style={{
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-end",
    gap:"20px",
    width:"100%"
}}
>
<EditCompleteBtn
onClick={()=>{
     editComplete()
   
   }}
/>
</div>

        </div>
                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "calc(100% - 80px - 80px)",
                        justifyContent: "flex-end"
                    }}
                >
                    <button
                        style={{
                            border: "1px solid #104A62",
                            color: "#104A62"
                        }}
                        onClick={(e)=>{writeDel("delivery",nowAddress)
                        //글쓰고 나면 변수들 초기화
                        navigate(-1)
                        }}
                    >글쓰기</button>
                </div> */}
            </PC>
        </div>
    );
}

export default EditDeliveryPage;