import { Button, useMediaQuery } from '@mui/material';
import React from 'react';
import { goBtnSx } from '../../const/BtnStyle';
import { useNavigate, useParams } from 'react-router-dom';

function GoCommunityPotBtn({active}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    const navigate=useNavigate()
    const params=useParams()
    return (
        <Button
        variant='outlined'
        sx={{width:"160px",
            height:"40px",
            color: "white",
            fontSize: isMobile?"14px":"16px",
            backgroundColor:  "white" , // 선택됐을 때 배경색 변경
            color:"#104A6E"  ,
            border:"1px solid #104A6E",
            ":hover": {
                backgroundColor: "#104A6E",
                color: "white",
            }
        }}
        onClick={()=>{
            navigate(`/${params.fullAddress}/${params.si}/${params.dong}/community-table/meeting-place/1`);
        }}
        >
            커뮤니티
        </Button>
    );
}

export default GoCommunityPotBtn;