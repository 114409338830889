import React, { useState, useEffect } from 'react';
import PageLayout from '../Layouts/PageLayout';
import TitleBar from '../components/TopBar/TitleBar';
import BorderTxtField from '../components/BorderTxtField';
import BoardTxtArea from '../components/BoardTxtArea';
import { formatForDbRgDate, noWriteTxtField, mdWidth, pcEmpty, smWidth } from '../const/const';
import { db } from '../service/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useRecoilState } from 'recoil';
import { mainLoadingState } from '../atom/atom';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { PC, Mobile } from '../ReactiveLayout';

function InquiryShowPage(props) {
    const location = useLocation();

    const inquiryId = JSON.parse(sessionStorage.getItem("inquiry")).docId; // 세션에서 docId만 가져옴
    const [inquiry, setInquiry] = useState(null);
    const [mainLoading, setMainLoading] = useRecoilState(mainLoadingState);
    const [adminReply, setAdminReply] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // 관리자 여부 확인
        const userId = sessionStorage.getItem("userId");
        const userPw = sessionStorage.getItem("userPw");
        if (userId === "Admin" && userPw === "rmfrhksfl") {
            setIsAdmin(true);
        }

        // Firebase에서 inquiry 데이터 가져오기
        const fetchInquiry = async () => {
            setMainLoading(true);
            try {
                const docRef = doc(db, "inquiry", inquiryId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setInquiry(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Failed to fetch inquiry:", error);
            }
            setMainLoading(false);
        };

        fetchInquiry();
    }, [inquiryId, setMainLoading]);

    const handleReplyChange = (e) => {
        setAdminReply(e.target.value);
    };

    const postReply = async () => {
        if (adminReply.trim() === "") {
            window.confirm(noWriteTxtField);
            return;
        }

        setMainLoading(true);
        try {
            const newReply = {
                content: adminReply,
                admin: true,
                date: formatForDbRgDate(new Date()),
            };
            const updatedReplies = [...(inquiry.replies || []), newReply];
            await updateDoc(doc(db, "inquiry", inquiryId), {
                replies: updatedReplies,
            });
            const res = window.confirm("댓글이 등록되었습니다.");
            setAdminReply("");
            if (res) {
                window.location.reload(); // 페이지 새로고침하여 최신 데이터 반영
            }
        } catch (error) {
            console.error("댓글 등록 실패:", error);
        }
        setMainLoading(false);
    };

    if (!inquiry) {
        return <div>Loading...</div>; // 데이터 로딩 중
    }

    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
            {/* PC UI */}
            <PC>
                <div style={{ width: mdWidth, minWidth: "900px" }}>
                    <div style={{ height: pcEmpty }} />
                    <TitleBar title={"문의사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={true}
                            placeholder={"아이디"}
                            value={inquiry.id}
                        />
                        <BorderTxtField
                            type={"password"}
                            readOnly={true}
                            placeholder={"비밀번호"}
                            value={inquiry.pw}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            width: "100%",
                            gap: "16px",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                            readOnly={true}
                            value={inquiry.title}
                            placeholder={"제목"}
                            width='calc(193px + 193px + 16px + 16px)'
                        />
                    </div>
                    <BoardTxtArea
                        readOnly={true}
                        value={inquiry.content}
                        width={"60%"}
                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "calc(36px * 2)"
                        }}
                    >
                        <input
                            disabled={true}
                            checked={inquiry.type === "0"}
                            value={"0"}
                            type='radio'
                        /> <span>공개</span>
                        <div style={{ width: "32px" }} />
                        <input
                            disabled={true}
                            checked={inquiry.type === "1"}
                            value={"1"}
                            type='radio'
                        /> <span>비공개</span>
                    </div>

                    {isAdmin&&<div style={{ marginTop: "20px", width: "100%" }}>
                        <TitleBar title={"댓글 작성"} useBtn={false} />
                        {/* {isAdmin && ( */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "16px"
                                }}
                            >
                                <BoardTxtArea
                                    value={adminReply}
                                    onChange={handleReplyChange}
                                    width={"60%"}
                                    placeholder={"관리자 댓글을 작성하세요"}
                                />
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: "#104A62",
                                        color: "white",
                                        fontSize: "16px",
                                        width: "160px",
                                        ":hover": {
                                            backgroundColor: "#104A62",
                                            color: "white",
                                            fontSize: "16px",
                                            width: "160px",
                                        }
                                    }}
                                    onClick={postReply}
                                >
                                    댓글 등록
                                </Button>
                            </div>
                        {/* )} */}
                    </div>}

                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <TitleBar title={"댓글 목록"} useBtn={false} />
                        <div style={{ marginTop: "10px" }}>
                            {(inquiry.replies || []).map((reply, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        padding: "10px",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    <strong>Admin</strong>
                                    <p>{reply.content}</p>
                                    <span style={{ fontSize: "12px", color: "#888" }}>{reply.date}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </PC>

            {/* Mobile UI */}
            <Mobile>
            <div style={{ width: smWidth,  }}>
                    <div style={{ height: pcEmpty }} />
                    <TitleBar title={"문의사항"} useBtn={false} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                    
                            readOnly={true}
                            placeholder={"아이디"}
                            value={inquiry.id}
                            fullWidth
                        />
                        <div
                        style={{
                            height:"16px"
                        }}
                        />
                        <BorderTxtField
                            type={"password"}
                            readOnly={true}
                            placeholder={"비밀번호"}
                            value={inquiry.pw}
                            fullWidth
                            style={{ marginTop: "16px" }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            width: "100%",
                            marginTop: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <BorderTxtField
                        
                            readOnly={true}
                            value={inquiry.title}
                            placeholder={"제목"}
                            fullWidth
                        />
                    </div>
                    <BoardTxtArea
                        readOnly={true}
                        value={inquiry.content}
                        width={"96%"}

                        height={"calc(36px * 4)"}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "16px",
                            alignItems: "flex-start"
                        }}
                    >
                        <input
                            disabled={true}
                            checked={inquiry.type === "0"}
                            value={"0"}
                            type='radio'
                        /> <span>공개</span>
                        <input
                            disabled={true}
                            checked={inquiry.type === "1"}
                            value={"1"}
                            type='radio'
                            style={{ marginTop: "8px" }}
                        /> <span>비공개</span>
                    </div>

                 { isAdmin&&  <div style={{ marginTop: "20px", width: "100%" }}>
                        <TitleBar title={"댓글 작성"} useBtn={false} />
                        {/* {isAdmin && ( */}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "16px",
                                    marginTop: "16px"
                                }}
                            >
                                <BoardTxtArea

                                    value={adminReply}
                                    onChange={handleReplyChange}
                                    width={"96%"}
                                    placeholder={"관리자 댓글을 작성하세요"}
                                />
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: "#104A62",
                                        color: "white",
                                        fontSize: "14px",
                                        width: "100%",
                                        ":hover":{
                                            backgroundColor: "#104A62",
                                        color: "white",
                                        fontSize: "14px",
                                        width: "100%",
                                        }
                                    }}
                                    onClick={postReply}
                                >
                                    댓글 등록
                                </Button>
                            </div>
                        {/* )} */}
                    </div>}

                    <div style={{ marginTop: "20px", width: "100%" }}>
                        <TitleBar title={"댓글 목록"} useBtn={false} />
                        <div style={{ marginTop: "10px" }}>
                            {(inquiry.replies || []).map((reply, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "10px",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                        marginBottom: "10px",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                >
                                    <strong>{reply.admin ? "Admin" : "사용자"}</strong>
                                    <p>{reply.content}</p>
                                    <span style={{ fontSize: "12px", color: "#888" }}>{reply.date}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Mobile>
        </div>
    );
}

export default InquiryShowPage;
