import { Button, Card, CardContent, Divider, TextField, Typography, useMediaQuery, Checkbox, FormControlLabel } from '@mui/material';
import React, { useState } from 'react';
import SearchBar from '../Bar/SearchBar';
import NoIconSearchBar from '../Bar/NoIconSearchBar';
import Flex from '../Flex/Flex';
import { mdMinWidth, mdWidth } from '../../const/const';
import { searchBtnSx } from '../../const/BtnStyle';

function TaxiSearchCard({
  onChangeGoTime,
  searchGoTime,
  searchGoSiTime,
  searchGoBunTime,
  onChangeSearchGoSiTime,
  onChangeSearchGoBunTime,
  startAddress,
  endAddress,
  onChangeStartAddress,
  onChangeEndAddress,
  author,
  onChangeAuthor,
  onSearch,selectedFields,setSelectedFields
}) {
  const isMobile = useMediaQuery('(max-width:767px)');

  // 각 필드의 체크박스 상태 관리
  // const [selectedFields, setSelectedFields] = useState({
  //   author: false,
  //   startAddress: false,
  //   endAddress: false,
  //   goTime: false
  // });
  console.log("author undefined>>>",selectedFields)

  const handleCheckboxChange = (event) => {
    setSelectedFields({
      ...selectedFields,
      [event.target.name]: event.target.checked
    });
  };

  function inputFormatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <Card
      variant='outlined'
      sx={{
        width: isMobile ? 'calc(100% - 20px - 20px)' : mdWidth,
        minWidth: isMobile ? null : mdMinWidth,
        border: "2px solid #002D82",
        margin: isMobile ? '0 10px' : '0',
        padding: isMobile ? '10px' : '20px'
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: isMobile ? 'column' : 'row',
          gap: "20px",
          alignItems: isMobile ? 'center' : 'flex-start',
          height: "100%"
        }}
      >
        {/* 글쓴이 섹션 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: isMobile ? '100%' : 'auto',
            alignItems: isMobile ? 'center' : 'flex-start'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Typography variant='h6'
            sx={{
              textDecoration:!selectedFields.author?"line-through":null,color:!selectedFields.author?"#C5C3C3":"black"
            }}
            >글쓴이</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.author}
                  onChange={handleCheckboxChange}
                  name="author"
                />
              }
              label=""
            />
          </div>
          <NoIconSearchBar 
          readOnly={!selectedFields.author}
          width={isMobile ? "100%" : "220px"} inputValue={author} onChangeInput={onChangeAuthor} />
        </div>

        <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />

        {/* 출발지 & 도착지 섹션 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: isMobile ? '100%' : 'auto',
            alignItems: isMobile ? 'center' : 'flex-start'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Typography variant='h6' sx={{
              textDecoration:!selectedFields.startAddress?"line-through":null,color:!selectedFields.startAddress?"#C5C3C3":"black"
            }}>출발지</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.startAddress}
                  onChange={handleCheckboxChange}
                  name="startAddress"
                />
              }
              label=""
            />
          </div>
          <NoIconSearchBar width={isMobile ? "100%" : "220px"} inputValue={startAddress} onChangeInput={onChangeStartAddress} readOnly={!selectedFields.startAddress}/>
          
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Typography variant='h6'
            sx={{
              textDecoration:!selectedFields.endAddress?"line-through":null,color:!selectedFields.endAddress?"#C5C3C3":"black"
            }}
            >도착지</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.endAddress}
                  onChange={handleCheckboxChange}
                  name="endAddress"
                />
              }
              label=""
            />
          </div>
          <NoIconSearchBar width={isMobile ? "100%" : "220px"} inputValue={endAddress} onChangeInput={onChangeEndAddress} readOnly={!selectedFields.endAddress}/>
        </div>

        <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />

        {/* 가는일시 섹션 */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: isMobile ? 'auto' : "160px",
            gap: "10px",
            alignItems: isMobile ? 'center' : 'flex-start',
            justifyContent: isMobile ? 'center' : 'flex-start',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
            <Typography variant='h6'
            sx={{
              textDecoration:!selectedFields.goTime?"line-through":null,color:!selectedFields.goTime?"#C5C3C3":"black"
            }}
            >일정</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFields.goTime}
                  onChange={handleCheckboxChange}
                  name="goTime"
                />
              }
              label=""
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: 'row',
              gap: isMobile ? "2px" : "10px",
              width: "100%"
            }}
          >
            <TextField
            disabled={!selectedFields.goTime}
              type='date'
              size='small'
              value={inputFormatDate(searchGoTime)}
              onChange={onChangeGoTime}
              sx={{ width: isMobile ? '160px' : 'auto' }}
              InputProps={{
                style: {
                  fontSize: isMobile ? "12px" : "auto",
                  height: '35.57px',
                }
              }}
            />
           
          </div>
        </div>
      </CardContent>

      <Flex />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? 'center' : 'flex-end'
        }}
      >
        <Button
          variant='contained'
          sx={{ ...searchBtnSx, width: isMobile ? '100%' : 'auto' }}
          onClick={onSearch}
        >
          조회하기
        </Button>
      </div>
    </Card>
  );
}

export default TaxiSearchCard;
