import { Typography } from '@mui/material';
import React from 'react';
import { mdWidth } from '../../const/const';
import FavoriteBtn from '../Btn/FavoriteBtn';
import { useNavigate, useParams } from 'react-router-dom';
import FilledDeliveryBtn from '../Btn/FilledDelivertBtn';
import FilledTaxiBtn from '../Btn/FilledTaxiBtn';
import FilledCommunityBtn from '../Btn/FilledCommunityBtn';
import Flex from '../Flex/Flex';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../service/firebase';
import { loginDialogState } from '../../atom/atom';
import { useRecoilState } from 'recoil';

function FavoriteBar() {
    const params=useParams()
    const navigate=useNavigate()
    const [loginDialog,setLoginDialog]=useRecoilState(loginDialogState)
   
    const onFavorite = async () => {
        const userId= sessionStorage.getItem("userId");
        const fullAddress = params.fullAddress;
        const si = params.si;
        const dong = params.dong;
      
        try {
          // Firestore에서 이미 즐겨찾기에 같은 fullAddress가 있는지 확인
        if (userId) {
           const q = query(
            collection(db, 'favorites'),
            where('userId', '==', userId),
            where('fullAddress', '==', fullAddress)
          );
      
          const querySnapshot = await getDocs(q);
      
          if (querySnapshot.empty) {
            // 해당 fullAddress가 즐겨찾기에 없을 경우 추가
            await addDoc(collection(db, 'favorites'), {
              fullAddress: fullAddress,
              si: si,
              dong: dong,
              userId: userId,
            });
            window.confirm('즐겨찾기에 추가되었습니다.');
          } else {
            window.confirm('이미 즐겨찾기에 추가된 항목입니다.');
          }
        }
        else{
          setLoginDialog(true)
        }
         
        } catch (e) {
          console.error("Error adding document: ", e);
          alert('즐겨찾기 추가 중 오류가 발생했습니다.');
        }
      };
    return (
        <div
        style={{
            width:mdWidth,
            minWidth:"900px",
            display:"flex",flexDirection:"row",
            alignItems:"center",
            gap:"30px",
            height:"50px",
            marginBottom:"30px",
            borderBottom:"1px solid #A5B2BA"
        }}
        >
            <FavoriteBtn
            onClick={onFavorite}
            />
            <Typography
            variant='h6'
            fontWeight={600}
            >{params.fullAddress}</Typography>
           <Flex/>
           <div
           style={{
            display:"flex",
            gap:"3px"
           }}
           >  <FilledDeliveryBtn onClick={()=>{

navigate(`/${params.fullAddress}/${params.si}/${params.dong}/delivery-table/${params.postType}/1`)
            }}/>
            <FilledTaxiBtn onClick={()=>{
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/taxi-table/${params.postType}/1`)
            }}/>
            <FilledCommunityBtn onClick={()=>{
  navigate(`/${params.fullAddress}/${params.si}/${params.dong}/community-table/${params.postType}/1`)

            }}/></div>
          
            </div>
    );
}

export default FavoriteBar;