import { Box, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';

function TitleWithSelect({value,onChange,type,placeholder,title,width,menus,selectMenu}) {
    return (
        <Box
         sx={{
            display:"flex",
            width:width,
            borderRadius:"8px",
            border:"2px solid #104A62",
        }}
        > 
        <div
        style={{
            width:"160px",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            fontSize:"16px",
            borderRight:"2px solid #104A62"
        }}
        >카테고리</div>
        
        <Select
        value={selectMenu}
        onChange={onChange}
        variant='standard'
           sx={{
            marginLeft:"20px",
            width: "100%",
            height:"46px",
        
            '& .MuiOutlinedInput-root': { // outlined 스타일을 override 하기 위한 CSS
                
              border: 'none',
              '&:hover': {
                border: 'none',
              },
              '&.Mui-focused': {
                border: 'none',
              },
            },
          }}
       inputProps={{
        style:{
            height:"40px",
            
        }
       }}
        >
           {menus.map((menu,idx)=><MenuItem
 value={idx}          
           >{menu}</MenuItem>) }
        </Select>
        </Box>
       
    );
}

export default TitleWithSelect;