import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { goBtnActiveSx, goBtnSx } from '../../const/BtnStyle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function GoDeliveryPotBtn({active,width=null,style}) {
    const isMobile = useMediaQuery('(max-width:767px)');
    const navigate=useNavigate()
    const params=useParams()
  const location=useLocation()
//   console.log("경로>>>",location.pathname)
    return (
        <Button
        variant='outlined'
        size='small'
        sx={{width:"160px",
            height:"40px",
            color: "white",
            fontSize: isMobile?"14px":"16px",
            backgroundColor:  "white" , // 선택됐을 때 배경색 변경
            color:"#104A6E"  ,
            border:"1px solid #104A6E",
            ":hover": {
                backgroundColor: "#104A6E",
                color: "white",
            }
        }}
        onClick={()=>{
            navigate(`/${params.fullAddress}/${params.si}/${params.dong}/delivery-table/meeting-place/1`);
        }}
        >
            배달팟
        </Button>
    );
}

export default GoDeliveryPotBtn;