import React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, InputBase, Menu, MenuItem, Divider } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MainLogo from '../images/main-logo.png';
import { appbarColor } from '../const/const';
import { useRecoilState } from 'recoil';
import { loginDialogState, nowAreaState, openAreaDialogState } from '../atom/atom';
import { CiSearch } from 'react-icons/ci';
import { IoIosNotifications, IoMenu, IoPerson } from 'react-icons/io5';
import IconBtn from './IconBtn';
import TxtBtn from './Btn/TxtBtn';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function CustomSmAppbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
  const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
  const [openAreaDialog, setOpenDialog] = useRecoilState(openAreaDialogState);
  const [nowUser, setNowUser] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    if (!loginDialog) {
      const user = sessionStorage.getItem("userId");
      setNowUser(user);
    }
  }, [loginDialog]);

  const openDialog = () => setOpenDialog(true);
  
  const goNotiPage = () => {navigate(`/${params.fullAddress}/${params.si}/${params.dong}/noti-page`)
  handleMenuClose()};
  const goFavPg = () => {navigate(`/${params.fullAddress}/${params.si}/${params.dong}/fav-page`)
  handleMenuClose()};
  const goInquiryPage = () => {navigate(`/${params.fullAddress}/${params.si}/${params.dong}/inquiry-page`)
  handleMenuClose()};
  const goMembershipPage = () => {
    if (!nowUser) {
      setLoginDialog(true);
    } else {
      navigate(`/${nowAddress.fullAddress}/membership-page`, {
        state: { userEmail: nowUser }
      });
    }
    handleMenuClose()
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: appbarColor }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => navigate(`/${params.fullAddress}/${params.si}/${params.dong}`)}>
            <img src={MainLogo} width={"35px"} alt="Main Logo" />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <CiSearch />
            </SearchIconWrapper>
            <StyledInputBase onClick={openDialog} placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
          </Search>
        </Box>
        <IconButton color="inherit" onClick={handleMenuOpen}>
          <IoMenu size={24} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: '100%',
              left: '0 !important',
              top: '64px !important', // adjust this value based on the height of your AppBar
            }
          }}
        >
          <MenuItem onClick={goFavPg}>
            <TxtBtn title="즐겨찾기" color='black' />
          </MenuItem>
          <Divider />
          <MenuItem onClick={goNotiPage}>
            <TxtBtn title="공지사항" color='black' />
          </MenuItem>
          <Divider />
          <MenuItem onClick={goInquiryPage}>
            <TxtBtn title="문의사항" color='black' />
          </MenuItem>
          {/* <Divider /> */}
          {/* <MenuItem onClick={handleMenuClose}>
            <TxtBtn title="알림" color='black' />
          </MenuItem> */}
          <Divider />
          <MenuItem onClick={goMembershipPage}>
            <TxtBtn title="마이페이지" color='black' />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
