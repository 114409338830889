import React, { useEffect, useState } from 'react';
import { lgFontSize } from '../const/const';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import GreyBtn from './Btn/GreyBtn';
import MembershipInfoEditDialog from './Dialog/MembershipInfoEditDialog';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import { db } from '../service/firebase'
function MembershipInfo({
 }) {
        const nowUserEmail=sessionStorage.getItem("userId")
        const nowUserPw=sessionStorage.getItem("userPw")
        const nowUserNickname=sessionStorage.getItem("userNickname")==="undefined"?"-":sessionStorage.getItem("userNickname")
        const [nickname,setNickname]=useState("")
        const [email,setEmail]=useState("")
        const [infoEditOpen,setInfoEditOpen]=useState(false)
        const navigate=useNavigate()
        const onOpenEdit=()=>{
            setInfoEditOpen(true)
        }
        const onCloseEdit=()=>{
            setInfoEditOpen(false)
        }
        const onChangeNickname=(e)=>{
            setNickname(e.target.value)
        }
        const onChangeEmail=(e)=>{
            setEmail(e.target.value)
        }
        const onSaveEditInfo = async () => {
            const docId = sessionStorage.getItem("userUuid");
            // 사용자 문서 가져오기
            const userDocRef = doc(db, "users", docId);
            const userDocSnap = await getDoc(userDocRef);
        
            if (userDocSnap.exists()) {
                try {
                    // 새로운 닉네임과 이메일 주소 설정
                    const newData = {
                        nickname: nickname,
                        email: email
                    };
        
                    // 기존 데이터 유지하면서 업데이트
                    sessionStorage.setItem("userId",email)
sessionStorage.setItem("userNickname",nickname)
                    await setDoc(userDocRef, { ...userDocSnap.data(), ...newData });
        
                    // 성공적으로 업데이트된 경우 상태 업데이트
                    setInfoEditOpen(false);
                } catch (error) {
                    console.error("Error updating document: ", error);
                }
            } else {
                console.error("No such document!");
            }
        };
        
        useEffect(()=>{

            setNickname(nowUserNickname)
            setEmail(nowUserEmail)
        },[])
        const onLogout=()=>{
            sessionStorage.setItem("userNickname","") 
            sessionStorage.setItem("userId","") 
            sessionStorage.setItem("userPw","") 
            sessionStorage.setItem("userUuid","") 
            sessionStorage.setItem("login","")
            navigate("/")
        }
    return (
        <div
style={{
    display:"flex",
    flexDirection:"column"
}}
><div
style={{
    fontSize:lgFontSize,
    fontWeight:700
}}
>회원 정보</div>
<div style={{
    display:"flex",
    flexDirection:"row",}}
>

    <div
    style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-end",
        width:"150px"
    }}
    >닉네임</div>
     <div
    style={{
        width:"30px"
    }}
    />
    <div>{nickname}</div>
</div>
<div style={{
    display:"flex",
    flexDirection:"row",}}
>

    <div
    style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-end",
        width:"150px"
    }}
    >이메일 주소</div>
     <div
    style={{
        width:"30px"
    }}
    />
    <div>{email}</div>
</div>
<div
style={{
    height:"300px"
}}
/>
<div

style={{
    display:"flex",
    flexDirection:"row",
    gap:"30px"
}}
>
<GreyBtn
title={"회원정보 변경"}
onClick={onOpenEdit}
/>
<MembershipInfoEditDialog 
onChangeNickname={onChangeNickname}
onChangeEmail={onChangeEmail}
open={infoEditOpen} 
onClose={onCloseEdit}
nickname={nickname}
email={email}
onSaveComplete={onSaveEditInfo}
/>
<GreyBtn
title={"로그아웃"}
onClick={onLogout}
/>
{/* <GreyBtn
title={"이메일 주소 변경"}
/>
<GreyBtn
title={"탈퇴"}
/> */}
</div>
</div>

    );
}

export default MembershipInfo;