import React, { useEffect, useState } from 'react';
import { lgFontSize } from '../const/const';
import { db } from '../service/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Table, TableCell, TableContainer, TableRow } from '@mui/material';

function MembershipReplies({nowUserNickname,
    nowUserEmail}) {
        const [repliesPerUser,setRepliesPerUser]=useState([])
        const postsCol = collection(db, "posts")
        const inquiryCol = collection(db, "inquiry")
        const fetchPosts = async () => {
            try {
              const querySnapshot = await getDocs(postsCol);
              const posts = [];
              querySnapshot.forEach((doc) => {
                // 각 문서에서 데이터를 가져와서 배열에 추가
                posts.push(doc.data());
              });
              return posts;
            } catch (error) {
              console.error("Error fetching posts: ", error);
              return []; // 에러 발생 시 빈 배열 반환
            }
          };
          const fetchInquiry = async () => {
            try {
              const querySnapshot = await getDocs(inquiryCol);
              const posts = [];
              querySnapshot.forEach((doc) => {
                // 각 문서에서 데이터를 가져와서 배열에 추가
                posts.push(doc.data());
              });
              return posts;
            } catch (error) {
              console.error("Error fetching posts: ", error);
              return []; // 에러 발생 시 빈 배열 반환
            }
          };
          const fetchAll=async()=>{
                 // 로그인 됐으면, 오너 유저아이디나 아이디, 비번 매칭해서 글 가져오기
                 const nowUserId=sessionStorage.getItem("userId");
                 const nowUserUuid=sessionStorage.getItem("userUuid");
                 const nowUserPw=sessionStorage.getItem("userPw");
                 // const login=sessionStorage.getItem("login")
            const res=await fetchPosts()
            const inquiryRes=await fetchInquiry()
            //게시물에서 댓글 가져오기
const postsFiltered=[];
for (let i = 0; i < res.length; i++) {
    const replies =!res[i].replies?[]: res[i].replies;

    for (let j = 0; j < replies.length; j++) {
        const reply = replies[j];
        if (reply.ownerUserId===nowUserUuid||reply.registerEmail===nowUserId) {
            postsFiltered.push(reply)
        }
    }
    
}
const inquiryFiltered=[];
for (let i = 0; i < inquiryRes.length; i++) {
    const replies = inquiryRes[i].replies;
    for (let j = 0; j < replies.length; j++) {
        const reply = replies[j];
        if (reply.ownerUserId===nowUserUuid||reply.registerEmail===nowUserId) {
            inquiryFiltered.push(reply)
        }
    }
    
}
setRepliesPerUser([...postsFiltered,...inquiryFiltered])
          }
        useEffect(()=>{
       fetchAll()

        },[])
    return (
        <div
style={{
    display:"flex",
    flexDirection:"column",
    overflowY:"scroll",
    height:"calc(20px * 20)"
}}
><div
style={{
    fontSize:lgFontSize,
    fontWeight:700,
    position:"fixed",
    backgroundColor:"white",
    width:"50%"
}}
>작성 댓글</div>
<Table
sx={{
 marginTop:"30px"
}}
>
    {repliesPerUser.map(p=><TableRow
    sx={{
        width:"100%",
        height:"20px"
    }}
    // style={{
    //     display:"flex",
    //     flexDirection:"row",
    //     height:"30px",
    //     marginTop:8,
    //     backgroundColor:"#d9d9d9",
    //     // gap:"8px"
    //     // justifyContent:"space-between"
    // }}
    >
        <TableCell
        style={{
            width:"100px",
            height:"20px"
        }}
        >{p.registerDate===""||p.registerDate===undefined?"-":p.registerDate}</TableCell>
        <TableCell
        style={{
            width:"100px"
        }}
        >{p.registerEmail===""||p.registerEmail===undefined?"-":p.registerEmail}</TableCell>
        <TableCell
        style={{
            width:"100px"
        }}
        >{p.reply===""||p.reply===undefined?"-":p.reply}</TableCell>
      
    </TableRow>)}
</Table>
</div>




    );
}

export default MembershipReplies;