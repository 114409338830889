import React, { useState } from 'react';
import PageLayout from '../Layouts/PageLayout';
import { lgFontSize, mdWidth, smWidth, pcEmpty } from '../const/const';
import { IoPerson } from 'react-icons/io5';
import BorderBtn from './BorderBtn';
import MembershipBorderBtn from '../components/MembershipBorderBtn';
import HorizontalDivider from '../components/HorizontalDivider';
import MembershipInfo from '../components/MembershipInfo';
import MembershipPosts from '../components/MembershipPosts';
import MembershipReplies from '../components/MembershipReplies';
import { Mobile, PC } from '../ReactiveLayout';

function MembershipPage(props) {
  const [toggle, setToggle] = useState("0");

  const getContent = (toggle) => {
    if (toggle === "0") {
      return <MembershipInfo />;
    } else if (toggle === "1") {
      return <MembershipPosts />;
    } else {
      return <MembershipReplies />;
    }
  };

  const onChangeToggle = (e) => {
    setToggle(e.target.id);
  };

  return (
    <PageLayout>
      {/* PC UI */}
      <PC>
        <div
          style={{
            padding: "32px",
            backgroundColor: "#F1EFEF",
            width: "calc(100% - 64px)",
            minHeight: "600px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center"
            }}
          >
            <IoPerson size={20} color='#919090' />
            <span
              style={{
                fontSize: lgFontSize,
                fontWeight: 700
              }}
            >Membership</span>
          </div>
          <div
            style={{
              backgroundColor: "white",
              height: "calc(100% - 64px - 32px)",
              padding: "16px", marginTop: "32px", marginBottom: "32px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "16px",
                paddingTop: "16px",
                paddingBottom: "16px"
              }}
            >
              <MembershipBorderBtn
                type={"0"}
                title={"회원정보 보기"}
                onClick={onChangeToggle}
              />
              {/* <MembershipBorderBtn
                type={"1"}
                title={"작성 글 보기"}
                onClick={onChangeToggle}
              />
              <MembershipBorderBtn
                type={"2"}
                title={"작성 댓글 보기"}
                onClick={onChangeToggle}
              /> */}
            </div>
            <HorizontalDivider />
            {/* 토글에 따라 내용 변경 */}
            {getContent(toggle)}
          </div>
        </div>
      </PC>

      {/* Mobile UI */}
      <Mobile>
        <div
          style={{
            padding: "16px",
            backgroundColor: "#F1EFEF",
            width: "calc(100% - 32px)",
            minHeight: "600px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center"
            }}
          >
            <IoPerson size={20} color='#919090' />
            <span
              style={{
                fontSize: lgFontSize,
                fontWeight: 700
              }}
            >Membership</span>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "16px", marginTop: "16px", marginBottom: "16px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "8px",
                paddingTop: "8px",
                paddingBottom: "8px"
              }}
            >
              <MembershipBorderBtn
                type={"0"}
                title={"회원정보 보기"}
                onClick={onChangeToggle}
              />
              {/* <MembershipBorderBtn
                type={"1"}
                title={"작성 글 보기"}
                onClick={onChangeToggle}
              />
              <MembershipBorderBtn
                type={"2"}
                title={"작성 댓글 보기"}
                onClick={onChangeToggle}
              /> */}
            </div>
            <HorizontalDivider />
            {/* 토글에 따라 내용 변경 */}
            {getContent(toggle)}
          </div>
        </div>
      </Mobile>
    </PageLayout>
  );
}

export default MembershipPage;
