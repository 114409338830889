import { Box, TextField } from '@mui/material';
import React from 'react';
import { FaSearch } from 'react-icons/fa';

function NoIconSearchBar({
    readOnly=false,
    width,
    height,
    inputValue,
    onChangeInput,
    onSearch,}) {
    return (
        <Box
        sx={{
           overflow:"hidden",
           display:"flex",
           flexDirection:"row",
           border:"2px solid #002D82",
           width:width??"320px",
           height:height??"35.57px",
           boxSizing:"content-box",
           margin:0,padding:0,
           borderRadius:"4px"
        }}
        >
           <TextField
           disabled={readOnly}
           placeholder='검색어를 입력하세요'
           sx={{
               padding:0,
               margin:0,
               width:"100%",
        
           }}
           value={inputValue}
           onChange={onChangeInput}
               
           variant='standard'
           />   
        </Box>
    );
}

export default NoIconSearchBar;