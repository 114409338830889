import { TextField } from '@mui/material';
import React from 'react';

function WriteTxtfield({value,onChange,type,placeholder,width,rows=1}) {
    return (
        <TextField
        // type={type}
        autoComplete='new-password'
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        multiline={rows>1}
        rows={rows} // 초기 표시할 줄 수
        sx={{
         
            width:width??"100%",
            borderRadius:"8px",
            border:"2px solid #104A62",
        }}
      
        />
    );
}

export default WriteTxtfield;