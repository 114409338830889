import React, { useEffect } from 'react';
import { FaPenAlt } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import { nowAreaState, nowPotNameState } from '../atom/atom';
import { useRecoilState } from 'recoil';
function WriteBtn({width=null,pageName,fontSize}) {
    const navigate=useNavigate()
    // const [nowPotName, setNowPotName] = useRecoilState(nowPotNameState);
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    const location=useLocation()
//     useEffect(()=>{
// // const initAddress={fullAddress:"",si:"",dong:""}
// //         setNowAddress(location.state.nowAddress===null?initAddress:location.state.nowAddress)
//         setNowPotName(nowPotName)
//     },[])
    const goWrite=()=>{
        navigate(`/${nowAddress.fullAddress}/type-select-${pageName}`,{
            state:{
                nowAddress:location.state.nowAddress,
                // nowPotName:nowPotName,
                // postType:location.state.postType,
                ...location.state
            }
        })
    }
    return (
        <button
        style={{
            
            width:width,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            gap:"3px",
            cursor:"pointer",
            border:"2px solid #104A62",
            borderRadius:"6px",
            backgroundColor:"white"
        }}
        onClick={(e)=>{
            goWrite(e)
        
        }}
        >
            <FaPenAlt/>
          <span
          style={{
            fontSize:fontSize,
          }}
          >글쓰기</span>  
        </button>
    );
}

export default WriteBtn;