import { Button } from '@mui/material';
import React from 'react';

function ColorToggle({title,active,onClick}) {
    const activeSx={
        fontSize:"16px",
        backgroundColor:"#114962",
        color:"white",
        ":hover":{
            fontSize:"16px",
            backgroundColor:"#114962",
            color:"white",  
        }
    }
    const inActiveSx={
        fontSize:"16px",
        border:"1px solid #114962",
        color:"#114962",
        ":hover":{
            fontSize:"16px",
            border:"1px solid #114962",
            color:"#114962",  
        }
    }
    const getBtnName=(title)=>{
        if (title==="같이주문") {
            return "같이시켜"
        } else if(title==="나눠요") {
            return "하나시켜 나눠먹어요"
        }else{return ""}
    }
    return (
        <Button
        id={title}
        // size='small'
        sx={active?activeSx:inActiveSx}
        onClick={onClick}
        >{getBtnName(title)}</Button>
    );
}

export default ColorToggle;