import React, { useEffect, useState } from 'react';
import { Mobile, PC } from '../ReactiveLayout';
import ResArea from '../components/ResArea';
import BlueRow from '../components/BlueRow';
import DeliveryBorderTable from '../components/DeliveryBorderTable';
import ReplyTitle from '../components/ReplyTitle';
import Reply from '../components/Reply';
import ReplyWriteBox from '../components/ReplyWriteBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { db } from '../service/firebase';
import { extractLinkFromString, mdWidth, pcEmpty, smWidth } from '../const/const';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { loginDialogState, nowAreaState } from '../atom/atom';
import { useRecoilState } from 'recoil';
import GoLoginDialog from '../components/Dialog/GoLoginDialog';
import PcResArea from '../components/Bar/PcResArea';
import HorizontalDivider from '../components/HorizontalDivider';
import ViewWriteTitleCard from '../components/Card/ViewWriteTitleCard';
import { Card, CardContent, Typography } from '@mui/material';
import moment from 'moment';
import MbResArea from '../components/Bar/MbResArea';

function ReplyDeliveryPage(props) {
    const location = useLocation();
    const docId = sessionStorage.getItem("editRowId");
    const ownerUserId = sessionStorage.getItem("userUuid");
    const [newWrite, setNewWrite] = useState([]);
    const [reply, setReply] = useState("");
    const [allReplies, setAllReplies] = useState([]);
    const [goLoginDialog, setGoLoginDialog] = useState(false);
    const [loginDialog, setLoginDialog] = useRecoilState(loginDialogState);
    const newViewWrite = JSON.parse(sessionStorage.getItem("viewWrite"));
    const [viewWrite, setViewWrite] = useState(newViewWrite);
    const [nowAddress, setNowAddress] = useRecoilState(nowAreaState);
    
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setViewWrite(newViewWrite);
        fetchR();
    }, []);

    const fetchRepliesPerDocId = async () => {
        try {
            const docRef = doc(db, "posts", viewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                setNewWrite(userData);
                const replies = userData["replies"] || [];
                return replies;
            } else {
                console.log("Document not found!");
                return [];
            }
        } catch (error) {
            console.error("Error fetching favorites: ", error);
            return [];
        }
    };

    const onRegisterReply = async (newReply,registerEmail,pw) => {
        try {
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                const formattedregDate = moment().format('YYYY.MM.DD HH:mm:ss');
                const login=sessionStorage.getItem("login")
                const newReplyObj = {
                    reply: newReply,
                    ownerUserId: ownerUserId,
                    registerEmail: registerEmail,
                    pw:pw,
                    registerDate: formattedregDate,
                    isLogin:login==="success"?true:false
                };
console.log(newReplyObj)
                if (newReply.trim() !== "") {
                    const updatedReplies = [...replies, newReplyObj];
                    await updateDoc(docRef, { replies: updatedReplies });
                    console.log("replies updated successfully!");
                    window.confirm("댓글이 업데이트 되었습니다.");
                    setReply("");
                    const allReplies = await fetchRepliesPerDocId();
                    setAllReplies(allReplies);
                }
            }
        } catch (error) {
            console.error("Error updating favorites: ", error);
        }
    };

    const onDeleteReply = async (replyIndex) => {
        try {
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                const updatedReplies = replies.filter((_, index) => index !== replyIndex);

                await updateDoc(docRef, { replies: updatedReplies });
                console.log("reply deleted successfully!");
                window.confirm("댓글이 삭제되었습니다.");
                const allReplies = await fetchRepliesPerDocId();
                setAllReplies(allReplies);
            }
        } catch (error) {
            console.error("Error deleting reply: ", error);
        }
    };

    const onUpdateReply = async (replyIndex, updatedReply) => {
        try {
            const docRef = doc(db, "posts", newViewWrite.docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const postsData = docSnap.data();
                const replies = postsData["replies"] || [];
                replies[replyIndex].reply = updatedReply;

                await updateDoc(docRef, { replies });
                console.log("reply updated successfully!");
                window.confirm("댓글이 수정되었습니다.");
                const allReplies = await fetchRepliesPerDocId();
                setAllReplies(allReplies);
            }
        } catch (error) {
            console.error("Error updating reply: ", error);
        }
    };

    const fetchR = async () => {
        const allReplies = await fetchRepliesPerDocId();
        setAllReplies(allReplies);
    };

    const goEditPage = () => {
        const loginStatus = sessionStorage.getItem("login");
        if (loginStatus === "success") {
            navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/edit/${viewWrite.docId}`);
        } else {
            setGoLoginDialog(true);
        }
    };

    const onDeleteDoc = async () => {
        try {
            const loginStatus = sessionStorage.getItem("login");
            if (loginStatus === "success") {
                await deleteDoc(doc(db, "posts", viewWrite.docId));
                navigate(`/${params.fullAddress}/${params.si}/${params.dong}/${params.tableType}/${params.postType}/1`);
                window.confirm("삭제 성공");
            } else {
                setGoLoginDialog(true);
            }
        } catch (error) {
            window.confirm("삭제 실패", error);
        }
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        spacing: {
            height: '17px'
        },
        divider: {
            width: smWidth,
            backgroundColor: '#D9D9D9'
        },
        list: {
            width: '90%',
            // height: `calc(50px * ${allReplies.length})`,
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            gap: '22px',
            alignItems: 'center',
            justifyContent: 'flex-start',
        }
    };
console.log("delivery 글>>>", viewWrite)
    return (
        <div style={styles.container}>
            <GoLoginDialog
                goLoginDialog={goLoginDialog}
                setGoLoginDialog={setGoLoginDialog}
                setLoginDialog={setLoginDialog}
            />
            <Mobile>
                <MbResArea/>
                <ViewWriteTitleCard
                    width='calc(100% - 32px)'
                    chipTxt={viewWrite.foodCategory??""}
                    title={`${viewWrite.title??"-"} ${viewWrite.registerDate}`}
                />
                <DeliveryBorderTable
                    minWidth='280px'
                    orderTime={viewWrite.registerDate??""}
                    foodCategory={viewWrite.foodCategory??""}
                    detailAddress={viewWrite.detailAddress??""}
                    chatLink={viewWrite.chatLink??""}
                />
                  <div
                style={{
                    width:'calc(100% - 32px)',
                   display:"flex",
                flexDirection:"row",
                alignItems:"flex-start",
                justifyContent:"flex-start",
                    marginTop:"20px",
                    marginBottom:"30px"
                }}

                >
                     <Typography
                 variant={'h9'}>
                    {viewWrite.contents}
                 </Typography>
                            
                        </div>
                <div style={styles.spacing} />
                <ReplyTitle
                    minWidth='266px'
                    length={allReplies.length}
                    onDeleteDoc={onDeleteDoc}
                    goEditPage={goEditPage}
                />
                <div style={styles.spacing} />
                <ReplyWriteBox
                    reply={reply}
                    onChangeReply={(e) => setReply(e.target.value)}
                    onRegisterReply={onRegisterReply}
                />
                <ul style={styles.list}>
                    {allReplies.map((r, index) => (
                        <li key={index} style={{ width: "100%", display: "flex", gap: "22px", alignItems: "center", justifyContent: "flex-start" }}>
                            <Reply
                              yesEdit={r.registerEmail
                                === sessionStorage.userId}
                                pw={r.pw}
                                email={r.registerEmail}
                                registerDate={r.registerDate}
                                reply={r.reply}
                                onDeleteReply={() => onDeleteReply(index)}
                                onUpdateReply={(updatedReply) => onUpdateReply(index, updatedReply)}
                            />
                        </li>
                    ))}
                </ul>
                <div style={styles.spacing} />
                <div style={styles.spacing} />
            </Mobile>
            <PC>
                <div style={{ height: pcEmpty }} />
                <PcResArea />
                <HorizontalDivider width={mdWidth} />
                {viewWrite && (
                    <ViewWriteTitleCard
                        chipTxt={viewWrite.foodCategory}
                        title={`${viewWrite.title??"-"} ${viewWrite.registerDate}`}
                    />
                )}
                <div style={{ width: mdWidth, display: "flex", gap: "22px", alignItems: "center", justifyContent: "flex-start" }}>
                    {viewWrite && (
                        <DeliveryBorderTable
                            maxWidth={"500px"}
                            orderTime={viewWrite.registerDate}
                            foodCategory={viewWrite.foodCategory}
                            detailAddress={viewWrite.detailAddress}
                            chatLink={viewWrite.chatLink}
                        />
                    )}
                       
                </div> 
                <div
                style={{
                    width:mdWidth,
                   display:"flex",
                flexDirection:"row",
                alignItems:"flex-start",
                justifyContent:"flex-start",
                    marginTop:"20px",
                    marginBottom:"30px"
                }}

                >
                     <Typography
                 variant={'h6'}>
                    {viewWrite.contents}
                 </Typography>
                            
                        </div>
                <Card variant='outlined' sx={{ width: mdWidth }}>
                    <CardContent>
                        <div style={{ width: "100%", display: "flex", gap: "22px", alignItems: "center", justifyContent: "flex-start" }}>
                            <ReplyTitle
                                emailToReport={newWrite.id}
                                yesEdit={newWrite.ownerUserId === sessionStorage.userUuid}
                                length={allReplies.length}
                                onDeleteDoc={onDeleteDoc}
                                goEditPage={goEditPage}
                            />
                        </div>
                        <div style={{ height: "16px" }} />
                        <ReplyWriteBox
                            reply={reply}
                            onChangeReply={(e) => setReply(e.target.value)}
                            onRegisterReply={onRegisterReply}
                        />
                        <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "22px",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}>
                            {allReplies.map((r, index) => (
                                <li key={index} style={{ width: "100%", display: "flex", gap: "22px", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Reply
                                    yesEdit={r.registerEmail
                                        === sessionStorage.userId}
                                        pw={r.pw}
                                        email={r.registerEmail}
                                        registerDate={r.registerDate}
                                        reply={r.reply}
                                        onDeleteReply={() => onDeleteReply(index)}
                                        onUpdateReply={(updatedReply) => onUpdateReply(index, updatedReply)}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div style={{ height: "17px" }} />
                    </CardContent>
                </Card>
            </PC>
        </div>
    );
}

export default ReplyDeliveryPage;
